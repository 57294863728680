import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import ResourceDAO from 'core-uda/model/ResourceDAO'
import { APP } from 'core-uda/model/Resource'
import { RIGHTS } from 'core-uda/Rights'
import { UDA_URL } from 'core-uda/model/ResourceDAO'
import InspectionReport from 'thm/model/inspectionReport/InspectionReport'
import { RESOURCE_CLASS } from 'core-uda/model/Resource'

/**
 * Report DAO
 */

let InspectionReportDAO = ResourceDAO.extend({
  resource: 'inspectionReport',
  resourceClass: InspectionReport,
  findAllParams: {
    fields: 'all',
  },
  customMethods: ['openPdfLink'],

  openPdfLink: function (id) {
    let me = this,
      tabLink = window.document.createElement('a'),
      href =
        UDA_URL +
        'inspectionReport/' +
        id +
        '/download?application=umv&jwt=' +
        APP().user.token
    tabLink.href = href
    tabLink.target = '_blank'
    document.body.appendChild(tabLink)
    tabLink.click()
    document.body.removeChild(tabLink)
  },
})

// Instance unique
let INSPECTION_REPORT_DAO = new InspectionReportDAO()

export { INSPECTION_REPORT_DAO }
export default InspectionReportDAO
