import _ from 'core-cmp/lodash'
import CDataUI from 'core-cdata/ui/CDataUI'
import { THM_ALERT_DEFINITION_UI } from 'thm/ui/common/alertDefinition/ThmAlertDefinitionUI'
import CollectedDataTimelineTplStache from 'thm/ui/common/collectedData/CollectedDataTimelineTpl.stache'

/**
 * User Interface elements for ThmCollectedData
 */
let ThmCollectedDataUI = CDataUI.extend({
  i18nPrefix: '',
  cdStatesForTimeline: {},
  updateTimelineOnCDLoaded: true,

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      i18nPrefix: 'thm.ui.common.collectedData.CollectedDataUI.',
      cdStatesForTimeline: {},
    })
  },

  /**
   * @override
   */
  getContextMenuActions: function (context) {
    let me = this
    return me
      ._super(context)
      .concat(
        THM_ALERT_DEFINITION_UI.getContextMenuActionsForType(
          'trajectories',
          context.data.mobile.customerId,
          context,
        ),
      )
  },

  getCDListForTimeline: function () {
    let cdDatas = APP('collectedData').getData(),
      capabilitiiesCDFields = APP().getCapabilities('collectedData').fields,
      fieldsPropertyList = Object.keys(capabilitiiesCDFields),
      list = []

    cdDatas.sort(function (a, b) {
      if (a.activityDateMillis > b.activityDateMillis) {
        return 1
      }
      return -1
    })

    _.forEach(cdDatas, function (cd) {
      let newCd = _.omit(cd, ['mobile', 'mapXY', 'index', 'feature']),
        sensorFields = _.difference(Object.keys(newCd), fieldsPropertyList),
        indexOfCd,
        data

      _.forEach(sensorFields, function (s) {
        indexOfCd = _.findIndex(list, { id: s })
        data = [
          cd['activityDateMillis'],
          Number(cd[s]),
          cd.mobileId,
          cd['mapXY'],
        ]

        if (indexOfCd === -1) {
          list.push({
            id: s,
            data: [data],
          })
        } else {
          list[indexOfCd].data.push(data)
        }
      })
    })

    list.sort(function (a, b) {
      if (a.id.toLowerCase() > b.id.toLowerCase()) {
        return 1
      }
      return -1
    })

    return list
  },

  setCDStatesForTimeline: function (cdStates) {
    let me = this

    me.cdStatesForTimeline = cdStates
  },

  getCDStatesForTimeline: function () {
    let me = this

    return me.cdStatesForTimeline
  },

  getCollectedDataTplTimeline: function () {
    return CollectedDataTimelineTplStache
  },
})

const THM_COLLECTED_DATA_UI = new ThmCollectedDataUI()

export default ThmCollectedDataUI
