import ResourceUI from 'core-uda/ui/ResourceUI'
import PeriodicPolling from 'core-uda/model/periodicPolling/PeriodicPolling'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'

let PeriodicPollingUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: PeriodicPolling.itemId,
      icon: 'command-time',
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      channel: I18N_FIELD('uda.periodicPolling.field.channel.options.'),
    }
  },
})

const PERIODIC_POLLING_UI = new PeriodicPollingUI()

export default PeriodicPollingUI
