import { APP } from 'core-uda/model/Resource'
import { RIGHTS, RAW_RIGHTS } from 'core-uda/Rights'
import InfoPanel from 'core-uda/ui/InfoPanel'
import CustomerInfoPanelTplStache from 'thm/ui/common/customer/CustomerInfoPanelTpl.stache'
import ApplicationDomain from 'core-uda/model/applicationDomain/ApplicationDomain'
import SynchronizedSource from 'core-uda/model/customer/SynchronizedSource'
import Parameter from 'core-uda/model/parameter/Parameter'
import { UTILS } from 'core-cmp/Utils'
import _ from 'core-cmp/lodash'
import SourceTplStache from 'thm/ui/common/customer/SourceTpl.stache'
// import EditRestrictionPopup from "thm/ui/common/customer/EditRestrictionPopup";
import EditSourceCustomerPopup from 'thm/ui/common/customer/EditSourceCustomerPopup'
// import RestrictionTplStache from "thm/ui/common/customer/RestrictionTpl.stache";
import ParametersCustomerTabStache from 'thm/ui/common/customer/ParametersCustomerTab.stache'
import ParametersConstructDatas from 'thm/ui/common/customer/ParametersConstructDatas'
import SynchronizedSourceTplStache from 'thm/ui/common/customer/SynchronizedSourceTpl.stache'
import SourcesTemplate from 'thm/ui/common/sources/PositionSourceTpl.stache'
import 'thm/ui/common/customer/Customer.scss'
import { canMap } from 'core-cmp/can'
import Customer, { CUSTOMER_CONST } from 'core-uda/model/customer/Customer'
import ConfirmationPopup from 'core-cmp/msg/ConfirmationPopup'
import CustomerUpdateConfirmPopup from 'thm/ui/common/customer/CustomerUpdateConfirmPopup'

// Logger for module "infopanel"
const log = Log.get('infopanel')

/**
 * @class thm.ui.common.customer.CustomerInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un client.
 * @param {Object} options
 */
let CustomerInfoPanel = InfoPanel.extend({
  template: CustomerInfoPanelTplStache,
  i18nPrefix: ['uda.customer.', 'thm.ui.common.customer.CustomerInfoPanel.'],
  id: 'customerInfoPanel',
  childTpl: null,
  entityId: 'customer',
  allowWidth: true,
  allowClose: true,
  allowClone: true,
  parameterDataToParam: {},
  parametersCleanDatas: [],

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    dataModel.attr({
      company: '',
    })
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
      siteOptions = [],
      projectOptions = RIGHTS('customer.project.list', '').split('\n'),
      domainNotAnimal = RIGHTS('user.domain') !== 'animal',
      contractNumbReq = RIGHTS('customer.contractNumber.required'),
      mailReq = RIGHTS('customer.Email.required'),
      showDomainId = true

    _.remove(projectOptions, (o) => _.trim(o) === '')

    me._super()

    SynchronizedSource.findAll().done(() => {
      me.updateTabsOptions()
    })

    me.viewModel.attr('hasBigData', false)
    me.viewModel.attr('hasSynchro', false)
    me.viewModel.attr('hasParameters', false)
    me.viewModel.attr('siteNone', !RIGHTS('customer.site.required'))

    if (!RIGHTS('customer.site.required')) {
      Customer.findSitesFromCustomer().done(function (datas) {
        for (let [key, value] of Object.entries(datas)) {
          siteOptions.push({ value: parseInt(key), text: value })
        }

        me.viewModel.attr('siteOptions', siteOptions)
        me.viewModel.attr('siteVisible', siteOptions.length > 0)
      })
    }

    me.viewModel.attr('projectNone', !RIGHTS('customer.project.required'))
    me.viewModel.attr('projectOptions', projectOptions)
    me.viewModel.attr('projectVisible', projectOptions.length > 0)
    me.viewModel.attr('flagNone', !RIGHTS('customer.flag.required'))
    me.viewModel.attr('tabs', 'customer')
    me.viewModel.attr('typeOptions', CUSTOMER_CONST.type)
    me.viewModel.attr(
      'contactBroadcastOptions',
      CUSTOMER_CONST.contactBroadcast,
    )
    // me.viewModel.attr('restrictionEditPage', EditRestrictionPopup.getId());
    me.viewModel.attr('sourceCustomerEditPage', EditSourceCustomerPopup.getId())
    me.fieldSelectOptionFromResource(
      'applicationDomain',
      'domainOptions',
      ApplicationDomain,
      'id',
      'name',
    )
    me.viewModel.attr('sourceTpl', SourceTplStache)
    // me.viewModel.attr('restrictionTpl', RestrictionTplStache);
    me.viewModel.attr('synchronizedSourceTpl', SynchronizedSourceTplStache)
    // me.viewModel.attr('onClickRestriction', me.proxy(me.onClickRestriction));
    me.viewModel.attr(
      'onClickSourceCustomer',
      me.proxy(me.onClickSourceCustomer),
    )
    me.viewModel.attr('ParametersTabTpl', ParametersCustomerTabStache)
    me.viewModel.attr('parameters', {})
    // me.viewModel.attr("aisRestrictionSetArray", []);
    me.viewModel.attr('sourcesEditParams', {
      hasListHeader: false,
      title: me.msg('source'),
    })

    showDomainId = me.viewModel.attr('domainOptions').length > 1
    me.viewModel.attr('contractNumbReq', contractNumbReq)
    me.viewModel.attr('mailReq', mailReq)
    me.viewModel.attr('domainNotAnimal', domainNotAnimal)
    me.viewModel.attr('showDomainId', showDomainId)
    me.viewModel.attr('sourcesTemplate', SourcesTemplate)
    me.viewModel.attr(
      'showOtherCustomersVessels',
      RIGHTS('data.mobile.shareWithOtherCustomers'),
    )

    me.viewModel.attr('dateParamsOptions', ['all', 'since', 'between'])
    me.viewModel.on('dataModel.dateParams', (ev, value) => {
      let aisAuthorizedTime = !!me.viewModel.attr('dataModel.aisAuthorizedTime')
      me.viewModel.attr(
        'dateParamsBetween',
        value == 'between' && aisAuthorizedTime,
      )
      me.viewModel.attr(
        'dateParamsSince',
        value == 'since' && aisAuthorizedTime,
      )
    })
    me.viewModel.on('dataModel.aisAuthorizedTime', (ev, value) => {
      let dateParams = me.viewModel.attr('dataModel.dateParams')
      me.viewModel.attr('dateParamsBetween', dateParams == 'between' && value)
      me.viewModel.attr('dateParamsSince', dateParams == 'since' && value)
    })
    me.viewModel.attr('dataModel.dateParams', 'all')
    //siteOptions = JSON.parse(RIGHTS('customer.site.list', ""));

    me.updateTabsOptions()

    APP()
      .whenData('customerParameters')
      .done(() => {
        me.updateParametersViewModel()
      })
  },

  updateViewModel: function () {
    let me = this

    me._super()

    APP()
      .whenData('customerParameters')
      .done(() => {
        me.updateParametersViewModel()
      })

    // me.updateRestrictionViewModel();

    if (
      me.viewModel.attr('tabs') === 'bigData' &&
      !me.viewModel.attr('dataModel.bigDataMode')
    ) {
      me.viewModel.attr('tabs', 'customer')
    }
  },

  // getEditedRestrictions: function (restrictions) {
  //   return restrictions ? JSON.parse(restrictions) || [] : [];
  // },

  // updateRestrictionViewModel: function () {
  //   let me           = this,
  //       restrictions = me.viewModel.attr('dataModel.aisRestrictionSet'),
  //       editedRestrictions;

  //   editedRestrictions = me.getEditedRestrictions(restrictions);
  //   me.viewModel.attr("aisRestrictionSetArray").attr(editedRestrictions, true);
  // },

  getEditedParameters: function (customerParameters) {
    let me = this,
      customerParameter,
      editedParameters = {}

    if (APP('customerParameters')) {
      // Remove deprecated customer parameter
      _.remove(
        customerParameters,
        (p) => !APP('customerParameters').find({ name: p.name }),
      )

      // Compute parameters value
      APP('customerParameters').each((p) => {
        customerParameter = _.find(customerParameters, { name: p.name })
        editedParameters[p.name.replace(/\./g, '_')] =
          Parameter.getParameterValue(customerParameter || p)
      })
    }
    return editedParameters
  },

  updateParametersViewModel: function () {
    let me = this,
      parametersConstruct,
      customerParameters = me.viewModel.attr('dataModel.parameters'),
      editedParameters

    customerParameters = customerParameters ? customerParameters.attr() : []
    editedParameters = me.getEditedParameters(customerParameters)

    me.viewModel.attr('parameters').attr(editedParameters, true)

    parametersConstruct = ParametersConstructDatas.updateParametersDatas(
      APP('customerParameters').getData(),
    )
    me.viewModel.attr('groups', parametersConstruct.groups)
    me.parameterDataToParam = parametersConstruct.dataToParam
    me.setOptionsForParameters(parametersConstruct.options)
  },

  setOptionsForParameters: function (options) {
    let me = this
    _.forEach(options, (option) => {
      let optionsToUse = option.options
      if (option.key == 'map_baseLayersOptions') {
        optionsToUse = RAW_RIGHTS().getRight('center.map.baseLayers')
          ? RAW_RIGHTS().getRight('center.map.baseLayers').split(',')
          : []
      }
      me.viewModel.attr(option.key, optionsToUse)
    })
  },

  updateTabsOptions: function () {
    let me = this,
      hasBigData = false,
      hasSynchro = false,
      hasParameters = false,
      tabs = [
        { value: 'customer', text: me.msg('customerOption'), icon: 'user' },
      ]

    if (!RIGHTS('data.customer.hideParameters')) {
      hasParameters = true
      tabs.push({
        value: 'parameters',
        text: me.msg('parametersOption'),
        icon: 'folder',
      })
    }
    if (
      RIGHTS('showAisRestrictions') &&
      !RIGHTS('data.customer.hideBigData') &&
      RIGHTS('data.datalake.authorized')
    ) {
      hasBigData = true
      tabs.push({
        value: 'bigData',
        text: me.msg('bigDataOption'),
        icon: 'database',
      })
    }
    if (
      APP().hasData('synchronizedSource') &&
      APP('synchronizedSource').size() > 0 &&
      !RIGHTS('data.customer.hideSynchronized')
    ) {
      hasSynchro = true
      tabs.push({
        value: 'synchro',
        text: me.msg('field.synchronizedSource'),
        icon: 'duplicate',
      })
    }

    me.viewModel.attr('hasBigData', hasBigData)
    me.viewModel.attr('hasSynchro', hasSynchro)
    me.viewModel.attr('hasParameters', hasParameters)
    me.viewModel.attr('tabsOptions', tabs)
  },

  getTitle: function () {
    let me = this
    return me.msg('title')
  },

  // onClickRestriction: function (wc, dom, wcEvent, wcList, item) {
  //   if (item) {
  //     EditRestrictionPopup.openPage({
  //       item:   item,
  //       value:  wcList.uiListVM.items,
  //       onSave: function (newItem) {
  //         let restrictions = wcList.uiListVM.items,
  //             index        = _.findIndex(restrictions, {id: newItem.id}),
  //             allProperties;

  //         // udpate
  //         if (index > -1) {
  //           delete newItem.id;
  //           allProperties = _.assign(restrictions[index].attr(), newItem);
  //           for (let prop in allProperties) {
  //             if (newItem[prop]) {
  //               restrictions[index].attr(prop, newItem[prop]);
  //             } else if (prop !== 'id') {
  //               restrictions[index].removeAttr(prop);
  //             }
  //           }
  //         } else { // create
  //           restrictions.push(new canMap({
  //             'id':        newItem.id,
  //             'from':      newItem.from,
  //             'to':        newItem.to,
  //             'lastxdays': newItem.lastxdays,
  //             'sources':   newItem.sources,
  //             'zones':     newItem.zones
  //           }));
  //         }
  //       }
  //     });
  //   }
  // },

  onClickSourceCustomer: function (wc, dom, wcEvent, wcList, item) {
    if (item) {
      EditSourceCustomerPopup.openPage({
        item: item,
        value: wcList.uiListVM.items,
        onSave: function (newItem) {
          let sourceCustomers = wcList.uiListVM.items,
            index = _.findIndex(sourceCustomers, { id: newItem.id }),
            allProperties

          // udpate
          if (index > -1) {
            allProperties = _.assign(sourceCustomers[index].attr(), newItem)
            for (let prop in allProperties) {
              if (!_.isNil(newItem[prop])) {
                sourceCustomers[index].attr(prop, newItem[prop])
              }
            }
          } else {
            // create
            sourceCustomers.push(
              new canMap({
                id: newItem.id,
                sourceId: newItem.sourceId,
                sourceCustomerId: newItem.sourceCustomerId,
                sourceCustomerCompany: newItem.sourceCustomerCompany,
                sourceLogin: newItem.sourceLogin,
                activated: newItem.activated,
                sourceName: newItem.sourceName,
              }),
            )
          }
        },
      })
    }
  },

  checkChanges: function () {
    let me = this,
      fromData = me.cloneData(),
      // fromRestriction = me.getEditedRestrictions(fromData.aisRestrictionSet),
      fromParameters = me.getEditedParameters(fromData.parameters || []),
      fromSources = fromData.sourceCustomers || [],
      // toRestriction   = me.viewModel.attr("aisRestrictionSetArray").attr(),
      toParameters = me.viewModel.attr('parameters').attr(),
      toSources = me.viewModel.attr('dataModel.sourceCustomers'),
      parametersDiff,
      // restrictionDiff,
      sourcesDiff

    toSources = toSources ? toSources.attr() || [] : []

    sourcesDiff = UTILS.diff(fromSources, toSources, { numberAsString: true })
    if (log.isDebug) {
      log.debug(
        'Sources NB Diff : ' +
          sourcesDiff.count +
          ' ; Equals : ' +
          sourcesDiff.equals,
      )
      log.debug(JSON.stringify(sourcesDiff.diff, null, 2))
    }

    // restrictionDiff = UTILS.diff(fromRestriction, toRestriction, {numberAsString: true});
    // if (log.isDebug) {
    //   log.debug('Restrictions NB Diff : ' + restrictionDiff.count + " ; Equals : " + restrictionDiff.equals);
    //   log.debug(JSON.stringify(restrictionDiff.diff, null, 2));
    // }

    parametersDiff = UTILS.diff(fromParameters, toParameters, {
      numberAsString: true,
    })
    if (log.isDebug) {
      log.debug(
        'Parameters NB Diff : ' +
          parametersDiff.count +
          ' ; Equals : ' +
          parametersDiff.equals,
      )
      log.debug(JSON.stringify(parametersDiff.diff, null, 2))
    }

    return (
      parametersDiff.count > 0 /*|| restrictionDiff.count > 0*/ ||
      sourcesDiff.count > 0 ||
      me._super()
    )
  },

  /**
   * @override
   */
  getDataForCheckChange: function () {
    let me = this,
      data = me._super()

    delete data.dateParams

    return data
  },

  /**
   * @override
   */
  getDataModelForCheckChange: function () {
    let me = this,
      dataModel = me._super()

    delete dataModel.dateParams

    return dataModel
  },

  getParametersToSave: function () {
    let me = this,
      customerParameters = me.viewModel.attr('dataModel.parameters'),
      editedParameters = me.viewModel.attr('parameters').attr(),
      parametersToSave = [],
      parameterToSave,
      value,
      customerParameter,
      key,
      item

    customerParameters = customerParameters ? customerParameters.attr() : []

    APP('customerParameters').each((p) => {
      key = p.name.replace(/\./g, '_')
      value = editedParameters[key]
      item = {
        definition: p.definition,
        value: null,
      }
      Parameter.setParameterValue(item, value) // edited value format to uda format
      customerParameter = _.find(customerParameters, { name: p.name })

      if (customerParameter) {
        parameterToSave = _.pick(customerParameter, [
          'id',
          'versionNum',
          'name',
        ])
      } else {
        parameterToSave = {
          id: p.id,
          name: p.name,
        }
      }
      parameterToSave.value = item.value
      parametersToSave.push(parameterToSave)
    })

    return parametersToSave
  },

  // getRestrictionToSave: function () {
  //   let me = this,
  //       restrictions;

  //   if (me.viewModel.attr('aisRestrictionSetArray')) {
  //     restrictions = me.viewModel.attr('aisRestrictionSetArray').attr();
  //     _.forEach(restrictions, function (obj) {
  //       delete obj.id;
  //     });
  //     return JSON.stringify(restrictions);
  //   }
  //   return undefined;
  // },

  getDataModelForSave: function () {
    let me = this,
      data = me._super(),
      domainOptions = me.viewModel.attr('domainOptions')

    data.parameters = me.getParametersToSave()
    // data.aisRestrictionSetArray = me.getRestrictionToSave();

    //the domain field is hidden if there is just one option, in that case we force the value
    if (domainOptions.length === 1) {
      data.domainId = domainOptions[0].value
    }
    //the type field is hidden if domain animal, in that case we force the value to "Client"
    if (_.isNil(data.type)) {
      data.type = 'client'
    }
    //the Broadcast Alert field is hidden if domain animal, in that case we force the value to "Never"
    if (_.isNil(data.contactBroadcast)) {
      data.contactBroadcast = 'never'
    }
    data.force = me.force
    return data
  },

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    const me = this
    return me.data.company
  },

  onSaveError: function ({ response }) {
    const me = this
    const { responseJSON } = response
    if (response.status === 406) {
      CustomerUpdateConfirmPopup.openPage({
        vesselErrors: responseJSON,
        confirmHandler: () => {
          // on refait un save avec force true dans la data
          me.force = true
          me.onSave()
        },
      })
    }
  },

  doBeforeSave: function () {
    const me = this
    // on remet force à false
    // traite le cas : un utilisateur supprime un vessel provoquant un warning, enregistre, valide le warning puis refait la meme chose sur un autre vessel
    // -> il doit aussi y avoir un warning la deuxieme fois
    me.force = false
  },
})

export default CustomerInfoPanel
