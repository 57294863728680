import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import $ from 'core-uda/uda-jquery'
import Toastr from 'core-cmp/Toastr'
import { UDA_URL } from 'core-uda/model/ResourceDAO'
import { APP } from 'core-uda/model/Resource'
import ErsOperationReportExportPopupTpl from 'thm/ui/common/ersOperationReport/ErsOperationReportExportPopupTpl.stache'

/**
 * @class ErsOperationReportExportPopup
 *
 */
let ErsOperationReportExportPopup = Page.extend({
  id: 'ersOperationReportExportPopup',
  routerType: 'popup',
  template: ErsOperationReportExportPopupTpl,
  i18nPrefix: ['thm.ui.common.ersActivity.ErsOperationReportExportPopup.'],
  autoBindActions: true,
  popupWidth: 400,

  // override
  initViewModel: function () {
    let me = this
    me._super()

    me.viewModel.attr('dataModelForm', {})
  },

  doBeforeOpen: function () {
    let me = this

    me.viewModel.attr('mobileId', me.context.mobileId)
    me.viewModel.attr('tripId', me.context.ersTripId)
    me.viewModel.attr('from', '')
    me.viewModel.attr('to', '')
  },

  checkNbDaysPeriod: function () {
    let me = this,
      millis = me.viewModel.attr('to') - me.viewModel.attr('from'),
      nbDays = millis / (60 * 60 * 24 * 1000)

    return nbDays <= 15 ? true : false
  },

  onSubmit: function () {
    let me = this

    if (me.checkNbDaysPeriod()) {
      me.downloadXlsxReport()
    } else {
      Toastr.showError(me.msg('tooLongPeriod'))
    }
  },

  downloadXlsxReport: function () {
    let me = this,
      request = new XMLHttpRequest(),
      params =
        'application=umv&token=' +
        APP().user.token +
        '&format=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
        '&fields="all"' +
        '&ersTrip=' +
        me.viewModel.attr('tripId') +
        '&selectBy=mobile:' +
        me.viewModel.attr('mobileId') +
        '&from=' +
        me.viewModel.attr('from').toISOString() +
        '&to=' +
        me.viewModel.attr('to').toISOString()

    request.open('POST', UDA_URL + 'ersOperationReports/longline', true)
    request.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded',
    )
    request.responseType = 'blob'

    me.viewModel.attr('saveStatus', 0)

    request.onload = function (e) {
      if (this.status === 200) {
        let blob = this.response,
          fileName =
            'ErsOperation_' +
            me.viewModel.attr('tripId') +
            '_' +
            me.viewModel.attr('mobileId') +
            '-' +
            PREF().format('date', new Date()).replace(/\//g, '-') +
            '.xlsx'
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          let downloadLink = window.document.createElement('a'),
            contentTypeHeader = request.getResponseHeader('Content-Type')

          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader }),
          )
          downloadLink.download = fileName
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      }
    }
    request.send(params)
    me.onClose()
  },

  onCancel: function () {
    let me = this

    me.onClose()
  },
})

export default ErsOperationReportExportPopup
