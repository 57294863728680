import _ from 'core-cmp/lodash'
import template from 'thm/ui/common/beaconType/CommandPiratTimers.stache'
import CommandHandler from 'core-mobile/ui/command/CommandHandler'
import CommandPirat from 'thm/ui/common/beaconType/CommandPirat'

const TYPES = ['normal', 'movement', 'tracking', 'nogps', 'lowbattery']
const PARAMS = [
  'trackingFrequencyRate',
  'gpsAcquisitionRate',
  'accelerationPeriod',
  'accelerationSampleRate',
  'code',
]
const M_TO_S = ['accelerationPeriod', 'trackingFrequencyRate']

/**
 * Handler for Pirat Timers Command.
 * @class CommandPiratTimers
 */
let CommandPiratTimers = CommandPirat.extend({
  id: 'piratTimers',
  i18nPrefix: 'thm.ui.common.beaconModel.CommandPiratTimers.',
  template: template,
  parametersData: {
    movement: '',
    livetracking: '',
    nogps: '',
    lowbattery: '',
  },
  selectOptionsMin: ['H', 'M'],
  selectOptionsSec: ['H', 'M', 'S'],

  /**
   * @override
   */
  init: function () {
    const me = this

    me._super()
    me.resetFields()
  },

  /**
   * Rest all field (except code)
   */
  resetFields: function () {
    const me = this

    _.each(TYPES, (t) => {
      me.attr(t, false)
      _.each(PARAMS, (p) => {
        me.attr(t + '-' + p, '')
        me.attr(t + '-' + p + '-sel', 'H')
      })
    })

    let defaultParameters = me.getDefaultSendCommandParameters()
    Object.keys(defaultParameters).forEach((key) => {
      me.attr(key, defaultParameters[key])
    })
  },

  /**
   * @override
   */
  onCommandChange: function (commandStatus) {
    const me = this

    me._super(commandStatus)
    me.resetFields()
  },

  /**
   * @override
   */
  onSubmit: function (params) {
    const me = this
    let value = [],
      valuePart

    _.each(TYPES, (t) => {
      valuePart = {}
      if (me[t]) {
        valuePart.mode = t
        valuePart.values = {}
        _.each(PARAMS, (p) => {
          let res = me[t + '-' + p]
          let resSelect = me[t + '-' + p + '-sel']
          if (!!res && res !== '') {
            res = resSelect === 'M' ? res * 60 : res
            res = resSelect === 'H' ? res * 3600 : res
            valuePart.values[p] = res
          }
        })

        value.push(valuePart)
      }
    })

    if (_.size(me.code) > 0) {
      value.push({ mode: 'unlock', values: { code: me.code } })
    }

    if (value.length > 0) {
      params['value'] = JSON.stringify(value)
    }
  },

  getDefaultSendCommandParameters: function () {
    return {
      code: 1000,
      'normal-trackingFrequencyRate': 1,
      'normal-trackingFrequencyRate-sel': 'H',
      'normal-gpsAcquisitionRate': 1,
      'normal-gpsAcquisitionRate-sel': 'H',
      'normal-accelerationSampleRate': 2,
      'normal-accelerationSampleRate-sel': 'S',

      'movement-trackingFrequencyRate': 1,
      'movement-trackingFrequencyRate-sel': 'M',
      'movement-gpsAcquisitionRate': 20,
      'movement-gpsAcquisitionRate-sel': 'S',
      'movement-accelerationSampleRate': 2,
      'movement-accelerationSampleRate-sel': 'S',

      'nogps-trackingFrequencyRate': 1,
      'nogps-trackingFrequencyRate-sel': 'H',
      'nogps-gpsAcquisitionRate': 2,
      'nogps-gpsAcquisitionRate-sel': 'H',
      'nogps-accelerationSampleRate': 2,
      'nogps-accelerationSampleRate-sel': 'S',

      'lowbattery-trackingFrequencyRate': 2,
      'lowbattery-trackingFrequencyRate-sel': 'H',
      'lowbattery-gpsAcquisitionRate': 2,
      'lowbattery-gpsAcquisitionRate-sel': 'H',
      'lowbattery-accelerationSampleRate': 2,
      'lowbattery-accelerationSampleRate-sel': 'S',
    }
  },
})

CommandHandler.register(CommandPiratTimers)

export default CommandPiratTimers
