import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import Recipient from 'core-uda/model/user/Recipient'
import template from 'thm/ui/common/recipient/RecipientTpl.stache'
import { PREF } from 'core-cmp/Preferences'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for Recipient
 */
let RecipientUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Recipient.itemId,
      i18nPrefix: 'thm.ui.common.recipient.RecipientUI.',
      icon: 'mail',
      labelField: 'alias',
      searchFields: ['alias', 'email'],
      template: template,
      isListEditable: true,
    })
  },
})

const RECIPIENT_UI = new RecipientUI()

export default RecipientUI
