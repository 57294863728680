import { I18N } from 'core-cmp/util/I18n'
import { FEATURE_FAMILY_TYPE } from 'core-gis/FeatureConstants'
import ZoneHandler from 'core-zone/service/info/ZoneHandler'

/**
 * @class thm.service.info.ZoneHandler
 * @parent thm.service.info
 * @constructor
 * Gestionnaire de contenu pour l'outils carto info.
 * @param {Object} options
 */
let UmvZoneHandler = ZoneHandler.extend(
  {
    /**
     * Indique si le handler sait générer le contenu informatif pour les attributs en argument.
     * @param {Object} attr attribus OpenLayers.
     * @return {boolean} true : si supportée, false sinon.
     */
    accept: function (attr) {
      return (
        attr &&
        (attr.type === FEATURE_FAMILY_TYPE.USER_ZONE ||
          attr.type === FEATURE_FAMILY_TYPE.OFFICIAL_ZONE ||
          attr.type === FEATURE_FAMILY_TYPE.PORT_ZONE ||
          attr.type === FEATURE_FAMILY_TYPE.LANDMARK_ZONE ||
          attr.type === FEATURE_FAMILY_TYPE.WORKSTATION_ZONE ||
          attr.type === FEATURE_FAMILY_TYPE.ITINERARY_ZONE ||
          attr.type === FEATURE_FAMILY_TYPE.ANNOTATION_ZONE)
      )
    },
  },
  {},
)

export default UmvZoneHandler
