import InfoPanel from 'core-uda/ui/InfoPanel'
import template from 'thm/ui/common/portType/PortTypeInfoPanelTpl.stache'
import PortType from 'core-zone/model/PortType'
import { RIGHTS } from 'core-uda/Rights'

/**
 * Form for type of port
 * @class PortTypeInfoPanel
 */
let PortTypeInfoPanel = InfoPanel.extend({
  template: template,
  i18nPrefix: ['uda.portType.', 'thm.ui.common.portType.PortTypeInfoPanel.'],
  id: 'portTypeInfoPanel',
  entityId: PortType.itemId,
  allowWidth: true,
  titleKey: 'title',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('showClient', RIGHTS('showClient'))
  },

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    let me = this
    return me.data.customerCompany + ' - ' + me.data.name
  },
})

export default PortTypeInfoPanel
