import template from 'thm/ui/common/beaconType/CommandPiratFreeForm.stache'
import CommandPirat from 'thm/ui/common/beaconType/CommandPirat'
import CommandHandler from 'core-mobile/ui/command/CommandHandler'

/**
 * Handler for Pirat Free Form Command.
 * @class CommandPiratFreeForm
 */
let CommandPiratFreeForm = CommandPirat.extend({
  id: 'piratFreeForm',
  i18nPrefix: 'thm.ui.common.beaconModel.CommandPiratFreeForm.',
  template: template,
  commandText: '',

  /**
   * @override
   */
  onSubmit: function (params) {
    const me = this
    params.value = `{"text":"${me.commandText}","code":"${me.code}"}`
  },
})

CommandHandler.register(CommandPiratFreeForm)

export default CommandPiratFreeForm
