import DataModel from 'core-uda/model/DataModel'

/**
 * Modèle des flottes de mobiles.
 */
let ReportTemplateModel = DataModel.extend({
  modelId: 'reportTemplates',
  itemId: 'reportTemplate',
  rightKey: 'report',
})

export default ReportTemplateModel
