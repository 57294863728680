import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import { DATA_AUTHORIZED, RIGHTS } from 'core-uda/Rights'
import Toastr from 'core-cmp/Toastr'
import MtccReportPageTplStache from 'thm/ui/common/mtccreport/MtccReportPageTpl.stache'
import Mobile from 'core-uda/model/mobile/Mobile'
import MtccReportPopup from 'thm/ui/common/mtccreport/MtccReportPopup'

let MtccReportPage = Page.extend({
  template: MtccReportPageTplStache,
  i18nPrefix: ['uda.mobile.field.', 'thm.ui.common.mtccreport.MtccReportPage.'],
  id: 'mtccReportPage',
  modal: true,
  childTpl: null,
  data: null,
  routerType: 'tab',
  autoBindActions: true,

  initViewModel: function () {
    let me = this,
      mobiles = [],
      methods = [
        { value: 'manual' },
        { value: 'auto' },
        { value: 'flowmeter' },
        { value: 'other' },
      ],
      typeOptions = [
        'Standby for departure to full away',
        'Full away to noon',
        'Noon to noon while ship is still underway',
        'Noon to end of passage',
        'End of passage to alongside/anchorage',
        'At berth',
        'Other',
      ]

    me._super()

    me.viewModel.attr('methods', methods)
    me.viewModel.attr('typeOptions', typeOptions)

    me.viewModel.attr('dataModel', {})

    me.bindViewModel()
  },

  updateViewModel: function () {
    let me = this

    me._super()

    me.viewModel.attr('otherMethodActivate', false)
    me.viewModel.attr('dataModel').attr(
      {
        vessel: '-1',
        imo: '',
        gt: '',
        nt: '',
        dwt: '',
        mainEngines: '',
        auxiliaryEngines: '',
        startDate: '',
        endDate: '',
        distance: '',
        hours: '',
        diesel: '',
        lfo: '',
        hfo: '',
        lpgPropane: '',
        lpgButane: '',
        lng: '',
        methanol: '',
        ethanol: '',
        other: '',
        method: 'manual',
        otherMethod: '',
      },
      true,
    )
  },

  bindViewModel: function () {
    let me = this

    me.viewModel.attr('dataModel').on('vessel', me.proxy(me.changeMobile))
    me.viewModel.attr('dataModel').on('method', me.proxy(me.changeMethod))
  },

  doAfterOpen: function () {
    let me = this
    me._super()
    me.updateViewModel()
  },

  changeMobile: function () {
    let me = this,
      mobileId = me.viewModel.attr('dataModel.vessel')

    if (mobileId != -1) {
      Mobile.loadForEdition(mobileId).done((mobile) => {
        me.viewModel.attr('dataModel').attr('imo', mobile.imo ? mobile.imo : '')
        me.viewModel
          .attr('dataModel')
          .attr('gt', mobile.tonnage ? mobile.tonnage : '')
        me.viewModel
          .attr('dataModel')
          .attr('dwt', mobile.umsTonnage ? mobile.umsTonnage : '')
        me.viewModel.attr('dataModel').attr('nt', '')
      })
    }
  },

  changeMethod: function () {
    let me = this,
      method = me.viewModel.attr('dataModel.method')

    me.viewModel.attr('otherMethodActivate', method == 'other')
  },

  onCancel: function () {
    let me = this

    me.close()
  },

  onValidate: function () {
    let me = this,
      params = me.viewModel.attr('dataModel')

    // 1- Vessel
    if (params.vessel == -1) {
      Toastr.showToastr('error', 'mtccReportNoVessel')
      return
    }
    // 2- Fuel consumption
    if (
      (_.isUndefined(params.diesel) || params.diesel == '') &&
      (_.isUndefined(params.lfo) || params.lfo == '') &&
      (_.isUndefined(params.hfo) || params.hfo == '') &&
      (_.isUndefined(params.lpgPropane) || params.lpgPropane == '') &&
      (_.isUndefined(params.lpgButane) || params.lpgButane == '') &&
      (_.isUndefined(params.lng) || params.lng == '') &&
      (_.isUndefined(params.methanol) || params.methanol == '') &&
      (_.isUndefined(params.ethanol) || params.ethanol == '') &&
      (_.isUndefined(params.other) || params.other == '')
    ) {
      Toastr.showToastr('error', 'mtccReportNoFuel')
      return
    }
    // 3- If method other -> specify
    if (
      params.method == 'other' &&
      (_.isUndefined(params.otherMethod) || params.otherMethod == '')
    ) {
      Toastr.showToastr('error', 'mtccReportSpecifyOther')
      return
    }

    MtccReportPopup.openSingleton(params)
  },
})

export default MtccReportPage
