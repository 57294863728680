import ResourceUI from 'core-uda/ui/ResourceUI'
import MissingLocation from 'core-uda/model/missingLocation/MissingLocation'
import ThmDateMobileCriteria from 'thm/ui/common/mobile/ThmDateMobileCriteria'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'
import SwitchCriteria from 'thm/ui/common/position/SwitchCriteria'
import RadiosCriteria from 'core-criteria/radios/RadiosCriteria'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'

/**
 * User Interface elements for MissingLocation
 */
let MissingLocationUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: MissingLocation.itemId,
      i18nPrefix: 'thm.ui.common.missingLocation.MissingLocationUI.',
      icon: 'estimatedPosition',
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      date: {
        udaNames: ['from', 'to'],
        extraUdaNames: ['since'],
        cmpClass: ThmDateMobileCriteria,
        noStatisticalAuthorized: false,
      },
      selectBy: {
        cmpClass: ThmSelectByCriteria,
      },
      notEmittingVessels: {
        cmpClass: SwitchCriteria,
        i18nPrefix: 'uda.missingLocation.field.',
      },
      exceptionLocations: {
        cmpClass: SwitchCriteria,
        i18nPrefix: 'uda.missingLocation.field.',
      },
      beaconOrVesselLocations: {
        cmpClass: RadiosCriteria,
        i18nPrefix: 'uda.missingLocation.field.',
        optionsKeyPrefix:
          'uda.missingLocation.field.beaconOrVesselLocations.options.',
        hideLabel: true,
        options: ['beacon', 'vessel'],
        defaultValue: 'beacon',
      },
    }
  },

  /**
   * @override
   */
  getExportOptions: function () {
    let me = this
    return [
      {
        text: me.msg('formattedExport'),
        value:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      { text: me.msg('pdf'), value: 'application/pdf' },
      { text: me.msg('html'), value: 'text/html' },
      { text: me.msg('csv'), value: 'text/csv' },
    ]
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      locIOR: I18N_FIELD('uda.alert.field.kind.'),
      nextLocationIOR: I18N_FIELD('uda.alert.field.kind.'),
      previousLocationIOR: I18N_FIELD('uda.alert.field.kind.'),
      shipowners: {
        dataType: 'string',
        format: (value) => {
          if (value !== undefined) return value.join(', ')
          return
        },
      },
    }
  },
})

const MISSING_LOCATION_UI = new MissingLocationUI()

export default MissingLocationUI
