import ResourceUI from 'core-uda/ui/ResourceUI'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import Position from 'core-uda/model/position/Position'
import $ from 'core-cmp/cmp-jquery'
import { PREF } from 'core-cmp/Preferences'
import { RIGHTS } from 'core-uda/Rights'
import { UDA_URL } from 'core-uda/model/ResourceDAO'
import Toastr from 'core-cmp/Toastr'
import ConfirmationPopup from 'core-cmp/msg/ConfirmationPopup'
import ThmDateLastPositionsCriteria from 'thm/ui/common/position/ThmDateLastPositionsCriteria'
import SwitchCriteria from 'thm/ui/common/position/SwitchCriteria'
import SourceCriteria from 'core-criteria/source/SourceCriteria'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'
import ThmSelectCpcCriteria from 'thm/ui/common/cpcPostData/ThmSelectCpcCriteria'
import ThmPositionModifyPopup from 'thm/ui/common/position/ThmPositionModifyPopup'
import PositionSource from 'core-uda/model/position/PositionSource'
import ThmPositionForwardPopup from 'thm/ui/common/position/ThmPositionForwardPopup'
import CpcPostDataUI from 'thm/ui/common/cpcPostData/CpcPostDataUI'
import { CELL_TPL_BATTERY_PERCENT } from 'core-uda/ui/ResourceUI'

const MODIFY_LOC_ACTION = (context) => {
  ThmPositionModifyPopup.openPage(context)
}

/**
 * User Interface elements for Position
 */
let ThmPositionUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Position.itemId,
      i18nPrefix: 'thm.ui.common.position.ThmPositionUI.',
      icon: 'position',
      extraColumnsToDelete: [],
      customExtraColumns: {
        i18nPrefix: 'uda.collectData.field.type.',
        activeColumns: true,
        allowSort: false,
        fieldOrigin: 'collectData',
        columnsToAdd: ['CLV'],
        deleteExtraColumns: true,
      },
    })
  },

  /**
   * Format used by Delays
   * @param value
   * @returns {string|*}
   */
  formatDelay: function (value) {
    if (_.isNumber(value)) {
      //toFixed peut renvoyer des 0 inutiles, on s'en débarasse avec un autre parseFloat
      return parseFloat(parseFloat(value).toFixed(3)) + ' s'
    } else {
      return value
    }
  },

  /**
   * @override
   */
  getUreContextMenuActions: function (context) {
    const isRowClick = context.type === 'data' && !!context.data,
      isLocationEditable =
        (isRowClick && RIGHTS('data.position.edit')) ||
        (isRowClick &&
          RIGHTS('data.position.editManual') &&
          context.data.nature === 'U')

    return [
      {
        id: 'modify',
        icon: 'edit',
        labelKey: '_Modify',
        isAuthorized: isLocationEditable,
        handler: MODIFY_LOC_ACTION,
      },
    ]
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    let me = this
    return {
      CLV: {
        cellTemplate: CELL_TPL_BATTERY_PERCENT,
      },
      speed: {
        format: (value) => {
          return PREF().format('speed', value)
        },
        dataType: 'string',
      },
      deviceSavedDelay: {
        format: (value) => me.formatDelay(value),
        dataType: 'string',
      },
      dataTransDelay: {
        format: (value) => me.formatDelay(value),
        dataType: 'string',
      },
      deviceProviderDelay: {
        format: (value) => me.formatDelay(value),
        dataType: 'string',
      },
      providerReceivedDelay: {
        format: (value) => me.formatDelay(value),
        dataType: 'string',
      },
      receivedSavedDelay: {
        format: (value) => me.formatDelay(value),
        dataType: 'string',
      },
      source: {
        format: (value) => PositionSource.getCodeLabel(value),
        dataType: 'string',
      },
      domestication: {
        dataType: 'string',
        format: I18N_FIELD('uda.fleet.field.').format,
      },
    }
  },

  /**
   * @override
   */
  getUdaFormat: function (field) {
    switch (field) {
      case 'deviceProviderDelay':
      case 'providerReceivedDelay':
      case 'receivedSavedDelay':
      case 'deviceSavedDelay':
        return 'duration:s'
      default:
        return null
    }
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      date: {
        udaNames: ['from', 'to'],
        extraUdaNames: ['since', 'lastPositions'],
        cmpClass: ThmDateLastPositionsCriteria,
      },
      selectBy: {
        cmpClass: ThmSelectByCriteria,
      },
      source: {
        cmpClass: SourceCriteria,
      },
      cpcFromIds: {
        cmpClass: ThmSelectCpcCriteria,
        cmpResource: 'cpcFrom',
      },
      excludePorts: {
        cmpClass: SwitchCriteria,
        i18nPrefix: 'uda.position.',
      },
      includeFads: {
        cmpClass: SwitchCriteria,
        i18nPrefix: 'uda.position.',
      },
    }
  },

  /**
   * @override
   */
  isMultipleRowSelectionAuthorized: function () {
    return (
      RIGHTS('data.position.delete') ||
      RIGHTS('data.position.deleteManual') ||
      RIGHTS('data.cpc.edit')
    )
  },

  /**
   * @override
   */
  getUreTopActionButtons: function () {
    let me = this,
      deleteAuthorized = true

    return [
      {
        id: 'delete',
        title: I18N.msg('thm.ui.common.position.ThmPositionUI.delete'),
        icon: 'delete',
        style: 'active',
        authorized:
          RIGHTS('data.position.delete') ||
          RIGHTS('data.position.deleteManual'),
        handler: (gridMainPage, ev) => {
          let positions = gridMainPage.gridElement.getSelectedRowsData()

          if (!_.isEmpty(positions)) {
            CpcPostDataUI
            ConfirmationPopup.openSingleton({
              title: me.msg('deletePositionTitle'),
              text: me.msg('deletePosition'),
              buttons: [{ action: 'yes' }, { action: 'no' }],
              onAction: function (action) {
                let deleteAuthorized = true

                if (action === 'yes') {
                  let listIdPosition = [],
                    requestDeferred

                  _.forEach(positions, (pos) => {
                    listIdPosition.push({ id: pos.id, nature: pos.nature })
                  })

                  if (
                    !RIGHTS('data.position.delete') &&
                    RIGHTS('data.position.deleteManual')
                  ) {
                    _.forEach(listIdPosition, (list) => {
                      if (list.nature !== 'U') {
                        Toastr.showError(me.msg('deleteManualError'), 5000)
                        deleteAuthorized = false
                        return false
                      }
                    })
                  }

                  if (deleteAuthorized) {
                    requestDeferred = $.ajax({
                      url: UDA_URL + 'positions/delete',
                      type: 'POST',
                      contentType: 'application/json',
                      data: JSON.stringify(listIdPosition),
                    })

                    requestDeferred
                      .done(() => {
                        gridMainPage.refresh()
                      })
                      .fail(() => {
                        Toastr.showError(me.msg('deleteError'), 5000)
                      })
                  }
                }
              },
            })
          } else {
            Toastr.showError(me.msg('selectPosition'), 5000)
          }
        },
      },
      {
        id: 'forward',
        title: I18N.msg('thm.ui.common.position.ThmPositionUI.forward'),
        icon: 'cancel',
        style: 'active',
        authorized: RIGHTS('data.cpc.edit'),
        handler: (gridMainPage, ev) => {
          let context = {
            positions: gridMainPage.gridElement.getSelectedRowsData(),
          }

          if (!_.isEmpty(context.positions)) {
            ThmPositionForwardPopup.openPage(context)
          } else {
            Toastr.showError(me.msg('selectPosition'), 5000)
          }
        },
      },
    ]
  },

  /**
   * @override
   */
  updateFieldsLabel: function (listField, hasTranslation) {
    let me = this,
      array = []

    if (RIGHTS('data.positions.displayStatisticZonesForPositions')) {
      let list = JSON.parse(RIGHTS('data.positions.zones'))
      _.forEach(Object.keys(list), (label) => {
        if (list[label] !== '') {
          array.push({ fieldName: label, label: list[label].label })
        }
      })
    }

    me._super(array, false)
  },

  /**
   * @override
   *
   * @param field
   * @returns {boolean}
   */
  isForbiddenField: function (field) {
    let me = this,
      regExp = new RegExp('^zone[0-9]+$')

    if (!RIGHTS('data.positions.displayStatisticZonesForPositions')) {
      return regExp.test(field)
    }

    return false
  },

  /**
   * @override
   */
  getLabel: function (item) {
    let me = this,
      zones = JSON.parse(RIGHTS('data.positions.zones'))

    if (zones[item]) {
      return zones[item].label
    }

    return
  },
})

const THM_POSITION_UI = new ThmPositionUI()

export default ThmPositionUI
