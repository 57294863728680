<div id="{{id}}" class="L-fit L-panel has-footer">
  <ui-page-header {cmp-config}="headerConfig">
    <field-state {(field)}="tabs" {options}="tabsOptions" options-template="content" options-sort="false" options-col="auto" class="tab">
      {{data.text}}
      {{#is data.value "bigData"}}
        <div class="icon-icon-right icon-s ui-icon-check-c model-ok check {{#unless dataModel.bigDataMode}}hidden{{/unless}}"></div>{{/is}}
      {{#is data.value "synchro"}}
        <div class="icon-icon-right icon-s ui-icon-check-c model-ok check {{#unless dataModel.synchronizedSource}}hidden{{/unless}}"></div>{{/is}}
    </field-state>
  </ui-page-header>

  <field-item item="dataModel" class="L-panel-content">

    <!---------------- TAB Client --------------------->

    <div class="{{#is tabs "customer"}}{{else}}hidden{{/is}}">
      <div class="header-group icon-left icon-m ui-icon-info inline">
        <h2>{{msg 'identification'}}</h2>
      </div>
      <div class="inlineGroup fields">
        <field-text name="company" max-length="100" required="true" col="6 12"></field-text>
        {{#if domainNotAnimal}}
            <field-select name="type" options="typeOptions" required="true" col="6 12"></field-select>
        {{/if}}
        <field-text name="department" max-length="80" col="6 12"></field-text>
        <field-text name="service" max-length="80" col="6 12"></field-text>
        {{#if domainNotAnimal}}
            <field-text name="webSite" max-length="50" col="6 12"></field-text>
        {{/if}}
        {{#if showDomainId}}
            <field-select name="domainId" {options}="domainOptions" col="6 12"></field-select>
        {{/if}}
        <field-textarea name="street" col="12 12"></field-textarea>
        <field-text name="city" max-length="50" col="6 12"></field-text>
        <field-text name="zipCode" max-length="12" col="6 12"></field-text>
        <field-text name="state" max-length="2" col="6 12"></field-text>
        <uda-select of="flags" name="countryId" {option-none="{{flagNone}}" col="6 12"></uda-select>
        {{#if domainNotAnimal}}
            <field-checkbox name="inmarsatBillable" col="6 12"></field-checkbox>
            {{#if contractNumbReq}}
                <field-text name="contractNumber" max-length="30" required="true" col="6 12"></field-text>
            {{else}}
                <field-text name="contractNumber" max-length="30" col="6 12"></field-text>
            {{/if}}

            <field-select name="project" options="projectOptions" col="6 12"
                          option-none="{{projectNone}}"
                          visible="{{projectVisible}}"></field-select>
            <field-text name="iridiumLogin" max-length="240" col="6 12"></field-text>
            <field-text name="iridiumPassword" max-length="32" col="6 12"></field-text>
            <field-select name="applicationSiteId" {options}="siteOptions" col="6 12"
                          option-none="{{siteNone}}"
                          visible="{{siteVisible}}"></field-select>
            <field-checkbox name="aisTrackingActivation" col="6 12"></field-checkbox>
        {{/if}}
        <field-checkbox name="mfaMode" col="6 12"></field-checkbox>
      </div>

      <div class="header-group icon-left icon-m ui-icon-user2">
        <h2>{{msg 'contact'}}</h2>
      </div>

      <div class="inlineGroup fields">
        <field-text name="contactTitle" max-length="3" col="6 12"></field-text>
        <field-text name="contactName" max-length="30" col="6 12"></field-text>
        <field-text name="contactFirstName" max-length="20" col="6 12"></field-text>
        <field-text name="contactPhone" format="phone-inter" max-length="20" col="6 12"></field-text>
        <field-text name="contactFax" format="phone-inter"  max-length="20" col="6 12"></field-text>
        <field-text name="contactMobilePhone" format="phone-inter" max-length="20" col="6 12"></field-text>
        {{#if domainNotAnimal}}
            <field-select name="contactBroadcast" options="contactBroadcastOptions" required="true"
                          col="6 12"></field-select>
        {{/if}}
        {{#if mailReq}}
            <field-text name="contactEmail" format="email" required="true" max-length="50" col="6 12"></field-text>
        {{else}}
            <field-text name="contactEmail" format="email" max-length="50" col="6 12"></field-text>
        {{/if}}
      </div>

      <div class="header-group icon-left icon-m ui-icon-annotation">
        <h2>{{msg 'monitoringReporting'}}</h2>
      </div>

      <div class="inlineGroup fields">
        <field-text name="monReportingPeriod" format="int" max="9999" required="true" class="l80-f20"
                    col="6 12"></field-text>
        <field-text name="monReportingUnderPct" format="int" max="999" required="true" class="l80-f20"
                    col="6 12"></field-text>
        <field-text name="monReportingStartHour" format="int" max="99" required="false" class="l80-f20"
                    col="6 12"></field-text>
        <field-text name="monReportingOverPct" format="int" max="999" required="true" class="l80-f20"
                    col="6 12"></field-text>
        <field-checkbox name="monReportingNormalOnly" col="7 12"></field-checkbox>
      </div>


      {{#if showOtherCustomersVessels}}
        <uda-list
            of="mobiles"
            name="otherCustomersMobiles"
            labelKey="mobilesFromOtherCustomers"
            class="inlineGroup"
            {update-for}="dataModel.id"
            update-function="filterVesselOtherCustomers"
        ></uda-list>
      {{/if}}

      <div class="header-group icon-left icon-m ui-icon-bars">
        <h2>{{msg 'comment'}}</h2>
      </div>
      <div class="inlineGroup fields">
        <field-textarea name="description" max-length="2000" col="12 12"></field-textarea>
      </div>
    </div>

    <!---------------- TAB Parameters --------------------->

    {{#if hasParameters}}
    <div class="{{#is tabs "parameters"}}{{else}}hidden{{/is}}">
      {{>ParametersTabTpl}}
    </div>
    {{/if}}

    <!---------------- TAB Big Data --------------------->

    {{#if hasBidData}}
    <div class="{{#is tabs "bigData"}}{{else}}hidden{{/is}}">
      <div class="inlineGroup fields">
        <field-checkbox name="bigDataMode" col="12 12"></field-checkbox>
        <field-checkbox name="aisAuthorized" {enabled}="dataModel.bigDataMode" class="disabledOpacity"></field-checkbox>
      </div>

      <uda-select of="dataSources"
                  name="dataSource"
                  item-none="true"
                  {enabled}="dataModel.bigDataMode"
                  {visible}="dataModel.aisAuthorized"
                  class="disabledOpacity"></uda-select>

      <field-checkbox name="aisAuthorizedTime" {enabled}="dataModel.bigDataMode" class="disabledOpacity"></field-checkbox>

      <field-hcombo name="dateParams" class="disabledOpacity" label="" {options}="dateParamsOptions"
                    {visible}="dataModel.aisAuthorizedTime" {enabled}="dataModel.bigDataMode"></field-hcombo>
      <field-date name="from" class="disabledOpacity" min="{{minDate}}" max="{{maxDate}}" updatemax="true"  period="fakePeriod" role="start"
                  format="dateTime" {visible}="dateParamsBetween" {enabled}="dataModel.bigDataMode"></field-date>
      <field-date name="to" class="disabledOpacity" min="{{minDate}}" max="{{maxDate}}" updatemax="true"  period="fakePeriod" role="end"
                  format="dateTime" {visible}="dateParamsBetween" {enabled}="dataModel.bigDataMode"></field-date>
      <field-text name="lastDays" class="disabledOpacity" format="pint" min="0" col="6 6"
                  label-align="right" {visible}="dateParamsSince" {enabled}="dataModel.bigDataMode"></field-text>
      <field-text name="lastHours" class="disabledOpacity" format="pnum" min="0" max="24" col="6 6"
                  label-align="right" {visible}="dateParamsSince" {enabled}="dataModel.bigDataMode"></field-text>

      <field-checkbox name="aisAuthorizedZone" {enabled}="dataModel.bigDataMode" class="disabledOpacity"></field-checkbox>
      <uda-select of="zones"
                  name="zone"
                  item-none="true"
                  {enabled}="dataModel.bigDataMode"
                  {visible}="dataModel.aisAuthorizedZone"
                  class="disabledOpacity"></uda-select>
    </div>
    {{/if}}

    <!---------------- TAB Synchro --------------------->

    {{#if hasSynchro}}
    <div class="{{#is tabs "synchro"}}{{else}}hidden{{/is}}">
      <div class="inlineGroup fields">
        <field-checkbox name="synchronizedSource" col="12 12"></field-checkbox>
      </div>
      <field-list name="sourceCustomers"
                  {items}="dataModel.sourceCustomers"
                  format="objectArray"
                  {item-template}="synchronizedSourceTpl"
                  (itemclick)="onClickSourceCustomer"
                  header-icon="duplicate"
                  edit-icon="add"
                  {edit-page}="sourceCustomerEditPage"
                  {enabled}="dataModel.synchronizedSource"
                  item-size="xl"
                  class="disabledOpacity"></field-list>

    </div>
    {{/if}}

  </field-item>

  <ui-submit-cancel class="L-scrollY-footer-btn" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
