import template from 'thm/ui/common/beaconType/CommandPiratLogicalOutput.stache'
import CommandPirat from 'thm/ui/common/beaconType/CommandPirat'
import CommandHandler from 'core-mobile/ui/command/CommandHandler'

/**
 * Handler for Pirat Logical Output Command.
 * @class CommandPiratLogicalOutput
 */
let CommandPiratLogicalOutput = CommandPirat.extend({
  id: 'piratLogicalOutput',
  i18nPrefix: 'thm.ui.common.beaconModel.CommandPiratLogicalOutput.',
  template: template,
  outputActivated: true,

  /**
   * @override
   */
  onSubmit: function (params) {
    const me = this,
      value = me.outputActivated ? '$dOut 1 1' : '$dOut 1 0'
    params.value = `{"text":"${value}","code":"${me.code}"}`
  },
})

CommandHandler.register(CommandPiratLogicalOutput)

export default CommandPiratLogicalOutput
