import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import Licence from 'core-uda/model/mobile/Licence'
import template from 'thm/ui/common/licence/LicenceTpl.stache'
import { PREF } from 'core-cmp/Preferences'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for Licence
 */
let LicenceUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Licence.itemId,
      i18nPrefix: 'thm.ui.common.licence.LicenceUI.',
      icon: 'licence',
      labelField: 'reference',
      template: template,
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      flagI18n: {
        format: (value) => {
          return value ? value[PREF('locale')] || '' : ''
        },
        dataType: 'string',
      },
    }
  },
})

const LICENCE_UI = new LicenceUI()

export default LicenceUI
