import Page from 'core-cmp/Page'
import { PREF } from 'core-cmp/Preferences'
import Toastr from 'core-cmp/Toastr'
import { UDA_URL } from 'core-uda/model/ResourceDAO'
import $ from 'core-cmp/cmp-jquery'

import ImportErsMessagePopupTplStache from 'thm/ui/common/ersManualImport/ImportErsMessagePopupTpl.stache'

let ImportErsMessagePopup = Page.extend({
  id: 'importErsMessagePopup',
  template: ImportErsMessagePopupTplStache,
  routerType: 'popup',
  modal: true,
  i18nPrefix: ['thm.ui.common.ersManualImport.ImportErsMessagePopup.'],
  autoBindActions: true,
  popupWidth: 400,

  initViewModel: function () {
    let me = this
    me._super()

    me.viewModel.attr('title', me.msg('title'))
  },

  onSubmit: function (event) {
    let me = this

    if (me.viewModel.file && me.viewModel.file[0]) {
      me.postFile(me.viewModel.file[0])
    } else {
      Toastr.showError(me.msg('selectFile'), 5000)
    }
  },

  postFile: function (file) {
    let me = this,
      formData = new FormData()

    formData.append('file', file)

    me.request = $.ajax({
      headers: {
        Accept: '*/*',
      },
      url: UDA_URL + 'manualImport/import',
      data: formData,
      type: 'POST',
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
    })

    me.listenStatus(me.request, 'saveStatus')
      .done(function (serverData) {
        Toastr.showInfo(me.msg('fileImported'), 5000)
        me.close()
      })
      .fail(function (x) {
        Toastr.showToastError('corruptedFile', 5000)
      })
  },
})

export default ImportErsMessagePopup
