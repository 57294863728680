import ToastRequestListener from 'core-uda/ui/ToastRequestListener'
import { APP } from 'core-uda/model/Resource'
import SarDrift from 'core-sar/model/sarDrift/SarDrift'
import SarSad from 'core-sar/model/sarSad/SarSad'
import SarSac from 'core-sar/model/sarSac/SarSac'
import SarSruWork from 'core-sar/model/sarSruWork/SarSruWork'

// Do not notify Search And rescue sub resources !
const NO_NOTIFY_RESSOURCES = [
  SarDrift.modelId,
  SarSad.modelId,
  SarSac.modelId,
  SarSruWork.modelId,
]

/**
 * @class mas.ui.ThmToastRequestListener
 * @parent mas.ui
 * @constructor
 * ThmToastRequestListener
 * @param {Object} options
 */
let ThmToastRequestListener = ToastRequestListener.extend({
  requestStart: function (ev, context) {
    let me = this

    if (
      context &&
      context.modelId &&
      NO_NOTIFY_RESSOURCES.includes(context.modelId)
    ) {
      return
    }

    if (APP().isMap()) {
      me._super.apply(me, arguments)
    }
  },

  requestDone: function (ev, context) {
    let me = this

    if (
      context &&
      context.modelId &&
      NO_NOTIFY_RESSOURCES.includes(context.modelId)
    ) {
      return
    }

    if (APP().isMap()) {
      me._super.apply(me, arguments)
    }
  },

  requestFail: function (ev, context) {
    let me = this

    if (
      context &&
      context.modelId &&
      NO_NOTIFY_RESSOURCES.includes(context.modelId)
    ) {
      return
    }

    if (APP().isMap()) {
      me._super.apply(me, arguments)
    }
  },

  searchStart: function () {
    let me = this

    if (APP().isMap()) {
      me._super.apply(me, arguments)
    }
  },

  searchEnd: function () {
    let me = this

    if (APP().isMap()) {
      me._super.apply(me, arguments)
    }
  },
})

export default ThmToastRequestListener
