import { THM_PREF } from 'thm/ThmPreferences'
import AlertDefinitionInfoPanel from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel'
import ChildStache from 'thm/ui/common/alertDefinition/AlertDefinitionConcentratedDispersedInfoPanelTpl.stache'

/**
 * @class AlertDefinitionConcentratedDispersedInfoPanel
 * Alert Definition "Concentrated / Dispersed" Form.
 */
let AlertDefinitionConcentratedDispersedInfoPanel =
  AlertDefinitionInfoPanel.extend({
    id: 'AlertDefinitionConcentratedDispersedInfoPanel',
    childTpl: ChildStache,
    timingAllowed: true,

    bindEvents: function () {
      let me = this
      me._super()
      THM_PREF.bind('unitChanged', function (ev, newVal, oldVal) {
        me.updateViewModel()
      })
    },
  })
export default AlertDefinitionConcentratedDispersedInfoPanel
