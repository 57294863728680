import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import { canMap } from 'core-cmp/can'
import EditSourceCustomerPopupTplStache from 'thm/ui/common/customer/EditSourceCustomerPopupTpl.stache'
import SynchronizedSource from 'core-uda/model/customer/SynchronizedSource'
import SourceCustomerTplStache from 'thm/ui/common/customer/SourceCustomerTpl.stache'
import SourceUserTplStache from 'thm/ui/common/customer/SourceUserTpl.stache'

/**
 * Fenêtre d'édition des restrictions.
 */
let EditSourceCustomerPopup = Page.extend({
  id: 'editSourceCustomerPopup',
  routerType: 'popup',
  template: EditSourceCustomerPopupTplStache,
  i18nPrefix: [
    'thm.ui.common.customer.EditSourceCustomerPopup.',
    'thm.ui.common.customer.CustomerInfoPanel.',
    'uda.customer.',
    'uda.synchronizedSource.',
  ],
  autoBindActions: true,
  popupWidth: 350,
  popupHeightMargin: 35,

  /**
   * @override
   */
  initViewModel: function (config) {
    let me = this

    me._super()

    me.viewModel.attr('sourceCustomer', {
      id: '',
      sourceId: undefined,
      sourceCustomerId: undefined,
      sourceCustomerCompany: undefined,
      sourceLogin: undefined,
      activated: undefined,
      lastSynchroDate: undefined,
    })
    me.viewModel.attr('hasSource', false)
    me.viewModel.attr('hasSourceCustomers', false)
    me.viewModel.attr('sourceCustomerTpl', SourceCustomerTplStache)
    me.viewModel.attr('sourceUserTpl', SourceUserTplStache)

    SynchronizedSource.findAll().done(function (datas) {
      me.viewModel.attr(
        'synchonizedSourceOptions',
        datas ? datas.getData() : [],
      )
      me.viewModel.attr('hasSource', datas && datas.getData().length > 0)
    })

    me.viewModel.on('sourceCustomer.sourceId', function (field, newValue) {
      let vm = this
      if (newValue) {
        if (APP('synchronizedSource', newValue)) {
          vm.attr(
            'sourceCustomer.sourceName',
            APP('synchronizedSource', newValue).sourceId,
          )
        }
        SynchronizedSource.findSourceCustomers(newValue).done(function (datas) {
          vm.attr('sourceCustomersOptions', datas.data)
        })
      }
    })

    me.viewModel.on(
      'sourceCustomer.sourceCustomerId',
      function (field, newValue) {
        let vm = this,
          indexCustomer,
          customer

        vm.attr('hasSourceCustomers', newValue ? true : false)
        if (newValue) {
          indexCustomer = _.findIndex(
            vm.attr('sourceCustomersOptions'),
            function (item) {
              return item.id === newValue
            },
          )
          if (indexCustomer > -1) {
            customer = vm.attr('sourceCustomersOptions')[indexCustomer]
            vm.attr('sourceCustomer.sourceCustomerCompany', customer.company)
          }
          SynchronizedSource.findUsersFromCustomer(
            vm.attr('sourceCustomer.sourceId'),
            newValue,
          ).done(function (datas) {
            vm.attr('usersFromCustomerOptions', datas.data)
          })
        }
      },
    )
  },

  setContext: function (context) {
    let me = this

    me._super(context)

    // update
    if (context.item) {
      me.viewModel.attr('sourceCustomer.id', context.item.id)
      me.viewModel.attr('sourceCustomer.sourceId', context.item.sourceId)
      me.viewModel.attr(
        'sourceCustomer.sourceCustomerId',
        context.item.sourceCustomerId,
      )
      me.viewModel.attr(
        'sourceCustomer.sourceCustomerCompany',
        context.item.sourceCustomerCompany,
      )
      me.viewModel.attr('sourceCustomer.sourceLogin', context.item.sourceLogin)
      me.viewModel.attr('sourceCustomer.activated', context.item.activated)
    } else {
      // create
      me.viewModel.attr('sourceCustomer.sourceId', undefined)
      me.viewModel.attr('sourceCustomer.sourceCustomerId', undefined)
      me.viewModel.attr('sourceCustomer.sourceCustomerCompany', undefined)
      me.viewModel.attr('sourceCustomer.sourceLogin', undefined)
      me.viewModel.attr('sourceCustomer.activated', undefined)
      me.viewModel.attr('sourceCustomer.lastSynchroDate', new Date())
    }
  },

  onSave: function (event) {
    let me = this,
      sourceCustomer = me.viewModel.attr('sourceCustomer').attr()

    // update
    if (me.context.onSave) {
      me.context.onSave(sourceCustomer)
    }

    // create
    if (me.context.setItems) {
      let items = [],
        lastSourceCustomer = _.last(me.context.items),
        newId = _.isUndefined(lastSourceCustomer)
          ? '0'
          : parseInt(lastSourceCustomer.id) + 1 + ''

      sourceCustomer.id = newId
      items.push(new canMap(sourceCustomer))
      me.context.setItems(items)
    }
    me.close()
  },
})

export default EditSourceCustomerPopup
