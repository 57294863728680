import ResourceUI from 'core-uda/ui/ResourceUI'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import ErsData from 'core-uda/model/ersData/ErsData'
import ErsDataComparePopup from 'thm/ui/common/ersData/comparePopup/ErsDataComparePopup'
import SelectCriteria from 'core-criteria/select/SelectCriteria'
import TextCriteria from 'core-criteria/text/TextCriteria'

import { I18N } from 'core-cmp/util/I18n'

const COMPARE_ALL_ACTION = (context, button) => {
  ErsData.findCorrected(context.data).done((result) => {
    ErsDataComparePopup.openPage({
      message: context.data,
      activities: result.ersActivities,
      compareAll: true,
    })
  })
}

const COMPARE_ACTION = (context, button) => {
  ErsData.findCorrected(context.data).done((result) => {
    ErsDataComparePopup.openPage({
      message: context.data,
      activities: result.ersActivities,
    })
  })
}

/**
 * User Interface elements for ErsQuery
 */
let ErsDataUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ErsData.itemId,
      icon: 'database',
      i18nPrefix: 'thm.ui.common.ersData.ErsDataUI.',
    })
  },

  /**
   * @override
   */
  getUreContextMenuActions: function (context) {
    const isRowClick = context.type === 'data',
      comparable = isRowClick && context.data && context.data.kind === 'UPD'

    return [
      {
        id: 'compare',
        icon: 'resizeH',
        labelKey: '_Compare',
        isAuthorized: comparable,
        handler: COMPARE_ACTION,
      },
      {
        id: 'compareAll',
        icon: 'resizeH',
        labelKey: '_CompareAll',
        isAuthorized: comparable,
        handler: COMPARE_ALL_ACTION,
      },
    ]
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      status: {
        cmpClass: SelectCriteria,
      },
      actionType: {
        cmpClass: SelectCriteria,
        optionsKeyPrefix: 'uda.ersData.field.actionType.options.',
        labelKey: 'uda.ersData.field.actionType',
      },
      tripPattern: {
        cmpClass: TextCriteria,
        labelKey: 'uda.ersTrip.field.number',
      },
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      kind: I18N_FIELD('uda.ersData.field.actionType.options.'),
    }
  },

  /**
   * @override
   */
  getUreMasterDetailLinks: function () {
    return [
      {
        type: 'oneToMany',
        foreignKeyFields: 'ersDataId',
        foreignResource: 'ersActivity',
        linkParams: {
          subLabel: I18N.msg('uda.ersData.field.parentDataId'),
          originField: 'parentDataId',
        },
      },
    ]
  },

  /**
   * @override
   */
  getUreMasterDetailResources: function () {
    return ['ersActivity']
  },
})

const ERS_DATA_UI = new ErsDataUI()

export default ErsDataUI
