import { PREF } from 'core-cmp/Preferences'
import ResourceUI from 'core-uda/ui/ResourceUI'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import LocationStatData from 'core-uda/model/locationStatData/LocationStatData'
import SelectCriteria from 'core-criteria/select/SelectCriteria'
import ThmSelectFlagCriteria from 'thm/ui/common/locationStatData/ThmSelectFlagCriteria'
import ThmDateWeeklyMonthlyCriteria from 'thm/ui/common/locationStatData/ThmDateWeeklyMonthlyCriteria'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'

/**
 * User Interface elements for LocationStatData
 */
let LocationStatDataUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: LocationStatData.itemId,
      i18nPrefix: 'thm.ui.common.locationStatData.LocationStatDataUI.',
      icon: 'dataToPosition',
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      date: {
        udaNames: ['from', 'to'],
        extraUdaNames: ['since', 'weekly', 'monthly'],
        cmpClass: ThmDateWeeklyMonthlyCriteria,
      },
      selectBy: {
        cmpClass: ThmSelectByCriteria,
      },
      flag: {
        cmpClass: ThmSelectFlagCriteria,
      },
    }
  },
})

const LOCATION_STAT_DATA_UI = new LocationStatDataUI()

export default LocationStatDataUI
