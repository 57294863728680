import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import { THM_PREF } from 'thm/ThmPreferences'
import { DATA_AUTHORIZED, RIGHTS } from 'core-uda/Rights'
import CollectedData from 'core-cdata/model/CollectedData'
import MtccReportPopupTplStache from 'thm/ui/common/mtccreport/MtccReportPopupTpl.stache'

let MtccReportPopup = Page.extend({
  template: MtccReportPopupTplStache,
  i18nPrefix: ['thm.ui.common.mtccreport.MtccReportPage.'],
  id: 'mtccReportPopup',
  routerType: 'popup',
  modal: true,
  mapDatas: {
    type: 'Type',
    speed: 'Speed (kts)',
    emission: 'Emission reduction technology onboard',
    tonnage: 'totalCargoMassCarried (mT)',
    teuFull: 'numberOfTEU (full)',
    teuEmpty: 'numberOfTEU (empty)',
    imo: 'IMO',
    gt: 'GT',
    nt: 'NT',
    dwt: 'DWT',
    mainEngines: 'powerOutputMainEngines (kW)',
    auxiliaryEngines: 'powerOutputAuxiliaryEngines (kW)',
    startDate: 'StartDateMillis',
    endDate: 'EndDateMillis',
    distance: 'distanceTravelled (nm)',
    hours: 'hoursUnderway',
    diesel: 'Diesel/Gas Oil (T)',
    lfo: 'LFO (T)',
    hfo: 'HFO (T)',
    lpgPropane: 'LPG Propane (T)',
    lpgButane: 'LPG Butane (T)',
    lng: 'LNG (T)',
    methanol: 'methanol (T)',
    ethanol: 'ethanol (T)',
    other: 'other (T)',
    method: 'measurementMethod',
    otherMethod: 'otherMeasurementMethod',
  },

  updateViewModel: function () {
    let me = this,
      datas = []

    me._super()

    let paramsNotEmpty = {}
    _.each(me.context._data, (v, k) => {
      if (v != '') {
        paramsNotEmpty[k] = v
      }
    })

    _.each(paramsNotEmpty, (v, k) => {
      switch (k) {
        case 'vessel':
          datas.push({
            key: me.msg('field.' + k),
            value: MOBILE_DATA.getMobileLabel(APP('mobiles', v)),
          })
          break
        case 'startDate':
        case 'endDate':
          datas.push({
            key: me.msg('field.' + k),
            value: THM_PREF.format('date', v),
          })
          break
        case 'method':
          datas.push({
            key: me.msg('field.' + k),
            value: me.msg('field.method.options.' + v),
          })
          break
        default:
          datas.push({ key: me.msg('field.' + k), value: v })
      }
    })

    me.viewModel.attr('datas', datas)
    me.paramsNotEmpty = paramsNotEmpty
  },

  doBeforeOpen: function () {
    let me = this
    me._super()
    me.updateViewModel()
  },

  bindEvents: function () {
    let me = this
    me._super()
    me.bindActions()
  },

  onSubmit: function () {
    let me = this,
      json = {}

    _.each(me.paramsNotEmpty, (v, k) => {
      let param = me.mapDatas[k]
      if (param) {
        if (k.indexOf('Date') > -1) {
          json[param] = v.getTime()
        } else if (k == 'method') {
          json[param] = me.msg('field.method.options.' + v)
        } else {
          json[param] = v
        }
      }
    })

    var cd = new CollectedData({
      mobileId: me.paramsNotEmpty.vessel,
      data: JSON.stringify(json),
      family: 'MTCC',
      subFamily: 'Report',
      source: 'U',
    })

    if (me.paramsNotEmpty.longitude != '' && me.paramsNotEmpty.latitude != '') {
      cd.attr(
        'loc',
        JSON.stringify([
          me.paramsNotEmpty.longitude,
          me.paramsNotEmpty.latitude,
        ]),
      )
    }

    //On sauvegarde
    me.listenStatus(cd.save(), 'saveStatus').done(function () {
      me.close()
    })
  },
})

export default MtccReportPopup
