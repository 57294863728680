import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import Command from 'core-uda/model/command/Command'
import { DATA_AUTHORIZED } from 'core-uda/Rights'

import SelectCriteria from 'core-criteria/select/SelectCriteria'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for ErsCommand
 */
let CommandUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Command.itemId,
      i18nPrefix: 'thm.ui.common.command.CommandUI.',
      icon: 'command',
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      kind: {
        cmpClass: SelectCriteria,
      },
      status: {
        cmpClass: SelectCriteria,
        labelAlign: 'top',
      },
    }
  },
})

const ERS_COMMAND_UI = new CommandUI()

export default CommandUI
