import ResourceUI from 'core-uda/ui/ResourceUI'
import CatchDeclaration from 'core-uda/model/catchDeclaration/CatchDeclaration'
import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for ErsQuery
 */
let CatchDeclarationUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: CatchDeclaration.itemId,
      icon: 'fishCatch',
      i18nPrefix: 'thm.ui.common.catchDeclaration.CatchDeclarationUI.',
    })
  },
})

const CATCH_DECLARATION_UI = new CatchDeclarationUI()

export default CatchDeclarationUI
