import _ from 'lodash'
import InfoPanel from 'core-uda/ui/InfoPanel'
import BeaconType from 'core-uda/model/beacon/BeaconType'
import template from 'thm/ui/common/beaconType/BeaconTypeInfoPanelTpl.stache'
import { COMMAND_GROUPS } from 'thm/model/beaconType/ThmBeaconType'

/**
 * Beacon Type form.
 * @class BeaconTypeInfoPanel
 */
let BeaconTypeInfoPanel = InfoPanel.extend({
  template: template,
  i18nPrefix: [
    'uda.beaconModel.',
    'thm.ui.common.beaconModel.BeaconTypeInfoPanel.',
  ],
  id: 'beaconTypeInfoPanel',
  entityId: BeaconType.itemId,
  allowWidth: true,
  allowClone: true,
  titleKey: 'title',

  /**
   * @override
   */
  getTitle: function () {
    let me = this
    return me._super() || ''
  },

  /**
   * @override
   */
  initViewModel: function () {
    const me = this,
      groupList = _.keys(COMMAND_GROUPS)

    groupList.sort()

    me._super()
    me.viewModel.attr('commandGroups', groupList)
  },

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this
    dataModel.attr({
      name: me.msg('_Clone') + ' - ' + dataModel.name,
    })
  },

  /**
   * @override
   */
  updateDataModel: function () {
    let me = this,
      dataModel = Object.assign({}, me.newData),
      commandGroup

    /**
     * data.commands is a array of command group  :
     * commands : [{name:"pool",activeValue:true},{name:"reportInterval",activeValue:true,progValues:"6h,1d"}]
     *
     * UI is waiting for a map :
     * pool: true,
     * reportInterval:true,
     * ...
     * progValues : "6h,1d"
     *
     * The code below transform commands array to map.
     */

    // If form is not a command list displayed but command group list.
    // Ex : "pirat" id a group of 3 commands (activate this group activates test 3 commands)
    _.each(COMMAND_GROUPS, (commands, group) => {
      commandGroup = _.find(dataModel.commands, { name: group }) || {}
      dataModel[group] = !!commandGroup.activeValue

      if (group === 'reportingInterval') {
        dataModel.progValues = commandGroup.values || ''
      }
    })
    delete dataModel.commands

    me.viewModel.attr('dataModel').attr({}, true)
    me.viewModel.attr('dataModel').attr(dataModel, true)
  },

  /**
   * @override
   */
  getDataModel: function () {
    let me = this,
      data = me._super(),
      commandGroupList = [],
      commandGroup

    /**
     * data.commands is a array of command group  :
     * commands : [{name:"pool",activeValue:true},{name:"reportInterval",activeValue:true,progValues:"6h,1d"}]
     *
     * UI is waiting for a map :
     * pool: true,
     * reportInterval:true,
     * ...
     * progValues : "6h,1d"
     *
     * The code below transform map to array.
     */

    // If form is not a command list displayed but command group list.
    // Ex : "pirat" id a group of 3 commands (activate this group activates test 3 commands)
    _.each(COMMAND_GROUPS, (commands, group) => {
      commandGroup = _.find(data.commands, { name: group })

      if (!commandGroup) {
        commandGroup = {
          name: group,
          activeValue: false,
        }
      }

      commandGroup.activeValue = data[group]
      commandGroupList.push(commandGroup)

      if (group === 'reportingInterval') {
        commandGroup.values = data.progValues
      }

      delete data[group]
    })

    data.commands = commandGroupList
    delete data.progValues

    return data
  },

  /**
   * @override
   */
  cleanForEqual: function (data) {
    if (data.commands) {
      // Remove deprecated entries and clean activeValue and values fields
      data.commands = _.filter(data.commands, (c) => {
        c.activeValue = !!c.activeValue // => change undefined or null to boolean
        if (_.isEmpty(c.values) || c.name !== 'reportingInterval') {
          delete c.values
        }
        return !!COMMAND_GROUPS[c.name]
      })

      // Add Missing entries (new command added)
      _.each(COMMAND_GROUPS, (commands, group) => {
        if (!_.find(data.commands, { name: group })) {
          data.commands.push({
            name: group,
            activeValue: false,
          })
        }
      })

      // Sort for comparison
      data.commands.sort((a, b) => a.name.localeCompare(b.name))
    }

    return data
  },
})

export default BeaconTypeInfoPanel
