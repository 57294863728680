import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import ZoneType from 'core-zone/model/ZoneType'
import template from 'thm/ui/common/zoneType/ZoneTypeTpl.stache'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for Zone Type
 */
let ZoneTypeUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ZoneType.itemId,
      i18nPrefix: 'thm.ui.common.zone.ZoneTypeUI.',
      icon: 'zoneLabel',
      template: template,
    })
  },
})

const ZONE_TYPE_UI = new ZoneTypeUI()

export default ZoneTypeUI
