import { PREF } from 'core-cmp/Preferences'
import TrajectoryStyleEngine from 'core-mobile/service/style/TrajectoryStyleEngine'
import _ from 'core-cmp/lodash'
import { THM_TRAJECTORY_STYLE_HELPER } from 'thm/service/style/ThmTrajectoryStyleHelper'

/**
 * Modèle de style "Fishing"
 */
let ThmFishingStyleEngine = TrajectoryStyleEngine.extend({
  modelName: 'Fishing',
  version: 1,
  specificRules: null,

  /**
   * Constructeur.
   */
  init: function (config) {
    let me = this

    me.specificRules = Object.assign(
      {
        lineDefault: {
          strokeDashstyle: 'solid',
          strokeColor: '#0247FE',
          strokeWidth: 2,
          strokeOpacity: 1,
        },
        lineLevel: {
          number: 2,
        },
        lineLevel1: {
          speed: 1,
          strokeColor: '#FABC02',
        },
        lineLevel2: {
          speed: 3,
          strokeColor: '#66B032',
        },
        lineLevel3: {
          speed: 8,
          strokeColor: '#FFFFFF',
        },
        line: function (feature, attr) {
          let speed = attr.trajectory.speedFor(attr.start),
            speedNumber = this.definitions['lineLevel'].number,
            duration = attr.trajectory.durationFor(attr.start),
            breakRouteValue = PREF().breakRouteValue,
            useBreakRoute = PREF().breakRoute,
            lineClazz = 'lineDefault',
            customAttributes = me.getCustomAttribut(attr, 'line'),
            position = attr.bo ? attr.bo.positions[attr.end] : undefined

          if (!isNaN(breakRouteValue)) {
            /** On convertit en secondes */
            breakRouteValue = breakRouteValue * 60
            if (duration > breakRouteValue && useBreakRoute) {
              if (this.definitions.lineBreakroute.strokeDashstyle == 'hidden') {
                return 'hidden'
              }
              lineClazz = 'lineBreakroute'
            }
          }

          // THM-10857 ::: When we have selected points we higlight the line
          if (
            position &&
            position.multiSelect &&
            !position.multiSelectExclusion
          ) {
            return _.assign(
              {
                extend: 'backLine',
              },
              customAttributes,
            )
          }

          let tabSpeed = []

          for (let i = 1; i <= speedNumber; i++) {
            tabSpeed.push([
              this.definitions['lineLevel' + i].speed,
              'lineLevel' + i,
            ])
          }

          tabSpeed.sort(function (x, y) {
            return x[0] > y[0] ? 1 : x[0] < y[0] ? -1 : 0
          })

          for (let i = tabSpeed.length - 1; i >= 0; i--) {
            if (speed > tabSpeed[i][0]) {
              return lineClazz + ' ' + tabSpeed[i][1]
            }
          }

          if (customAttributes) {
            return _.assign(
              {
                extend: lineClazz,
              },
              customAttributes,
            )
          }

          return lineClazz
        },
        pointLabel: {
          label: function (feature, attributes) {
            return THM_TRAJECTORY_STYLE_HELPER.getLabel({
              model: this.definitions,
              isLast: false,
              position:
                attributes.estimatedPosition ||
                attributes.trajectory.positions[attributes.end],
            })
          },
          textOffsetX: 10,
          textOffsetY: 10,
          textAlign: 'left',
          textBaseline: 'top',
          fontColor: '#0247FE',
          textFont: 'bold 8px tahoma,arial,verdana,sans-serif',
          textStrokeColor: 'rgba(255,255,255,1)',
          textStrokeWidth: 2,
        },
        lastPointLabel: {
          label: function (feature, attributes) {
            return THM_TRAJECTORY_STYLE_HELPER.getLabel({
              model: this.definitions,
              isLast: true,
              position:
                attributes.estimatedPosition ||
                attributes.trajectory.positions[attributes.end],
            })
          },
          textOffsetX: 10,
          textOffsetY: 10,
          textAlign: 'left',
          textBaseline: 'top',
          fontColor: '#0247FE',
          textFont: 'bold 8px tahoma,arial,verdana,sans-serif',
          textStrokeColor: 'rgba(255,255,255,1)',
          textStrokeWidth: 2,
        },
      },
      config ? config.specificRules : {},
    )

    me._super()
  },
})

ThmFishingStyleEngine.INSTANCE = new ThmFishingStyleEngine()

export default ThmFishingStyleEngine
