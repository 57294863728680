import ResourceUI from 'core-uda/ui/ResourceUI'
import LesInmc from 'core-uda/model/beacon/LesInmc'
import template from 'thm/ui/common/lesInmc/LesInmcUITpl.stache'

/**
 * User Interface elements for LesInmc
 */
let LesInmcUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: LesInmc.itemId,
      i18nPrefix: 'thm.ui.common.lesInmc.LesInmcUI.',
      icon: 'station',
      template: template,
      isListEditable: true,
    })
  },
})

const LES_INMC_UI = new LesInmcUI()

export { LES_INMC_UI }
export default LesInmcUI
