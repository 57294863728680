import { PREF } from 'core-cmp/Preferences'
import ResourceUI from 'core-uda/ui/ResourceUI'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import Trip from 'core-uda/model/trip/Trip'
import SelectCriteria from 'core-criteria/select/SelectCriteria'

/**
 * User Interface elements for Trip
 */
let TripUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Trip.itemId,
      i18nPrefix: 'thm.ui.common.trip.TripUI.',
      icon: 'route',
    })
  },
})

const TRIP_UI = new TripUI()

export default TripUI
