import ColumnManagementPage from 'core-uda/ui/grid/columnManagement/ColumnManagementPage'

let msg = I18N.prefix()

/**
 * @class ThmColumnManagementPage
 *
 * Gestion des colonnes.
 */
let ThmColumnManagementPage = ColumnManagementPage.extend({
  /**
   * @override
   */
  updateGrid: function () {
    let me = this,
      data = me.viewModel.attr('datas')

    me.child.grid.gridinstance.option(
      'groupPanel.visible',
      data.attr('group') !== 'none',
    )

    me._super()
  },
})

export default ThmColumnManagementPage
