import ResourceUI from 'core-uda/ui/ResourceUI'
import Fmc from 'core-uda/model/fmc/Fmc'
import template from 'thm/ui/common/fmc/FmcUITpl.stache'

/**
 * User Interface elements for Fmc
 */
let FmcUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Fmc.itemId,
      i18nPrefix: 'thm.ui.common.fmc.FmcUI.',
      icon: 'fmc',
      template: template,
    })
  },
})

const FMC_UI = new FmcUI()

export { FMC_UI }
export default FmcUI
