import _ from 'core-cmp/lodash'

import { PREF } from 'core-cmp/Preferences'
import TrajectoryStyleEngine from 'core-mobile/service/style/TrajectoryStyleEngine'
import Mobile from 'core-uda/model/mobile/Mobile'
import { THM_TRAJECTORY_STYLE_HELPER } from 'thm/service/style/ThmTrajectoryStyleHelper'

/**
 * Modèle abstrait dont hérite les autres modèles de stryles de trajectoires.
 */
let ThmTrackingStyleEngine = TrajectoryStyleEngine.extend({
  modelName: 'Tracking',
  version: 3,
  specificRules: null,

  /**
   * Constructeur.
   */
  init: function () {
    let me = this

    me.specificRules = {
      lineDefault: {
        strokeColor: '#0247FE',
      },
      lineBreakroute: {
        strokeDashstyle: 'dash',
      },
      point: function (feature, attributes) {
        let estimated = !!attributes.estimatedPosition,
          position = estimated
            ? attributes.trajectory.getLastEstimatedPosition()
            : attributes.trajectory.positions[attributes.end],
          isMobileData = true

        // Test il all positions are created from mobile Data (collected data, sensors,...)
        for (let i = attributes.start; i <= attributes.end; i++) {
          if (!attributes.trajectory.positions[i].isMobileData) {
            isMobileData = false
            break
          }
        }

        let properties = {
          isLast: !estimated && attributes.trajectory.isLast(attributes.end),
          isSelected: feature.intent === 'select',
          isEstimated: estimated,
          isMultiSelected:
            position.multiSelect && !position.multiSelectExclusion,
          hasHeading: _.isNumber(position.heading),
          hasSpeed: _.isNumber(position.speed),
          hasStationarySpeed: PREF().stationarySpeed,
          heading: attributes.trajectory.changeHeading(position),
          stationarySpeed: PREF().stationarySpeedValue,
          speed: position.speed,
          dynamicPointType: this.definitions['mobileDynamicTypes'].select,
          dynamicColorType: this.definitions['mobileDynamicColors'].select,
          mobileTypes: position.mobile.mobileTypeId,
          shipowners: Mobile.getMainOwnerId(position.mobile) || 'defaultType',
          flags: position.mobile.flagId,
          mobileReportingStatuss: position.mobileReportingStatusId,
          instance: this,
          isMobileData: isMobileData,
          lateForAnalyse: position.lateForAnalyse,
          toBeAnalysed: position.toBeAnalysed,
          customAttributes: me.getCustomAttribut(attributes, 'point'),
        }
        return this.definitions.getPoint(properties, attributes)
      },
      line: function (feature, attributes) {
        let trajectory = attributes.trajectory,
          duration = trajectory.durationFor(attributes.start),
          breakRouteValue = PREF().breakRouteValue,
          useBreakRoute = PREF().breakRoute,
          customAttributes = me.getCustomAttribut(attributes, 'line'),
          position = attributes.bo
            ? attributes.bo.positions[attributes.end]
            : undefined

        if (!isNaN(breakRouteValue)) {
          breakRouteValue = breakRouteValue * 60 // minute to seconds
          if (duration > breakRouteValue && useBreakRoute) {
            if (this.definitions.lineBreakroute.strokeDashstyle === 'hidden') {
              return 'hidden'
            }
            if (customAttributes) {
              return _.assign(
                {
                  extend: 'lineBreakroute',
                },
                customAttributes,
              )
            }
            return 'lineBreakroute'
          }
        }

        // THM-10857 ::: When we have selected points we higlight the line
        if (
          position &&
          position.multiSelect &&
          !position.multiSelectExclusion
        ) {
          return _.assign(
            {
              extend: 'backLine',
            },
            customAttributes,
          )
        }

        if (customAttributes) {
          return _.assign(
            {
              extend: 'lineDefault',
            },
            customAttributes,
          )
        }
        return 'lineDefault'
      },
      pointLabel: {
        label: function (feature, attributes) {
          return THM_TRAJECTORY_STYLE_HELPER.getLabel({
            model: this.definitions,
            isLast: false,
            position:
              attributes.estimatedPosition ||
              attributes.trajectory.positions[attributes.end],
          })
        },
        textOffsetX: 10,
        textOffsetY: 10,
        textAlign: 'left',
        textBaseline: 'top',
        fontColor: '#0247FE',
        textFont: 'bold 8px tahoma,arial,verdana,sans-serif',
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
      },
      lastPointLabel: {
        label: function (feature, attributes) {
          return THM_TRAJECTORY_STYLE_HELPER.getLabel({
            model: this.definitions,
            isLast: true,
            position:
              attributes.estimatedPosition ||
              attributes.trajectory.positions[attributes.end],
          })
        },
        textOffsetX: 10,
        textOffsetY: 10,
        textAlign: 'left',
        textBaseline: 'top',
        fontColor: '#0247FE',
        textFont: 'bold 8px tahoma,arial,verdana,sans-serif',
        textStrokeColor: 'rgba(255,255,255,1)',
        textStrokeWidth: 2,
      },
    }

    me._super()
  },
})

ThmTrackingStyleEngine.INSTANCE = new ThmTrackingStyleEngine()

export default ThmTrackingStyleEngine
