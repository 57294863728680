import Page from 'core-cmp/Page'
import EnumFilter from 'core-cmp/wc/util/EnumFilter'
import template from 'thm/ui/common/fleet/FleetListSearchPageTpl.stache'
import { APP } from 'core-uda/model/Resource'

/**
 * Fenêtre d'édition d'un point d'une zone.
 */
let FleetListSearchPage = Page.extend({
  id: 'fleetListSearchPage',
  routerType: 'popup',
  positionMode: 'mouse',
  popupWidth: 350,
  popupFloat: true,
  template: template,
  autoBindActions: true,
  modal: false,
  titleKey: '_Search',

  init: function (config) {
    let me = this,
      kinds = ['mobileList', 'criteriaList']

    me.kindFilter = new EnumFilter({
      field: 'kind',
      css: 'kind-match',
      pattern: kinds,
      clearPattern: kinds,
    })

    me._super(config)
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('kindOptions', me.kindFilter.clearPattern)
    me.viewModel.on('kind', me._onKindChange.bind(me))
    me.viewModel.on('searchQuery', me._onSearchQueryChange.bind(me))
    me.viewModel.attr('kindOptions', [
      {
        value: 'mobileList',
        icon: 'mobile',
        text: me.msg('uda.fleet.field.kind.mobileList'),
      },
      {
        value: 'criteriaList',
        icon: 'search',
        text: me.msg('uda.fleet.field.kind.criteriaList'),
      },
    ])
  },

  doSearch: function () {
    let me = this

    if (me.notDoSearch) {
      return
    }
    me.context.headerModel.doSearch()
  },

  _onKindChange: function () {
    let me = this

    me.kindFilter.setPattern(me.viewModel.attr('kind'))
    me.doSearch()
  },

  _onSearchQueryChange: function () {
    let me = this

    me.context.headerModel.searchQuery = me.viewModel.attr('searchQuery')
    me.doSearch()
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this

    me._super()
    me.notDoSearch = true

    me.context.itemFilter.addFilter(me.kindFilter, true)

    me.viewModel.attr('kind', me.kindFilter.pattern)
    me.viewModel.attr('searchQuery', me.context.headerModel.searchQuery)

    me.notDoSearch = false
  },

  onClear: function () {
    let me = this

    me.notDoSearch = true
    me.context.itemFilter.clear()
    me.viewModel.attr('kind', me.kindFilter.pattern)
    me.notDoSearch = false

    me.context.headerModel.searchQuery = ''
    me.context.headerModel.searchByString(null)
  },
})

export default FleetListSearchPage
