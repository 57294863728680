import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import Species from 'core-uda/model/species/Species'
import template from 'thm/ui/common/species/SpeciesTpl.stache'
import { PREF } from 'core-cmp/Preferences'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for Species
 */
let SpeciesUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Species.itemId,
      i18nPrefix: 'thm.ui.common.species.SpeciesUI.',
      icon: 'fish',
      domainIcons: { animal: 'paw' },
      searchFields: ['name', 'code'],
      template: template,
      isListEditable: true,
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      i18n: {
        format: function (value) {
          return value && value[PREF('locale')] ? value[PREF('locale')] : ''
        },
        cellTemplate: function (cellElement, cellInfo) {
          let value =
            cellInfo.value || cellInfo.data.name || cellInfo.data.sciName
          cellElement.append(value)
        },
        dataType: 'string',
      },
    }
  },

  /**
   * @override
   */
  getLabel: function (item) {
    return `${item.name} (${item.code})`
  },
})

const SPECIES_UI = new SpeciesUI()

export default SpeciesUI
