import ResourceUI from 'core-uda/ui/ResourceUI'
import Cpc from 'core-uda/model/cpc/Cpc'
import template from 'thm/ui/common/cpc/CpcUITpl.stache'

/**
 * User Interface elements for Cpc
 */
let CpcUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this
    me._super({
      resourceId: Cpc.itemId,
      i18nPrefix: 'thm.ui.common.cpc.CpcUI.',
      icon: 'mail2',
      template: template,
    })
  },
})

const CPC_UI = new CpcUI()

export default CpcUI
