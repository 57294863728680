import { canStache } from 'core-cmp/can'
import _ from 'core-cmp/lodash'
import $ from 'core-uda/uda-jquery'
import { APP } from 'core-uda/model/Resource'
import { THM_PREF } from 'thm/ThmPreferences'
import InfoPanel from 'core-uda/ui/InfoPanel'
import StatusInfoPanelTplStache from 'thm/ui/common/status/StatusInfoPanelTpl.stache'
import InfoPanelHeaderTplStache from 'thm/ui/common/templates/InfoPanelHeaderTpl.stache'
import { MAIN_PAGE } from 'core-uda/ui/UdaMainPage'

canStache.registerPartial('InfoPanelHeaderTpl', InfoPanelHeaderTplStache)

let StatusInfoPanel = InfoPanel.extend({
  id: 'statusInfoPanel',
  template: StatusInfoPanelTplStache,
  i18nPrefix: ['uda.collectData.field.', 'umv.ui.StatusInfoPanel.'],
  childTpl: null,
  data: null,
  entityId: 'statusData',
  allowMenu: false,

  updatableWithHandler: function (handler) {
    let me = this

    if (!me.data || !me.data.mobile) {
      return false
    }

    switch (handler.id) {
      case 'mobilePosition':
        return handler.model.trajectory.mobile.id === me.data.mobile.id
      case 'mobileLine':
        return handler.model.trajectory.mobile.id === me.data.mobile.id
    }
  },

  updateWithHandler: function (handler) {
    let me = this

    switch (handler.id) {
      case 'mobilePosition':
      case 'mobileLine':
        me.scrollToStatusData(
          me.getStatusDataForPosition(me.data.positions[handler.model.index]),
        )
        me.scrollToStatusData(
          me.getStatusDataForPosition(me.data.positions[handler.model.index]),
        )
    }
  },

  updateViewModel: function () {
    let me = this,
      isBehaviour = false,
      statusData = []

    me._super()

    if (me.newData.statusData) {
      _.each(me.newData.statusData, function (statusPerDate, id) {
        var status = {
          date: THM_PREF.format(
            'dateTime',
            new Date(statusPerDate.statusDateMillis),
          ),
          dateMillis: statusPerDate.statusDateMillis,
          data: [],
          id: id,
        }

        _.each(statusPerDate.data, function (data) {
          if (data.type === 'BEH') {
            isBehaviour = true
          }

          let tradValue = ''

          if (
            me
              .msg('type.' + data.type + '.' + _.camelCase(data.value))
              .indexOf('???') === -1
          ) {
            tradValue = me.msg(
              'type.' + data.type + '.' + _.camelCase(data.value),
            )
          } else if (
            me.msg('defaultValue.' + _.camelCase(data.value)).indexOf('???') ===
            -1
          ) {
            tradValue = me.msg('defaultValue.' + _.camelCase(data.value))
          } else if (
            me.msg('defaultValue.' + data.value).indexOf('???') === -1
          ) {
            tradValue = me.msg('defaultValue.' + data.value)
          } else {
            tradValue = data.value
          }

          status.data.push({
            type: data.type,
            typeLib: me.msg('type.' + data.type),
            isBehaviour: isBehaviour,
            value: tradValue,
          })
        })

        statusData.push(status)
      })
    }

    me.viewModel.attr('statusData', statusData.length > 0 ? statusData : false)

    if (statusData && statusData.length > 0) {
      setTimeout(function () {
        me.scrollToStatusData(
          me.getStatusDataForPosition(me.data.positions[me.data.index]),
        )
      }, 300)
    }
  },

  bindEvents: function () {
    let me = this

    me._super()

    me.$el.on('click', { me: me }, me.onClickTarget)
  },

  /**
   * @override
   */
  _cloneData: function (data) {
    return data
  },

  onClickTarget: function (ev) {
    let me = ev.data.me,
      target = $(ev.target)

    if (target.hasClass('ui-icon-center')) {
      let statusId = target.parents('.statusPage').attr('id'),
        status = _.filter(ev.data.me.viewModel.attr('statusData'), {
          id: statusId,
        })[0],
        index = me.getPositionForStatusData(status),
        trajectory = APP('trajectories', me.data.mobile.id)

      MAIN_PAGE().showObjectInfo(
        'mobilePosition',
        {
          trajectory: trajectory,
          index: index,
        },
        {
          moveTargetLoc: trajectory.positions[index],
        },
      )
    }
  },

  getStatusDataForPosition: function (position) {
    let me = this,
      locDate = position.locDateMillis,
      difference = 1e15,
      id = 0

    _.each(me.viewModel.attr('statusData'), function (status) {
      if (status.dateMillis == locDate) {
        id = status.id
        difference = -10
      }

      if (Math.abs(status.dateMillis - locDate) < difference) {
        difference = Math.abs(status.dateMillis - locDate)
        id = status.id
      }
    })

    return id
  },

  scrollToStatusData: function (id) {
    let me = this

    $('#' + me.id + ' .L-scrollY-content').scrollTop(
      $('#' + id)[0].offsetTop - 40,
    )
    $('.statusPage').removeClass('model-active')
    $('#' + id).addClass('model-active')
  },

  getPositionForStatusData: function (status) {
    let me = this,
      dateMillis = status.dateMillis,
      difference = 1e15,
      index = 0

    _.each(
      APP('trajectories', me.data.mobile.id).positions,
      function (position, i) {
        if (position.locDateMillis == dateMillis) {
          difference = -10
          index = i
        }

        if (Math.abs(position.locDateMillis - dateMillis) < difference) {
          difference = Math.abs(position.locDateMillis - dateMillis)
          index = i
        }
      },
    )

    return index
  },

  getTitle: function () {
    let me = this

    return me.msg('_Status')
  },
})
export default StatusInfoPanel
