import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import ErsOperationReport from 'core-uda/model/ersOperationReport/ErsOperationReport'
import { I18N } from 'core-cmp/util/I18n'
import { PREF } from 'core-cmp/Preferences'
import ThmErsTripCriteria from 'thm/ui/common/criteria/ThmErsTripCriteria'

/**
 * User Interface elements for ErsQuery
 */
let ErsOperationReportUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ErsOperationReport.itemId,
      i18nPrefix: 'thm.ui.common.ersOperationReport.ErsOperationReportUI.',
      icon: 'vessel',
    })
  },

  isAuthorizedForGridMode(gridModel) {
    return !!RIGHTS('data.ersOperationReport')
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      loc: {
        dataType: 'string',
        calculateCellValue: function (rowData) {
          return (
            PREF().format('latitude', rowData.loc[0]) +
            ', ' +
            PREF().format('longitude', rowData.loc[1])
          )
        },
      },
    }
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      ersTrip: {
        cmpClass: ThmErsTripCriteria,
      },
    }
  },
})

const ERS_OPERATION_REPORT_UI = new ErsOperationReportUI()

export default ErsOperationReportUI
