import Resource from 'core-uda/model/Resource'
import { APP } from 'core-uda/model/Resource'
import ReportModel from 'thm/model/report/ReportModel'

/**
 * Report.
 */
let Report = Resource.extend(
  {
    modelClazz: ReportModel,
  },
  {
    templateId: undefined,
    resourceId: undefined,
    fileName: undefined,
    reportRecord: undefined,

    /**
     * Save report as file.
     */
    saveAsFile: function () {
      let me = this
      APP().saveAs({
        b64: me.reportRecord,
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        fileName: me.fileName,
      })
    },
  },
)

export default Report
