import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import { PREF } from 'core-cmp/Preferences'
import Toastr from 'core-cmp/Toastr'
import InfoPanel from 'core-uda/ui/InfoPanel'
import 'thm/ui/common/mobile/MobileInfoPanel.css'
import $ from 'thm/thm-jquery'
import { MOBILE_DATA } from 'core-uda/model/mobile/MobileData'
import MobileImage from 'core-uda/model/mobile/MobileImage'
import MobileMergePopup from 'thm/ui/common/mobile/MobileMergePopup'
import MobileFixBeaconAssociationPopup from 'thm/ui/common/mobile/MobileFixBeaconAssociationPopup'
import EmptyTplStache from 'core-uda/ui/EmptyTpl.stache'
import Mobile from 'core-uda/model/mobile/Mobile'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import { UDA_URL } from 'core-uda/model/ResourceDAO'
import PositionSource from 'core-uda/model/position/PositionSource'
import ConfirmationPopup from 'core-cmp/msg/ConfirmationPopup'
import ResourceUI from 'core-uda/ui/ResourceUI'
import EditEventPopup from 'thm/ui/common/mobile/EditEventPopup'
import EventsTabMobileTplStache from 'thm/ui/common/mobile/EventsTabMobileTpl.stache'
import EventTplStache from 'thm/ui/common/mobile/EventTpl.stache'

/**
 * @class thm.ui.common.mobile.MobileInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un mobile.
 * @param {Object} options
 */
let MobileInfoPanel = InfoPanel.extend({
  template: EmptyTplStache,
  childTpl: null,
  entityId: 'mobiles',
  id: 'mobileInfoPanel',
  isImageLoaded: false,
  tabs: [],
  i18nPrefix: [
    'thm.ui.common.mobile.MobileInfoPanel.',
    'uda.mobile.',
    'uda.shipowner.',
    'thm.ui.common.mobile.EditEventPopup.',
    'thm.ui.common.mobile.MobileMergePopup.',
  ],
  disabledFields: ['flagCountryCode'],
  allowCenter: false,

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('form').attr({
      currentTab: 'identification',
    })
    me.viewModel.attr('form').on('currentTab', me.proxy(me._updateTab))
  },

  fieldsToChangeAfterCloning: function (dataModel) {
    dataModel.attr({
      activeBeaconRef: null,
      activeBeaconId: null,
      activeBeaconDnid: null,
      activeBeaconMember: null,
      activeBeaconSubAddress: null,
      activeBeaconSerialNumber: null,
      activeBeaconProviderName: null,
      activeBeaconProviderType: null,
      activeBeaconModelId: null,
      activeBeaconModelName: null,
    })
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
      tabs = [],
      lexicon = RIGHTS('user.domain'),
      reader = new FileReader()
    me._super()

    me.viewModel.attr('EventsTabTpl', EventsTabMobileTplStache)
    me.viewModel.attr('eventTpl', EventTplStache)
    me.viewModel.attr('eventEditPage', EditEventPopup.getId())
    me.viewModel.attr('eventExportOptions', {
      eventComment: {
        header: me.msg('field.eventComment'),
        width: 400,
      },
      eventTypeName: {
        header: me.msg('_Type'),
      },
      eventDate: {
        header: me.msg('_Date'),
      },
    })

    reader.onload = function (e) {
      me.adjustImage(e.target.result)
    }

    me.viewModel.attr('pageId', me.id)
    me.viewModel.attr('aisAvailable', RIGHTS('position.aisFields'))
    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.attr(
      'sourceAvailable',
      $.isTrue(APP().getCapabilities('position', 'parameters').source) &&
        RIGHTS('data.positionsCriteria.sources'),
    )
    me.viewModel.attr(
      'natureAvailable',
      $.isTrue(APP().getCapabilities('position', 'parameters').nature) &&
        RIGHTS('data.positionsCriteria.natures'),
    )

    tabs.push(
      {
        value: 'identification',
        text: me.msg('titleIdentification'),
      },
      {
        value: 'status',
        text: me.msg('titleStatus'),
      },
    )
    if (lexicon === 'fishing' || lexicon === 'tracking') {
      tabs.push({
        value: 'contacts',
        text: me.msg('contact'),
      })
    }
    tabs.push({
      value: 'specifications',
      text: me.msg('titleSpecifications'),
    })
    tabs.push({
      value: 'events',
      text: me.msg('titleEvents'),
    })
    if (RIGHTS('data.ssas')) {
      tabs.push({
        value: 'ssas',
        text: me.msg('titleSsas'),
      })
    }
    me.tabs = tabs
    me.viewModel.attr('tabs', me.tabs)

    me.viewModel.attr('certificateFormModel', {
      token: APP().user.token,
      url: '',
      application: 'umv',
      format:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      template: 'certificate',
      data: '',
    })
    me.viewModel.attr('lastSource', '')
    me.viewModel.attr('lastNature', '')

    me.viewModel.attr('image', null)
    me.viewModel.attr('imgChanged', false)
    me.viewModel.attr('imgSrc', '')
    me.viewModel.on('image', function (evt, fileList) {
      if (fileList === '' || !fileList.length) {
        this.attr('imgChanged', false)
        return // updateViewModel form field reset
      }
      reader.readAsDataURL(fileList[0])
      this.attr('imgChanged', true)
    })
  },

  /**
   * @override
   */
  bindEvents: function () {
    const me = this

    me._super()
    me.bindActions(me.$findById('image-delete'))
  },

  updateViewModel: function () {
    let me = this
    me._super()

    me.viewModel.attr('managementMode', !!me.managementMode)

    let source = ' '
    if (me.newData.lastSource) {
      source =
        me.newData.lastSource +
        ' - ' +
        PositionSource.getCodeLabel(me.newData.lastSource)
    }
    me.viewModel.attr('lastSource', source)
    me.viewModel.attr(
      'lastNature',
      me.newData.lastNature ? me.newData.lastNature : '',
    )
    me.viewModel.attr(
      'mobileTypeOptions',
      APP('mobileTypes').filterByCustomerId(me.newData.customerId),
    )
    me.showImage()
  },

  _updateTab: function () {
    let me = this,
      value = me.viewModel.attr('form.currentTab')
    me.setCurrentTab(value)
  },

  setCurrentTab: function (value) {
    let me = this

    if (value === 'identification') {
      if (me.data.imageId) {
        me.showImage()
      }
    }

    me.$find('.tab').hide()

    me.$findById('tab-' + value).show()
    me.$findById('tab-' + value).css('width', '100%')
    me.$all.css('width', '100%')

    me.viewModel.attr('form').attr('currentTab', value)
  },

  /**
   * @override
   */
  getTitle: function () {
    let me = this
    return MOBILE_DATA.getMobileLabel(me.data)
  },

  /**
   * @override
   */
  getSubTitle: function () {
    let me = this
    return MOBILE_DATA.getMobileAdditionalLabels(me.data)
  },

  /**
   * @override
   */
  getToolbarActions: function () {
    let me = this,
      actions = me._super()

    let actionsConf = [
      {
        action: 'merge',
        icon: 'vesselMeeting',
        label: me.msg('mergeVessels'),
        authorized: RIGHTS('data.mergeMobile.create'),
      },
      {
        action: 'fixBeaconAssociation',
        icon: 'beacon',
        label: me.msg('fixBeaconAssociation'),
        authorized: RIGHTS('data.fixBeaconAssociation.create'),
      },
      {
        action: 'printCertificate',
        icon: 'print',
        label: me.msg('printCertificate'),
        authorized: RIGHTS('umv.certificate'),
      },
    ]

    _.forEach(actionsConf, (actionConf) => {
      me.addToolbarAction(actions, actionConf)
    })

    return actions
  },

  /**
   * Applique le formattage de date
   * @param {Object} mobile
   */
  formatDate: function (mobile) {
    let value, newField
    for (let field in mobile) {
      if (field.indexOf('Millis') != -1) {
        value = new Date(mobile[field])
        newField = field.replace('Millis', '')
        mobile[newField] = PREF().format('dateTime', value)
      }
    }
    return mobile
  },

  showImage: function () {
    let me = this,
      mobile = APP('mobiles', me.newData.id),
      image = mobile ? mobile.image : null

    me.viewModel.attr('imgSrc', '')
    if (image) {
      me.adjustImage(image)
    } else if (me.data.imageId) {
      MobileImage.findOne(me.data.imageId).done((image) => {
        if (!!mobile) {
          mobile.image = me.data.image = image
        }
        me.adjustImage(image)
      })
    }
  },

  adjustImage: function (image) {
    let me = this,
      prefix = ''

    // check if the image is already in base64
    if (!image.includes('data:image/')) {
      prefix = 'data:image/png;base64,'
    }

    me.viewModel.attr('imgSrc', prefix + image)
  },

  onDeleteMobileImage: function (ev) {
    let me = this

    ev.stopPropagation() // Do not open lightbox
    me.viewModel.attr('imgSrc', '')
    me.viewModel.attr('imgChanged', true)
  },

  openView: function () {
    let me = this
    me._super()
    me.setCurrentTab('identification')
  },

  /**
   * @override
   */
  checkChanges: function () {
    let me = this

    // Read only => skip test (prevent bad diff)
    if (!me.editMode) {
      return false
    }
    if (me.viewModel.attr('imgChanged') || me.viewModel.attr('activeChanged')) {
      return true
    }

    return me._super()
  },

  /**
   * @override
   */
  cleanForEqual: function (attr) {
    let me = this

    delete attr.lastApwStatus
    delete attr.lastLoc
    delete attr.lastSpeed
    delete attr.lastLocDate
    delete attr.lastHeading
    delete attr.lastNature
    delete attr.lastSource
    delete attr.registrationPortName
    delete attr.shipownersIds
    delete attr.flagCountryName
    delete attr.flagCountryCode
    delete attr.mobileTypeName
    delete attr.isAisMobile

    if (attr.fishingGears && !_.isString(attr.fishingGears)) {
      attr.fishingGears = JSON.stringify(attr.fishingGears)
    }

    if (_.isNil(attr.apwDelay)) {
      attr.apwDelay = 300
    }

    // If center.ignoreEmptyZones is set and mobile used empty zone as registration port
    // ignore the difference
    if (RIGHTS('ignoreEmptyZones') && !APP('zones', attr.registrationPortId)) {
      delete attr.registrationPortId
    }

    return attr
  },

  onPrintCertificate: function () {
    let me = this,
      mobile = me.data,
      $form = me.$el.find('form'),
      datas = {},
      today = new Date()

    // 1) Création des variables spéciales
    datas = {
      dateSys: today.toString('dd/MM/yyyy'),
      yearSys: today.toString('yyyy'),
      signature: APP().user.login,
      agency: APP().user.agency,
      activeBeaconSerialNumber: _.isUndefined(mobile.activeBeaconSerialNumber)
        ? ''
        : mobile.activeBeaconSerialNumber,
    }

    if (mobile.hasValidLicence) {
      if (!!mobile.licences && mobile.licences.length > 0) {
        let validLicence = -1
        _.each(mobile.licences, function (licence) {
          if (!!licence.beginDateMillis && licence.endDateMillis) {
            let begin = new Date(licence.beginDateMillis),
              end = new Date(licence.endDateMillis)
            if (begin < today && today < end) {
              validLicence = licence.reference
            }
          }
        })
        if (validLicence != -1) {
          datas['licenceRef'] = validLicence
        }
      }
    }

    // 2) Envoie à UDA
    me.viewModel
      .attr('certificateFormModel')
      .attr('url', UDA_URL + 'mobile/' + mobile.id + '/docx')
    me.viewModel
      .attr('certificateFormModel')
      .attr('data', JSON.stringify(datas))

    let xhr = new XMLHttpRequest()
    xhr.open('POST', UDA_URL + 'mobile/' + mobile.id + '/docx')
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhr.responseType = 'blob'

    xhr.onload = function (e) {
      if (this.status == 200) {
        let blob = new Blob([this.response], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        let a = document.createElement('a')
        a.style = 'display: none'
        document.body.appendChild(a)
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'certificate.docx'
        a.click()
        window.URL.revokeObjectURL(url)
      } else {
        let blob = new Blob([this.response], { type: 'application/json' }),
          fr = new FileReader()

        fr.onload = function () {
          try {
            let err = JSON.parse(this.result),
              parameters = [MOBILE_DATA.getMobileLabel(mobile)].concat(
                err.errors[0].args,
              )
            Toastr.showToastr(
              'error',
              'printCertificate.' + err.errors[0].key,
              parameters,
            )
          } catch (e) {
            Toastr.showToastr('error', 'internalServerError')
          }
        }

        fr.readAsText(blob)
      }
    }
    xhr.send($form.serialize())
  },

  onMerge: function () {
    let me = this

    let vesselList = _.filter(
      APP('mobile').filterVesselByCustomerId(me.data.customerId),
      function (vessel) {
        return vessel.id !== me.data.id
      },
    )

    if (vesselList.length === 0 && APP().hasAllMobiles) {
      ConfirmationPopup.openPage({
        title: me.msg('alert'),
        text: me.msg('mergeMissingVessel'),
        buttons: [{ action: 'cancel' }],
        onAction: function (action) {
          if (action === 'cancel') {
          }
        },
      })
    } else {
      MobileMergePopup.openPage({
        title: me.msg(''),
        text: me.msg('mergeInfo'),
        customerId: me.data.customerId,
        vessel: me.data,
      })
    }
  },

  /**
   * Fix beacon association button action
   */
  onFixBeaconAssociation: function () {
    let me = this

    MobileFixBeaconAssociationPopup.openPage({
      beacons: me.data.beacons,
      customerId: me.data.customerId,
      mobileIdSource: me.data.id,
    })
  },

  /**
   * @override
   */
  onAfterSave: function (forceClose, data) {
    let me = this

    data.ignoreActive = true

    me._super(forceClose, data)
  },
})

export default MobileInfoPanel
