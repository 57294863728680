import InfoPanel from 'core-uda/ui/InfoPanel'
import template from 'thm/ui/common/globalZoneType/GlobalZoneTypeInfoPanelTpl.stache'
import GlobalZoneType from 'core-zone/model/GlobalZoneType'

/**
 * Form for type of global zone
 * @class GlobalZoneTypeInfoPanel
 */
let GlobalZoneTypeInfoPanel = InfoPanel.extend({
  template: template,
  i18nPrefix: [
    'uda.globalZoneType.',
    'thm.ui.common.globalZoneType.GlobalZoneTypeInfoPanel.',
  ],
  id: 'globalZoneTypeInfoPanel',
  entityId: GlobalZoneType.itemId,
  allowWidth: true,
  titleKey: 'title',
})

export default GlobalZoneTypeInfoPanel
