import Resource from 'core-uda/model/Resource'
import ReportTemplateModel from 'thm/model/report/ReportTemplateModel'

/**
 * Report Template
 */
let ReportTemplate = Resource.extend(
  {
    modelClazz: ReportTemplateModel,
  },
  {},
)

export default ReportTemplate
