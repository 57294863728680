import Page from 'core-cmp/Page'
import EmptyFilter from 'core-cmp/wc/util/EmptyFilter'
import template from 'thm/ui/common/flag/FlagListSearchPageTpl.stache'
import { APP } from 'core-uda/model/Resource'

/**
 * Fenêtre d'édition d'un point d'une zone.
 */
let FlagListSearchPage = Page.extend({
  id: 'flagListSearchPage',
  routerType: 'popup',
  positionMode: 'mouse',
  popupWidth: 350,
  popupFloat: true,
  template: template,
  autoBindActions: true,
  modal: false,
  titleKey: '_Search',
  i18nPrefix: 'thm.ui.common.zone.FlagListSearchPage.',

  init: function (config) {
    let me = this

    me._super()

    me.wsFilter = new EmptyFilter({
      field: 'webserviceUrl',
      inverse: true,
      defaultValue: undefined,
    })

    me._super(config)
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.on('onlyWebService', me._onOnlyWebService.bind(me))
    me.viewModel.on('searchQuery', me._onSearchQueryChange.bind(me))
  },

  doSearch: function () {
    let me = this

    if (me.notDoSearch) {
      return
    }
    me.context.headerModel.doSearch()
  },

  _onOnlyWebService: function () {
    let me = this

    me.wsFilter.setPattern(me.viewModel.attr('onlyWebService') ? 'isNil' : null)
    me.doSearch()
  },

  _onSearchQueryChange: function () {
    let me = this

    me.context.headerModel.searchQuery = me.viewModel.attr('searchQuery')
    me.doSearch()
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this

    me._super()
    me.notDoSearch = true

    me.context.itemFilter.addFilter(me.wsFilter, true)

    me.viewModel.attr('onlyWebService', false)
    me.viewModel.attr('searchQuery', me.context.headerModel.searchQuery)

    me.notDoSearch = false
  },

  onClear: function () {
    let me = this

    me.notDoSearch = true
    me.context.itemFilter.clear()
    me.viewModel.attr('onlyWebService', false)
    me.notDoSearch = false

    me.context.headerModel.searchQuery = ''
    me.context.headerModel.searchByString(null)
  },
})

export default FlagListSearchPage
