import _ from 'core-cmp/lodash'
import { canStache } from 'core-cmp/can'
import FieldListSelectionPage from 'core-cmp/wc/FieldListSelectionPage'
import { I18N } from 'core-cmp/util/I18n'
import { APP } from 'core-uda/model/Resource'
import { RIGHTS } from 'core-uda/Rights'
import FishingStyleInfoPanel from 'core-mobile/ui/style/FishingStyleInfoPanel'
import AnimalStyleInfoPanelTpl from 'thm/ui/umv/style/AnimalStyleInfoPanelTpl.stache'
import template from 'thm/ui/umv/style/DefaultTpl.stache'
import AnimalStyleEngine from 'thm/service/style/AnimalStyleEngine'

// Ajout d'un helper canjs pour avoir la fonction format dans les templates
canStache.registerHelper('animalStyleInfoPanelUI', (resource, id) => {
  resource = _.isFunction(resource) ? resource() : resource
  id = _.isFunction(id) ? id() : id
  let items = [],
    item

  switch (resource) {
    case 'importances':
      items = APP('animal').getImportanceOptions()
      break
    case 'sexs':
      items = APP('animal').getGenderOptions()
      break
    case 'alertDefinitions':
      items = RIGHTS('alertTypes')
        .split(',')
        .map((a, id) => {
          return { id: a, text: I18N.msg('uda.alert.field.kind.' + a) }
        })
      break
  }

  item = items.find((i) => '' + i.id === '' + id)
  if (!item) {
    return null
  }

  return item.text
})

/**
 * AnimalStyleInfoPanel
 */
let AnimalStyleInfoPanel = FishingStyleInfoPanel.extend(
  {
    ID: 'trajectory-Animal',
    ENGINE: AnimalStyleEngine.INSTANCE,
  },
  {
    id: 'animalStyleInfoPanel',
    i18nPrefix: [
      'core-uda.ui.style.StyleInfoPanel.',
      'uda.style.',
      'uda.style.trajectory.',
      'uda.animal.',
      'thm.ui.umv.style.AnimalStyleInfoPanel.',
    ],
    childTpl: AnimalStyleInfoPanelTpl,
    defautModel: {},
    dynamicFields: [
      'animalDynamicTypes',
      'animalDynamicColors',
      'animalAlertDynamicTypes',
    ],

    /**
     * @override
     */
    getDefaultRules: function () {
      return AnimalStyleEngine.INSTANCE.rules
    },

    initDefaultModel: function () {
      let me = this,
        defaultStyleModel = me.getDefaultRules()

      me.defaultModel = {
        alertDraw: true,
        animalDynamicTypes: {
          select: defaultStyleModel.animalDynamicTypes.select,
          default: defaultStyleModel.animalDynamicTypes['default'],
        },
        animalDynamicColors: {
          select: defaultStyleModel.animalDynamicColors.select,
          default: defaultStyleModel.animalDynamicColors['default'],
        },
        animalAlertDynamicTypes: {
          select: defaultStyleModel.animalAlertDynamicTypes.select,
          default: defaultStyleModel.animalAlertDynamicTypes['default'],
        },
        lineDefault: {
          strokeWidth: defaultStyleModel.lineDefault.strokeWidth,
          strokeColor: defaultStyleModel.lineDefault.strokeColor,
        },
        lineLevel: {
          number: defaultStyleModel.lineLevel.number,
        },
        lineLevel1: {
          speed: defaultStyleModel.lineLevel1.speed,
          strokeColor: defaultStyleModel.lineLevel1.strokeColor,
        },
        lineLevel2: {
          speed: defaultStyleModel.lineLevel2.speed,
          strokeColor: defaultStyleModel.lineLevel2.strokeColor,
        },
        lineLevel3: {
          speed: defaultStyleModel.lineLevel3.speed,
          strokeColor: defaultStyleModel.lineLevel3.strokeColor,
        },
      }
    },

    /**
     * @override
     */
    initViewModel: function () {
      let me = this
      me._super()
      me.viewModel.attr('categories', ['animals'])
      me.viewModel.attr('alertCategories', ['arrows'])
      me.viewModel.attr(
        'animalFamilies',
        ['importance', 'sex'].map((f) => {
          return {
            value: f + 's',
            text: me.msg(f + 's'),
          }
        }),
      )
    },

    onAddAnimalDynamicPoint: function () {
      let me = this
      me.getParentPage()
        .closeSubPages(true)
        .done(function (closedPage) {
          me.openType('animalDynamicTypes', !!closedPage)
        })
    },

    onAddAnimalDynamicColor: function () {
      let me = this,
        config = me.viewModel.attr('dataModel.animalDynamicColors'),
        resource = config.select,
        items =
          resource === 'importances'
            ? APP('animal').getImportanceOptions()
            : APP('animal').getGenderOptions()

      me.getParentPage()
        .closeSubPages(true)
        .done((closedPage) => {
          let page = FieldListSelectionPage.getUnused(),
            oldValues = config.values ? config.values.attr() : []

          page.open(
            {
              action: 'edit',
              items: items,
              value: items.filter(
                (i) =>
                  _.map(oldValues, 'id').includes('' + i.id) ||
                  _.map(oldValues, 'id').includes(i.id),
              ),
              setItems: (items) => {
                let values = _.reduce(
                  items,
                  (map, item) => {
                    map[item.id] = _.assign(
                      {},
                      me.getDefaultRules()['animalDynamicColors'].default, // default color
                      _.find(oldValues, { id: item.id }), // current color (can be null)
                      { id: item.id, resource: resource },
                    )
                    return map
                  },
                  {},
                )
                config.attr('values', values)
              },
              params: _.assign({
                title:
                  config.select === 'importances'
                    ? me.msg('importances')
                    : me.msg('sexs'),
              }),
              listConfig: {
                itemTemplate: template,
              },
              listHeaderConfig: {
                searchMode: 'memory',
                searchFields: ['text'],
                counter: true,
              },
            },
            !!closedPage,
          )
        })
    },

    onAddAnimalAlertDynamicTypes: function () {
      let me = this,
        config = me.viewModel.attr('dataModel.animalAlertDynamicTypes'),
        resource = config.select,
        items = RIGHTS('alertTypes')
          .split(',')
          .map((a, id) => {
            return { id: a, text: I18N.msg('uda.alert.field.kind.' + a) }
          })

      me.getParentPage()
        .closeSubPages(true)
        .done((closedPage) => {
          let page = FieldListSelectionPage.getUnused(),
            oldValues = config.values ? config.values.attr() : []

          page.open(
            {
              action: 'edit',
              items: items,
              value: items.filter((i) => _.map(oldValues, 'id').includes(i.id)),
              setItems: (items) => {
                let values = _.reduce(
                  items,
                  (map, item) => {
                    map[item.id] = _.assign(
                      {},
                      me.getDefaultRules()['animalAlertDynamicTypes'].default, // default color
                      _.find(oldValues, { id: item.id }), // current color (can be null)
                      { id: item.id, resource: resource },
                    )
                    return map
                  },
                  {},
                )
                config.attr('values', values)
              },
              params: _.assign({
                title: me.msg('alertsDefinition'),
              }),
              listConfig: {
                itemTemplate: template,
              },
              listHeaderConfig: {
                searchMode: 'memory',
                searchFields: ['text'],
                counter: true,
              },
            },
            !!closedPage,
          )
        })
    },
  },
)

export default AnimalStyleInfoPanel
