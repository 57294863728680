import ResourceUI from 'core-uda/ui/ResourceUI'
import Herd from 'core-uda/model/herd/Herd'

/**
 * User Interface elements for Animal
 */
let HerdUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Herd.itemId,
      i18nPrefix: 'thm.ui.common.herd.HerdUI.',
      icon: 'fish',
    })
  },
})

const HERD_UI = new HerdUI()

export default HerdUI
