<div id="{{id}}" class="L-fit L-panel">
    <ui-page-header {cmp-config}="headerConfig"></ui-page-header>
    <field-item item="dataModel" class="L-scrollY-content">
        <div class="inlineGroup fields">
            <field-text name="name"></field-text>
        </div>

        <div id="hasKind" class="{{#unless hasKind}}hidden{{/unless}}">
            <div id="vesselInfo">
                <div class="header-group icon-left icon-m ui-icon-vessel">
                    <h2>{{msg 'vesselInfo'}}</h2>
                </div>

                <div class="inlineGroup fields">
                    <field-checkbox {(field)}="registry" col="6 12"></field-checkbox>
                    <field-checkbox {(field)}="callSign" col="6 12"></field-checkbox>
                    <field-checkbox {(field)}="mobileName" col="6 12"></field-checkbox>
                    <field-checkbox {(field)}="ref" col="6 12"></field-checkbox>
                    <field-checkbox {(field)}="imo" col="6 12"></field-checkbox>
                </div>
            </div>
            {{#unless isTextOrNafNorway}}
                <div id="beaconInfo">
                    <div class="header-group icon-left icon-m ui-icon-beacon">
                        <h2>{{msg 'beaconInfo'}}</h2>
                    </div>

                    <div class="inlineGroup fields">
                        <field-checkbox {(field)}="beaconReference" col="6 12"></field-checkbox>
                        <field-checkbox {(field)}="beaconModel" col="6 12"></field-checkbox>
                        <field-checkbox {(field)}="satProvider" col="6 12"></field-checkbox>
                    </div>
                </div>
            {{/unless}}
            {{#unless isFLUX}}
                <div id="dateAndHours">
                    <div class="header-group icon-left icon-m ui-icon-calendar">
                        <h2>{{msg 'dateAndHours'}}</h2>
                    </div>

                    <div class="inlineGroup fields">
                      {{#if isTEXT}}
                          <field-format name="dateFormat" family="date"></field-format>
                          <field-format name="timeFormat" family="time"></field-format>
                          <field-hcombo label="" name="timeZone" options="timeZoneOptions" col="6 12"></field-hcombo>
                          <field-text label="GMT +" {(field)}="timeZoneValue" format="inthalf" min="-12" max="14"
                                      {visible}="isTimeZone"></field-text>
                      {{/if}}

                      {{#if isNafOrNafNorway}}
                          <field-select name="dateFormat" options="nafDateFormats"></field-select>
                          <field-select name="timeFormat" options="nafTimeFormats"></field-select>
                          <field-checkbox name="sendRecordNumber" col="6 12"></field-checkbox>
                          <field-checkbox name="sendRecordDate" col="6 12"></field-checkbox>
                          <field-checkbox name="sendRecordTime" col="6 12"></field-checkbox>
                      {{/if}}
                    </div>
                </div>
            {{/unless}}
            {{#if isNafOrNafNorway}}
                <div id="location">
                    <div class="header-group icon-left icon-m ui-icon-position">
                        <h2>{{msg 'location'}}</h2>
                    </div>

                    <div class="inlineGroup fields">
                        <field-select name="positionFormat" options="positionFormats"></field-select>
                        <field-checkbox name="sendHeadingSpeed"></field-checkbox>
                        <field-hcombo class="l20-f80" name="sendDefaultHeading"
                                      options="defaultHeadingOptions"></field-hcombo>
                        <field-text label="" name="defaultHeading" format="pnum" max="360"
                                    {visible}="isDefaultHeading"></field-text>
                    </div>
                </div>
            {{/if}}
            {{#if isTEXT}}
                <div id="speedUnit">
                    <div class="header-group icon-left icon-m ui-icon-speed">
                        <h2>{{msg 'speedUnit'}}</h2>
                    </div>

                    <div class="inlineGroup fields">
                        <field-select label="" name="speedUnit" col="6 12" options="speedUnits"></field-select>
                        <field-select label="" name="speedFormat" col="6 12" options="speedFormats"></field-select>
                    </div>
                </div>
            {{/if}}
            {{#unless isFLUX}}
              <div id="specMessage">
                  <div class="header-group icon-left icon-m ui-icon-tools">
                      <h2>{{msg 'specMessage'}}</h2>
                  </div>

                  <div class="inlineGroup fields">
                      <field-checkbox name="sendInZone" col="6 12"></field-checkbox>
                      <field-checkbox name="sendOutZone" col="6 12"></field-checkbox>
                      <field-checkbox name="sendZoneOutPosition" col="6 12" {visible}="isNafOrNafNorway"></field-checkbox>
                      <field-checkbox name="sendManualLoc" col="6 12"></field-checkbox>
                      <field-checkbox name="sendAnchored" col="6 12"></field-checkbox>
                      <field-text name="anchorRadius" col="6 12" format="distance" format-unit="m" min="0"></field-text>
                  </div>
              </div>
            {{/unless}}
            {{#if isNafOrNafNorway}}
                <div id="extraFields">
                    <div class="inlineGroup fields">
                        <field-checkbox name="fromOverride" col="6 12"></field-checkbox>
                        <field-text label="" name="fromValue" col="6 12" {visible}="dataModel.fromOverride"></field-text>
                        <field-checkbox name="useTrailingSlashes" col="6 12"></field-checkbox>
                    </div>
                </div>
            {{/if}}
        </div>
    </field-item>
    <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
