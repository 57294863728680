import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import { I18N } from 'core-cmp/util/I18n'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'
import SelectByStache from 'thm/ui/common/cpcPostData/ThmSelectStatusCriteria.stache'

/**
 * @class ThmSelectStatusCriteria
 */
let ThmSelectStatusCriteria = ThmSelectByCriteria.extend(
  {
    /**
     * @override
     */
    readParameters: function (resource, criteriaConfig, criteriaName) {
      return { options: ['status'] }
    },
  },
  {
    /**
     * @override
     * @param config
     */
    init: function (config) {
      let me = this
      me.stache = SelectByStache
      me._super(config)
      me.typeOptions = [
        {
          value: 'all',
          text: I18N.msg('uda.cpcPostData.status.all'),
        },
      ]

      me.statuses = [
        { value: 0, text: I18N.msg('uda.cpcPostData.status.ready') },
        { value: 1, text: I18N.msg('uda.cpcPostData.status.sending') },
        { value: 2, text: I18N.msg('uda.cpcPostData.status.sent') },
        { value: 3, text: I18N.msg('uda.cpcPostData.status.failed') },
        { value: 4, text: I18N.msg('uda.cpcPostData.status.accepted') },
        { value: 5, text: I18N.msg('uda.cpcPostData.status.rejected') },
      ]

      me.criteriaFormModel.attr('status', '0')
    },

    /**
     * @override
     */
    setOptions: function () {
      let me = this

      me.resourceMap.status = true
      me.states.status = false
      me.typeOptions.push({
        value: 'status',
        text: I18N.msg('uda.cpcPostData.status.selection'),
      })
    },

    /**
     * @override
     */
    setParams: function (params) {
      let me = this,
        type,
        id

      me.criteriaFormModel.type = 'all'
      if (params[me.name]) {
        type = me.name
        id = params[me.name].split(',')

        if (!_.isNil(me.states[type])) {
          me.criteriaFormModel.type = type
          me.criteriaFormModel[type] = id
        }
      }
    },

    /**
     * @override
     */
    formatModelToUDA: function () {
      let me = this,
        params = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : '',
        paramsUda = {},
        type = params.type

      if (me.formatModelToUDACustom) {
        paramsUda = me.formatModelToUDACustom(params)
      } else {
        if (type !== 'all' && !_.isEmpty(me.criteriaFormModel[type])) {
          let ids = me.criteriaFormModel[type]
          paramsUda[resourceName + me.name] = ids
        }
      }

      return paramsUda
    },

    /**
     * @override
     */
    getUdaExportData: function () {
      let me = this,
        params = me.criteriaFormModel,
        type = params.type,
        typeOption = _.find(me.typeOptions, { value: type }),
        statusId = me.criteriaFormModel[type]

      return [
        {
          key: typeOption.text,
          value: type === 'all' ? '' : me.statuses[statusId].text,
        },
      ]
    },
  },
)

export default ThmSelectStatusCriteria
