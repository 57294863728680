import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import { canMap } from 'core-cmp/can'
import { APP } from 'core-uda/model/Resource'
import Toastr from 'core-cmp/Toastr'
import template from 'thm/ui/common/mobile/EditEventPopupTpl.stache'

/**
 * Edit mobile life event.
 */
let EditEventPopup = Page.extend({
  id: 'editEventPopup',
  routerType: 'popup',
  template: template,
  i18nPrefix: [
    'thm.ui.common.mobile.EditEventPopup.',
    'uda.mobile.',
    'uda.genericMobile',
  ],
  autoBindActions: true,
  popupWidth: 350,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this

    me._super()

    me.viewModel.attr('dataModel', {
      id: '',
      eventDate: '',
      eventTypeId: '',
      eventComment: '',
      customerId: '',
    })
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me = this,
      item

    switch (me.context.action) {
      case 'create': {
        me.viewModel.attr('dataModel').attr(
          {
            eventDate: new Date(),
            eventComment: '',
          },
          true,
        )
        break
      }
      case 'update': {
        item = me.context.item
        me.viewModel
          .attr('dataModel')
          .attr(item.attr ? item.attr() : item, true)
        break
      }
      default:
        break
    }
    me.viewModel.attr('dataModel.customerId', me.context.params.customerId) // required for mobileLifeEventType création
  },

  onSave: function (evt) {
    let me = this,
      event = me.viewModel.attr('dataModel').attr()

    delete event.customerId
    event.eventTypeName = APP('mobileLifeEventType', event.eventTypeId).name

    if (_.isNil(event.eventDate) || _.isNil(event.eventTypeId)) {
      Toastr.showToastr('error', 'emptyFields')
      return
    }

    switch (me.context.action) {
      case 'create': {
        event.eventDateMillis = event.eventDate.getTime()
        event.eventDate = PREF().toServerDate(event.eventDate)
        event.id = '0'
        me.context.onSave(me.context.action, event)
        break
      }
      case 'update': {
        // Update me.context.item with edited values (do not create a new object !)
        event.id = me.context.item.id
        me.context.item.attr(event, true)
        me.context.onSave(me.context.action, me.context.item)
        break
      }
      default:
        break
    }
    me.close()
  },
})

export default EditEventPopup
