import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import { APP } from 'core-uda/model/Resource'
import { I18N } from 'core-cmp/util/I18n'
import Page from 'core-cmp/Page'
import Utils from 'core-cmp/Utils'
import ImportNafTemplate from 'thm/ui/common/cpcPostData/ImportNafTemplate'
import CpcPostData from 'core-uda/model/cpcPostData/CpcPostData'
import CpcMobilePosition from 'thm/ui/common/cpcPostData/CpcMobilePosition'
import template from 'thm/ui/common/cpcPostData/CpcPostDataCreatePageTpl.stache'
import Grid from 'core-cmp/Grid'
import Toastr from 'core-cmp/Toastr'
import UdaGrid from 'core-uda/ui/UdaGrid'
import DateFormat from 'core-cmp/format/DateFormat'

const speciesDataStore = new DevExpress.data.CustomStore({
  key: 'faoCode',
  loadMode: 'raw',
  cacheRawData: false,
  load: function () {
    return _.filter(APP().getJsonData('species'), (code) => {
      return !_.isNil(code)
    })
  },
})

/**
 * @class CpcPostDataCreatePage
 */
let CpcPostDataCreatePage = Page.extend({
  routerType: 'tab',
  template: template,
  i18nPrefix: [
    'uda.cpcPostData.',
    'thm.ui.common.cpcPostData.cpcPostDataCreatePage.',
  ],
  id: 'CpcPostDataCreatePanel',
  titleKey: 'thm.ui.common.cpcPostData.cpcPostDataCreatePage.titleTab',
  autoBindActions: true,
  nafTemplate: {},
  activities: [],
  activitiesList: {},
  activityLabels: [],
  cpcId: undefined,
  mobileLabels: [
    'callSign',
    'name',
    'internalRef',
    'externalRef',
    'transhipFrom',
  ],
  positionLabels: ['longitude', 'latitude', 'speed', 'course'],
  labelGBRForced: ['GBS', 'GBE', 'GBW', 'GBN'],
  localValuesLocation: {
    mobiles: '#CpcPostDataCreatePanel-mobileSelect field-list-select',
    transhipTo: '#transhipTo field-list-select',
    transhipFrom: '#transhipFrom field-list-select',
  },
  authorizedFieldsByActivityType: {
    catch: {
      grids: [
        { gridName: 'catches' },
        { gridName: 'discardCatches' },
        { gridName: 'onboardWeight' },
        { gridName: 'undersize' },
        { gridName: 'qtyOnOffloaded' },
      ],
    },
  },
  catchesGridConfig: {
    width: '100%',
    height: 300,
    showBorders: true,
    filterRow: null,
    editing: {
      mode: 'popup',
      allowAdding: true,
      popup: {
        showTitle: true,
        maxWidth: 600,
        maxHeight: 300,
      },
      form: {
        colCount: 1,
      },
    },
    onToolbarPreparing: function (obj) {
      if (obj.toolbarOptions && !_.isEmpty(obj.toolbarOptions.items)) {
        let addRowButton = _.find(
          obj.toolbarOptions.items,
          (btn) => btn.name === 'addRowButton',
        )
        if (addRowButton) {
          addRowButton.location = 'before'
          addRowButton.options.text = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addCatch',
          )
          addRowButton.options.hint = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addCatch',
          )
          addRowButton.options.elementAttr = {
            id: 'grid-catches-add-row-button',
          }
        }
      }
    },
    sorting: {
      mode: 'single',
    },
    export: {
      enabled: false,
    },
    columnResizingMode: 'widget',
    columns: [
      {
        caption: 'uda.species.one',
        columns: [
          {
            id: 'code',
            dataField: 'speciesCode',
            width: '25%',
            dataType: 'string',
            alignment: 'center',
            allowSorting: true,
            lookup: {
              dataSource: {
                store: speciesDataStore,
              },
              displayExpr: function (item) {
                return (
                  item.code + ' - ' + (item.i18n[PREF('locale')] || item.name)
                )
              },
              valueExpr: 'code',
            },
            cellTemplate: function (container, options) {
              return $('<span>').text(options.value)
            },
          },
        ],
      },
      {
        caption: 'catchesGrid.quantity',
        columns: [
          {
            id: 'weightFished',
            dataField: 'weightFished',
            width: '50%',
            dataType: 'number',
            alignment: 'center',
            allowSorting: true,
          },
        ],
      },
      {
        dataField: 'idCatches',
        caption: I18N.msg('jbe.activity.Activity.action'),
        alignment: 'center',
        formItem: {
          visible: false,
        },
        width: '15%',
        allowHeaderFiltering: false,
        cellTemplate: function (container, options) {
          let deleteMsg = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.deleteTitle',
          )
          $(
            '<div id="idCatches" class="btn btn-cancel icon-m ui-icon-delete delete-item-grid" title="' +
              deleteMsg +
              '"></div>',
          )
            .on(
              'dxclick',
              function (e) {
                $('#grid-catches-')
                  .dxDataGrid('instance')
                  .deleteRow(options.rowIndex)
              }.bind({ options: options }),
            )
            .appendTo(container)
        },
      },
    ],
  },
  discardCatchesGridConfig: {
    width: '100%',
    height: 300,
    showBorders: true,
    filterRow: null,
    editing: {
      mode: 'popup',
      allowAdding: true,
      popup: {
        showTitle: true,
        maxWidth: 600,
        maxHeight: 300,
      },
      form: {
        colCount: 1,
      },
    },
    onToolbarPreparing: function (obj) {
      if (obj.toolbarOptions && !_.isEmpty(obj.toolbarOptions.items)) {
        let addRowButton = _.find(
          obj.toolbarOptions.items,
          (btn) => btn.name === 'addRowButton',
        )
        if (addRowButton) {
          addRowButton.location = 'before'
          addRowButton.options.text = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addDiscardCatch',
          )
          addRowButton.options.hint = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addDiscardCatch',
          )
          addRowButton.options.elementAttr = {
            id: 'grid-discardCatches-add-row-button',
          }
        }
      }
    },
    sorting: {
      mode: 'single',
    },
    export: {
      enabled: false,
    },
    columnResizingMode: 'widget',
    columns: [
      {
        caption: 'uda.species.one',
        columns: [
          {
            id: 'code',
            dataField: 'speciesCode',
            width: '25%',
            dataType: 'string',
            alignment: 'center',
            allowSorting: true,
            lookup: {
              dataSource: {
                store: speciesDataStore,
              },
              displayExpr: function (item) {
                return (
                  item.code + ' - ' + (item.i18n[PREF('locale')] || item.name)
                )
              },
              valueExpr: 'code',
            },
            cellTemplate: function (container, options) {
              return $('<span>').text(options.value)
            },
          },
        ],
      },
      {
        caption: 'catchesGrid.quantity',
        columns: [
          {
            id: 'weightFished',
            dataField: 'weightFished',
            width: '50%',
            dataType: 'number',
            alignment: 'center',
            allowSorting: true,
          },
        ],
      },
      {
        dataField: 'idDiscardCatches',
        caption: I18N.msg('jbe.activity.Activity.action'),
        alignment: 'center',
        formItem: {
          visible: false,
        },
        width: '15%',
        allowHeaderFiltering: false,
        cellTemplate: function (container, options) {
          let deleteMsg = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.deleteTitle',
          )
          $(
            '<div id="idDiscardCatches" class="btn btn-cancel icon-m ui-icon-delete delete-item-grid" title="' +
              deleteMsg +
              '"></div>',
          )
            .on(
              'dxclick',
              function (e) {
                $('#grid-discardcatches-')
                  .dxDataGrid('instance')
                  .deleteRow(options.rowIndex)
              }.bind({ options: options }),
            )
            .appendTo(container)
        },
      },
    ],
  },
  onboardWeightGridConfig: {
    width: '100%',
    height: 300,
    showBorders: true,
    filterRow: null,
    editing: {
      mode: 'popup',
      allowAdding: true,
      popup: {
        showTitle: true,
        maxWidth: 600,
        maxHeight: 300,
      },
      form: {
        colCount: 1,
      },
    },
    onToolbarPreparing: function (obj) {
      if (obj.toolbarOptions && !_.isEmpty(obj.toolbarOptions.items)) {
        let addRowButton = _.find(
          obj.toolbarOptions.items,
          (btn) => btn.name === 'addRowButton',
        )
        if (addRowButton) {
          addRowButton.location = 'before'
          addRowButton.options.text = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addOnboardWeight',
          )
          addRowButton.options.hint = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addOnboardWeight',
          )
          addRowButton.options.elementAttr = {
            id: 'grid-onboardWeight-add-row-button',
          }
        }
      }
    },
    sorting: {
      mode: 'single',
    },
    export: {
      enabled: false,
    },
    columnResizingMode: 'widget',
    columns: [
      {
        caption: 'uda.species.one',
        columns: [
          {
            id: 'code',
            dataField: 'speciesCode',
            width: '25%',
            dataType: 'string',
            alignment: 'center',
            allowSorting: true,
            lookup: {
              dataSource: {
                store: speciesDataStore,
              },
              displayExpr: function (item) {
                return (
                  item.code + ' - ' + (item.i18n[PREF('locale')] || item.name)
                )
              },
              valueExpr: 'code',
            },
            cellTemplate: function (container, options) {
              return $('<span>').text(options.value)
            },
          },
        ],
      },
      {
        caption: 'catchesGrid.quantity',
        columns: [
          {
            id: 'weightFished',
            dataField: 'weightFished',
            width: '50%',
            dataType: 'number',
            alignment: 'center',
            allowSorting: true,
          },
        ],
      },
      {
        dataField: 'idOnboardWeight',
        caption: I18N.msg('jbe.activity.Activity.action'),
        alignment: 'center',
        formItem: {
          visible: false,
        },
        width: '15%',
        allowHeaderFiltering: false,
        cellTemplate: function (container, options) {
          let deleteMsg = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.deleteTitle',
          )
          $(
            '<div id="idOnboardWeight" class="btn btn-cancel icon-m ui-icon-delete delete-item-grid" title="' +
              deleteMsg +
              '"></div>',
          )
            .on(
              'dxclick',
              function (e) {
                $('#grid-onboardweight-')
                  .dxDataGrid('instance')
                  .deleteRow(options.rowIndex)
              }.bind({ options: options }),
            )
            .appendTo(container)
        },
      },
    ],
  },
  undersizeGridConfig: {
    width: '100%',
    height: 300,
    showBorders: true,
    filterRow: null,
    editing: {
      mode: 'popup',
      allowAdding: true,
      popup: {
        showTitle: true,
        maxWidth: 600,
        maxHeight: 300,
      },
      form: {
        colCount: 1,
      },
    },
    onToolbarPreparing: function (obj) {
      if (obj.toolbarOptions && !_.isEmpty(obj.toolbarOptions.items)) {
        let addRowButton = _.find(
          obj.toolbarOptions.items,
          (btn) => btn.name === 'addRowButton',
        )
        if (addRowButton) {
          addRowButton.location = 'before'
          addRowButton.options.text = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addUndersize',
          )
          addRowButton.options.hint = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addUndersize',
          )
          addRowButton.options.elementAttr = {
            id: 'grid-undersize-add-row-button',
          }
        }
      }
    },
    sorting: {
      mode: 'single',
    },
    export: {
      enabled: false,
    },
    columnResizingMode: 'widget',
    columns: [
      {
        caption: 'uda.species.one',
        columns: [
          {
            id: 'code',
            dataField: 'speciesCode',
            width: '25%',
            dataType: 'string',
            alignment: 'center',
            allowSorting: true,
            lookup: {
              dataSource: {
                store: speciesDataStore,
              },
              displayExpr: function (item) {
                return (
                  item.code + ' - ' + (item.i18n[PREF('locale')] || item.name)
                )
              },
              valueExpr: 'code',
            },
            cellTemplate: function (container, options) {
              return $('<span>').text(options.value)
            },
          },
        ],
      },
      {
        caption: 'catchesGrid.quantity',
        columns: [
          {
            id: 'weightFished',
            dataField: 'weightFished',
            width: '50%',
            dataType: 'number',
            alignment: 'center',
            allowSorting: true,
          },
        ],
      },
      {
        dataField: 'idUndersize',
        caption: I18N.msg('jbe.activity.Activity.action'),
        alignment: 'center',
        formItem: {
          visible: false,
        },
        width: '15%',
        allowHeaderFiltering: false,
        cellTemplate: function (container, options) {
          let deleteMsg = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.deleteTitle',
          )
          $(
            '<div id="idUndersize" class="btn btn-cancel icon-m ui-icon-delete delete-item-grid" title="' +
              deleteMsg +
              '"></div>',
          )
            .on(
              'dxclick',
              function (e) {
                $('#grid-undersize-')
                  .dxDataGrid('instance')
                  .deleteRow(options.rowIndex)
              }.bind({ options: options }),
            )
            .appendTo(container)
        },
      },
    ],
  },
  qtyOnOffloadedGridConfig: {
    width: '100%',
    height: 300,
    showBorders: true,
    filterRow: null,
    editing: {
      mode: 'popup',
      allowAdding: true,
      popup: {
        showTitle: true,
        maxWidth: 600,
        maxHeight: 300,
      },
      form: {
        colCount: 1,
      },
    },
    onToolbarPreparing: function (obj) {
      if (obj.toolbarOptions && !_.isEmpty(obj.toolbarOptions.items)) {
        let addRowButton = _.find(
          obj.toolbarOptions.items,
          (btn) => btn.name === 'addRowButton',
        )
        if (addRowButton) {
          addRowButton.location = 'before'
          addRowButton.options.text = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addQtyOnOffloaded',
          )
          addRowButton.options.hint = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.addQtyOnOffloaded',
          )
          addRowButton.options.elementAttr = {
            id: 'grid-qtyOnOffloaded-add-row-button',
          }
        }
      }
    },
    sorting: {
      mode: 'single',
    },
    export: {
      enabled: false,
    },
    columnResizingMode: 'widget',
    columns: [
      {
        caption: 'uda.species.one',
        columns: [
          {
            id: 'code',
            dataField: 'speciesCode',
            width: '25%',
            dataType: 'string',
            alignment: 'center',
            allowSorting: true,
            lookup: {
              dataSource: {
                store: speciesDataStore,
              },
              displayExpr: function (item) {
                return (
                  item.code + ' - ' + (item.i18n[PREF('locale')] || item.name)
                )
              },
              valueExpr: 'code',
            },
            cellTemplate: function (container, options) {
              return $('<span>').text(options.value)
            },
          },
        ],
      },
      {
        caption: 'catchesGrid.quantity',
        columns: [
          {
            id: 'weightFished',
            dataField: 'weightFished',
            width: '50%',
            dataType: 'number',
            alignment: 'center',
            allowSorting: true,
          },
        ],
      },
      {
        dataField: 'idQtyOnOffloaded',
        caption: I18N.msg('jbe.activity.Activity.action'),
        alignment: 'center',
        formItem: {
          visible: false,
        },
        width: '15%',
        allowHeaderFiltering: false,
        cellTemplate: function (container, options) {
          let deleteMsg = I18N.msg(
            'thm.ui.common.cpcPostData.cpcPostDataCreatePage.deleteTitle',
          )
          $(
            '<div id="idQtyOnOffloaded" class="btn btn-cancel icon-m ui-icon-delete delete-item-grid" title="' +
              deleteMsg +
              '"></div>',
          )
            .on(
              'dxclick',
              function (e) {
                $('#grid-qtyonoffloaded-')
                  .dxDataGrid('instance')
                  .deleteRow(options.rowIndex)
              }.bind({ options: options }),
            )
            .appendTo(container)
        },
      },
    ],
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this

    me._super()

    me.viewModel.attr('type', null)
    me.viewModel.attr('activity', null)
    me.viewModel.attr('stacheGenerated', false)
    me.viewModel.attr('dataModel', {})
    me.viewModel.attr('rawMessage', '//SR//ER//')
    me.viewModel.attr('mobileSelected', false)
    me.viewModel.attr('activityOptions', [])

    me.viewModel.on('type', (evt, value) => {
      if (!_.isNil(value)) {
        let activityOptions = _.map(me.activitiesList[value], (template) => {
          return {
            value: template,
            text: template,
          }
        })
        me.cpcId = me.nafTemplate[value].cpc
        me.viewModel.attr('activityOptions', activityOptions)
        me.activities = activityOptions
      }
    })

    me.viewModel.on('activity', (evt, value) => {
      me.clearActivity()
      if (!_.isNil(value)) {
        me.activityRender({
          type: me.viewModel.attr('type'),
          activity: value,
        })
      }
    })

    me.viewModel.on('dataModel.mobileId', (evt, value) => {
      if (!_.isNil(value)) {
        let mobileInfo = me.getLocal('mobiles', value)

        me.viewModel.attr('dataModel.mobileId', mobileInfo['id'])

        if (!_.isNil(me.activityLabels)) {
          _.forEach(me.activityLabels, (label) => {
            if (label === 'transhipFrom') {
              if (
                !_.isNil(mobileInfo['internalRef']) &&
                mobileInfo['internalRef'] !== ''
              ) {
                me.viewModel.attr(
                  'dataModel.' + label,
                  mobileInfo['internalRef'],
                )
              }
            } else if (label === 'from') {
              if (!_.isNil(mobileInfo['flagCountryCode'])) {
                me.viewModel.attr(
                  'dataModel.' + label,
                  mobileInfo['flagCountryCode'],
                )
              } else {
                me.viewModel.attr('dataModel.' + label, '')
              }
            } else {
              if (!_.isNil(mobileInfo[label]) && mobileInfo[label] !== '') {
                me.viewModel.attr('dataModel.' + label, mobileInfo[label])
              } else if (
                label === 'externalReference' &&
                !_.isNil(mobileInfo['ref']) &&
                mobileInfo['ref'] !== ''
              ) {
                me.viewModel.attr('dataModel.' + label, mobileInfo['ref'])
              }
            }
          })
        }
        me.viewModel.attr('mobileSelected', true)
      }
    })
  },

  activityRender: function (config) {
    let me = this,
      renderer,
      toGenerate,
      msgType = me.nafTemplate[config.type],
      msgAct = msgType.templates[config.activity]

    toGenerate = _.sortBy(msgAct.toGenerate, (field) => field.pos)
    me.$findById('generated-form').html('')
    me.viewModel.attr('rawMessage', '//SR//ER//')
    me.viewModel.attr('stacheAutoGenerated', undefined)
    _.delay(() => {
      renderer = Utils.generateFieldsStache(
        toGenerate,
        me.viewModel,
        false,
        '4 6 12',
        'dataModel',
        '',
        { fieldGroup: 'thm.ui.common.cpcPostData.cpcPostDataCreatePage.' },
      )

      me.$findById('generated-form').html($(renderer(me.viewModel)).children())
      me.viewModel.attr('stacheGenerated', true)
      //me.viewModel.attr('stacheAutoGenerated', renderer(me.viewModel));
      me.updateGrids()
      me.activityLabels = _.map(toGenerate, 'label')
      me.bindEventsForm()
      if (!_.isEmpty(_.intersection(me.activityLabels, me.mobileLabels))) {
        me.viewModel.attr('neededMobile', true)
      } else {
        me.viewModel.attr('neededMobile', false)
      }
      if (!_.isEmpty(_.intersection(me.activityLabels, me.positionLabels))) {
        me.viewModel.attr('neededPosition', true)
      } else {
        me.viewModel.attr('neededPosition', false)
      }
      //me.render(me.$container);
    }, 100)
  },

  updateGrids: function () {
    let me = this,
      gridConfigId,
      config

    _.each(me.authorizedFieldsByActivityType.catch.grids, (grid) => {
      gridConfigId = grid.gridName
      let gridNameId = 'grid-' + gridConfigId + '-',
        gridDiv = $('#' + gridNameId.toLowerCase())
      // attr() : => create copy
      if (!_.isNil(gridDiv[0])) {
        if (!me[grid.gridName]) {
          config = me[gridConfigId + 'GridConfig'].attr()
          _.each(config.columns, function (col) {
            if (col.caption) {
              col.caption = me.msg(col.caption)
              _.each(col.columns, (subCol) => {
                me.updateColumnOption(grid, subCol)
              })
            } else {
              me.updateColumnOption(grid, col)
            }
          })
          if (!_.isUndefined(grid.gridName))
            //At the load of page, the i18n file aren't load yet, the popup title isn't appear like it does this line is use to fix this
            config.editing.popup.title = I18N.msg(
              'thm.ui.common.cpcPostData.cpcPostDataCreatePage.' +
                grid.gridName +
                'PopupTitle',
            )
          me[grid.gridName] = new UdaGrid(gridDiv, config)
        } else {
          gridDiv[0].replaceWith(me[grid.gridName].$el.html())
        }
      }
    })
  },

  updateColumnOption: function (grid, col) {
    let me = this,
      gridConfigId = grid.gridName

    col.caption = me.msg(gridConfigId + '.' + col.id)
    if (!_.isUndefined(grid.gridName)) col.id = grid.gridName + '_' + col.id
    else Toastr.showToastr('error', 'failedInitGrid')

    Grid.updateColumnOptionFormat(col)
  },

  initNafTemplate: function () {
    let me = this,
      typeOptions = [],
      templates = _.sortBy(APP().getJsonData('nafTemplate'), 'id')

    me.nafTemplate = _.last(templates).value.data
    me.viewModel.attr('typeOptions', Object.keys(me.nafTemplate))
    _.forEach(Object.keys(me.nafTemplate), (type) => {
      typeOptions.push({
        value: type,
        text: type,
      })
      let typeBuffer = me.nafTemplate[type]
      me.activitiesList[type] = Object.keys(typeBuffer.templates)
    })
  },

  doBeforeOpen: function () {
    let me = this
    me._super()
    me.initNafTemplate()
  },

  bindEventsForm: function () {
    let me = this

    _.forEach(me.activityLabels, (label) => {
      me.viewModel.on('dataModel.' + label, (evt) => {
        me.generateRawMessage(
          label,
          _.take(
            me.viewModel.attr('rawMessage').split('//'),
            me.viewModel.attr('rawMessage').split('//').length - 2,
          ),
        )
      })
      me.generateRawMessage(
        label,
        _.take(
          me.viewModel.attr('rawMessage').split('//'),
          me.viewModel.attr('rawMessage').split('//').length - 2,
        ),
      )
    })

    _.forEach(me.authorizedFieldsByActivityType.catch.grids, (grid) => {
      if (!_.isNil(me[grid.gridName])) {
        me[grid.gridName].gridinstance.on('rowInserting', (e) => {
          let catches = _.clone(
              me[grid.gridName].gridinstance.option('dataSource'),
            ),
            valueMessage = ''

          catches.push(e.data)

          for (let i = 0; i < catches.length; i++) {
            if (i < catches.length - 1) {
              valueMessage +=
                catches[i].speciesCode + catches[i].weightFished + ' '
            } else {
              valueMessage += catches[i].speciesCode + catches[i].weightFished
            }
          }

          me.generateRawMessageGrid(
            grid.gridName,
            valueMessage,
            _.take(
              me.viewModel.attr('rawMessage').split('//'),
              me.viewModel.attr('rawMessage').split('//').length - 2,
            ),
          )
        })

        me[grid.gridName].gridinstance.on('rowRemoving', (e) => {
          let catches = _.clone(
              me[grid.gridName].gridinstance.option('dataSource'),
            ),
            valueMessage = ''

          catches = _.filter(catches, (c) => {
            return c.__KEY__ !== e.data.__KEY__
          })

          for (let i = 0; i < catches.length; i++) {
            if (i < catches.length - 1) {
              valueMessage +=
                catches[i].speciesCode + catches[i].weightFished + ' '
            } else {
              valueMessage += catches[i].speciesCode + catches[i].weightFished
            }
          }

          me.generateRawMessageGrid(
            grid.gridName,
            valueMessage,
            _.take(
              me.viewModel.attr('rawMessage').split('//'),
              me.viewModel.attr('rawMessage').split('//').length - 2,
            ),
          )
        })
      }
    })
  },

  generateRawMessageGrid: function (label, value, rawMsg) {
    let me = this

    if (me.viewModel.attr('fieldsProperties').attr()[label]) {
      let fieldCode = me.viewModel.attr('fieldsProperties').attr()[label].code

      if (_.isNil(value) || value === '') {
        for (let i = 0; i < rawMsg.length; i++) {
          let rawMsgFieldCode = rawMsg[i].split('/')[0]
          if (fieldCode === rawMsgFieldCode) {
            rawMsg.splice(i, 1)
          }
        }
      } else {
        let fieldInfo = fieldCode + '/' + value

        for (let i = 0; i < rawMsg.length; i++) {
          let rawMsgFieldCode = rawMsg[i].split('/')[0]
          if (fieldCode === rawMsgFieldCode) {
            rawMsg[i] = fieldInfo
          }
        }
        if (!rawMsg.includes(fieldInfo)) {
          rawMsg.push(fieldInfo)
        }
      }
    }

    rawMsg.push('ER//')

    me.viewModel.attr('rawMessage', rawMsg.join('//'))
  },

  generateRawMessage: function (label, rawMsg) {
    let me = this,
      value = me.viewModel.attr('dataModel.' + label)

    if (me.viewModel.attr('fieldsProperties').attr()[label]) {
      let fieldCode = me.viewModel.attr('fieldsProperties').attr()[label].code

      if (
        _.isNil(value) ||
        value === '' ||
        (fieldCode === 'TT' && _.isNil(me.getLocal('transhipTo'))) ||
        (fieldCode === 'TF' && _.isNil(me.getLocal('transhipFrom')))
      ) {
        for (let i = 0; i < rawMsg.length; i++) {
          let rawMsgFieldCode = rawMsg[i].split('/')[0]
          if (fieldCode === rawMsgFieldCode) {
            rawMsg.splice(i, 1)
          }
        }
      } else if (!label.toLowerCase().includes('date')) {
        let fieldValue =
            typeof value === 'string' ? value.toUpperCase() : value,
          tempValue

        switch (fieldCode) {
          case 'TT':
            fieldValue = me.getLocal('transhipTo').callSign
            break
          case 'TF':
            fieldValue = me.getLocal('transhipFrom').callSign
            break
          case 'LA':
            tempValue = PREF()
              .getFormater('latitude')
              .to(fieldValue, '00°00′ N', 'dm', 0, true) // returns "DD°MM′ N/S"
            tempValue = tempValue.split(/[′°\s]+/) // ["DD", "MM", "N/S"]
            fieldValue = tempValue[2] + tempValue[0] + tempValue[1]
            break
          case 'LO':
            tempValue = PREF()
              .getFormater('longitude')
              .to(fieldValue, '000°00′ W', 'dm', 0, true) // returns "DDD°MM′ E/W"
            tempValue = tempValue.split(/[′°\s]+/) // ["DDD", "MM", "E/W"]
            fieldValue = tempValue[2] + tempValue[0] + tempValue[1]
            break
          case 'FS': // Forced GBR value due to tierce company transmission (THM-10707)
            fieldValue =
              me.labelGBRForced.indexOf(value) > -1 ? 'GBR' : fieldValue
            break
        }

        let fieldInfo = fieldCode + '/' + fieldValue

        for (let i = 0; i < rawMsg.length; i++) {
          let rawMsgFieldCode = rawMsg[i].split('/')[0]
          if (fieldCode === rawMsgFieldCode) {
            rawMsg[i] = fieldInfo
          }
        }

        if (!rawMsg.includes(fieldInfo)) {
          rawMsg.push(fieldInfo)
        }
      } else {
        let fieldCode = me.viewModel
          .attr('fieldsProperties')
          .attr()
          [label].code.split('/')

        if (fieldCode.length > 1) {
          let fieldDateCode = fieldCode[0],
            fieldTimeCode = fieldCode[1],
            fieldValue = value,
            fieldYear = fieldValue.getUTCFullYear().toString(),
            fieldMonth = ('0' + (fieldValue.getUTCMonth() + 1)).slice(-2),
            fieldDay = ('0' + fieldValue.getUTCDate()).slice(-2),
            fieldMinutes = ('0' + fieldValue.getUTCMinutes()).slice(-2),
            fieldHours = ('0' + fieldValue.getUTCHours()).slice(-2)

          let fieldDate =
              fieldDateCode + '/' + fieldYear + fieldMonth + fieldDay,
            fieldTime = fieldTimeCode + '/' + fieldHours + fieldMinutes

          for (let i = 0; i < rawMsg.length; i++) {
            if (rawMsg[i].includes(fieldDateCode)) {
              rawMsg[i] = fieldDate
            } else if (rawMsg[i].includes(fieldTimeCode)) {
              rawMsg[i] = fieldTime
            }
          }

          if (!rawMsg.includes(fieldDate)) {
            rawMsg.push(fieldDate)
          }
          if (!rawMsg.includes(fieldTime)) {
            rawMsg.push(fieldTime)
          }
        } else {
          let fieldDateCode = fieldCode[0],
            fieldValue = value,
            fieldYear = fieldValue.getUTCFullYear().toString(),
            fieldMonth = ('0' + (fieldValue.getMonth() + 1)).slice(-2),
            fieldDay = ('0' + fieldValue.getDate()).slice(-2),
            fieldDate = fieldDateCode + '/' + fieldYear + fieldMonth + fieldDay

          for (let i = 0; i < rawMsg.length; i++) {
            if (rawMsg[i].includes(fieldDateCode)) {
              rawMsg[i] = fieldDate
            }
          }

          if (!rawMsg.includes(fieldDate)) {
            rawMsg.push(fieldDate)
          }
        }
      }
    }

    rawMsg.push('ER//')

    rawMsg = _.uniq(rawMsg)

    me.viewModel.attr('rawMessage', rawMsg.join('//'))
  },

  clearActivity: function () {
    let me = this

    me.viewModel.attr('rawMessage', '//SR//ER//')
    me.viewModel.attr('dataModel.mobileId', null)

    if (!_.isNil(me.viewModel.attr('dataModel.transhipTo'))) {
      me.viewModel.attr('dataModel.transhipTo', null)
    }

    _.forEach(me.authorizedFieldsByActivityType.catch.grids, (grid) => {
      me.$findById('grid-' + grid.gridName + '-').remove()
      delete me[grid.gridName]
    })

    me.viewModel.attr('mobileSelected', false)
    me.viewModel.attr('stacheAutoGenerated', undefined)
    me.viewModel.attr('stacheGenerated', false)
    me.viewModel.attr('neededMobile', false)
    me.viewModel.attr('neededPosition', false)
    me.$findById('generated-form').html('')
  },

  onCancel: function () {
    let me = this

    me.viewModel.attr('dataModel.date', '')
    me.viewModel.attr('dataModel.longitude', '')
    me.viewModel.attr('dataModel.latitude', '')

    me.viewModel.attr('type', null)
    me.viewModel.attr('activity', null)
    me.viewModel.attr('activityOptions', [])
    me.clearActivity()
  },

  onImport: function () {
    let me = this
    ImportNafTemplate.openPage({
      reloadNaf: function () {
        me.close()
      },
    })
  },

  onPosition: function () {
    let me = this

    CpcMobilePosition.openPage({
      mobileId: me.viewModel.attr('dataModel.mobileId'),
      selectedPosition: function (position) {
        if (!_.isNil(position)) {
          _.forEach(me.activityLabels, (label) => {
            if (label === 'longitude') {
              me.viewModel.attr('dataModel.longitude', position.loc[0])
            } else if (label === 'latitude') {
              me.viewModel.attr('dataModel.latitude', position.loc[1])
            } else if (label === 'longitudeDecimal') {
              me.viewModel.attr('dataModel.longitudeDecimal', position.loc[0])
            } else if (label === 'latitudeDecimal') {
              me.viewModel.attr('dataModel.latitudeDecimal', position.loc[1])
            } else if (label === 'date') {
              me.viewModel.attr('dataModel.' + label, position.locDate)
            } else {
              if (!_.isNil(position[label])) {
                me.viewModel.attr('dataModel.' + label, position[label])
              }
            }
          })
        }
      },
    })
  },

  onSubmit: function () {
    let me = this,
      cpcPostData = new CpcPostData({
        cpcId: me.nafTemplate[me.viewModel.attr('type')].cpc,
        rawData: me.viewModel.attr('rawMessage'),
        mobileId: me.viewModel.attr('dataModel.mobileId'),
        postStatus: 'submitted',
      })

    me.listenStatus(cpcPostData.save(), 'saveStatus').done(() => {
      Toastr.showSuccess(me.msg('dataTransSend'), 5000)
      setTimeout(() => {
        me.onCancel()
        me.close()
      }, 750)
    })
  },
})

export default CpcPostDataCreatePage
