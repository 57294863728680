import ResourceUI from 'core-uda/ui/ResourceUI'
import DataSource from 'core-uda/model/dataSource/DataSource'
import template from 'thm/ui/common/dataSource/DataSourceUITpl.stache'

/**
 * User Interface elements for DataSource
 */
let DataSourceUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: DataSource.itemId,
      i18nPrefix: 'thm.ui.common.dataSource.DataSourceUI.',
      icon: 'satellite',
      template: template,
      isListEditable: true,
    })
  },
})

const DATA_SOURCE_UI = new DataSourceUI()

export { DATA_SOURCE_UI }
export default DataSourceUI
