import { RIGHTS } from 'core-uda/Rights'
import ResourceUI from 'core-uda/ui/ResourceUI'
import VesselLog from 'core-uda/model/vesselLog/VesselLog'
import FieldValueCriteria from 'core-criteria/fieldValue/FieldValueCriteria'
import { UTILS } from 'core-cmp/Utils'
import _ from 'core-cmp/lodash'

/**
 * User Interface elements for VesselLog
 */
let VesselLogUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this
    me._super({
      resourceId: VesselLog.itemId,
      i18nPrefix: 'thm.ui.common.vesselLog.VesselLogUI.',
      icon: 'vesselMeeting',
    })
  },

  getUreCriteria: function () {
    return {
      fieldValue: {
        cmpClass: FieldValueCriteria,
        classVesselField: 'classVesselLogField',
        i18nPrefix: 'core.criteria.fieldValue.fieldValueCriteria.',
        options: RIGHTS('mobile.fields')
          .split(',')
          .map((current) => (current === 'name' ? 'mobileName' : current)),
        optionsKeyPrefix: 'uda.mobile.field.',
        resourceName: 'vesselLog',
        //comparators:      ["startWith", "equals", "contains"]
      },
    }
  },

  /**
   * @override
   */
  onGridRowPrepared: function (dxEvent, allDatas, lastSearch) {
    let filterFunction = (currentVal, currentKey) => {
        //we don't want to color the cell when we go from "" to "undefined" or from "undefined" to ""
        if (currentVal.from + '' == 'undefined') {
          // convert cur.from and cur.to to string to avoid (0 == '') problem
          return currentVal.to + '' != ''
        } else if (currentVal.from + '' == '') {
          return currentVal.to + '' != 'undefined'
        } else {
          // Excluding dates and id
          return (
            currentKey !== 'creationDate' &&
            currentKey !== 'creationDateMillis' &&
            currentKey !== 'id' &&
            currentKey !== 'mobileId'
          )
        }
      },
      listofVesselId = lastSearch['daoParams']['selectBy'] // Get search criteria params

    if (listofVesselId) {
      const typeParams = listofVesselId.split(':')
      if (typeParams[0] == 'mobile' && !typeParams[1].includes(',')) {
        let diffFromPrev = {},
          diffFromNext = {}

        if (dxEvent.rowIndex !== 0) {
          diffFromPrev = UTILS.diff(
            allDatas[dxEvent.rowIndex - 1],
            dxEvent.data,
            {
              numberAsString: true,
              nilAsFalse: true,
            },
          ).diff
          diffFromPrev = _.pickBy(diffFromPrev, filterFunction)
        }
        if (dxEvent.rowIndex !== allDatas.length - 1) {
          diffFromNext = UTILS.diff(
            dxEvent.data,
            allDatas[dxEvent.rowIndex + 1],
            {
              numberAsString: true,
              nilAsFalse: true,
            },
          ).diff
          diffFromNext = _.pickBy(diffFromNext, filterFunction)
        }

        let diff = _.assign({}, diffFromPrev, diffFromNext)
        // convert from object with 'param: {from: xxx, to: yyy}' to array with '{from: xxx, to: yyy, key: param}'
        diff = _.map(diff, (dif, key) => {
          let res = dif
          res.key = key
          return res
        })
        diff = _.filter(diff, (c) => c.key.substring(0, 1) !== '_')

        // Checking if we have at least one data in array before continues
        if (diff && diff.length > 0) {
          diff.forEach(function (diffElements) {
            // find columns and cells information if key found to color after
            let toColor = _.find(
              dxEvent.columns,
              (val) => val.dataField == diffElements.key,
            )
            if (toColor) {
              let children = dxEvent.rowElement.children(
                "td[aria-describedby='" + toColor.id + "']",
              )
              children.css('background-color', 'yellow')
            }
          })
        }
      }
    }
  },
})

const VESSEL_LOG_UI = new VesselLogUI()

export default VesselLogUI
