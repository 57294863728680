import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import { APP } from 'core-uda/model/Resource'
import { PREF } from 'core-cmp/Preferences'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import ResourceUI from 'core-uda/ui/ResourceUI'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import CpcPostData from 'core-uda/model/cpcPostData/CpcPostData'
import SelectCriteria from 'core-criteria/select/SelectCriteria'
import ThmSelectCpcCriteria from 'thm/ui/common/cpcPostData/ThmSelectCpcCriteria'
import ThmSelectStatusCriteria from 'thm/ui/common/cpcPostData/ThmSelectStatusCriteria'
import ThmSelectCpcProtocolCriteria from 'thm/ui/common/cpcPostData/ThmSelectCpcProtocolCriteria'
import CpcPostDataCreatePage from 'thm/ui/common/cpcPostData/CpcPostDataCreatePage'
import InformationPopup from 'core-cmp/msg/InformationPopup'
import ImportNafTemplate from 'thm/ui/common/cpcPostData/ImportNafTemplate'

/**
 * User Interface elements for CpcPostData
 */
let CpcPostDataUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: CpcPostData.itemId,
      i18nPrefix: 'thm.ui.common.cpcPostData.CpcPostDataUI.',
      icon: 'data',
    })
  },

  /**
   * @override
   */
  getUreTopActionButtons: function (mainPage) {
    let me = this
    return [
      {
        id: 'resendDataTransmission',
        title: I18N.msg('thm.ui.common.cpcPostData.CpcPostDataUI.resend'),
        icon: 'mail2',
        handler: (gridMainPage, ev) => {
          if (
            gridMainPage.gridElement.getOption().selection.mode !== 'multiple'
          ) {
            gridMainPage.gridElement.changeSelectionMode('multiple')
          } else {
            gridMainPage.gridElement.changeSelectionMode('single')
          }
          gridMainPage.attr('resendDataTransmissionEnabled', true)
          gridMainPage.gridElement.multipleSelectionChanged()
          gridMainPage.tools.refreshByDisplay()
        },
      },
      {
        id: 'validate',
        title: I18N.msg('thm.ui.common.cpcPostData.CpcPostDataUI.check'),
        icon: 'check',
        display: () => mainPage.attr('resendDataTransmissionEnabled'),
        handler: (gridMainPage, ev) => {
          let dataTransmission = gridMainPage.gridElement.getSelectedRowsData(),
            ids = _.map(dataTransmission, 'id').toString()

          CpcPostData.resend(ids)

          gridMainPage.attr('resendDataTransmissionEnabled', false)
          gridMainPage.gridElement.changeSelectionMode('single')
          gridMainPage.gridElement.multipleSelectionChanged()
          gridMainPage.tools.refreshByDisplay()

          InformationPopup.openPage({
            titleKey: '_Refresh',
            textKey: 'needReload',
            validateKey: 'validate',
          })
        },
      },
      {
        id: 'cancel',
        title: I18N.msg('_Cancel'),
        icon: 'close',
        display: () => mainPage.attr('resendDataTransmissionEnabled'),
        handler: (gridMainPage, ev) => {
          gridMainPage.attr('resendDataTransmissionEnabled', false)
          gridMainPage.gridElement.changeSelectionMode('single')
          gridMainPage.gridElement.multipleSelectionChanged()
          gridMainPage.tools.refreshByDisplay()
        },
      },
      {
        id: 'cpcPostData-create',
        title: me.msg('create.label'),
        icon: 'add',
        authorized: !!(
          APP().user.roleName === 'Administrator' || 'Super Operator'
        ),
        handler: (gridMainPage, ev) => {
          if (_.isEmpty(APP().getJsonData('nafTemplate'))) {
            ImportNafTemplate.openPage()
          } else {
            CpcPostDataCreatePage.openPage()
          }
        },
      },
    ]
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      cpc: {
        cmpClass: ThmSelectCpcCriteria,
      },
      status: {
        cmpClass: ThmSelectStatusCriteria,
      },
      cpcProtocolKind: {
        cmpClass: ThmSelectCpcProtocolCriteria,
      },
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    let me = this
    return {
      dataTransDelay: {
        format: (value) => {
          if (_.isNumber(value)) {
            return parseFloat(parseFloat(value).toFixed(3)) + ' s'
          } else {
            return value
          }
        },
        dataType: 'string',
      },
    }
  },

  /**
   * @override
   */
  getUdaFormat: function (field) {
    return field == 'dataTransDelay' ? 'duration:s' : null
  },
})

const CPC_POST_DATA_UI = new CpcPostDataUI()

export default CpcPostDataUI
