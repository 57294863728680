import _ from 'core-cmp/lodash'
import { I18N } from 'core-cmp/util/I18n'
import { APP } from 'core-uda/model/Resource'
import Criteria from 'core-criteria/Criteria'
import ResourceUI from 'core-uda/ui/ResourceUI'
import ThmListSelectCriteriaStache from 'thm/ui/common/criteria/ThmListSelectCriteria.stache'

/**
 * @class ThmListSelectCriteria
 */
let ThmListSelectCriteria = Criteria.extend({
  udaName: 'string',
  labelAlign: 'string',
  value: 'string',

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this

    if (config.i18nPrefix) {
      config.i18nPrefix = _.isString(config.i18nPrefix)
        ? [config.i18nPrefix]
        : config.i18nPrefix
    } else {
      config.i18nPrefix = []
    }
    if (!config.labelKey && config.i18nPrefix) {
      config.labelKey = config.i18nPrefix + 'label'
    }
    me.stache = ThmListSelectCriteriaStache
    me.labelAlign = 'left'

    me._super(config)

    me.udaName = me.name
    me.visible = true

    if (me.resourceName) {
      me.name = me.resourceName
      me.udaNames = [me.udaName] // For preference loading see Criteria.js
    }

    me.value = '-1'
  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me = this,
      value = me.value,
      resourceName = !_.isNil(me.resource) ? me.resource + '.' : '',
      paramsUda = {}

    if (value) {
      paramsUda[resourceName + me.udaName] = value
    }

    if (me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(paramsUda)
    }

    return paramsUda
  },

  /**
   * @override
   */
  getUDAParamsNames: function () {
    let me = this,
      names = [me.udaName]

    if (me.getUDAParamsNamesCustom) {
      names = me.getUDAParamsNamesCustom(names)
    }
    return names
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this

    if (me.formatUDAToModelCustom) {
      params = me.formatUDAToModelCustom(params)
    }

    me.value = params[me.udaName]
  },

  getUdaExportData: function () {
    let me = this,
      id = me.value,
      item = APP(me.name, id),
      data = []

    if (item) {
      data.push({
        key: I18N.msg('uda.' + APP().getItemKey(me.name) + '.one'),
        value: ResourceUI.getLabel(me.name, item),
      })
    }

    return data
  },
})

export default ThmListSelectCriteria
