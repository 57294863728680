import html2canvas from 'html2canvas'
import $ from 'core-cmp/cmp-jquery'
import { PREF } from 'core-cmp/Preferences'
import ZoomBar from 'core-uda/ui/view/ZoomBar'

/**
 * @class isv.ui.main.ZoomBar
 * @parent isv.ui
 * @constructor
 * Barre d'outils zoom.
 * @param {Object} options
 */
let ThmZoomBar = ZoomBar.extend({
  /**
   * @override
   */
  _createCanvas: function (callback) {
    let me = this,
      $map = $('#map'),
      statement = RIGHTS('shape.defaultCopyrightStatement'),
      textLinesToWrite = [],
      copyrightHeight,
      font = '20px sans-serif'

    $map.addClass('png-export')

    if (statement) {
      me.getLinesToWrite(
        textLinesToWrite,
        statement,
        20,
        GIS.get$Map().get(0).offsetWidth * 0.75,
        font,
      ) // draw the text at some position (x, y) and set its width to 75% of total canvas width

      copyrightHeight = textLinesToWrite.length * 20

      copyrightHeight = copyrightHeight < 50 ? 50 : copyrightHeight

      copyrightHeight *= 2
    }

    html2canvas(GIS.get$Map().get(0), {
      useCORS: true,
      logging: false,
      height: statement
        ? GIS.get$Map().get(0).offsetHeight + copyrightHeight
        : GIS.get$Map().get(0).offsetHeight,
    }).then((canvas) => {
      if (statement) {
        let ctx = canvas.getContext('2d'),
          menuWidth = PREF('umvLeftMenuLocked') ? 340 : 55,
          x = GIS.get$Map().get(0).offsetWidth / 2 + menuWidth,
          y = GIS.get$Map().get(0).offsetHeight + copyrightHeight * 0.35

        ctx.textBaseline = 'bottom' // start with drawing text from top
        ctx.textAlign = 'center' // align text on left
        ctx.font = font // set a font and size
        ctx.fillStyle = 'black' // set a color for the text

        for (let i = 0; i < textLinesToWrite.length; i++) {
          ctx.fillText(textLinesToWrite[i], x, y + i * 20)
        }
      }

      me._afterMapCanvas(canvas).done(() => {
        $map.removeClass('png-export')
        callback(canvas)
      })
    })
  },

  /**
   *  Converts a text into an array of string so that it can be displayed into a container
   *  given its width, the height of a line and current font
   *
   * @param lines
   * @param text
   * @param lineHeight
   * @param fitWidth
   */
  getLinesToWrite: function (lines, text, lineHeight, fitWidth, font) {
    let canvas = document.createElement('canvas'),
      body = document.getElementsByTagName('body')[0],
      words = text.split(' '),
      currentLine = 0,
      idx = 1

    canvas.id = 'test'
    body.appendChild(canvas)
    let cursorLayer = document.getElementById('test')
    let context = canvas.getContext('2d')

    context.font = font
    fitWidth = fitWidth || 0

    if (fitWidth <= 0) {
      lines.push(text)
      return
    }

    while (words.length > 0 && idx <= words.length) {
      let str = words.slice(0, idx).join(' '),
        w = context.measureText(str).width
      if (w > fitWidth) {
        if (idx == 1) {
          idx = 2
        }
        lines.push(words.slice(0, idx - 1).join(' '))
        currentLine++
        words = words.splice(idx - 1)
        idx = 1
      } else {
        idx++
      }
    }
    if (idx > 0) lines.push(words.join(' '))
  },
})
export default ThmZoomBar
