import ResourceUI from 'core-uda/ui/ResourceUI'
import template from 'thm/ui/common/illegalVessel/IllegalVesselUITpl.stache'
import { CELL_TPL_KIND_ICON, I18N_FIELD } from 'core-uda/ui/ResourceUI'
import IllegalVessel from 'core-uda/model/illegalVessel/IllegalVessel'

/**
 * User Interface elements for Fleet
 */
let IllegalVesselUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: IllegalVessel.itemId,
      i18nPrefix: 'thm.ui.common.illegalVessel.IllegalVesselUI.',
      icon: 'vessel',
      template: template,
    })
  },

  isAuthorizedForGridMode(gridModel) {
    return !!RIGHTS('data.illegalVessel')
  },
})

const ILLEGAL_VESSEL_UI = new IllegalVesselUI()

export { ILLEGAL_VESSEL_UI }
export default IllegalVesselUI
