import Page from 'core-cmp/Page'
import Toastr from 'core-cmp/Toastr'
import { I18N } from 'core-cmp/util/I18n'
import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import ConfirmationPopupTplStache from 'core-cmp/msg/ConfirmationPopupTpl.stache'
import 'thm/ui/common/userGroup/UserGroupConfirmationPopup.css'
import ConfirmationPopup from 'core-cmp/msg/ConfirmationPopup'

/**
 * Confirmation popup.
 * @class UserGroupConfirmationPopup
 * @extends Page
 */
let UserGroupConfirmationPopup = ConfirmationPopup.extend({
  id: 'userGroupConfirmationPopup',
  routerType: 'popup',
  popupWidth: 400,
  allowClose: false,
  modal: true,
  template: ConfirmationPopupTplStache,
  i18nPrefix: 'core-cmp.msg.ConfirmationPopup.',
  autoBindActions: true,
  useComponentAsViewModel: true,
  styles: {
    ok: 'btn-ok',
    yes: 'btn-ok',
    save: 'btn-ok',
    apply: 'btn-ok',
    cancel: 'btn-cancel',
    no: 'btn-cancel',
    new: 'btn-ok',
    add: 'btn-ok',
  },
  hasValidationText: false,

  /**
   * @override
   */
  open: function (context) {
    let me = this
    me._super(context)

    // In case the popup is too high
    if (context.longText) {
      me.attr('popupHeight', '250')
    }
  },
})

export default UserGroupConfirmationPopup
