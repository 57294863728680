import ResourceUI from 'core-uda/ui/ResourceUI'
import ExternalImage from 'core-uda/model/externalImage/ExternalImage'
import template from 'thm/ui/common/externalImage/ExternalImageTpl.stache'

/**
 * User Interface elements for ExternalImage
 */
let ExternalImageUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ExternalImage.itemId,
      i18nPrefix: 'thm.ui.common.externalImage.ExternalImageUI.',
      icon: 'opacity',
      template: template,
    })
  },
})

const EXTERNAL_IMAGE_UI = new ExternalImageUI()

export default ExternalImageUI
