import ThmTrajectoryStyleInfoPanel from 'thm/ui/style/ThmTrajectoryStyleInfoPanel'
import TrackingStyleInfoPanelTplStache from 'thm/ui/style/ThmTrackingStyleInfoPanelTpl.stache'
import FishingStyleEngine from 'core-mobile/service/style/FishingStyleEngine'

/**
 * FishingStyleInfoPanel
 */
let ThmFishingStyleInfoPanel = ThmTrajectoryStyleInfoPanel.extend(
  {
    ID: 'trajectory-Fishing',
    ENGINE: FishingStyleEngine.INSTANCE,
  },
  {
    id: 'fishingStyleInfoPanel',
    childTpl: TrackingStyleInfoPanelTplStache,
    defautModel: {},

    /**
     * @override
     */
    getDefaultRules: function () {
      return FishingStyleEngine.INSTANCE.rules
    },

    initDefaultModel: function () {
      let me = this,
        defaultStyleModel = me.getDefaultRules()

      me.defaultModel = {
        defaultPoint: {
          pointRadius: defaultStyleModel.defaultPoint.pointRadius,
          strokeColor: defaultStyleModel.defaultPoint.strokeColor,
        },
        pointLabel: {
          fontColor: defaultStyleModel.pointLabel.fontColor,
          textFont: defaultStyleModel.pointLabel.textFont,
        },
        lineDefault: {
          strokeWidth: defaultStyleModel.lineDefault.strokeWidth,
          strokeColor: defaultStyleModel.lineDefault.strokeColor,
        },
        selected: {
          pointRadius: defaultStyleModel.selected.pointRadius,
          strokeColor: defaultStyleModel.selected.strokeColor,
          fillColor: defaultStyleModel.selected.fillColor,
        },
        backLine: {
          strokeWidth: defaultStyleModel.backLine.strokeWidth,
          strokeColor: defaultStyleModel.backLine.strokeColor,
        },
        lineLevel: {
          number: defaultStyleModel.lineLevel.number,
        },
        lineLevel1: {
          speed: defaultStyleModel.lineLevel1.speed,
          strokeColor: defaultStyleModel.lineLevel1.strokeColor,
        },
        lineLevel2: {
          speed: defaultStyleModel.lineLevel2.speed,
          strokeColor: defaultStyleModel.lineLevel2.strokeColor,
        },
        lineLevel3: {
          speed: defaultStyleModel.lineLevel3.speed,
          strokeColor: defaultStyleModel.lineLevel3.strokeColor,
        },
        estimationPoint: {
          fillColor: defaultStyleModel.estimationPoint.fillColor,
        },
        estimationLine: {
          strokeWidth: defaultStyleModel.estimationLine.strokeWidth,
        },
        lineBreakroute: {
          strokeDashstyle: defaultStyleModel.lineBreakroute.strokeDashstyle,
        },
        mobileDynamicTypes: {
          select: defaultStyleModel.mobileDynamicTypes.select,
          default: defaultStyleModel.mobileDynamicTypes['default'],
        },
        mobileDynamicColors: {
          select: defaultStyleModel.mobileDynamicColors.select,
          default: defaultStyleModel.mobileDynamicColors['default'],
        },
      }
    },

    /**
     * @override
     */
    initViewModel: function () {
      let me = this
      me._super()
      me.viewModel.attr('fishing', true)
      me.viewModel.attr('number2', true)
      me.viewModel.attr('number3', false)
    },

    /**
     * @override
     */
    updateViewModel: function () {
      let me = this
      me._super()
      me.fillLineNumber()
    },

    bindViewModel: function () {
      let me = this
      // Bug fix: dataModel.action needs to be initialized to attach an event to it
      // must be before me._super();
      me.viewModel.dataModel.attr('lineLevel', {})
      me._super()
      me.viewModel.on('dataModel.lineLevel.number', () => me.fillLineNumber())
    },

    fillLineNumber: function () {
      let me = this,
        dataModel = me.getDataModel(),
        number = dataModel.lineLevel.number
      me.viewModel.attr('number2', false)
      me.viewModel.attr('number3', false)

      switch (number) {
        case 2:
          me.viewModel.attr('number2', true)
          break
        case 3:
          me.viewModel.attr('number2', true)
          me.viewModel.attr('number3', true)
          break
      }
    },
  },
)
export default ThmFishingStyleInfoPanel
