import InfoPanel from 'core-uda/ui/InfoPanel'
import { RIGHTS } from 'core-uda/Rights'
import FishingGearInfoPanelTplStache from 'thm/ui/common/fishingGear/FishingGearInfoPanelTpl.stache'

/**
 * @class thm.ui.common.licence.FishingGearInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un engin de pêche.
 * @param {Object} options
 */
let FishingGearInfoPanel = InfoPanel.extend({
  template: FishingGearInfoPanelTplStache,
  i18nPrefix: [
    'uda.fishingGear.',
    'thm.ui.common.fishingGear.FishingGearInfoPanel.',
  ],
  id: 'fishingGearInfoPanel',
  childTpl: null,
  entityId: 'fishingGear',
  allowWidth: true,
  allowClone: true,
  titlekey: 'title',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('showClient', RIGHTS('showClient'))
  },

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this

    dataModel.attr({
      name: me.msg('Clone') + ' - ' + dataModel.name,
    })
  },
})

export default FishingGearInfoPanel
