import AlertDefinitionInfoPanel from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel'
import ChildStache from 'thm/ui/common/alertDefinition/AlertDefinitionPortInOutInfoPanelTpl.stache'

/**
 * @classAlertDefinitionPortInOutInfoPanel
 * Alert Definition "Port In Out" Form.
 */
let AlertDefinitionPortInOutInfoPanel = AlertDefinitionInfoPanel.extend({
  id: 'alertDefinitionPortInOutInfoPanel',
  childTpl: ChildStache,

  /**
   * @override
   */
  forceDefaultValues: function () {
    let me = this,
      dataModel = me.getDataModel()

    if (_.isUndefined(dataModel.monitorIn)) {
      me.viewModel.attr('dataModel.monitorIn', false)
    }
    if (_.isUndefined(dataModel.monitorOut)) {
      me.viewModel.attr('dataModel.monitorOut', false)
    }

    // Pour avoir la même valeur dans originalValue et dataModel
    me.viewModel.attr(
      'dataModel.unauthorizedPortsOnly',
      dataModel.unauthorizedPortsOnly === 'true',
    )

    me.updateOriginalValue()
  },
})
export default AlertDefinitionPortInOutInfoPanel
