import InfoPanel from 'core-uda/ui/InfoPanel'
import template from 'thm/ui/common/userGroup/UserGroupInfoPanelTpl.stache'
import UserGroup from 'core-uda/model/userGroup/UserGroup'
import $ from 'core-cmp/cmp-jquery'
import ConfirmationPopup from 'core-cmp/msg/ConfirmationPopup'
import { I18N } from 'core-cmp/util/I18n'
import _ from 'core-cmp/lodash'
import UserGroupConfirmationPopup from 'thm/ui/common/userGroup/UserGroupConfirmationPopup'

/**
 * User Group form
 * @class UserGroupInfoPanel
 */
let UserGroupInfoPanel = InfoPanel.extend({
  template: template,
  i18nPrefix: ['uda.userGroup.', 'thm.ui.common.userGroup.UserGroupInfoPanel.'],
  id: 'userGroupInfoPanel',
  entityId: UserGroup.itemId,
  allowWidth: true,
  allowClone: true,
  titleKey: 'title',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('onBeforeDeleteFleet', me.proxy(me.onBeforeDeleteFleet))
    me.viewModel.attr('onBeforeDeleteZone', me.proxy(me.onBeforeDeleteZone))
    me.viewModel.attr('onBeforeDeleteUser', me.proxy(me.onBeforeDeleteUser))
  },

  onBeforeDeleteZone: function (uiListModel, el, event, beforeContext) {
    let me = this

    me.beforeDelete(uiListModel, el, event, beforeContext, 'zone')
  },

  onBeforeDeleteFleet: function (uiListModel, el, event, beforeContext) {
    let me = this

    me.beforeDelete(uiListModel, el, event, beforeContext, 'fleet')
  },

  beforeDelete: function (uiListModel, el, event, beforeContext, objectType) {
    let me = this,
      deferred = new $.Deferred(),
      requestDef = new $.Deferred()

    UserGroupConfirmationPopup.openSingleton({
      title: me.msg('beforeDeleteTitle'),
      text: me.msg('waitingForLinkedObject'),
      longText: true,
      buttons: [
        { action: 'yes', label: me.msg('delete'), style: 'btn-ok' },
        { action: 'cancel', label: me.msg('cancel'), style: 'btn-cancel' },
      ],
      deferred: requestDef,
      onAction: function (action) {
        if (action === 'yes') {
          deferred.resolve()
        } else {
          deferred.reject()
        }
      },
    })
    beforeContext.deferred = deferred

    UserGroup.getLinkedObjects(
      me.data.id,
      objectType,
      beforeContext.item.id,
    ).done((res) => {
      if (!Object.keys(res).length) {
        requestDef.resolve(me.msg('noLinkedObjects', beforeContext.item.name))
      } else {
        requestDef.resolve(
          me.msg(
            'returnLinkedObject',
            beforeContext.item.name,
            UserGroup.formatLinkObjetctsResponse(res),
          ),
        )
      }
    })
  },

  onBeforeDeleteUser: function (uiListModel, el, event, beforeContext) {
    let me = this,
      deferred = new $.Deferred(),
      requestDef = new $.Deferred()

    UserGroupConfirmationPopup.openSingleton({
      title: me.msg('beforeDeleteTitle'),
      text: me.msg('waitingForLinkedObject'),
      longText: true,
      buttons: [
        { action: 'userOnly', label: me.msg('userOnly'), style: 'btn-ok' },
        { action: 'all', label: me.msg('allObjects'), style: 'btn-ok' },
        { action: 'cancel', label: me.msg('cancel'), style: 'btn-cancel' },
      ],
      deferred: requestDef,
      onAction: function (action) {
        if (action === 'userOnly') {
          me.viewModel.attr('dataModel').attr('deleteOnlyUser', true)
          deferred.resolve()
          me.onSave(false)
        } else if (action === 'all') {
          deferred.resolve()
        } else {
          deferred.reject()
        }
      },
    })
    beforeContext.deferred = deferred

    UserGroup.getLinkedObjects(me.data.id, 'user', beforeContext.item.id).done(
      (res) => {
        if (!Object.keys(res).length) {
          requestDef.resolve(
            me.msg('noLinkedObjects', beforeContext.item.login),
          )
        } else {
          requestDef.resolve(
            me.msg(
              'returnLinkedObjectUser',
              beforeContext.item.login,
              UserGroup.formatLinkObjetctsResponse(res),
            ),
          )
        }
      },
    )
  },
})

export default UserGroupInfoPanel
