import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import BeaconType from 'core-uda/model/beacon/BeaconType'
import template from 'thm/ui/common/beaconType/BeaconTypeUITpl.stache'
import { PREF } from 'core-cmp/Preferences'
import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for BeaconType
 */
let BeaconTypeUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: BeaconType.itemId,
      i18nPrefix: 'thm.ui.common.beaconModel.BeaconTypeUI.',
      icon: 'beaconLabel',
      searchFields: ['name'],
      template: template,
      isListEditable: true,
    })
  },
})

const BEACON_TYPE_UI = new BeaconTypeUI()

export { BEACON_TYPE_UI }
export default BeaconTypeUI
