import TrajectoryStyleHelper from 'core-mobile/service/style/TrajectoryStyleHelper'
import { PREF } from 'core-cmp/Preferences'
import { RIGHTS } from 'core-uda/Rights'
import GIS from 'core-gis/Default'
import { THM_MOBILE_DATA } from 'thm/model/mobile/ThmMobileData'

/**
 * Surcharge pour les styles
 */
let ThmTrajectoryStyleHelper = TrajectoryStyleHelper.extend({
  /**
   * @override
   */
  getLabel: function (config) {
    let me = this,
      pref = config.isLast
        ? PREF().lastLabelPositionsValue
        : PREF().labelPositionsValue,
      position = config.position,
      value = '',
      labelLevel = RIGHTS('map.labelLevel', 0),
      zoom = GIS.getZoom()

    if (pref) {
      if (pref === 'mobile' || pref === 'name' || pref === 'mobileAndDate') {
        value = THM_MOBILE_DATA.getMobileLabelForData(position)
      }
      if (pref === 'date' || pref === 'mobileAndDate') {
        value += pref === 'mobileAndDate' ? ' - ' : ''
        value += PREF().format('dateTime', new Date(position.locDateMillis))
      }
    }
    return zoom >= labelLevel ? value : ''
  },
})

var THM_TRAJECTORY_STYLE_HELPER = new ThmTrajectoryStyleHelper()

export { THM_TRAJECTORY_STYLE_HELPER }
export default ThmTrajectoryStyleHelper
