import ResourceUI from 'core-uda/ui/ResourceUI'
import Palette from 'core-uda/model/userObject/Palette'
import template from 'core-uda/ui/palette/PaletteTpl.stache'
import 'core-uda/ui/palette/Palette.css'

/**
 * User Interface elements for Palette.
 */
let ThmPaletteUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Palette.itemId,
      i18nPrefix: 'thm.ui.common.palette.ThmPaletteUI.',
      icon: 'style',
      template: template,
      labelField: 'name',
      searchFields: 'name',
      isListEditable: true,
    })
  },
})

const THM_PALETTE_UI = new ThmPaletteUI()

export default ThmPaletteUI
