import Resource from 'core-uda/model/Resource'
import { APP } from 'core-uda/model/Resource'
import InspectionReportModel from 'thm/model/inspectionReport/InspectionReportModel'

/**
 * Report.
 */
let InspectionReport = Resource.extend(
  {
    modelClazz: InspectionReportModel,
  },
  {},
)

export default InspectionReport
