import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import ErsStatistic from 'core-uda/model/ersStatistic/ErsStatistic'

import SelectCriteria from 'core-criteria/select/SelectCriteria'
import ThmListCriteria from 'thm/ui/common/criteria/ThmListCriteria'

import { I18N } from 'core-cmp/util/I18n'
import { PREF } from 'core-cmp/Preferences'

/**
 * User Interface elements for ErsStatistic
 */
let ErsStatisticUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ErsStatistic.itemId,
      icon: 'chart',
      i18nPrefix: 'thm.ui.common.ersStatistic.ErsStatisticUI.',
      extraColumnsToDelete: [], // Used to delete dynamic species columns after a URE request
      exportListIntoColumnsUda: {
        speciesDetail: {
          headerObject: 'speciesI18n',
          valueObject: 'quantity',
        },
      },
      onlyDisplayResultColumns: [
        'speciesId',
        'speciesCode',
        'speciesName',
        'speciesI18n',
        'speciesCatId',
        'speciesCatCode',
        'speciesCatI18n',
        'countryId',
        'countryCode',
        'countryName',
        'vesselId',
        'vesselName',
        'vesselImmat',
        'timeUnit',
        'timeUnitDB',
        'tripId',
        'tripNumber',
        'tripOpenDate',
        'tripOpenDateMillis',
        'tripCloseDate',
        'tripCloseDateMillis',
        'fleetId',
        'fleetName',
        'gearId',
        'gearCode',
        'gearName',
        'harbourId',
        'harbourLocode',
        'harbourName',
        'receiverId',
        'receiverName',
        'total',
      ],
      resultDynamicColumnsConfig: {
        columns: [],
        activeColumns: true,
        deleteExtraColumns: true,
        extraI18n: 'uda.ersStatistic.weightUnitIntoColumns.',
        paramExtraI18n: 'weightUnit',
        listProperty: 'speciesDetail',
        columnLabelProperty: 'speciesI18n',
        columnValueProperty: 'quantity',
      },
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      weightUnit: {
        cmpClass: SelectCriteria,
        optionsKeyPrefix: 'uda.ersStatistic.field.weightUnit.',
        initValue: 't',
        i18nPrefix: 'uda.ersStatistic.weightUnit.',
        labelKey: 'uda.ersStatistic.weightUnit.field.select',
      },
      kindSpeGroup: {
        cmpClass: SelectCriteria,
        optionsKeyPrefix: 'uda.ersStatistic.field.kindSpeGroup.',
        initValue: 'spe',
        i18nPrefix: 'uda.ersStatistic.kindSpeGroup.',
        labelKey: 'uda.ersStatistic.kindSpeGroup.field.select',
      },
      kind: {
        cmpClass: SelectCriteria,
        optionsKeyPrefix: 'uda.ersStatistic.field.kind.',
        hideOrShowAnotherCriterias: {
          timeUnitFormat: {
            inCase: 'display',
            values: ['time', 'vesselTime', 'countryTime'],
          },
        },
        initValue: 'vesselTrip',
        i18nPrefix: 'uda.ersStatistic.kind.',
        labelKey: 'uda.ersStatistic.kind.field.select',
      },
      timeUnitFormat: {
        cmpClass: SelectCriteria,
        hideCriteriaFirstTime: true,
        optionsKeyPrefix: 'uda.ersStatistic.field.timeUnit.',
        i18nPrefix: 'uda.ersStatistic.timeUnit.',
        labelKey: 'uda.ersStatistic.timeUnit.field.select',
      },
      kindAct: {
        cmpClass: SelectCriteria,
        optionsKeyPrefix: 'uda.ersStatistic.field.kindAct.',
        hideOrShowAnotherCriterias: {
          receivers: {
            inCase: 'display',
            values: ['TRA'],
          },
          ports: {
            inCase: 'display',
            values: ['LAN', 'PNO'],
          },
        },
        initValue: 'FAR',
        i18nPrefix: 'uda.ersStatistic.kindAct.',
        labelKey: 'uda.ersStatistic.kindAct.field.select',
      },
      receivers: {
        cmpClass: ThmListCriteria,
        i18nPrefix: 'uda.ersActivity.receiver.',
        resourceName: 'mobiles',
      },
      ports: {
        cmpClass: ThmListCriteria,
        i18nPrefix: 'uda.zone.port.',
        resourceName: 'zones',
        optionsUpdateFunction: 'findPorts',
      },
      species: {
        cmpClass: ThmListCriteria,
        i18nPrefix: 'uda.species.',
        customOptions: [
          {
            value: 'speciesCats',
            udaName: 'speciesCats',
            i18nPrefix: 'uda.speciesCat.',
            text: I18N.msg('uda.speciesCat.selection'),
          },
        ],
      },
      flags: {
        cmpClass: ThmListCriteria,
        i18nPrefix: 'uda.flag.',
      },
      fishingGears: {
        cmpClass: ThmListCriteria,
        i18nPrefix: 'uda.fishingGear.',
      },
      zones: {
        cmpClass: ThmListCriteria,
        extraUdaNames: ['minLon', 'minLat', 'maxLon', 'maxLat'],
        i18nPrefix: 'uda.zone.',
        optionsUpdateFunction: 'findZeeOrFaoZones',
        customOptions: [
          {
            value: 'loc',
            text: I18N.msg('uda.zone.field.geometry.type.rectangle'),
          },
        ],
        formatModelToUDACustom: ErsStatistic.zoneFormatModelToUDA,
      },
    }
  },

  /**
   * Add parameters to grid search (URE/UME)
   */
  addParamsToGridSearch: function (params, gridMode) {
    if (gridMode === 'URE') {
      params.locale = PREF('locale')
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return [
      'speciesId',
      'speciesCode',
      'speciesName',
      'speciesI18n',
      'speciesCatId',
      'speciesCatCode',
      'speciesCatI18n',
      'countryId',
      'countryCode',
      'countryName',
      'vesselId',
      'vesselName',
      'vesselImmat',
      'timeUnit',
      'timeUnitDB',
      'tripId',
      'tripNumber',
      'tripOpenDate',
      'tripOpenDateMillis',
      'tripCloseDate',
      'tripCloseDateMillis',
      'fleetId',
      'fleetName',
      'gearId',
      'gearCode',
      'gearName',
      'harbourId',
      'harbourLocode',
      'harbourName',
      'receiverId',
      'receiverName',
      'total',
    ]
  },
})

const ERS_STATISTIC_UI = new ErsStatisticUI()

export default ErsStatisticUI
