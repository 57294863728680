import ZoneUI from 'core-zone/ui/ZoneUI'
import { CELL_TPL_KIND_ICON } from 'core-uda/ui/ResourceUI'
import { THM_ALERT_DEFINITION_UI } from 'thm/ui/common/alertDefinition/ThmAlertDefinitionUI'
import template from 'thm/ui/common/zone/ZoneUITpl.stache'
import ZoneListSearchPage from 'thm/ui/common/zone/ZoneListSearchPage'
import Zone from 'core-zone/model/Zone'
import 'thm/ui/common/zone/ThmZoneUI.css'
import FieldListSelectionPage from 'core-cmp/wc/FieldListSelectionPage'

/**
 * User Interface elements for ThmZone
 */
let ThmZoneUI = ZoneUI.extend({
  /**
   * @override
   */
  init: function (config) {
    let me = this

    me._super({
      i18nPrefix: 'thm.ui.common.zone.ThmZoneUI.',
      template: template,
      searchFields: ['name', 'reference'],
      searchPageId: ZoneListSearchPage.getId(),
    })
  },

  /**
   * @override
   */
  registerUI: function () {
    let me = this
    me._super()

    APP().on('forceUiListSearchReload', () => {
      _.each(FieldListSelectionPage.getCreatedList(), (page) => {
        if (page.context.resourceId == me.resourceId) {
          page.refreshList()
        }
      })
    })
  },

  /**
   * @override
   */
  customFilterFunction: function (item) {
    return item
      ? APP('favoriteZonePreferences') &&
          APP('favoriteZonePreferences').isFavoriteZone(item.id)
      : false
  },

  /**
   * @override
   */
  getListSelectionPageHeaderActions: function () {
    let me = this

    return [
      {
        id: 'showAllFavorites',
        icon: 'checkStar',
        authorized: !!APP('favoriteZonePreferences'),
        label: me.msg('showAllFavorites'),
        handler: function (context, button, ev) {
          let favoriteZones = APP('favoriteZonePreferences').getFavoriteZones(),
            areAllSelected = _.reduce(
              favoriteZones,
              (res, zone) => {
                return (
                  res &&
                  APP('zones').selection.getItemStateById(zone.id) == 'true'
                )
              },
              true,
            )

          if (areAllSelected) {
            APP('zones').selection.setItemState(favoriteZones, 'false')
          } else {
            APP('zones').selection.setItemState(favoriteZones, 'true')
          }
        },
      },
    ]
  },

  /**
   * @override
   */
  getContextMenuActions: function (context) {
    let me = this,
      additionalActions = THM_ALERT_DEFINITION_UI.getContextMenuActionsForType(
        'zones',
        context.data.customerId,
        context,
      )
    additionalActions.push({
      id: 'addFavoriteZone',
      label: me.msg('addFavoriteZone'),
      icon: 'star3',
      authorized:
        APP('favoriteZonePreferences') &&
        !APP('favoriteZonePreferences').isFavoriteZone(context.data.id),
      handler: function (ctx, button, ev) {
        APP('favoriteZonePreferences').addZoneToFavorites(ctx.data.id)
      },
    })
    additionalActions.push({
      id: 'removeFavoriteZone',
      label: me.msg('removeFavoriteZone'),
      icon: 'star3',
      authorized:
        APP('favoriteZonePreferences') &&
        APP('favoriteZonePreferences').isFavoriteZone(context.data.id),
      handler: function (ctx, button, ev) {
        APP('favoriteZonePreferences').removeZoneFromFavorites(ctx.data.id)
      },
    })
    return me._super(context).concat(additionalActions)
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      name: {
        dataType: 'string',
        cellTemplate: function (container, options) {
          CELL_TPL_KIND_ICON(container, options, Zone)
        },
      },
      kind: {
        dataType: 'string',
        cellTemplate: function (container, options) {
          CELL_TPL_KIND_ICON(container, options, Zone)
        },
      },
      userGroups: {
        dataType: 'string',
        format: (value) => {
          let names = ''
          if (value !== undefined) {
            names = value
              .map(
                (id) =>
                  (APP('userGroups', id) && APP('userGroups', id).name) || '',
              )
              .join(', ')
          }
          return names
        },
      },
    }
  },
})

const THM_ZONE_UI = new ThmZoneUI()
export default ThmZoneUI
