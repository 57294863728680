import template from 'thm/ui/common/beaconType/CommandPeriodicPollingTpl.stache'
import CommandHandler from 'core-mobile/ui/command/CommandHandler'
import Toastr from 'core-cmp/Toastr'

/**
 * Handler for periodic polling Command.
 * @class CommandPeriodicPolling
 */
let CommandPeriodicPolling = CommandHandler.extend({
  id: 'periodic_polling',
  i18nPrefix: 'thm.ui.common.beaconModel.CommandPeriodicPolling.',
  template: template,
  endHrdr: new Date(new Date().getTime() + 90 * 60000),
  startHrdr: new Date(new Date().getTime() + 30 * 60000),
  channel: 'iridium',
  periodicity: 10,
  pollNumber: 3,
  channelOption: [
    {
      value: 'POLL',
      text: 'IRIDIUM',
    },
    {
      value: 'POLL_GPRS',
      text: 'GPRS',
    },
  ],

  /**
   * @override
   */
  checkErrors: function () {
    const me = this

    if (
      !me.periodicity ||
      !me.pollNumber ||
      !me.channel ||
      !me.endHrdr ||
      !me.startHrdr
    ) {
      Toastr.showToastError('noValueError', 3000)
      return true
    }

    return false
  },

  /**
   * @override
   */
  onSubmit: function (params) {
    const me = this

    let startDate = me.startHrdr.toISOString(),
      endDate = me.endHrdr.toISOString()

    params.channel = me.channel
    params.startDate = startDate
    params.endDate = endDate
    params.frequency = me.periodicity
    params.maxNbOfRetry = me.pollNumber
    params.enabled = true // THM-11537 - Periodic Polling are always enabled at the creation
  },
})

CommandHandler.register(CommandPeriodicPolling)

export default CommandPeriodicPolling
