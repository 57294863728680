import { APP } from 'core-uda/model/Resource'
import { PREF } from 'core-cmp/Preferences'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import BeaconDefaultInfoPanelTplStache from 'thm/ui/common/beacon/BeaconDefaultInfoPanelTpl.stache'
import BeaconInfoPanel from 'core-mobile/ui/beacon/BeaconInfoPanel'
import _ from 'core-cmp/lodash'
import Customer from 'core-uda/model/customer/Customer'
import Toastr from 'core-cmp/Toastr'
import LesInmc from 'core-uda/model/beacon/LesInmc'
import ZoneTplStache from 'thm/ui/common/zone/ZoneUITpl.stache'
import CustomerTplStache from 'core-uda/ui/customer/CustomerUITpl.stache'
import { BEACON_CONST } from 'core-uda/model/beacon/Beacon'
import BeaconThemisUpdateCustomersPopup from 'thm/ui/common/beacon/BeaconThemisUpdateCustomersPopup'
import BeaconTypeModel from 'core-uda/model/beacon/BeaconTypeModel'
import BeaconType from 'core-uda/model/beacon/BeaconType'

const KIND_CONSTRAINTS = {
  marimsys: ['int', 4, 4],
  guardservice: ['int', 8, 8],
  cls_argos: ['int', 6, 6],
  cls_iridium: ['int', 1, 20],
  pro: ['int', 15, 15],
  hun: ['int', 7, 7],
  mov: ['alphanum', 1, 15],
  sit: ['alphanum', 1, 15],
  megatrack: ['int', 1, 8],
  neotecdata: ['alphanum', 12, 12],
  detektor: ['alphanum', 1, 15],
  satlink: ['alphanum', 1, 15],
  globalstar: ['int', 8, 8],
  generic: ['alphanum', 1, 15],
  amalgam: ['int', 1, 15],
  sogindonesia: ['int', 1, 15],
  psn: ['int', 1, 15],
  faria: ['alphanum', 1, 15],
  generic_indeso: ['int', 1, 15],
  marimsys_idp: ['int', 4, 4],
  geosupply: ['alphanum', 1, 15],
}

/**
 * Beacon form.
 * @class DefaultInfoPanel
 */
let BeaconDefaultInfoPanel = BeaconInfoPanel.extend({
  template: BeaconDefaultInfoPanelTplStache,
  i18nPrefix: [
    'core-mobile.ui.beacon.BeaconInfoPanel.',
    'uda.beacon.',
    'thm.ui.common.beacon.BeaconDefaultInfoPanel.',
  ],
  id: 'beaconDefaultInfoPanel',
  allowWidth: true,
  allowClone: true,

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this

    dataModel.attr({
      reference: '',
    })
    me.viewModel.attr('editRefDisabled', false)
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
      contractNumbReq = RIGHTS('customer.contractNumber.required')

    me._super()

    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.attr('hasCenterParamClient', false)
    me.viewModel.attr('fishingContext', RIGHTS('user.domain') === 'fishing')
    me.viewModel.attr('inmarsat2', false)
    me.viewModel.zoneTpl = ZoneTplStache
    me.viewModel.customerTpl = CustomerTplStache

    if (!RIGHTS('showClient')) contractNumbReq = false

    me.viewModel.attr('contractNumbReq', contractNumbReq)
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this,
      isNew = _.isNil(me.data.id)

    me._super()
    me.setParamsFieldsByKind()
    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.attr('isNew', isNew)
    me.loadOptions()
    me.setDefaultValues()
  },

  loadOptions: function () {
    let me = this

    me.updateFieldOptionsFromResource('customerOptions', Customer)
    me.fieldSelectOptionFromResource(
      'lesInmc',
      'lesInmcOptions',
      LesInmc,
      'id',
      'company',
    )
    me.viewModel.attr('inmarsatOceanOptions', BEACON_CONST.oceanRegion) // Used by inmarsat beacons
  },

  /**
   * @Override
   */
  getTitle: function () {
    let me = this
    return me.msg(_.camelCase(me.viewModel.attr('dataModel.kind') + '_title'))
  },

  setParamsFieldsByKind: function () {
    let me = this,
      kind = me.viewModel.attr('dataModel.kind'),
      kindInmarsat = !!_.find(APP('beacon').kindsInmarsat, (k) => k === kind),
      kindConstraints = kindInmarsat
        ? ['int', 9, 9]
        : KIND_CONSTRAINTS[kind] || ['alphanum', 1, 100],
      kindName

    if (me.creation) {
      me.viewModel.attr('dataModel.satelliteProviderName', kind)
    }

    me.viewModel.attr('inmcPart', kind === 'les_inmc')
    me.viewModel.attr('varmacPart', kind === 'varmac')
    me.viewModel.attr('inmarsatPart', kindInmarsat)
    me.viewModel.attr(
      'editRefDisabled',
      !RIGHTS('data.beacon.editRef') === true,
    )
    me.viewModel.attr(
      'inmarsat2',
      kindInmarsat && !!me.viewModel.attr('dataModel.inmarsatDnid2'),
    )
    me.viewModel.attr('formatReference', kindConstraints[0])
    me.viewModel.attr('minLengthReference', kindConstraints[1])
    me.viewModel.attr('maxLengthReference', kindConstraints[2])

    if (!me.viewModel.attr('dataModel.satelliteProviderName')) {
      let beaconKindOptions = APP('beacon').getKindOptions()

      kindName =
        beaconKindOptions && beaconKindOptions.length
          ? APP('beacon')
              .getKindOptions()
              .find((option) => kind === option.id).name
          : ''

      me.viewModel.attr('dataModel.satelliteProviderName', kindName)
    }

    if (me.creation) {
      me.viewModel.attr('editRefDisabled', !RIGHTS('data.beacon.edit') === true) // THM-10918 - enable the field if we try to create not update
    }
  },

  onCustomerContractNumber: function () {
    let me = this,
      selectedCustomer = APP(
        'customer',
        me.viewModel.attr('dataModel.customerId'),
      )

    if (selectedCustomer && selectedCustomer.contractNumber) {
      me.viewModel.attr(
        'dataModel.contractNumber',
        selectedCustomer.contractNumber,
      )
      Toastr.showSuccess(
        me.msg('contractNumberFound', selectedCustomer.contractNumber),
        5000,
      )
    } else {
      Toastr.showError(me.msg('contractNumberNotFound'), 5000)
    }
  },

  /**
   * @override
   */
  cleanForEqual: function (attr) {
    let me = this

    if (!me.viewModel.attr('inmcPart')) {
      delete attr.lesInmcId
    }
    if (!me.viewModel.attr('inmarsatPart')) {
      delete attr.inmarsatSerialNumber
      delete attr.inmarsatSubAddress
      delete attr.inmarsatDnid
      delete attr.inmarsatMember
      delete attr.inmarsatOcean
      delete attr.inmarsatDnid2
      delete attr.inmarsatMember2
      delete attr.inmarsatOcean2
    }
    if (!me.viewModel.attr('varmacPart')) {
      delete attr.password
    }
    if (!me.viewModel.attr('fishingContext')) {
      delete attr.portId
      delete attr.installer
    }

    return attr
  },

  /**
   * @override
   */
  checkErrors: function (data) {
    let me = this
    if (
      me.viewModel.attr('inmcPart') &&
      !data.lesInmcId &&
      !data.beaconTypeId
    ) {
      Toastr.showToastr('error', 'noBeaconTypeSelected')
      Toastr.showToastr('error', 'noLesInmcId')
      return true
    } else if (!data.beaconTypeId) {
      Toastr.showToastr('error', 'noBeaconTypeSelected')
      return true
    } else if (me.viewModel.attr('inmcPart') && !data.lesInmcId) {
      Toastr.showToastr('error', 'noLesInmcId')
      return true
    }
    return me._super(data)
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me = this,
      dataModel = me.getDataModel()

    // if undefined, UDA do not update the port to null
    if (!dataModel.portId) {
      dataModel.portId = ''
    }

    if (
      me.viewModel.attr('inmarsatPart') &&
      _.isNil(dataModel.inmarsatSubAddress)
    ) {
      dataModel.inmarsatSubAddress = 1
    }

    return dataModel
  },

  setDefaultValues() {
    let me = this
    // only for new beacons
    if (!me.data.id) {
      const types = APP()
        .getData('beaconTypes')
        .filter(
          (item) =>
            _.lowerCase(item.satelliteProviderName) ===
            _.lowerCase(me.viewModel.attr('dataModel.satelliteProviderName')),
        )
      if (types.length === 1) {
        me.viewModel.attr('dataModel.beaconTypeId', types[0].id)
      }
      const lesInmcs = APP().getData('lesInmcs')
      if (lesInmcs.length === 1) {
        me.viewModel.attr('dataModel.lesInmcId', lesInmcs[0].id)
      }
      me.viewModel.attr('dataModel.inmarsatOcean', 'currentOceanRegion')
    }
  },

  /****************** POPUP CUSTOMER ******************/

  onEditionCustomer: function () {
    let me = this

    BeaconThemisUpdateCustomersPopup.openPage({
      customerId: me.data.customerId,
      customer: me.data.customerCompany,
      beaconId: me.data.id,
      displayVessel: me.data.mobileId ? true : false,
    })
  },
})

export default BeaconDefaultInfoPanel
