import _ from 'core-cmp/lodash'
import { PREF } from 'core-cmp/Preferences'
import InfoPanel from 'core-uda/ui/InfoPanel'
import CpcFormatInfoPanelTplStache from 'thm/ui/common/cpcFormat/CpcFormatInfoPanelTpl.stache'

const toSupportedDateFormat = (dateFormatStr) =>
  dateFormatStr.replaceAll('Y', 'y').replaceAll('m', 'M').replaceAll('D', 'd')
const toSupportedTimeFormat = (timeFormatStr) =>
  timeFormatStr
    .replaceAll('h', 'H')
    .replaceAll('K', 'k')
    .replaceAll('M', 'm')
    .replaceAll('S', 's')

const KIND_FLUX = 'flux'
const KIND_TEXT = 'text'
const KIND_NAF = 'naf'
const KIND_NAF_NORWAY = 'nafNorway'
const ALL_KINDS = [KIND_FLUX, KIND_TEXT, KIND_NAF, KIND_NAF_NORWAY]

const VESSEL_IDENTIFIER_OPTIONS = [
  { modelValue: 'registryNo', viewValue: 'registry' },
  { modelValue: 'callSign', viewValue: 'callSign' },
  { modelValue: 'name', viewValue: 'mobileName' },
  { modelValue: 'ref', viewValue: 'ref' },
  { modelValue: 'imo', viewValue: 'imo' },
]

const BEACON_IDENTIFIERS_OPTIONS = [
  { modelValue: 'beaconReference', viewValue: 'beaconReference' },
  { modelValue: 'beaconModel', viewValue: 'beaconModel' },
  { modelValue: 'satProvider', viewValue: 'satProvider' },
]

/**
 * @class CpcFormatInfoPanel
 * Afficher les infos d'un cpcFormat
 */
let CpcFormatInfoPanel = InfoPanel.extend({
  template: CpcFormatInfoPanelTplStache,
  i18nPrefix: [
    'uda.mobile.',
    'uda.cpcFormat.',
    'thm.ui.common.cpcFormat.CpcFormatInfoPanel.',
  ],
  id: 'cpcFormatInfoPanel',
  entityId: 'cpcFormat',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()

    // NAF
    me.viewModel.attr('nafDateFormats', ['YYMMDD', 'YYYYMMDD'])
    me.viewModel.attr('nafTimeFormats', ['HHMM', 'HHMMSS'])
    me.viewModel.attr('positionFormats', [
      '+/- DD.ddd / +/- DDD.ddd',
      '+/- DD.dddd / +/- DDD.dddd',
      'hDDMM / hDDDMM',
      'hDDddd / hDDDddd',
      'hDDdddd / hDDDdddd',
    ])
    me.viewModel.attr('defaultHeadingOptions', [
      {
        value: false,
        text: me.msg('field.defaultHeading.options.noCap'),
      },
      {
        value: true,
        text: me.msg('field.defaultHeading.options.cap'),
      },
    ])

    // TEXT
    me.viewModel.attr('timeZoneOptions', [
      {
        value: 'GMT',
        text: me.msg('field.timeZoneOptions.options.GMT'),
      },
      {
        value: 'LOCALE',
        text: me.msg('field.timeZoneOptions.options.localTime'),
      },
      {
        value: 'GMT+',
        text: me.msg('field.timeZoneOptions.options.timeZone'),
      },
    ])
    me.viewModel.attr(
      'speedUnits',
      PREF().getFormater('speed').getAvailableUnits(),
    )
    me.viewModel.attr('speedFormats', ['#', '#.#', '#.##', '#.###', '#.####'])
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this
    me.viewModel.on('dataModel.timeZone', function (ev, value) {
      if (value) {
        me.viewModel.attr('isTimeZone', value.includes('GMT+'))
      }
    })

    me.viewModel.on('dataModel.sendDefaultHeading', function (ev, value) {
      me.viewModel.attr('isDefaultHeading', value === 'true')
    })
  },

  /**
   * @override
   */
  updateViewModel: function () {
    const me = this
    me._super()

    // Kind
    const isFlux = me.data.kind === KIND_FLUX
    const isText = me.data.kind === KIND_TEXT
    const isNaf = me.data.kind === KIND_NAF
    const isNafNorway = me.data.kind === KIND_NAF_NORWAY
    me.viewModel.attr(
      'hasKind',
      ALL_KINDS.some((kind) => kind === me.data.kind),
    )
    me.viewModel.attr('isTEXT', isText)
    me.viewModel.attr('isNAF', isNaf)
    me.viewModel.attr('isFLUX', isFlux)
    me.viewModel.attr('isNafNorway', isNafNorway)
    me.viewModel.attr('isTextOrNafNorway', isText || isNafNorway)
    me.viewModel.attr('isNafOrNafNorway', isNaf || isNafNorway)

    // dateFormat et timeFormat
    if (!isFlux) {
      // pas pour flux
      // ce n'est pas la meme valeur par defaut entre text et les 2 NAF
      // de plus, pour les kind 'text', on blinde les formats venant du back pour s'assurer qu'ils soient compatibles avec le composant field-format
      if (isText) {
        me.viewModel.attr(
          'dataModel.dateFormat',
          me.data.dateFormat
            ? toSupportedDateFormat(me.data.dateFormat)
            : 'dd/MM/yyyy',
        )
        me.viewModel.attr(
          'dataModel.timeFormat',
          me.data.timeFormat
            ? toSupportedTimeFormat(me.data.timeFormat)
            : 'HH:mm:ss',
        )
      } else {
        me.viewModel.attr(
          'dataModel.dateFormat',
          me.data.dateFormat || 'YYMMDD',
        )
        me.viewModel.attr('dataModel.timeFormat', me.data.timeFormat || 'HHMM')
      }
    }

    // Mobile fields
    const vesselIdentifiers = me.data.vesselIdentifierField
      ? me.data.vesselIdentifierField.split(',')
      : []
    VESSEL_IDENTIFIER_OPTIONS.forEach(({ modelValue, viewValue }) => {
      me.viewModel.attr(viewValue, vesselIdentifiers.includes(modelValue))
    })

    // Beacon fields
    const beaconIdentifiers = me.data.beaconIdentifierField
      ? me.data.beaconIdentifierField.split(',')
      : []
    BEACON_IDENTIFIERS_OPTIONS.forEach(({ modelValue, viewValue }) => {
      me.viewModel.attr(viewValue, beaconIdentifiers.includes(modelValue))
    })

    if (me.data.sendDefaultHeading) {
      me.viewModel.attr('isDefaultHeading', true)
    }

    me.viewModel.attr('timeZoneValue', 0)
    if (me.data.timeZone && me.data.timeZone.match(/GMT[+-]/g)) {
      me.viewModel.attr('dataModel.timeZone', 'GMT+')
      me.viewModel.attr(
        'timeZoneValue',
        me.data.timeZone.replace('GMT', '').replace('+', ''),
      )
    }
  },

  /**
   * @override
   **/
  getDataModel: function () {
    const me = this
    const dataModel = me.viewModel.attr('dataModel').attr()

    // vesselIdentifierField
    dataModel.vesselIdentifierField = VESSEL_IDENTIFIER_OPTIONS.filter(
      ({ viewValue }) => me.viewModel.attr(viewValue),
    )
      .map(({ modelValue }) => modelValue)
      .join(',')

    // beaconIdentifierField pour flux ou naf uniquement
    if ([KIND_FLUX, KIND_NAF].includes(dataModel.kind)) {
      dataModel.beaconIdentifierField = BEACON_IDENTIFIERS_OPTIONS.filter(
        ({ viewValue }) => me.viewModel.attr(viewValue),
      )
        .map(({ modelValue }) => modelValue)
        .join(',')
    }

    if (dataModel.timeZone === 'GMT+') {
      const timeZoneValue = me.viewModel.attr('timeZoneValue')
      dataModel.timeZone =
        timeZoneValue >= 0 ? 'GMT+' + timeZoneValue : 'GMT' + timeZoneValue
    }

    return dataModel
  },

  /**
   * @override
   **/
  modifyDiffBeforeCheck: function (diff) {
    const me = this
    _.each(diff.diff, ({ from, to }, key) => {
      if (from === 'undefined' && (_.isNil(to) || to === '')) {
        delete diff.diff[key]
      }
    })

    if (me.data.kind === KIND_TEXT) {
      // Permet de gérer la cas d'un mauvais dateFormat sur une vielle donnée
      // Bof bon endroit pour faire ça ? me.data devrait déjà etre corrigé (à faire dans CpcFormat.loadForEdition ?)
      const dateFormatDiff = diff.diff.dateFormat
      if (dateFormatDiff) {
        const { from, to } = dateFormatDiff
        if (from && toSupportedDateFormat(from) === to) {
          delete diff.diff.dateFormat
        }
      }

      // Idem dateFormat ci-dessus
      const timeFormatDiff = diff.diff.timeFormat
      if (timeFormatDiff) {
        const { from, to } = timeFormatDiff
        if (from && toSupportedTimeFormat(from) === to) {
          delete diff.diff.timeFormat
        }
      }
    }

    diff.count = Object.keys(diff.diff).length
    return diff
  },
})

export default CpcFormatInfoPanel
