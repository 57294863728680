import ResourceUI from 'core-uda/ui/ResourceUI'
import FishingProtocol from 'core-uda/model/fishing/FishingProtocol'
import template from 'thm/ui/common/fishingProtocol/FishingProtocolTpl.stache'

/**
 * User Interface elements for FishingProtocol
 */
let FishingProtocolUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: FishingProtocol.itemId,
      i18nPrefix: 'thm.ui.common.fishingProtocol.FishingProtocolUI.',
      icon: 'fishing',
      template: template,
    })
  },
})

const FISHING_PROTOCOL_UI = new FishingProtocolUI()

export default FishingProtocolUI
