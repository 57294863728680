import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import InfoPanel from 'core-uda/ui/InfoPanel'
import UdaGrid from 'core-uda/ui/UdaGrid'
import { I18N } from 'core-cmp/util/I18n'
import { PREF } from 'core-cmp/Preferences'
import Grid from 'core-cmp/Grid'
import Utils from 'core-cmp/Utils'
import ErsOperationReportInfoPanelTplStache from 'thm/ui/common/ersOperationReport/ErsOperationReportInfoPanelTpl.stache'
import ErsActivityEditPage from 'thm/ui/common/ersActivity/ErsActivityEditPage'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import 'thm/ui/common/ersActivity/ErsActivityInfoPanel.css'
import ErsFormUI from 'core-uda/model/ersFormUI/ErsFormUI'
import ErsOperationReportExportPopup from 'thm/ui/common/ersOperationReport/ErsOperationReportExportPopup'

const FN = {
  subActivityCOE: (item) => {
    return _.find(item.subActivities, { kind: 'COE' })
  },
  subActivityCOX: (item) => {
    return _.find(item.subActivities, { kind: 'COX' })
  },
}

/**
 * @class thm.ui.common.ersActivity.ErsActivityInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un voyage
 * @param {Object} options
 */
let ErsOperationReportInfoPanel = InfoPanel.extend({
  template: ErsOperationReportInfoPanelTplStache,
  i18nPrefix: [
    'uda.ersActivity.',
    'thm.ui.common.ersActivity.ErsActivityInfoPanel.',
  ],
  id: 'ersOperationReportInfoPanel',
  entityId: 'ersActivity',
  catchesGridConfig: {
    width: '100%',
    filterRow: null,
    sorting: {
      mode: 'single',
    },
    export: {
      enabled: false,
    },
    columnResizingMode: 'widget',
    columns: [
      {
        caption: 'uda.species.one',
        columns: [
          {
            id: 'speciesName',
            dataField: 'speciesName',
            width: 150,
            dataType: 'string',
            alignment: 'center',
            //allowFiltering: true,
            allowSorting: true,
          },
          {
            id: 'length',
            dataField: 'length',
            width: 130,
            dataType: 'string',
            alignment: 'center',
            //allowFiltering: true,
            allowSorting: true,
          },
        ],
      },
    ],
  },
  grid: { gridName: 'catchesGrid', place: 'catchDetail' },

  /**
   * @override
   */
  doAfterRender: function () {
    let me = this
    me._super()
    me.updateGrids()
  },

  updateGrids: function () {
    let me = this,
      config = me['catchesGridConfig'].attr()

    _.each(config.columns, function (col) {
      if (col.caption) {
        col.caption = me.msg(col.caption)
        _.each(col.columns, (subCol) => {
          me.updateColumnOption(me.grid, subCol)
        })
      } else {
        me.updateColumnOption(me.grid, col)
      }
    })
    if (!_.isUndefined('catchesGridConfig')) {
      me['catchesGridConfig'] = new UdaGrid(me.$findById('catchesGrid'), config)
    }
  },

  updateColumnOption: function (grid, col) {
    let me = this,
      gridConfigId = grid.gridName

    col.caption = me.msg(gridConfigId + '.' + col.id)
    col.id = grid.gridName + '_' + col.id
    Grid.updateColumnOptionFormat(col)
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this
    me._super()
    me.setDataGrid()
  },

  setDataGrid: function () {
    let me = this,
      data =
        me.data.speciesLengths && me.data.speciesLengths.attr
          ? me.data.speciesLengths.attr()
          : []
    if (!_.isNil(me['catchesGridConfig'])) {
      me['catchesGridConfig'].beginUpdate()
      me['catchesGridConfig'].option('dataSource', data)
      me['catchesGridConfig'].endUpdate()
    }
  },

  /**
   * @override
   */
  getToolbarActions: function () {
    let me = this,
      actions = me._super()

    me.addToolbarAction(actions, {
      action: 'operationRecord',
      icon: 'export',
      label: me.msg('_OperationRecord'),
    })

    return actions
  },

  onOperationRecord: function () {
    let me = this,
      item = me.getDataModel()

    ErsOperationReportExportPopup.openPage(item)
  },
})

export default ErsOperationReportInfoPanel
