import _ from 'core-cmp/lodash'
import ResourceDAO from 'core-uda/model/ResourceDAO'
import MeteoStationRecord from 'thm/model/meteoStationRecord/MeteoStationRecord'

/**
 * Meteo Station Record DAO
 */

let MeteoStationRecordDAO = ResourceDAO.extend({
  resource: 'meteoStationRecord',
  resourceClass: MeteoStationRecord,
  findAllParams: {
    fields: 'all',
    mostRecentData: 'true',
  },
})

// Instance unique
let METEO_STATION_RECORD_DAO = new MeteoStationRecordDAO()

export { METEO_STATION_RECORD_DAO }
export default MeteoStationRecordDAO
