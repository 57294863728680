import { APP } from 'core-uda/model/Resource'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import ThmZoneInfoPanel from 'thm/ui/common/zone/ThmZoneInfoPanel'
import ZoneUserInfoPanelTplStache from 'thm/ui/common/zone/ZoneUserInfoPanelTpl.stache'

/**
 * @class ZoneUserInfoPanel
 * User zone form
 */
let ZoneUserInfoPanel = ThmZoneInfoPanel.extend({
  id: 'zoneUserInfoPanel',
  childTpl: ZoneUserInfoPanelTplStache,
  i18nPrefix: ThmZoneInfoPanel.extendI18nPrefix(
    'umv.ui.zone.ZoneUserInfoPanel.',
  ),

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr(
      'globalZoneAuthorized',
      DATA_AUTHORIZED('globalZoneTypes.edit'),
    )
    me.viewModel.attr('dataModel.global', false)
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this
    me.viewModel.on('dataModel.global', (ev, value) => {
      me.viewModel.attr('showClient', RIGHTS('showClient') && !value)
      me.viewModel.attr(
        'globalZoneAuthorized',
        DATA_AUTHORIZED('globalZoneTypes.edit') || value,
      )
    })

    me.viewModel.compute('dataModel.seasonal').bind('change', () => {
      if (me.viewModel.attr('dataModel.seasonal')) {
        if (!me.viewModel.attr('dataModel.beginDate')) {
          me.viewModel.attr('dataModel.beginDate', new Date().addDays(-1))
        }
        if (!me.viewModel.attr('dataModel.endDate')) {
          me.viewModel.attr('dataModel.endDate', new Date())
        }
      } else {
        me.viewModel.removeAttr('dataModel.beginDate')
        me.viewModel.removeAttr('dataModel.endDate')
      }
    })
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this,
      userZoneTypes = APP().getJsonData('userZoneTypes'),
      globalZoneTypeIds = DATA_AUTHORIZED('globalZoneType')
        ? APP().getJsonData('globalZoneType')
        : null

    me.data.attr(
      'typeId',
      me.data.typeId ||
        (userZoneTypes && userZoneTypes[0] ? userZoneTypes[0].id : undefined),
    )
    me.data.attr(
      'globalZoneTypeId',
      me.data.globalZoneTypeId ||
        (globalZoneTypeIds && globalZoneTypeIds[0]
          ? globalZoneTypeIds[0].id
          : undefined),
    )

    if (_.isNil(me.data.attr('shared'))) {
      me.data.attr('shared', false)
    }

    me._super()
  },
})
export default ZoneUserInfoPanel
