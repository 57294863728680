import ResourceUI from 'core-uda/ui/ResourceUI'
import ReportSchedule from 'core-uda/model/reportSchedule/ReportSchedule'
import ThmRecipientCriteria from 'thm/ui/common/criteria/ThmRecipientCriteria'

/**
 * User Interface elements for ReportSchedule
 */
let ReportScheduleUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ReportSchedule.itemId,
      i18nPrefix: 'thm.ui.common.reportSchedule.ReportScheduleUI.',
      icon: 'annotation',
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      recipientIds: {
        cmpClass: ThmRecipientCriteria,
      },
    }
  },
})

const REPORT_SCHEDULE_UI = new ReportScheduleUI()
export default ReportScheduleUI
