import ResourceUI from 'core-uda/ui/ResourceUI'
import CpcCondition from 'core-uda/model/cpcCondition/CpcCondition'
import template from 'core-uda/model/cpcCondition/CpcConditionUITpl.stache'

/**
 * User Interface elements for CpcCondition
 */
let CpcConditionUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this
    me._super({
      resourceId: CpcCondition.itemId,
      i18nPrefix: 'thm.ui.common.cpcCondition.CpcConditionUI.',
      icon: 'config',
      template: template,
    })
  },
})

const CPC_CONDITION_UI = new CpcConditionUI()

export default CpcConditionUI
