import ResourceUI from 'core-uda/ui/ResourceUI'
import ReportLog from 'core-uda/model/reportLog/ReportLog'
import ThmRecipientCriteria from 'thm/ui/common/criteria/ThmRecipientCriteria'
import _ from 'core-cmp/lodash'
import Toastr from 'core-cmp/Toastr'

/**
 * User Interface elements for ReportLog
 */
let ReportLogUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ReportLog.itemId,
      i18nPrefix: 'thm.ui.common.reportLog.ReportLogUI.',
      icon: 'annotation',
    })
  },

  /**
   * @override
   */
  isMultipleRowSelectionAuthorized: function () {
    return true
  },

  /**
   * @override
   */
  getUreTopActionButtons: function (mainPage) {
    let me = this
    return [
      {
        id: 'resendDataTransmission',
        title: I18N.msg('thm.ui.common.reportLog.ReportLogUI.manualExecution'),
        icon: 'cancel',
        handler: (gridMainPage, ev) => {
          let selectedRowsData = gridMainPage.gridElement.getSelectedRowsData()
          if (selectedRowsData.length) {
            let ids = _.map(selectedRowsData, 'reportScheduleId')
            ReportLog.manualExecution(ids).done(() => {
              Toastr.showInfo(
                I18N.msg(
                  'thm.ui.common.reportLog.ReportLogUI.manualExecutionDone',
                ),
                5000,
              )
            })
          } else {
            Toastr.showError(
              I18N.msg(
                'thm.ui.common.reportLog.ReportLogUI.noLineSelectedError',
              ),
              5000,
            )
          }
        },
      },
    ]
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      recipientIds: {
        cmpClass: ThmRecipientCriteria,
      },
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      recipientNames: {
        dataType: 'string',
        format: (value) => {
          let names = ''
          if (value !== undefined) {
            names = value.join(', ')
          }
          return names
        },
      },
      recipientIds: {
        dataType: 'string',
        format: (value) => {
          let names = ''
          if (value !== undefined) {
            names = value.join(', ')
          }
          return names
        },
      },
    }
  },
})

const REPORT_LOG_UI = new ReportLogUI()

export default ReportLogUI
