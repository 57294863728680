import DataModel from 'core-uda/model/DataModel'

/**
 * Repport model.
 */
let ReportModel = DataModel.extend({
  modelId: 'reports',
  itemId: 'report',
  rightKey: 'mobile',
})

export default ReportModel
