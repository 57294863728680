<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-state {(field)}="tabs" options="tabsOptions" options-sort="false" {options-col}="tabsColOptions"
                 class="tab"></field-state>
  </ui-page-header>

  <field-item item="dataModel" class="L-scrollY-content">

    <!---------------- TAB Alert --------------------->

    <div id="{{id}}-definition" class="{{#is tabs "definition"}}{{else}}hidden{{/is}}">
      <div class="header-group icon-left icon-m ui-icon-info">
        <h2>{{msg '_GeneralInfo'}}</h2>
      </div>
      <div class="inlineGroup fields">
        {{#if manual}}
          <field-textarea name="name" required="true" col="12"></field-textarea>
          {{#if showClient}}
            <uda-select of="customers" name="customerId" col=12" {enabled}="_cmp.creation"></uda-select>
          {{/if}}
        {{else}}
          <field-text name="name" required="true" col="6 12"></field-text>
          {{#if showClient}}
            <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
          {{/if}}
        {{/if}}

        <field-checkbox name="activated" col="6 6" {visible}="activatedAuthorized"></field-checkbox>
        <field-checkbox name="shared" col="6 6" {visible}="sharedAuthorized" {enabled}="sharedEdit"></field-checkbox>

        {{#if beaconEventsOptions}}
            <a cls-action="selectAll" class="btn ui-icon-check icon-l icon-left" style="left: 6px" >
              {{msg "thm.ui.common.alertDefinition.AlertDefinitionInfoPanel.selectAll"}}</a>
            <field-checkboxes label="" name="beaconEvents" options-sort="false" options="beaconEventsOptions"></field-checkboxes>
        {{/if}}
      </div>
      {{#if child2Tpl}}
        {{>child2Tpl}}
      {{/if}}
      {{#if childTpl}}
        {{>childTpl}}
      {{/if}}

      {{#if timingAllowed}}
        <div class="inlineGroup fields">
          <field-checkbox name="alertOnStart" col="6 12"></field-checkbox>
          <field-checkbox name="alertOnEnd" col="6 12"></field-checkbox>
        </div>
      {{/if}}
    </div>

    <!------------------- TAB MOBILE ------------------------->

    <div id="{{id}}-context" class="{{#is tabs "context"}}{{else}}hidden{{/is}}">
      {{#if mobileAllowed}}
        <div class="header-group icon-left icon-m ui-icon-mobile">
          <h2>{{msg 'Mobiles'}}</h2>
        </div>
        <div id="{{id}}-common" class="inlineGroup fields">
          <field-hcombo name="mobileType" label="" options="mobileTypes" col="12 12"></field-hcombo>
        </div>
        <uda-list of="mobiles" name="mobiles" {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId" {visible}="hasMobiles" class="inlineGroup allow-delete-item"></uda-list>
        <uda-list of="fleets" name="fleets" {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId" {visible}="hasFleets" class="inlineGroup"></uda-list>
      {{/if}}
      {{#if zoneAllowed}}
        <uda-list of="zones" name="zones" {update-for}="dataModel.customerId"
                  update-function="findForAlertDefinitions" class="inlineGroup"></uda-list>
      {{/if}}
    </div>

    <!------------------- TAB ADVANCED SETTINGS -------------->

    <div id="{{id}}-context" class="{{#is tabs "settings"}}{{else}}hidden{{/is}}">
      <div class="{{#unless advancedDateTabAuthorized}}hidden{{/unless}}">
        <div class="header-group icon-left icon-m ui-icon-calendar">
          <h2>{{msg 'alertRestrictions'}}</h2>
        </div>

        <div class="inlineGroup fields">
          <field-checkbox name="advancedSeasonal"></field-checkbox>
          <div class="{{#unless dataModel.advancedSeasonal}}hidden{{/unless}}">
            <div class="alertDef-date-selector-container">
              <span>{{msg 'startDayUTC'}}</span>
              <field-select label="" name="advancedSeasonalStartDay" format="int" {options}="daysStart"
                            col="1 2"></field-select>
              <div class="alertDef-selector-separator">/</div>
              <field-select label="" name="advancedSeasonalStartMonth" format="int" options="months"
                            col="1 2"></field-select>
            </div>
            <div class="alertDef-date-selector-container">
              <span>{{msg 'endDayUTC'}}</span>
              <field-select label="" name="advancedSeasonalEndDay" format="int" {options}="daysEnd"
                            col="1 2"></field-select>
              <div class="alertDef-selector-separator">/</div>
              <field-select label="" name="advancedSeasonalEndMonth" format="int" options="months"
                            col="1 2"></field-select>
            </div>
          </div>
          <field-checkbox name="advancedCurfew"></field-checkbox>
          <div class="{{#unless dataModel.advancedCurfew}}hidden{{/unless}}">
            <field-checkboxes label="" name="advancedCurfewDays" options="availableCurfewDays"
                              format="array"
                              {visible}="dataModel.advancedCurfew"></field-checkboxes>
            <field-hcombo name="advancedCurfewBetween" options="curfewBetweenOptions"
                          label-align="top"></field-hcombo>

            <div class="alertDef-time-selector-container">
              <span>{{msg 'startTimeUTC'}}</span>
              <field-select label="" name="advancedCurfewStartTimeHour" format="int" options="hours"
                            col="1 2"></field-select>
              <div class="alertDef-selector-separator">:</div>
              <field-select label="" name="advancedCurfewStartTimeMinute" format="int" options="minutes"
                            col="1 2"></field-select>
            </div>
            <div class="alertDef-time-selector-container">
              <span>{{msg 'endTimeUTC'}}</span>
              <field-select label="" name="advancedCurfewEndTimeHour" format="int" options="hours"
                            col="1 2"></field-select>
              <div class="alertDef-selector-separator">:</div>
              <field-select label="" name="advancedCurfewEndTimeMinute" format="int" options="minutes"
                            col="1 2"></field-select>
            </div>
          </div>
        </div>
      </div>

      <div class="{{#unless whiteListTabAuthorized}}hidden{{/unless}}">
        <div class="header-group icon-left icon-m ui-icon-mobile">
          <h2 title="{{msg 'whitelist.title'}}">{{msg 'whiteList'}}</h2>
        </div>

        <div id="{{id}}-common" class="inlineGroup fields">
          <field-hcombo name="whiteListType" label="" options="whiteListTypes" col="12 12"></field-hcombo>
        </div>
        <uda-list of="mobiles" name="authorizedMobiles" {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId" {visible}="hasWhiteListMobiles"
                  class="inlineGroup"></uda-list>
        <uda-list of="fleets" name="authorizedFleets" {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId" {visible}="hasWhiteListFleets"
                  class="inlineGroup"></uda-list>
      </div>

      {{#periodicPollingAuthorized}}
        <div class="{{#unless periodicPollingAuthorized}}hidden{{/unless}}">
          <div class="header-group icon-left icon-m ui-icon-mobile">
            <h2>{{msg 'periodicPolling'}}</h2>
          </div>

          <div id="{{id}}-common" class="inlineGroup fields">
            <field-checkbox name="ppActivated"></field-checkbox>

            <div id="{{id}}-pp" class="inlineGroup fields {{#unless dataModel.ppActivated}}hidden{{/unless}}">
              <field-text name="ppFrequency"
                          {{#if dataModel.ppActivated}}required="true"{{/if}} col="6 12" format="int"></field-text>
              <field-text name="ppNbOfRetry"
                          {{#if dataModel.ppActivated}}required="true"{{/if}} col="6 12" format="int"></field-text>
              <field-select name="ppChannel" {options}="ppChannelOptions"
                            {{#if dataModel.ppActivated}}required="true"{{/if}}
                            title="{{msg 'channelTitle'}}"></field-select>
            </div>
          </div>

        </div>
      {{/periodicPollingAuthorized}}

    </div>

    <!------------------- TAB ACTIONS ------------------------->

    <div id="{{id}}-actions" class="{{#is tabs "actions"}}{{else}}hidden{{/is}}">
      <div class="header-group icon-left icon-m ui-icon-calendar">
        <h2>{{msg 'timingTitle'}}</h2>
      </div>

      <div class="inlineGroup fields">
        <field-checkbox label-key="notifyOnAck" name="notifyOnAck" col="12 12"></field-checkbox>

      </div>

        <div class="header-group icon-left icon-m ui-icon-mail">
            <h2>{{msg 'recipientTitle'}}</h2>
        </div>
        <uda-list of="recipients"
                  name="notifyRecipients"
                  label-key="notifyTabTitle"
                  {update-for}="dataModel.customerId"
                  update-function="filterByCustomerId"
                  create-function="createForCustomer"
                  class="inlineGroup"></uda-list>
      {{#if isUserAlertDefinition}}
          <div class="inlineGroup fields">
              <field-checkbox name="notifyShipowner" col="6 12"></field-checkbox>
              <field-checkbox name="notifyMobile" col="6 12"></field-checkbox>
            {{#if showClient}}
                <field-checkbox name="notifyClient" col="6 12"></field-checkbox>
            {{/if}}
          </div>
      {{/if}}

      {{#if commandAccess}}
        <div class="header-group icon-left icon-m ui-icon-beacon">
            <h2>{{msg 'actionTitle'}}</h2>
        </div>
        <div class="inlineGroup fields">
          <field-checkbox name="actionActivated" col="6 12"></field-checkbox>
          <field-select name="actionType" options="actionTypeOptions" options-sort="false" col="6 12"
                        {visible}="dataModel.actionActivated"></field-select>
          <field-text name="actionOptions" required="true" col="12 12"
                      {visible}="hasReportingIntervalOption"></field-text>
        </div>
      {{/if}}

      <div class="header-group icon-left icon-m ui-icon-edit">
        <h2>{{msg 'customAlertTitle'}}</h2>
      </div>
      <div class="inlineGroup fields">
        <h5>{{msg 'vesselFieldsTitle'}}</h5>
        <field-checkboxes name="vesselProperties" label="" options="vesselPropertiesOptions" col="12 12"
                          options-col="3"></field-checkboxes>
        <h5>{{msg 'alertFieldsTitle'}}</h5>
        <field-checkboxes name="alertInfos" label="" {options}="alertInfosOptions" col="12 12"
                          options-col="3"></field-checkboxes>
        <field-select name="coordinatesFormat" options="coordinatesFormat" options-sort="false" col="12 12"
                      {visible}="dataModel.alertInfos.coordinates"></field-select>

        <field-checkbox name="headerTitle" options="field.header" col="12 12"></field-checkbox>
        <field-text name="header" label="" col="12 12" {visible}="dataModel.headerTitle"></field-text>
        <field-checkbox name="freeTextTitle" options="field.freeText" col="12 12"></field-checkbox>
        <field-textarea name="freeText" label="" col="12 12" {visible}="dataModel.freeTextTitle"></field-textarea>
      </div>
    </div>

  </field-item>
  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus"></ui-submit-cancel>
</div>
