import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import Utils from 'core-cmp/Utils'
import { APP } from 'core-uda/model/Resource'
import SelectionPage from 'core-uda/ui/SelectionPage'
import MessageMobileListCmp from 'thm/ui/common/message/MessageMobileListCmp'
import 'thm/ui/common/message/MessageMobileListPage.css'
import $ from 'thm/thm-jquery'

/**
 * @class thm.ui.commonmobile.MobileListPage
 * @parent thm.ui
 * @constructor
 * Page de la liste des mobiles.
 * @param {Object} options
 */
let MessageMobileListPage = SelectionPage.extend({
  id: 'messageMobileListPage',
  currentPeriod: 'week',
  allowedProvider: 'CLS_IRIDIUM',
  i18nPrefix: 'thm.ui.common.mobile.MessageMobileListPage.',
  titleKey: '_Contacts',

  // override
  initChilds: function () {
    var me = this
    me._super()

    me.addChild({
      childId: 'itemList',
      cmpClass: MessageMobileListCmp,
      id: me.id,
      title: me.title,
    })
  },

  initViewModel: function () {
    var me = this
    me._super()
  },

  bindEvents: function () {
    var me = this
    me._super()

    APP().on('messagesLoaded', function () {
      var model = me.configureModel()
      me.getChild('itemList').setModel(model)
      me.updateMessageCounter()
      me.getChild('itemList')._setVirtualModel(model)
    })
  },

  setCurrentPeriod: function (period) {
    var me = this
    me.currentPeriod = period
    var messagePage = Page.get('messagePage')
    if (!!messagePage.mobileId) {
      me.getChild('itemList').onItemClickOpen(messagePage.mobileId)
    }
  },

  updateMessageCounter: function (id, counter) {
    var me = this
    me.getChild('itemList').viewModel.attr(
      'messageCounter',
      APP().messagesCount,
    )
    if (id) {
      var virtualModel = me.getChild('itemList').virtualModel
      if (!!virtualModel && _.isFunction(virtualModel.map)) {
        Utils.addMapToArray(virtualModel)
      }
      virtualModel.map[id].counter = counter
      me.getChild('itemList')._setVirtualModel(virtualModel)
    }
  },

  // override
  open: function (context, jqmConfig) {
    var me = this,
      model = me.configureModel()

    me.getChild('itemList').setModel(model)
    me.updateMessageCounter()
    me.getChild('itemList')._setVirtualModel(model)
    me._super(context, jqmConfig)
  },

  configureModel: function () {
    let me = this,
      model = [],
      tempModel = $.extend(true, [], APP().getData('mobiles'))

    $.each(tempModel, function (i, mobile) {
      if (mobile.activeBeaconModelId) {
        let beaconModel = APP('beaconTypes', mobile.activeBeaconModelId)
        if (!!beaconModel) {
          if (me.allowedProvider === beaconModel.satelliteProviderName) {
            let msg = !!APP().models['messages']
              ? APP().models['messages'].getMobileMessages(mobile.id)
              : undefined
            if (!msg) {
              mobile.counter = 0
            } else {
              mobile.counter = msg.getCountNoReadMsg()
            }
            model.push(mobile)
          }
        }
      }
    })
    delete model.map

    model.sort(function (a, b) {
      if (a.counter < b.counter) {
        return 1
      }
      if (a.counter > b.counter) {
        return -1
      }
      // a doit être égale à b

      if (a.counter === b.counter) {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        }
      }
      return 0
    })

    Utils.addMapToArray(model)
    return model
  },

  getTimeForPeriod: function () {
    var me = this,
      date = new Date().add({ minutes: new Date().getTimezoneOffset() })

    switch (me.currentPeriod) {
      case 'week':
        date = date.addWeeks(-1).getTime()
        break
      case 'month':
        date = date.addMonths(-1).getTime()
        break
      case 'months':
        date = date.addMonths(-3).getTime()
        break
    }

    return date
  },
})

export default MessageMobileListPage
