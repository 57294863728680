import _ from 'core-cmp/lodash'
import Page from 'core-cmp/Page'
import template from 'thm/ui/common/ersQuery/ErsQueryCreatePageTpl.stache'
import ErsQuery from 'core-uda/model/ersQuery/ErsQuery'

/**
 * @class ErsQueryCreatePage
 */
let ErsQueryCreatePage = Page.extend({
  routerType: 'tab',
  template: template,
  i18nPrefix: ['uda.ersQuery.', 'thm.ui.common.ersQuery.ErsQueryUI.'],
  id: 'ErsQueryCreatePagePanel',
  titleKey: 'thm.ui.common.ersQuery.ErsQueryUI.create.label',
  autoBindActions: true,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
      centerFlag = RIGHTS('flag'),
      queryFormat = RIGHTS('ersQueryFormat')

    me._super()

    me.viewModel.attr('dataModel', {})
    me.viewModel.attr(
      'kindOptions',
      ErsQuery.getTypeList(queryFormat, centerFlag),
    )
    me.viewModel.attr(
      'identifierTypeOptions',
      ErsQuery.getIdentifierTypeList(queryFormat),
    )
    me.viewModel.attr(
      'exportModeOptions',
      queryFormat == 'Flux'
        ? ['vesselIdentifier', 'tripNumber']
        : ['vesselIdentifier'],
    )
    me.viewModel.attr('dataModel.exportMode', 'vesselIdentifier')
    me.viewModel.attr('isFlux', queryFormat == 'Flux')
  },

  onCancel: function () {
    let me = this

    me.close()
  },

  onSubmit: function () {
    let me = this,
      query = new ErsQuery(me.viewModel.dataModel.attr()),
      flag = APP('flags', query.destination)

    if (flag) {
      query.attr('destination', flag.countryCode)
    }
    if (query.attr('exportMode') == 'vesselIdentifier') {
      query.attr('tripNumber', undefined)
    }
    if (query.attr('exportMode') == 'tripNumber') {
      query.attr('identifierType', undefined)
      query.attr('identifierValue', undefined)
    }

    me.listenStatus(query.save(), 'saveStatus').done(() => {
      _.delay(me.close.bind(me), 1000)
    })
  },
})

export default ErsQueryCreatePage
