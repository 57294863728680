<div id="{{id}}" class="{{#if hideCriteriaFirstTime}}hidden{{/if}}">
  <field-item item="criteriaFormModel">
    <field-hcombo id="criteria-selectByType" name="type" label="" {options}="typeOptions"></field-hcombo>

    {{#if resourceMap.mobile}}
      <uda-list of="mobiles" name="mobile" (itemsupdated)="onItemsUpdated" label-key="uda.mobile.severalSelected" 
                {visible}="states.mobile" class="inlineGroup"></uda-list>
    {{/if}}
    {{#if resourceMap.fleet}}
      <uda-list of="fleets"
                name="fleet"
                label-key="uda.fleet.severalSelected"
                {visible}="states.fleet"
                (itemsupdated)="onItemsUpdated" 
                update-function="getValidFleets"
                class="inlineGroup"></uda-list>
    {{/if}}
    {{#if resourceMap.beacon}}
      <uda-list of="beacons" name="beacon" (itemsupdated)="onItemsUpdated" label-key="uda.beacon.severalSelected"
                {visible}="states.beacon" class="inlineGroup"></uda-list>
    {{/if}}
    {{#if resourceMap.flag}}
      <uda-list of="flags" name="flag" (itemsupdated)="onItemsUpdated" label-key="uda.flag.severalSelected"
                {visible}="states.flag" class="inlineGroup"></uda-list>
    {{/if}}
  </field-item>
</div>
