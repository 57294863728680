import _ from 'core-cmp/lodash'
import Page from 'core-cmp/Page'
import { PREF } from 'core-cmp/Preferences'
import Toastr from 'core-cmp/Toastr'
import { APP } from 'core-uda/model/Resource'
import InfoPanel from 'core-uda/ui/InfoPanel'
import { MOBILE_DATA } from 'core-uda/model/mobile/MobileData'
import AlertImage from 'core-alert/model/AlertImage'

import AlertInfoPanelTplStache from 'thm/ui/common/alert/AlertInfoPanelTpl.stache'
import ImageListTpl from 'thm/ui/common/pictures/ImageListTpl.stache'

/**
 * @class AlertInfoPanel
 */
let AlertInfoPanel = InfoPanel.extend({
  template: AlertInfoPanelTplStache,
  i18nPrefix: [
    'thm.ui.common.alert.AlertInfoPanel.',
    'uda.mobile.',
    'uda.alert.',
  ],
  childTpl: null,
  id: 'alertInfoPanel',
  entityId: 'alert',
  allowEdit: true,
  allowMenu: false,
  allowDelete: false,

  // override
  bindEvents: function () {
    let me = this
    me._super()

    APP().on('alertsLoaded', function (evt, action, item) {
      if (!me.getParentPage().isOpen) {
        return
      }
      if (me.data && APP('alerts', me.data.id)) {
        me.data = APP('alerts', me.data.id)

        me.loadForEdition(me.data).always(() => {
          me.updateViewModel(true)
        })
      }
    })

    APP().on('itemUpdated', function (evt, modelId, actionId, index, item) {
      if (item.id === me.data.id) {
        me.data = APP('alerts', item.id)
        me.updateViewModel()
      }
    })
  },

  initViewModel: function () {
    let me = this,
      tabs = []
    me._super()

    me.viewModel.attr('ImageListTpl', ImageListTpl)

    me.viewModel.attr('form').attr({
      currentTab: 'alertInfo',
    })
    me.viewModel.attr('form').on('currentTab', me.proxy(me._updateTab))

    tabs.push(
      {
        value: 'alertInfo',
        text: me.msg('alertInfo'),
      },
      {
        value: 'pictures',
        text: me.msg('picturesTitle'),
      },
    )

    me.tabs = tabs
    me.viewModel.attr('tabs', me.tabs)
    me.viewModel.attr('loc', '')
    me.viewModel.attr('locDate', '')
    me.viewModel.attr('imagesToDisplay', [])
    me.viewModel.attr('imagesToDelete', [])
    me.viewModel.attr('imgChanged', false)
  },

  /**
   * @override
   */
  openView: function () {
    let me = this
    me._super()
    me.setCurrentTab('alertInfo')
  },

  initImageReader: function () {
    let me = this

    me.viewModel.attr('images', null)
    me.viewModel.on('images', function (evt, fileList) {
      if (!fileList || fileList === '' || !fileList.length) {
        return // updateViewModel form field reset
      }
      me.prepareImages(fileList)
      this.attr('imgChanged', true)
    })
  },

  updateViewModel: function (refresh) {
    let me = this,
      mobile = me.data.mobile,
      alertDef = APP('alertDefinitions', me.data.alertDefinitionId)
    me._super()
    me.viewModel.attr('noLoc', !me.data.loc)
    me.viewModel.attr('loc', PREF().format('position', me.data.loc))
    me.viewModel.attr(
      'locDate',
      PREF().format('dateTime', new Date(me.newData.locDateMillis)),
    )
    me.viewModel.attr('alertDefinition', !!me.data.alertDefinitionId)
    me.viewModel.attr(
      'dataModel.alarmDate',
      PREF().format('dateTime', new Date(me.newData.alarmDateMillis)),
    )
    me.viewModel.attr('estimated', me.data.estimated)
    me.viewModel.attr('alertDefinitionName', !!alertDef ? alertDef.name : '')
    me.viewModel.attr('kind', me.msg('field.kind.' + me.newData.kind))
    me.viewModel.attr('dataModel.mobile', mobile)

    // THM-12452 - if text if json we take the translation form ersAlertReport-field
    let alertText = me.data.text
    try {
      let json = JSON.parse(alertText)
      if (json.key) {
        alertText = json
      }
    } catch (e) {}

    me.viewModel.attr(
      'dataModel.text',
      _.isObject(alertText)
        ? I18N.msg(
            'uda.ersAlertReport.field.text.' + alertText.key,
            alertText.args,
          )
        : alertText,
    )

    if (refresh) {
      if (me.data.images) {
        // Lors d'un update de l'alerte courante on merge les possibles nouvelles images avec celles déjà affichées moins celles supprimées

        //We remove images already to delete by user before refresh from the list
        let newImgsWithoutDeletedOne = me.data.images.filter(
          (i) =>
            !me.viewModel
              .attr('imagesToDelete')
              .attr()
              .find((d) => d.id === i.id),
        )

        //We merge the images list with the previous one

        newImgsWithoutDeletedOne.forEach((img) => {
          //If a new image has been sync during refresh we add it to the list
          if (!me.viewModel.attr('imagesToDisplay').indexOf(img)) {
            me.viewModel.attr('imagesToDisplay').push(img)
          }
        })
      }
    } else {
      me.viewModel.attr('imagesToDisplay', me.data.images)
    }
    me.initImageReader()

    if (!_.isNil(me.newData.validationDateMillis)) {
      me.viewModel.attr(
        'dataModel.validationDate',
        PREF().format('dateTime', new Date(me.newData.validationDateMillis)),
      )
    }

    if (!_.isNil(me.newData.ackDateMillis)) {
      me.viewModel.attr(
        'dataModel.ackDate',
        PREF().format('dateTime', new Date(me.newData.ackDateMillis)),
      )
    }
  },

  cloneData: function () {
    let me = this,
      mobile = me.data.mobile,
      position = me.data.position,
      cloneData

    // Gros problème de liaison cyclique qui plante le clone
    // alert => alert.position => position.alerts => alert
    // Et mobile qui est un objet CanJs
    // => il faut les cloner manuellement
    delete me.data.mobile
    delete me.data.position

    cloneData = me._super()

    cloneData.mobile = mobile

    // Surrimme les liens cyclique entre alert et position : canjs n'aime pas ca !
    if (position) {
      cloneData.position = _.clone(position)
      delete cloneData.position.alerts
    }
    me.data.mobile = mobile
    me.data.position = position

    return cloneData
  },

  cleanForEqual: function (attr) {
    let me = this

    delete attr.validationDate // create from validationDateMillis
    delete attr.alarmDate // create from alarmDateMillis
    delete attr.ackDate // create from ackDateMillis
    delete attr.creationDate // create from ackDateMillis
    delete attr.modificationDate // create from ackDateMillis
    delete attr.position // not editable
    delete attr.lastLoc // not editable
    delete attr.lastLocDate // not editable
    delete attr.lastSpeed // not editable
    delete attr.mobile // not editable

    return attr
  },

  /**
   * @override
   */
  getTitle: function () {
    let me = this,
      mobile = me.data.mobile
    if (!mobile && me.data.position) {
      mobile = me.data.position.mobile
    }

    return MOBILE_DATA.getMobileLabel(mobile)
  },

  /**
   * @override
   */
  getSubTitle: function () {
    let me = this,
      mobile = me.data.mobile
    if (!mobile && me.data.position) {
      mobile = me.data.position.mobile
    }
    if (mobile) {
      MOBILE_DATA.getMobileAdditionalLabels(mobile)
    }
  },

  onShowMobile: function () {
    const me = this
    Page.openDataPanel({
      type: 'mobile',
      data: { id: me.newData.mobileId, ignoreActive: true },
      target: 'right',
    })
  },

  onShowAlertDefinition: function () {
    const me = this

    Page.openDataPanel({
      type: 'alertDefinition',
      data: APP('alertDefinitions', me.newData.alertDefinitionId),
      target: 'right',
    })
  },

  adjustImage: function (image) {
    let me = this,
      prefix = ''

    // check if the image is already in base64
    if (!image.includes('data:image/')) {
      prefix = 'data:image/png;base64,'
    }

    return prefix + image
  },

  prepareImages: function (files) {
    let me = this

    for (let file of files) {
      let reader = new FileReader()
      reader.onload = function (e) {
        if (
          !_.find(
            me.viewModel.attr('imagesToDisplay'),
            (f) => f.id === _.camelCase(file.name),
          )
        ) {
          me.viewModel.attr('imagesToDisplay').push({
            id: _.camelCase(file.name),
            b64Image: me.adjustImage(e.target.result),
            status: 'save',
          })
        }
      }

      reader.readAsDataURL(file)
    }
  },

  onDeleteAlertImage: function (jqueryEvent) {
    let me = this,
      idToDelete = jqueryEvent.currentTarget.id.split('-')[0],
      imageToDelete = me.viewModel
        .attr('imagesToDisplay')
        .attr()
        .find((image) => image.id === idToDelete)

    if (imageToDelete.status === 'database') {
      me.viewModel.attr('imagesToDelete').push(imageToDelete)
    }

    me.viewModel.attr(
      'imagesToDisplay',
      me.viewModel
        .attr('imagesToDisplay')
        .attr()
        .filter((image) => image.id !== idToDelete),
    )
  },

  _updateTab: function () {
    let me = this,
      value = me.viewModel.attr('form.currentTab')
    me.setCurrentTab(value)
  },

  setCurrentTab: function (value) {
    let me = this

    me.$find('.tab').hide()

    me.$findById('tab-' + value).show()
    me.$findById('tab-' + value).css('width', '100%')
    me.$all.css('width', '100%')

    me.viewModel.attr('form').attr('currentTab', value)
  },

  /**
   * @override
   */
  onSave: function (forceClose) {
    //Save And delete pictures here
    let me = this,
      imagesToDelete = [],
      imagesToSave = []

    if (!me.checkChanges()) {
      Toastr.showToastr('info', 'noChanges')
    } else {
      me.viewModel.attr('saveStatus', 0)

      imagesToDelete = me.viewModel
        .attr('imagesToDelete')
        .attr()
        .map((alert) => {
          return new AlertImage({
            id: alert.id,
          }).destroy()
        })

      imagesToSave = me.viewModel
        .attr('imagesToDisplay')
        .attr()
        .filter((i) => i.status === 'save')
        .map((alert) => {
          return new AlertImage({
            b64Image: alert.b64Image,
            alertId: me.data.id,
          }).save()
        })

      $.when([...imagesToDelete, ...imagesToSave])
        .done((data) => {
          me.viewModel.attr('imagesToDelete', [])
          me.viewModel.attr('imagesToDisplay', [])

          me.loadForEdition(me.data).done((data) => {
            if (forceClose) {
              me.close(false, forceClose)
            }
            me.updateViewModel()
            me.viewModel.attr('saveStatus', 1)
          })
        })
        .fail((errors) => {
          me.onSaveError(errors)
          me.viewModel.attr('saveStatus', 1)
        })
    }
  },

  /**
   * @override
   */
  checkChanges: function () {
    let me = this

    if (
      me.viewModel.attr('imagesToDelete').length > 0 ||
      me.viewModel
        .attr('imagesToDisplay')
        .attr()
        .find((i) => i.status === 'save')
    ) {
      return true
    }

    return false
  },

  /**
   * @override
   *
   */
  doAfterOpen: function () {
    let me = this

    me._super()

    me.viewModel.attr('imagesToDisplay', [])
    me.viewModel.attr('imagesToDelete', [])
  },

  /**
   * @override
   */
  cancelUpdate: function () {
    let me = this

    me.viewModel.attr('imagesToDisplay', me.data.images || [])
    me.viewModel.attr('imagesToDelete', [])
    me.initImageReader()

    me._super()
  },
})

export default AlertInfoPanel
