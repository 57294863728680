import ResourceUI from 'core-uda/ui/ResourceUI'
import SsasAuditLog from 'core-uda/model/ssas/SsasAuditLog'

/**
 * User Interface elements for SsasAudtiLogs
 */
let SsasAuditLogUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: SsasAuditLog.itemId,
      i18nPrefix: 'thm.ui.common.ssas.SsasUI.',
      icon: 'user',
    })
  },
})

const SSAS_AUDIT_LOG_UI = new SsasAuditLogUI()

export { SSAS_AUDIT_LOG_UI }
export default SsasAuditLogUI
