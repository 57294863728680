import AlertDefinitionInfoPanel from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel'

/**
 * @class AlertDefinitionFishingLicenceExpiredInfoPanel
 * Alert Definition "FishingLicenceExpired" Form.
 */
let AlertDefinitionFishingLicenceExpiredInfoPanel =
  AlertDefinitionInfoPanel.extend({
    id: 'alertDefinitionFishingLicenceExpiredInfoPanel',
    timingAllowed: true,
  })
export default AlertDefinitionFishingLicenceExpiredInfoPanel
