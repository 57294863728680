import _ from 'lodash'
import Page from 'core-cmp/Page'
import GIS from 'core-gis/Default'
import { APP } from 'core-uda/model/Resource'
import Toastr from 'core-cmp/Toastr'
import Toolbar from 'core-uda/ui/toolbar/Toolbar'
import ConfirmationPopup from 'core-cmp/msg/ConfirmationPopup'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import { MAIN_PAGE } from 'core-uda/ui/UdaMainPage'
import { DATA_CONTEXT } from 'core-uda/model/DataContext'
import SADToolsPage from 'core-sar/ui/SADToolsPage'
import UnitConversionPage from 'core-uda/ui/settings/UnitConversionPage'

/**
 * @class thm.ui.umv.Toolbar
 * @parent thm.ui
 * @constructor
 * Outil de recherche.
 * @param {Object} options
 */
let ThmToolbar = Toolbar.extend({
  // id:            'map-toolbar',
  rootIcon: 'info',
  handledObject: null,
  i18nPrefix: 'thm.ui.umv.ThmToolbar.',

  // override
  init: function (config) {
    let me = this

    me._super(config)

    me.addButtons([
      {
        id: 'info',
        icon: 'info',
        isToggle: true,
        title: me.msg('info.title'),
        handler: function () {
          if (me.fromZone) {
            me.fromZone = false
            GIS.getTool('zones').onActionProcessed()
            return
          }
          GIS.activateTool('info')
          me.trigger('info')
        },
      },
      {
        id: 'measure',
        icon: 'measure',
        isToggle: true,
        title: me.msg('measure.title'),
        handler: () => {
          MAIN_PAGE().starMeasureList?.close()
          MAIN_PAGE().tagList?.close()

          // Activate measure tool
          const activate = (action) => {
            GIS.activateTool('measure', {
              action,
              allowValidate: DATA_AUTHORIZED('searchAndRescue.edit'),
              validateIcon: 'buoy',
              validateTooltip: this.msg('createSarSad'),
            }).done(({ action, positions }) => {
              if (action === 'validate') {
                MAIN_PAGE().viewModel.attr('sarX', {
                  positions,
                  featureInfos: {
                    type: 'datumLine',
                  },
                })
              }
            })
            me.trigger('measure')
          }

          if (MAIN_PAGE().measureList?.modelList?.length > 0) {
            ConfirmationPopup.openPage({
              title: me.msg('measureToolConfirm.title'),
              text: me.msg('measureToolConfirm.text'),
              buttons: [
                { action: 'replace', label: me.msg('_Replace') },
                { action: 'add', label: me.msg('_Add') },
              ],
              onAction: function (action) {
                activate(action)
              },
            })

            return
          }

          activate()
        },
        authorized: function () {
          return RIGHTS('umv.tools.measure')
        },
      },
      {
        id: 'starMeasure',
        icon: 'star',
        isToggle: true,
        title: me.msg('measure.title'),
        handler: function () {
          MAIN_PAGE().measureList?.close()
          MAIN_PAGE().tagList?.close()

          if (MAIN_PAGE().starMeasureList?.modelList?.length > 0) {
            ConfirmationPopup.openPage({
              title: me.msg('measureToolConfirm.title'),
              text: me.msg('measureToolConfirm.text'),
              buttons: [
                { action: 'replace', label: me.msg('_Replace') },
                { action: 'add', label: me.msg('_Add') },
              ],
              onAction: function (action) {
                GIS.activateTool('starMeasure', { action })
                me.trigger('starMeasure')
              },
            })

            return
          }

          GIS.activateTool('starMeasure')
          me.trigger('starMeasure')
        },
        authorized: function () {
          return RIGHTS('umv.tools.starMeasure')
        },
      },
      {
        id: 'distance',
        icon: 'measureMultiple',
        isToggle: true,
        title: me.msg('distance.title'),
        handler: function () {
          APP().distanceTimelineActivated = true
          APP().trigger('distanceTimelineActivated')
        },
        display: function () {
          return (
            document.body.clientWidth >= 600 &&
            APP('positions') &&
            APP('positions').hasAllData
          )
        },
        authorized: function () {
          return (
            DATA_AUTHORIZED('positions') &&
            RIGHTS('map.distancePointToVesselsTool')
          )
        },
      },
      {
        id: 'tag',
        icon: 'tag',
        isToggle: true,
        title: me.msg('tag.title'),
        handler: function () {
          MAIN_PAGE().measureList?.close()
          MAIN_PAGE().starMeasureList?.close()
          GIS.activateTool('tag')
          me.trigger('tag')
        },
        authorized: function () {
          return RIGHTS('umv.tools.tag') && DATA_AUTHORIZED('positions')
        },
      },
      {
        id: 'animation',
        icon: 'animate',
        isToggle: true,
        title: me.msg('animation.title'),
        handler: function () {
          let count = APP('trajectories').getDisplayedMobileIds().length,
            lastRequest = DATA_CONTEXT.getLastParams()

          if (!lastRequest) {
            return
          }

          if (count === 0) {
            Toastr.showToastInfo('animation.noMobiles', 10000, [count, 1000])
            setTimeout(function () {
              me._handleButton(me.getButton('info'))
            }, 500)
            return
          }

          if (count > 1000) {
            Toastr.showToastInfo('animation.tooManyMobiles', 10000, [
              count,
              1000,
            ])
            setTimeout(function () {
              me._handleButton(me.getButton('info'))
            }, 500)
            return
          }

          if (lastRequest.alwaysLastPos) {
            Toastr.showToastInfo('animation.mostRecentData', 10000)
            setTimeout(function () {
              me._handleButton(me.getButton('info'))
            }, 500)
            return
          }
          APP().setAnimationMode(true)
          GIS.deactivateCurrentTool()
        },
        authorized: function () {
          return RIGHTS('umv.tools.animation') && DATA_AUTHORIZED('positions')
        },
        display: function () {
          return APP('positions') && APP('positions').hasAllData
        },
      },
      {
        id: 'grid',
        icon: 'grid',
        title: me.msg('grid.title'),
        handler: function () {
          MAIN_PAGE().gridPanel.toggle()
        },
        display: function () {
          return (
            document.body.clientWidth > 600 &&
            ((APP('positions') && APP('positions').hasAllData) ||
              APP().hasCollectedDatas() ||
              APP().hasSensorMessages() ||
              APP().hasRadarEchos() ||
              APP().hasRadarProducts() ||
              APP().isSarAlertAuthorized())
          )
        },
        isSingleAction: true,
      },
      {
        id: 'zone',
        icon: 'zones',
        disabledFeatureInfo: true,
        isToggle: true,
        title: me.msg('zone.title'),
        handler: function (ctx) {
          MAIN_PAGE().hideInfo()
          me.activateZoneControl(ctx)
        },
        authorized: function () {
          return DATA_AUTHORIZED('zone.create')
        },
        isSingleAction: true,
      },
      {
        id: 'selection',
        icon: 'selectPosition',
        disabledFeatureInfo: true,
        isToggle: true,
        title: me.msg('selection.title'),
        handler: function () {
          GIS.activateTool('info')
          me.fromZone = true
          MAIN_PAGE().hideInfo()
          Page.get('zoneToolsPage').open({
            mode: 'selection',
          })
        },
        display: function () {
          return (
            document.body.clientWidth >= 600 &&
            APP('positions') &&
            APP('positions').hasAllData
          )
        },
        authorized: function () {
          return RIGHTS('umv.tools.selection') && DATA_AUTHORIZED('positions')
        },
      },
      {
        id: 'zoneStats',
        icon: 'measureZone',
        disabledFeatureInfo: true,
        isToggle: true,
        title: me.msg('statistic.title'),
        handler: function () {
          GIS.activateTool('info')
          me.fromZone = true
          MAIN_PAGE().hideInfo()
          Page.get('zoneToolsPage').open({
            mode: 'zoneStats',
          })
        },
        display: function () {
          return (
            document.body.clientWidth >= 600 &&
            APP('positions') &&
            APP('positions').hasAllData
          )
        },
        authorized: function () {
          return RIGHTS('umv.tools.selection') && DATA_AUTHORIZED('positions')
        },
      },
      {
        id: 'searchAreaDetermination',
        icon: 'binoculars',
        disabledFeatureInfo: true,
        isToggle: true,
        title: me.msg('sad.title'),
        handler: function () {
          GIS.activateTool('info')
          me.fromZone = true
          MAIN_PAGE().hideInfo()
          SADToolsPage.openPage({ mode: 'searchAreaDetermination' })
        },
        authorized: () =>
          DATA_AUTHORIZED('searchAndRescue') &&
          DATA_AUTHORIZED('sarOperation.create'),
      },
      {
        // Added re. THM-13899
        id: 'unitConversion',
        icon: 'resizeH',
        disabledFeatureInfo: true,
        isToggle: true,
        title: me.msg('unitConversion.title'),
        authorized: function () {
          return RIGHTS('umv.tools.unitConversion')
        },
        handler: function () {
          UnitConversionPage.openPage({
            onClose: () => me.backToInfoTool(),
          })
        },
      },
      {
        id: 'selectionBD',
        icon: 'positionDatabase',
        isToggle: true,
        title: me.msg('selectionBD.title'),
        handler: function () {
          GIS.activateTool('zones', { id: 'drawRectangle' })
          Page.get('zoneToolsPage').open({
            mode: 'selectionBD',
          })
        },
        authorized: function () {
          return RIGHTS('umv.tools.selectionBD') && DATA_AUTHORIZED('positions')
        },
      },
      {
        id: 'locationSelection',
        icon: 'position',
        isToggle: true,
        title: me.msg('locationSelection.title'),
        handler: function () {
          GIS.getTool('locationSelection').activate()
        },
        display: function () {
          return false
        },
        authorized: function () {
          return DATA_AUTHORIZED('positions')
        },
      },
    ])
  },

  activateZoneControl: function (ctx) {
    let me = this,
      zoneToolsPage = Page.get('zoneToolsPage'),
      context = ctx || MAIN_PAGE().toolbar.handledObject || {}

    GIS.activateTool('info')

    //Initialisation du wrapper
    zoneToolsPage.openContext = null
    switch (context.type) {
      case 'edit': {
        GIS.activateTool('zones', {
          id: 'modifyShape',
          params: {
            feature: context.feature,
            mapXY: context.mapXY,
          },
        })
        break
      }
      case 'remove': {
        GIS.activateTool('zones', {
          id: 'remove',
          params: {
            feature: context.feature,
            mapXY: context.mapXY,
          },
        })
        break
      }
      case 'radarProduct': {
        GIS.activateTool('zones', { id: 'drawPoint' })
        zoneToolsPage.openContext = 'radarProduct'
        break
      }
      default: {
        zoneToolsPage.open({
          mode: 'zone',
        })
      }
    }
    MAIN_PAGE().toolbar.handledObject = null

    me.fromZone = true
  },
})

export default ThmToolbar
