import ResourceUI from 'core-uda/ui/ResourceUI'
import Animal from 'core-uda/model/mobile/Animal'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'

/**
 * User Interface elements for Animal
 */
let AnimalUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Animal.itemId,
      i18nPrefix: 'thm.ui.common.animal.AnimalUI.',
      icon: 'paw',
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      importance: I18N_FIELD('uda.animal.field.important.'),
      sex: I18N_FIELD('uda.animal.field.sex.'),
      maturity: I18N_FIELD('uda.animal.field.maturity.'),
    }
  },
})

const ANIMAL_UI = new AnimalUI()

export default AnimalUI
