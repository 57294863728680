import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import { I18N } from 'core-cmp/util/I18n'
import SelectStache from 'thm/ui/common/position/ThmDateLastPositionsCriteria.stache'
import Criteria from 'core-criteria/Criteria'
import DateCriteria from 'core-criteria/date/DateCriteria'

/**
 * @class ThmDateLastPositionsCriteria
 */
let ThmDateLastPositionsCriteria = DateCriteria.extend(
  {
    /**
     * @override
     */
    readParameters: function (resource, criteriaConfig, criteriaName) {
      return Criteria.readDefaultParameters(
        resource,
        criteriaConfig,
        criteriaName,
      )
    },
  },
  {
    dateParamsLastPositions: 'boolean',
    lastPositionsAuthorized: 'boolean',

    /**
     * @override
     * @param config
     */
    init: function (config) {
      let me = this
      me.stache = SelectStache
      me._super(config)

      me.dateParams.push({
        value: 'lastPositions',
        text: I18N.msg('uda.position.lastPositionsOptions'),
      })

      me.criteriaFormModel.lastPositions = ''

      me.criteriaFormModel.on('dateParams', me.proxy(me.changeDateParams))
      me.on('lastDaysAuthorized', me.proxy(me.changeLastDaysDateParams))
      me.on('mostRecentDataAuthorized', me.proxy(me.changeLastDaysDateParams))
      me.changeDateParams()
    },

    /**
     * @override
     */
    setOptions: function () {
      let me = this
      me._super()
      me.lastPositionsAuthorized = me.options.lastPositions
    },

    /**
     * @override
     */
    changeLastDaysDateParams: function () {
      let me = this,
        lastDaysAuth = me.lastDaysAuthorized,
        mostRecentAuth = me.mostRecentDataAuthorized,
        lastPositionsAuth = me.lastPositionsAuthorized,
        choices = ['between']

      choices = lastDaysAuth ? ['lastDays'].concat(choices) : choices
      choices = mostRecentAuth ? choices.concat(['mostRecentData']) : choices
      choices = lastPositionsAuth ? choices.concat(['lastPositions']) : choices
      me.dateParams = choices
      me.criteriaFormModel.dateParams = lastDaysAuth ? 'lastDays' : 'between'
    },

    /**
     * @override
     */
    changeDateParams: function () {
      let me = this,
        from = me.criteriaFormModel.from,
        to = me.criteriaFormModel.to,
        dateParam = me.criteriaFormModel.dateParams

      switch (dateParam) {
        case 'lastDays':
          me.dateParamsLastDays = true
          me.dateParamsBetween = false
          me.dateParamsLastPositions = false
          me.lastType = ['positions', 'fishing']
          break
        case 'between':
          if (from == ' ') {
            from = new Date()
          }
          if (to == ' ') {
            to = new Date()
          }
          me.criteriaFormModel.from = from
          me.criteriaFormModel.to = to
          me.dateParamsLastDays = false
          me.dateParamsBetween = true
          me.dateParamsLastPositions = false
          me.lastType = ['positions']
          me.criteriaFormModel.lastType = 'positions'
          break
        case 'mostRecentData':
          me.dateParamsLastDays = false
          me.dateParamsBetween = false
          me.dateParamsLastPositions = false
          me.lastType = ['positions']
          me.criteriaFormModel.lastType = 'positions'
          break
        case 'lastPositions':
          me.dateParamsLastDays = false
          me.dateParamsBetween = false
          me.dateParamsLastPositions = true
          me.lastType = ['positions']
          me.criteriaFormModel.lastType = 'positions'
          break
      }
    },

    /**
     * @override
     */
    formatModelToUDA: function () {
      let me = this,
        params = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : '',
        paramsUda = {}

      if (params.dateParams === 'lastPositions') {
        paramsUda[resourceName + 'lastPositions'] = params.lastPositions
      } else {
        paramsUda = me._super()
      }

      if (paramsUda.alwaysLastPos) {
        paramsUda.alwaysLastLoc = paramsUda.alwaysLastPos
        delete paramsUda.alwaysLastPos
      }

      return paramsUda
    },

    /**
     * @override
     */
    getUDAParamsNames: function () {
      let me = this,
        names = [
          'since',
          'alwaysLastPos',
          'lastFishingTrips',
          'lastPositions',
          'from',
          'to',
          'lastFishingTrips',
        ]

      if (me.getUDAParamsNamesCustom) {
        names = me.getUDAParamsNamesCustom(names)
      }
      return names
    },

    /**
     * @override
     */
    setParams: function (params) {
      let me = this

      if (params.since) {
        me.dateParamsLastDays = true
        me.dateParamsBetween = false
        me.dateParamsLastPositions = false

        me.criteriaFormModel.dateParams = 'lastDays'

        let hours = params.since / 3600
        params.lastDays = hours / 24
        params.lastHours = hours - params.lastDays * 24

        me.criteriaFormModel.lastDays = params.lastDays || ''
        me.criteriaFormModel.lastHours = params.lastHours || ''
      } else if (params.from && params.to) {
        me.dateParamsLastDays = false
        me.dateParamsBetween = true
        me.dateParamsLastPositions = false
        me.criteriaFormModel.dateParams = 'between'

        me.criteriaFormModel.from = PREF().fromServerDate(params.from)
        me.criteriaFormModel.to = PREF().fromServerDate(params.to)
      } else if (params.lastPositions) {
        me.dateParamsLastDays = false
        me.dateParamsBetween = false
        me.dateParamsLastPositions = true
        me.criteriaFormModel.dateParams = 'lastPositions'

        me.criteriaFormModel.lastPositions = params.lastPositions
      } else {
        me.dateParamsLastDays = false
        me.dateParamsBetween = false
        me.dateParamsLastPositions = false
        me.criteriaFormModel.dateParams = 'mostRecentData'
      }
    },
  },
)
export default ThmDateLastPositionsCriteria
