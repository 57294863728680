import ThmZoneInfoPanel from 'thm/ui/common/zone/ThmZoneInfoPanel'
import ChildTpl from 'thm/ui/common/zone/ZonePortInfoPanelTpl.stache'

/**
 * @class ZonePortInfoPanel
 */
let ZonePortInfoPanel = ThmZoneInfoPanel.extend({
  id: 'zonePortInfoPanel',
  childTpl: ChildTpl,
  zoneTypeId: 'portTypes',
})

export default ZonePortInfoPanel
