import ResourceUI from 'core-uda/ui/ResourceUI'
import CpcFormat from 'core-uda/model/cpcFormat/CpcFormat'

/**
 * User Interface elements for CpcFormat
 */
let CpcFormatUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this
    me._super({
      resourceId: CpcFormat.itemId,
      i18nPrefix: 'thm.ui.common.cpcFormat.CpcFormatUI.',
      icon: 'annotation',
    })
  },
})

const CPC_FORMAT_UI = new CpcFormatUI()

export default CpcFormatUI
