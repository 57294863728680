import _ from 'core-cmp/lodash'
import { I18N } from 'core-cmp/util/I18n'
import Criteria from 'core-criteria/Criteria'
import ResourceUI from 'core-uda/ui/ResourceUI'
import ThmListCriteriaStache from 'thm/ui/common/criteria/ThmListCriteria.stache'
import 'core-criteria/list/ListCriteria.css'

/**
 * @class ThmListCriteria
 */
let ThmListCriteria = Criteria.extend({
  typeOptions: 'array',
  states: 'observable',
  resourceMap: 'observable',
  udaName: 'string',
  customOptions: '*',

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this

    if (config.i18nPrefix) {
      config.i18nPrefix = _.isString(config.i18nPrefix)
        ? [config.i18nPrefix]
        : config.i18nPrefix
    } else {
      config.i18nPrefix = []
    }
    if (!config.labelKey && config.i18nPrefix) {
      config.labelKey = config.i18nPrefix + 'severalSelected'
    }

    config.i18nPrefix.push('core.criteria.zone.ZoneWithLocCriteria.')
    me.stache = ThmListCriteriaStache

    me._super(config)
    me.udaName = me.name
    me.visible = true

    if (me.resourceName) {
      me.name = me.resourceName
      me.udaNames = [me.udaName] // For preference loading see Criteria.js
    }

    me.criteriaFormModel = {
      type: 'all',
    }

    me.typeOptions = [
      {
        value: 'all',
        text: me.msg('all'),
      },
    ]
    me.states = {}
    me.resourceMap = {}
    me.criteriaFormModel.on('type', me.proxy(me.changeType))
    me.changeType()
  },

  /**
   * @override
   */
  setOptions: function () {
    let me = this,
      alreadyDeclared = !!me.typeOptions.attr().find((i) => i.value === me.name)

    me.resourceMap[me.name] = true
    me.states.set(me.name, false)
    if (!alreadyDeclared) {
      me.typeOptions.push({
        value: me.name,
        text: me.msg('selection'),
      })
    }
    me.set(me.name + 'Label', me.label)

    if (me.customOptions) {
      _.each(me.customOptions, (o) => {
        me.resourceMap[o.value] = true
        me.typeOptions.push(o)
        me.states.set(o.value, false)
        me.set(o.value + 'Label', me.msg(o.i18nPrefix + 'severalSelected'))
      })
    }
  },

  changeType: function () {
    let me = this

    _.each(me.typeOptions, (option) => {
      me.states.set(option.value, option.value === me.criteriaFormModel.type)
    })
  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me = this,
      params = me.criteriaFormModel,
      resourceName = !_.isNil(me.resource) ? me.resource + '.' : '',
      paramsUda = {},
      type = params.type,
      ids,
      udaName = me.udaName,
      co

    co = _.find(me.typeOptions, { value: type })
    if (co) {
      udaName = co.udaName || udaName
    }

    if (!!me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(params)
    } else {
      if (type === 'loc') {
        paramsUda[resourceName + 'maxLat'] = params.maxLat
        paramsUda[resourceName + 'maxLon'] = params.maxLon
        paramsUda[resourceName + 'minLat'] = params.minLat
        paramsUda[resourceName + 'minLon'] = params.minLon
      } else if (type !== 'all') {
        ids = (me.criteriaFormModel[type] || []).join(',')
        paramsUda[resourceName + udaName] = ids
      }
    }

    //We call change type in order to force component updating, else it can display as empty
    me.changeType()

    return paramsUda
  },

  /**
   * @override
   */
  getUDAParamsNames: function () {
    let me = this,
      names = ['maxLat', 'maxLon', 'minLat', 'minLon']

    _.each(me.typeOptions, (o) => {
      if (o.udaName) {
        names.push(o.udaName)
      }
    })

    if (me.getUDAParamsNamesCustom) {
      names = me.getUDAParamsNamesCustom(names)
    }
    return names
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this,
      ids,
      udaName

    me.criteriaFormModel.type = 'all'

    _.find(me.typeOptions, (option) => {
      udaName = option.udaName || me.udaName
      if (params[udaName]) {
        ids = params[udaName].split(',')
        me.criteriaFormModel.type = me.resourceName
        me.criteriaFormModel.attr(me.resourceName, ids)
        return true // End loop
      }
    })
  },

  /**
   * @override
   */
  getUdaExportData: function () {
    let me = this,
      params = me.criteriaFormModel,
      type = params.type,
      typeOption = _.find(me.typeOptions, { value: type }),
      ids = me.criteriaFormModel[type],
      data = []

    data.push({ key: typeOption.text, value: '' })

    if (type !== 'all') {
      _.each(APP(type).filterByIdList(ids), (item) => {
        data.push({ key: '', value: ResourceUI.getLabel(type, item) })
      })
    }

    return data
  },
})

export default ThmListCriteria
