import ResourceUI from 'core-uda/ui/ResourceUI'
import CpcProtocol from 'core-uda/model/cpcProtocol/CpcProtocol'
import _ from 'core-cmp/lodash'

/**
 * User Interface elements for CpcProtocol
 */
let CpcProtocolUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this
    me._super({
      resourceId: CpcProtocol.itemId,
      i18nPrefix: 'thm.ui.common.cpcProtocol.CpcProtocolUI.',
      icon: 'station',
    })
  },

  /**
   * @override
   */
  loadForEdition: function (cpcProtocol) {
    if (cpcProtocol.kind === 'smtp' && _.isNil(cpcProtocol.attached)) {
      cpcProtocol.attached = true
    }
  },
})

const CPC_PROTOCOL_UI = new CpcProtocolUI()

export default CpcProtocolUI
