<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

  <field-item item="dataModel" class="L-panel-content inlineGroup fields">
    <field-text name="name" col="6 12" max-length="100" required="true" class="l20-f80"></field-text>
    {{#if showClient}}
      <uda-select of="customers" name="customerId" col="6 12" {enabled}="_cmp.creation"></uda-select>
    {{/if}}
    <field-text name="adminRef" max-length="30" label-key="adminRef" col="6 12"></field-text>
    <field-text name="person" max-length="100" col="6 12" class="l20-f80"></field-text>
    <field-textarea name="address" max-length="240" col="12 12"></field-textarea>
    <field-select name="personIdentityType" options="personIdentityTypeOptions" option-none="true"
                  col="6 12"></field-select>
    <field-text name="personIdentityNumber" max-length="20" col="6 12"></field-text>
    <field-text name="phone" format="phone-inter" max-length="60" col="6 12"></field-text>
    <field-text name="fax" format="phone-inter" max-length="60" col="6 12"></field-text>
    <field-text name="email" format="email" max-length="100" col="6 12"></field-text>
    <field-select name="broadcastMode" label-key="broadcastMode" options="broadcastModeOptions"
                  options-name="contactBroadcast" col="6 12"></field-select>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="editable"></ui-submit-cancel>
</div>
