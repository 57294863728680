import ResourceUI from 'core-uda/ui/ResourceUI'
import FishingTrip from 'core-uda/model/fishingTrip/FishingTrip'

/**
 * User Interface elements for FishingTrip
 */
let FishingTripUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: FishingTrip.itemId,
      i18nPrefix: 'thm.ui.common.fishingTrip.FishingTripUI.',
      icon: 'route',
    })
  },
})

const FISHING_TRIP_UI = new FishingTripUI()

export default FishingTripUI
