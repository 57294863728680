import ResourceUI from 'core-uda/ui/ResourceUI'
import SatProvider from 'core-uda/model/satProvider/SatProvider'
import template from 'thm/ui/common/satProvider/SatProviderTpl.stache'

/**
 * User Interface elements for SatProvider
 */
let SatProviderUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: SatProvider.itemId,
      i18nPrefix: 'thm.ui.common.satProvider.SatProviderUI.',
      icon: 'vesselDetection',
      labelField: 'name',
      searchFields: ['name'],
      template: template,
      isListEditable: true,
    })
  },
})

const SAT_PROVIDER_UI = new SatProviderUI()

export default SatProviderUI
