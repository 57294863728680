import { MAIN_USER_OBJECT_DAO } from 'core-uda/model/userObject/AbstractUserObjectDAO'
import NafTemplate from 'thm/model/nafTemplate/NafTemplate'

/**
 * Modèle de NafTemplate.
 */
let NafTemplateDAO = MAIN_USER_OBJECT_DAO().extend({
  resourceClass: NafTemplate,
  findAllParams: {
    fields: 'all',
    family: 15,
    type: 'nafTemplate',
  },
  isJsonValue: true,
})

// Instance unique
let NAF_TEMPLATE_DAO = new NafTemplateDAO()

export { NAF_TEMPLATE_DAO }

export default NafTemplateDAO
