import $ from 'core-cmp/cmp-jquery'
import { UTILS } from 'core-cmp/Utils'
import 'core-cmp/cmp-fields'
import ThmLoginPage from 'thm/ui/main/ThmLoginPage'
import ThmApplication from 'thm/ThmApplication'

// Chargement des DAO
import { DATA_CONTEXT } from 'core-uda/model/DataContext'
import 'core-uda/model/i18n/I18nDAO'
import { ALERT_DAO } from 'core-alert/model/AlertDAO'
import { ALERT_DEFINITION_DAO } from 'core-alert/model/AlertDefinitionDAO'
import { ALERT_IMAGE_DAO } from 'core-alert/model/AlertImageDAO'
import { BEACON_DAO } from 'core-uda/model/beacon/BeaconDAO'
import 'core-uda/model/beacon/ModemDAO'
import 'core-uda/model/beacon/ModemAlertDAO'
import { BEACON_TYPE_DAO } from 'core-uda/model/beacon/BeaconTypeDAO'
import { COMMAND_DAO } from 'core-uda/model/command/CommandDAO'
import 'core-uda/model/command/CommandLogDAO'
import 'core-uda/model/customerObject/CollectedDataPreferencesDAO'
import 'core-uda/model/customerObject/CriteriaPreferencesDAO'
import 'core-uda/model/customerObject/CustomerChartModelDAO'
import 'core-uda/model/customerObject/CustomerPrefObjectDAO'
import 'core-uda/model/customerObject/GridCustomerPreferencesDAO'
import 'core-uda/model/customerObject/ImportPresetDAO'
import 'core-uda/model/customerObject/FlagOnPositionDAO'
import 'core-uda/model/customerObject/ResourcesPreferencesDAO'
import 'core-uda/model/customerObject/StyleDAO'
import { ERS_ACTIVITY_DAO } from 'core-uda/model/ersActivity/ErsActivityDAO'
import { ERS_QUERY_DAO } from 'core-uda/model/ersQuery/ErsQueryDAO'
import { ERS_ALERT_REPORT_DAO } from 'core-uda/model/ersAlertReport/ErsAlertReportDAO'
import { ERS_AUDIT_REPORT_DAO } from 'core-uda/model/ersAuditReport/ErsAuditReportDAO'
import { ERS_DATA_DAO } from 'core-uda/model/ersData/ErsDataDAO'
import { ERS_TRIP_DAO } from 'core-uda/model/ersTrip/ErsTripDAO'
import { ERS_FISHING_QUOTA_REPORT_DAO } from 'core-uda/model/ersFishingQuotasReport/ErsFishingQuotasReportDAO'
import { ERS_MISSING_DECLARATION_REPORT_DAO } from 'core-uda/model/ersMissingDeclarationReport/ErsMissingDeclarationReportDAO'
import { ERS_STATISTIC_DAO } from 'core-uda/model/ersStatistic/ErsStatisticDAO'
import 'core-uda/model/exceptionReport/ExceptionReportDAO'
import 'core-uda/model/userAction/UserActionDAO'
import 'core-uda/model/auditLogs/AuditLogDAO'
import 'core-uda/model/position/HistoricalPositionDAO'
import 'core-uda/model/trip/TripDAO'
import 'core-uda/model/mobileLifeEvent/MobileLifeEventDAO'
import 'core-uda/model/locationStatData/LocationStatDataDAO'
import 'core-uda/model/cpcPostData/CpcPostDataDAO'
import 'core-uda/model/vesselLog/VesselLogDAO'
import 'core-cdata/model/CollectedDataDAO'
import { FISHINGGEAR_DAO } from 'core-uda/model/mobile/FishingGearDAO'
import { FLAG_DAO } from 'core-uda/model/flag/FlagDAO'
import FlagModel from 'core-uda/model/flag/FlagModel'
import { FLEET_DAO } from 'core-uda/model/fleet/FleetDAO'
import { HERD_DAO } from 'core-uda/model/herd/HerdDAO'
import { LICENCE_DAO } from 'core-uda/model/mobile/LicenceDAO'
import 'core-uda/model/message/MessageDAO'
import { MOBILE_DAO } from 'core-uda/model/mobile/MobileDAO'
import 'core-uda/model/parameter/CustomerParameterDAO'
import 'core-uda/model/mobile/MobileImageDAO'
import 'core-uda/model/mobile/MobileTypeDAO'
import { SHIPOWNER_DAO } from 'core-uda/model/mobile/ShipownerDAO'
import 'core-uda/model/parameter/ParameterDAO'
import 'core-zone/model/PortTypeDAO'
import { POSITION_DAO } from 'core-uda/model/position/PositionDAO'
import 'core-uda/model/position/TrajectoryDAO'
import 'core-uda/model/position/PositionSourceDAO'
import 'core-uda/model/preference/PreferenceDAO'
import { CATCH_DECLARATION_DAO } from 'core-uda/model/catchDeclaration/CatchDeclarationDAO'
import 'core-radar/model/RadarEchoDAO'
import 'core-radar/model/RadarImageDAO'
import 'core-radar/model/RadarGeoImageDAO'
import 'core-radar/model/RadarProductDAO'
import { RADAR_PRODUCT_OIL_DAO } from 'core-radar/model/RadarProductOilDAO'
import 'core-radar/model/RadarProductShipDAO'
import 'core-radar/model/RadarProjectDAO'
import 'core-radar/model/RadarShipReportDAO'
import { RADAR_OIL_REPORT_DAO } from 'core-radar/model/RadarOilReportDAO'
import 'core-radar/model/RadarSlickDAO'
import 'core-uda/model/right/RightDAO'
import 'core-sensor/model/SensorMessageDAO'
import 'core-sensor/model/SensorTypeDAO'
import { SPECIES_DAO } from 'core-uda/model/species/SpeciesDAO'
import { SPECIES_CAT_DAO } from 'core-uda/model/species/SpeciesCatDAO'
import 'core-uda/model/mobile/StatusCodeDAO'
import StatusCodeModel from 'core-uda/model/mobile/StatusCodeModel'
import SpeciesModel from 'core-uda/model/species/SpeciesModel'
import 'core-uda/model/status/StatusDataDAO'
import 'core-uda/model/status/StatusMessageDAO'
import 'core-uda/model/user/CapabilitiesDAO'
import { RECIPIENT_DAO } from 'core-uda/model/user/RecipientDAO'
import { USER_DAO } from 'core-uda/model/user/UserDAO'
import UserObject from 'core-uda/model/userObject/UserObject'
import CustomerObject from 'core-uda/model/customerObject/CustomerObject'
import 'core-uda/model/userObject/UmeFavoritePreferencesDAO'
import 'core-uda/model/userObject/FavoriteRequestDAO'
import 'core-uda/model/userObject/GridPreferencesDAO'
import 'core-uda/model/userObject/GridUserPreferencesDAO'
import 'core-uda/model/userObject/InfoPanelPreferencesDAO'
import 'core-uda/model/userObject/PaletteDAO'
import 'thm/model/nafTemplate/NafTemplateDAO'
import 'thm/model/inspectionReport/InspectionReportDAO'
import 'core-uda/model/fishing/FishingProtocolDAO'
import 'core-uda/model/satProvider/SatProviderDAO'
import 'core-uda/model/mobile/MobileLifeEventTypeDAO'
import 'core-uda/model/fmc/FmcDAO'
import { EXTERNAL_IMAGE_DAO } from 'core-uda/model/externalImage/ExternalImageDAO'
import { MOBILE_MERGE_DAO } from 'core-uda/model/mobile/MobileMergeDAO'
import 'core-uda/model/beacon/FixBeaconAssociationDAO'
import 'core-uda/model/beacon/LesInmcDAO'
import 'core-uda/model/mobile/GenericMobileDAO'
import 'core-uda/model/mobile/FixCustomerAssociationDAO'
import { ANIMAL_DAO } from 'core-uda/model/mobile/AnimalDAO'
import { PERIODIC_POLLING_DAO } from 'core-uda/model/periodicPolling/PeriodicPollingDAO'
import 'core-uda/model/customer/SynchronizedSourceDAO'
import { REPORT_TEMPLATE_DAO } from 'thm/model/report/ReportTemplateDAO'
import { REPORT_DAO } from 'thm/model/report/ReportDAO'
import { INSPECTION_REPORT_DAO } from 'thm/model/inspectionReport/InspectionReportDAO'
import { QUOTA_DAO } from 'core-uda/model/fishing/QuotaDAO'
import { PORT_DAO } from 'core-uda/model/port/PortDAO'
import 'core-uda/model/manualImport/ManualImportDAO'
import 'core-uda/model/compose/ComposeDAO'
import 'core-uda/model/ersFormUI/ErsFormUIDAO'
import 'core-uda/model/generalAlertBroadcast/GeneralAlertBroadcastDAO'
import 'core-uda/model/illegalVessel/IllegalVesselDAO'
import 'core-uda/model/sharedVessel/SharedVesselDAO'
import 'core-uda/model/reportSchedule/ReportScheduleDAO'
import 'core-uda/model/reportLog/ReportLogDAO'
import 'core-uda/model/ersOperationReport/ErsOperationReportDAO'
import 'core-uda/model/missingLocation/MissingLocationDAO'
import 'thm/model/meteoStationRecord/MeteoStationRecordDAO'
//import "core-uda/model/userObject/PreferencesDAO"; Ne surtout pas l'inclure ! entre en conflit avec Preference pour UMV
import 'core-uda/model/userObject/RequestDAO'
import 'core-uda/model/userObject/UserChartModelDAO'
import 'core-uda/model/userObject/ViewsDAO'
import 'core-uda/model/userObject/ZonePreferencesDAO'
import 'core-zone/model/UserZoneTypeDAO'
import 'core-zone/model/GlobalZoneTypeDAO'
import { ZONE_DAO } from 'core-zone/model/ZoneDAO'
import 'core-zone/model/ZoneTypeDAO'
import 'core-uda/model/beacon/BeaconLogDAO'
import 'core-uda/model/folder/FolderDAO'
import 'core-uda/model/beacon/BeaconWithoutFolderDAO'
import 'core-uda/model/user/UserDAO'
import 'core-uda/model/userGroup/UserGroupDAO'
import UserModel from 'core-uda/model/user/UserModel'
import { CUSTOMER_DAO } from 'core-uda/model/customer/CustomerDAO'
import { ROLE_DAO } from 'core-uda/model/role/RoleDAO'
import 'core-uda/model/applicationDomain/ApplicationDomainDAO'
import 'core-uda/model/satellite/SatelliteDAO'
import 'core-uda/model/satellitePreference/SatellitePreferenceDAO'
import { FISHING_TIME_DAO } from 'core-uda/model/fishing/FishingTimeDAO'
import 'core-zone/model/WorldTileDAO'
import 'core-uda/model/fishingTrip/FishingTripDAO'

import { SAR_OPERATION_DAO } from 'core-sar/model/sarOperation/SarOperationDAO'
import { SAR_OPERATION_LOG_DAO } from 'core-sar/model/sarOperationLog/SarOperationLogDAO'
import { SAR_SAD_DAO } from 'core-sar/model/sarSad/SarSadDAO'
import { SAR_SAC_DAO } from 'core-sar/model/sarSac/SarSacDAO'
import { SAR_DRIFT_DAO } from 'core-sar/model/sarDrift/SarDriftDAO'
import 'core-drift/model/mobidriftObject/MobidriftObjectDAO'
import { SAR_ALERT_DAO } from 'core-sar/model/sarAlert/SarAlertDAO'
import 'core-sar/model/sarReportSap/SarReportSapDAO'
import 'core-sar/model/sarReportSit/SarReportSitDAO'
import 'core-sar/model/mobileSRUnit/MobileSRUnitDAO'
import 'core-sar/model/sarSruWork/SarSruWorkDAO'
import 'core-sar/model/sarAvnst/SarAvnstDAO'
import 'core-uda/model/vesselTypeCode/VesselTypeCodeDAO'

import 'core-uda/model/geoAnnotation/GeoAnnotationDAO'

import 'core-uda/model/ldapUser/LdapUserDAO'
import 'core-uda/model/exportConfig/ExportConfigDAO'

import 'core-geotiff/model/GeoTiffDAO'
import 'core-uda/model/acquisitionException/AcquisitionExceptionDAO'

import { STATUS_CODE_DAO } from 'core-uda/model/mobile/StatusCodeDAO'
import { PREFERENCE_DAO } from 'core-uda/model/preference/PreferenceDAO'
import { RESOURCES_PREFERENCES_DAO } from 'core-uda/model/customerObject/ResourcesPreferencesDAO'
import { CRITERIA_PREFERENCES_DAO } from 'core-uda/model/customerObject/CriteriaPreferencesDAO'
import { GRID_USER_PREFERENCES_DAO } from 'core-uda/model/userObject/GridUserPreferencesDAO'
import { UME_FAVORITE_PREFERENCES_DAO } from 'core-uda/model/userObject/UmeFavoritePreferencesDAO'
import { GRID_PREFERENCES_DAO } from 'core-uda/model/userObject/GridPreferencesDAO'
import { FLAG_ON_POSITION_DAO } from 'core-uda/model/customerObject/FlagOnPositionDAO'
import { MOBILE_TYPE_DAO } from 'core-uda/model/mobile/MobileTypeDAO'
import { POSITION_SOURCE_DAO } from 'core-uda/model/position/PositionSourceDAO'
import { DATA_SOURCE_DAO } from 'core-uda/model/dataSource/DataSourceDAO'
import { IMPORT_PRESET_DAO } from 'core-uda/model/customerObject/ImportPresetDAO'
import { USER_ZONE_TYPE_DAO } from 'core-zone/model/UserZoneTypeDAO'
import { GLOBAL_ZONE_TYPE_DAO } from 'core-zone/model/GlobalZoneTypeDAO'
import { PORT_TYPE_DAO } from 'core-zone/model/PortTypeDAO'
import { APPLICATIONDOMAIN_DAO } from 'core-uda/model/applicationDomain/ApplicationDomainDAO'
import { CPC_DAO } from 'core-uda/model/cpc/CpcDAO'
import { CPC_CONDITION_DAO } from 'core-uda/model/cpcCondition/CpcConditionDAO'
import { CPC_FORMAT_DAO } from 'core-uda/model/cpcFormat/CpcFormatDAO'
import { CPC_FROM_DAO } from 'core-uda/model/cpcFrom/CpcFromDAO'
import { CPC_PROTOCOL_DAO } from 'core-uda/model/cpcProtocol/CpcProtocolDAO'
import { STYLE_DAO } from 'core-uda/model/customerObject/StyleDAO'
import { SATPROVIDER_DAO } from 'core-uda/model/satProvider/SatProviderDAO'
import { FAVORITE_REQUEST_DAO } from 'core-uda/model/userObject/FavoriteRequestDAO'
import { INFO_PANEL_PREFERENCES_DAO } from 'core-uda/model/userObject/InfoPanelPreferencesDAO'
import { VIEWS_DAO } from 'core-uda/model/userObject/ViewsDAO'
import { PALETTE_DAO } from 'core-uda/model/userObject/PaletteDAO'
import { CONCENTRIC_RADIUS_DAO } from 'core-uda/model/userObject/ConcentricRadiusDAO'
import { NAF_TEMPLATE_DAO } from 'thm/model/nafTemplate/NafTemplateDAO'
import { ZONE_PREFERENCES_DAO } from 'core-uda/model/userObject/ZonePreferencesDAO'
import { FAVORITE_ZONE_PREFERENCES_DAO } from 'core-uda/model/userObject/FavoriteZonePreferencesDAO'
import { SATELLITE_PREFERENCE_DAO } from 'core-uda/model/satellitePreference/SatellitePreferenceDAO'
import { CUSTOMER_CHART_MODEL_DAO } from 'core-uda/model/customerObject/CustomerChartModelDAO'
import { CUSTOMER_PARAMETER_DAO } from 'core-uda/model/parameter/CustomerParameterDAO'
import { GRID_CUSTOMER_PREFERENCES_DAO } from 'core-uda/model/customerObject/GridCustomerPreferencesDAO'
import { GENERAL_ALERT_BROADCAST_DAO } from 'core-uda/model/generalAlertBroadcast/GeneralAlertBroadcastDAO'
import { GEO_ANNOTATION_PREFERENCES_DAO } from 'core-uda/model/userObject/GeoAnnotationPreferencesDAO'
import { PUBLIC_IMAGE_DAO } from 'core-uda/model/publicImage/PublicImageDAO'
import { GFCM_INSPECTION_REPORT_DAO } from 'core-uda/model/gfcmInspectionReport/GfcmInspectionReportDAO'
//SSAS DAO
import { SSAS_AUDITLOG_DAO } from 'core-uda/model/ssas/SsasAuditLogDAO'
import { SSAS_CONTACT_DAO } from 'core-uda/model/ssas/SsasContactDAO'
import { SSAS_TESTLOG_DAO } from 'core-uda/model/ssas/SsasTestLogDAO'
import { SSAS_VESSEL_DAO } from 'core-uda/model/ssas/SsasVesselDAO'

// CPC DAOS
import 'core-uda/model/cpc/CpcDAO'
import 'core-uda/model/cpcCondition/CpcConditionDAO'
import 'core-uda/model/cpcFormat/CpcFormatDAO'
import 'core-uda/model/cpcFrom/CpcFromDAO'
import 'core-uda/model/cpcProtocol/CpcProtocolDAO'
// FIXTURE
// import {USE_CASE} from "thm/ThmFixture";

import RecipientModel from 'core-uda/model/user/RecipientModel'
import AlertDefinitionModel from 'core-alert/model/AlertDefinitionModel'
import ZoneModel from 'core-zone/model/ZoneModel'
import ZoneTypeModel from 'core-zone/model/ZoneTypeModel'
import MobileModel from 'core-uda/model/mobile/MobileModel'

// Import core-uda object override
import 'thm/model/beaconType/ThmBeaconType'

import 'core-metoc/ui/wc'
import 'core-drift/ui/wc'
import 'thm/thm-wc'

UserObject.USE_TYPE = false
CustomerObject.USE_TYPE = false

const CF = 'id,versionNum,modificationDateMillis,udaAccess,'

// Add Themis spécific configuration
ALERT_DAO.findAllParams.fields =
  CF +
  'creationDateMillis,alarmDateMillis,endDateMillis,kind,kindRaw,mobileId,userId,mobileLicensEndDateMillis,mobileLicensBeginDateMillis,activeBeaconDnid,activeBeaconDnid2,activeBeaconMember,activeBeaconMember2,' +
  'text,alertDefinitionId,shared,positionId,loc,locDateMillis,validationStatus,validationDateMillis,validationUser,counter,action,mobileName,alertDefinitionName,mobileReference,mobileImo,mobileMmsi,' +
  'activeBeaconId,flagId,mobileFlagCountryName,mobileFlagCountryCode,activeBeaconProviderName,activeBeaconProviderType,activeBeaconRef,action,alerts,calculatedReportingFrequency,mobileLicenseIssueDateMillis,' +
  'ackDateMillis,ackUser,activeBeaconOcean,activeBeaconOcean2,mobileInternalRef,mobileRegistry,activeBeaconModel,modificationUser,adminPortName,mobileCallSign,activeBeaconSerialNumber,mobileTypeName,' +
  'activeBeaconModelId,updateType,mobileHasValidLicense,alertZone,creationDate,alarmDate,modificationDate,locDate'
ALERT_DEFINITION_DAO.findAllParams.fields =
  CF +
  'name,notifyRecipients,zones,kind,mobiles,fleets,userId,customerId,shared' // fleets, notifyRecipients & zones : required for UME masterDetail
BEACON_DAO.findAllParams.fields =
  CF +
  'kind,reference,customerId,mobileId,mobileName,model,active,creationDateMillis' // active required for send command popup
BEACON_TYPE_DAO.findAllParams.fields =
  CF +
  'satelliteProviderId,satelliteProviderName,satelliteProviderType,name,commands' // satelliteProviderName required for BeaconTypeModel.filterBySatelliteProviderName
CUSTOMER_DAO.findAllParams.fields =
  CF + 'company,countryId,domainId,parameters,contractNumber' // contractNumber required for BeaconDefaultInfoPanel.onCustomerContractNumber
ROLE_DAO.findAllParams.fields = CF + 'name,description'
FISHINGGEAR_DAO.findAllParams.fields =
  CF + 'customerId,faoCode,name,description'
FLAG_DAO.findAllParams.fields =
  CF + 'countryCode,countryName,i18n,webserviceUrl'
FLEET_DAO.findAllParams.fields =
  CF +
  'kind,customerId,customerCompany,name,type,userId,criteria,shared,mobiles,domestication' // criteria important to detect invalid declarations
HERD_DAO.findAllParams.fields =
  CF +
  'kind,customerId,customerCompany,name,type,userId,criteria,shared,mobiles' // criteria important to detect invalid declarations
LICENCE_DAO.findAllParams.fields =
  CF + 'reference,customerId,vesselId,flagId,fishingGearsIds,zonesIds'
MOBILE_DAO.findAllParams.fields =
  CF +
  'name,imo,mmsi,activeBeaconId,activeBeaconRef,callSign,internalRef,ref,registry,customerCompany,customerId,' +
  'flagCountryName,flagCountryCode,flagId,imageId,activeBeaconModelId,mobileTypeId,mobileTypeName,shipowners,' + // ownerId for UME=> patch UME to use shipowners
  'hasValidLicence,activeBeaconDnid,activeBeaconDnid2,kind,unknown,calculatedReportingPeriod,fleetId,styleId,' + // for SRUnits
  'basicEndurance,sruTypeRef,basicSpeed,sarBusinessData,chargeLevel,sharedCustomers'
ANIMAL_DAO.findAllParams.fields =
  MOBILE_DAO.findAllParams.fields +
  ',representativity,sex,maturity,breed,' +
  'externalImage,speciesId,importance,birthDate,fleetId,alerts'
EXTERNAL_IMAGE_DAO.findAllParams.fields = CF + 'type,b64Image'
ALERT_IMAGE_DAO.findAllParams.fields = CF + 'alertId,b64Image,id'
MOBILE_MERGE_DAO.findAllParams.fields = CF
PERIODIC_POLLING_DAO.findAllParams.fields =
  CF +
  'enabled,shared,status,mobileId,name,startDate,endDate,frequency,nbOfRetry,maxNbOfRetry,channel'
GENERAL_ALERT_BROADCAST_DAO.findAllParams.fields =
  CF + 'alertKind,customerId,notifyClient,notifyMobile,notifyShipowner'
STATUS_CODE_DAO.findAllParams.fields = CF + 'name,period,reference,reserved'
PREFERENCE_DAO.findAllParams.fields = CF + 'name,value'
CUSTOMER_PARAMETER_DAO.findAllParams.fields =
  CF + 'name,value,definition,customer'
MOBILE_TYPE_DAO.findAllParams.fields =
  CF + 'type,description,customerId,customerCompany,bigdata,bigdataId'
POSITION_SOURCE_DAO.findAllParams.fields = CF + 'name,code,sourceType,enabled'
USER_ZONE_TYPE_DAO.findAllParams.fields = CF + 'name,customerId,description'
GLOBAL_ZONE_TYPE_DAO.findAllParams.fields = CF + 'name,customerId,description' //TODO VERIFIER, AUCUNE VALEUR SUR UK
PORT_TYPE_DAO.findAllParams.fields = CF + 'name,customerId,description' //TODO VERIFIER, AUCUNE VALEUR SUR UK
APPLICATIONDOMAIN_DAO.findAllParams.fields =
  CF + 'name,description,enabled,invoiceLevel,lexicon,roles'
CPC_DAO.findAllParams.fields =
  CF +
  'name,description,activated,cpcFormatId,cpcProtocolId,flagId,flagCountryName,' +
  'flagCountryCode,cpcConditions,accord,customerId,saveSentMessages'
CPC_CONDITION_DAO.findAllParams.fields =
  CF + 'name,enabled,target,customerId,customerCompany,fleets,mobiles,zones'
CPC_FORMAT_DAO.findAllParams.fields =
  CF +
  'name,kind,dateFormat,timeFormat,timeZone,positionFormat,vesselIdentifier,' +
  'sendHeadingSpeed,sendDefaultHeading,sendManualLoc,sendInZone,sendOutZone,sendAnchored,speedUnit,speedFormat,fromValue,' +
  'sendRecordDate,sendRecordNumber,sendRecordTime,sendZoneOutPosition,useTrailingSlashes,fromOverride,beaconIdentifierField,' +
  'customerId,customerCompany'
CPC_FROM_DAO.findAllParams.fields =
  CF + 'name,code,url,accord,description,modificationUser'
CPC_PROTOCOL_DAO.findAllParams.fields =
  CF +
  'name,kind,url,method,ackType,secured,attached,hostname,username,password,' +
  'path,subject,fromAddress,toAddress,checkHostName,checkEndValidity,checkStartValidity,customerId,customerCompany'
SATPROVIDER_DAO.findAllParams.fields =
  CF +
  'name,provType,provDelay,checkDelay,driftDelay,provMaxWait,enabled,beaconTypes'

const USER_OBJECTS_FIELDS = 'name,value,family'
const CUSTOMER_OBJECTS_FIELDS = USER_OBJECTS_FIELDS + ',contentType,customerId'
RESOURCES_PREFERENCES_DAO.findAllParams.fields = CF + CUSTOMER_OBJECTS_FIELDS
CRITERIA_PREFERENCES_DAO.findAllParams.fields = CF + CUSTOMER_OBJECTS_FIELDS
GRID_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
GRID_CUSTOMER_PREFERENCES_DAO.findAllParams.fields =
  CF + CUSTOMER_OBJECTS_FIELDS
GRID_USER_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
UME_FAVORITE_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
FLAG_ON_POSITION_DAO.findAllParams.fields = CF + CUSTOMER_OBJECTS_FIELDS
IMPORT_PRESET_DAO.findAllParams.fields = CF + CUSTOMER_OBJECTS_FIELDS
STYLE_DAO.findAllParams.fields = CF + CUSTOMER_OBJECTS_FIELDS
FAVORITE_REQUEST_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
INFO_PANEL_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
VIEWS_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
PALETTE_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
CONCENTRIC_RADIUS_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
NAF_TEMPLATE_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
ZONE_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
FAVORITE_ZONE_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
SATELLITE_PREFERENCE_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS
CUSTOMER_CHART_MODEL_DAO.findAllParams.fields = CF + CUSTOMER_OBJECTS_FIELDS
GEO_ANNOTATION_PREFERENCES_DAO.findAllParams.fields = CF + USER_OBJECTS_FIELDS

RECIPIENT_DAO.findAllParams.fields =
  CF + 'alias,customerId,email,mobilePhone,ftp,aftn,protocolsOrder'
SHIPOWNER_DAO.findAllParams.fields =
  CF + 'adminRef,customerId,name,broadcastMode'
SPECIES_DAO.findAllParams.fields =
  CF + 'code,customerId,name,family,sciName,gregarious'
SPECIES_CAT_DAO.findAllParams.fields = CF + 'speciesId,code,i18n'
USER_DAO.findAllParams.fields =
  CF + 'email,customerId,name,firstName,kind,login,roleId'
USER_DAO.setCustomHeaders({ contentType: 'application/json' })
REPORT_DAO.setCustomHeaders({ contentType: 'application/json' })
REPORT_DAO.findAllParams.fields =
  CF + 'fileName,resourceId,resourceName,templateId,userId'
INSPECTION_REPORT_DAO.setCustomHeaders({ contentType: 'application/json' })
INSPECTION_REPORT_DAO.findAllParams.fields =
  CF +
  'inspectionReport,inspectionReportNumber,pathToReport,portState,actionsTaken,apparentInfringements,inspectingAuthority,inspectionPurpose,creationDateMillis,requestMutualAssistance'
REPORT_TEMPLATE_DAO.setCustomHeaders({ contentType: 'application/json' })
ZONE_DAO.findAllParams.fields =
  CF +
  'name,kind,arrivalPortId,departurePortId,geometry.type,customerId,customerCompany,reference,typeId,' +
  'typeName,styleName,userId,category,shared,text,speed,speedTolerance,breadth'

ERS_ACTIVITY_DAO.findAllParams.fields =
  CF +
  'kind,reportType,tripId,timeMillis,mobileDonorId,mobileRecieverId,portId,' +
  'zoneFaoId,zoneFishId,vesselFishingGearId,vesselName,vesselRef,vesselInternalRef,vesselRegistry,vesselCallSign,mainActivityId,subActivities'
ERS_TRIP_DAO.findAllParams.fields =
  CF +
  'tripNumber, openDateMillis, closeDateMillis, fishingDays, tripDays,' +
  'vesselId,closeActivityId,openActivityId,ersAlertCount'
ERS_DATA_DAO.findAllParams.fields = CF + 'parentDataId,vesselId,rn,refCn'
ERS_QUERY_DAO.findAllParams.fields = CF + 'receivedRequest,responseStatus'

QUOTA_DAO.findAllParams.fields =
  CF +
  'name,validity,startDate,endDate,unit,quotaUsed,fished,percAlarm,remaining,' +
  'overfishing,vesselIds,speciesIds'
CATCH_DECLARATION_DAO.findAllParams.fields =
  CF + 'dcNumber,dcDate,dcDelegation,detail,speciesDetail'

PORT_DAO.findAllParams.fields = CF + 'code,name'
RADAR_PRODUCT_OIL_DAO.findAllParams.fields =
  CF +
  'reference,date,type,shape,imageId,productOilId,productId,sourceId,productSlickCount,' +
  'hasWind,hasNaturalFilm,hasTraffic,hasHistrory,hasWreck,hasPipe,shapeFlag,shapeImpactFlag,edgeFlag,edgeImpactFlag,' +
  'contrastFlag,contrastImpactFlag'
ZONE_DAO.setCustomHeaders({ contentType: 'application/json' })
LICENCE_DAO.setCustomHeaders({ contentType: 'application/json' })
MOBILE_DAO.setCustomHeaders({ contentType: 'application/json' })
RADAR_OIL_REPORT_DAO.setCustomHeaders({ contentType: 'application/json' })
SAR_ALERT_DAO.setCustomHeaders({ contentType: 'application/json' })
SAR_OPERATION_DAO.findAllParams.fields =
  CF +
  'initiator,incidentType,casualtyName,exercise,exam,nbDeadPeople,result' +
  ',nbInjuredPeople,customerId,requiredAssistance,mobileName,info,summary,casualtyDescription,creationDateMillis' +
  ',nbPeopleFound,nbPersonsAtRisk,name,flagName,closed,operationType,casualtyPositionsData,userId'
SAR_OPERATION_LOG_DAO.findAllParams.fields =
  CF +
  'sarSadId,creationDateMillis,modificationUser,sarSacId,origin,sarOperationId,message'
SAR_SAD_DAO.findAllParams.fields =
  CF +
  'backtrackData,searchDateMillis,divergence,divergenceEnabled,idForOperation,incidentTimeMillis' +
  ',leewayValues,locData,manualData,name,creationUser,sarOperationId,type,manualOriginData,creationDateMillis'

SAR_SAC_DAO.findAllParams.fields =
  CF +
  'sarSadId,explanations,idForSad,name,creationUser,optimalSearchArea,optimalCoverageFactor' +
  ',optimalSearchRadius,pod,poc,searchEndDateMillis,startSearchDateMillis,searchedObject,searchTerrain,weather,visibility,vesselsAsSru'
SAR_DRIFT_DAO.findAllParams.fields =
  CF +
  'driftingObject,altitude,usedCurrent,endDate,heading,endDateMillis' +
  ',windCurrentError,glideRatio,startDateMillis,result,driftBuilder,usedTideCurrent,velocityError,glide,isOnLand' +
  ',usedWind,stepTime,sarSadId,datedStreamJson,particlesPerObject,useWindCurrent,useDivergence, usedCustomizeFixError, terrainAltitude, uncertaintyRadius'
PUBLIC_IMAGE_DAO.findAllParams.fields = CF + 'creationDate,creationDateMillis'

// Besoin pour UMV de mettre les requetes de positions en POST
POSITION_DAO.loadDataRequest.type = 'POST'
POSITION_DAO.replaceDataRequest.type = 'POST'
POSITION_DAO.jsonInsteadResource = false
POSITION_DAO.forceFields = ',id, nature'
POSITION_DAO.findAllParams.fields =
  'heading,speed,id,mobileId,loc,locDateMillis,locDate,modificationDateMillis,nature,alarm,source,reference,originEvent' +
  ',lateForAnalyse,toBeAnalysed,qualityOverall,activeBeaconId,activeBeaconRef,beaconId,beaconRef,versionNum,seqNum' +
  ',creationDate,creationDateMillis,supplierDate,supplierDateMillis,receivedDate,receivedDateMillis,beaconZoneId,port' +
  // These mobile fields are required for position grid. It's not optimized because theses fields are duplicated
  // for all positions of the same  mobile => To optimized changing position response format
  ',mobileName,mobileMmsi,mobileImo,mobileCallSign,mobileCompany,mobileReference,mobileRegistry' +
  ',mobileInternalRef,mobileFlagCountryName,mobileFlagCountryCode,mobileTypeName,mobileTypeId' +
  ',deviceProviderDelay,providerReceivedDelay,receivedSavedDelay,deviceSavedDelay,dataTransDelay,fishingGears,fishingGearsCodeList,fishingGearsNameList' +
  // THM-9871 - add new fields ZONE_ICES and ZONE_FAO
  ',zoneFao,zoneIces,zone1,zone2,zone3,zone4,zone5,zone6,zone7,zone8'

ERS_STATISTIC_DAO.findAllParams.locale = PREF().locale
ERS_STATISTIC_DAO.findAllParams.fields =
  'id,countryId,fleetId,speciesId,tripId,vesselId'
ERS_MISSING_DECLARATION_REPORT_DAO.findAllParams.fields = 'id,tripId,vesselId'
ERS_AUDIT_REPORT_DAO.findAllParams.fields = 'id,auditSpeciesId,tripId,vesselId'
ERS_ALERT_REPORT_DAO.findAllParams.fields = 'id,tripId,vesselId'
ERS_FISHING_QUOTA_REPORT_DAO.findAllParams.fields =
  'id,licenceId,quotaId,tripId,vesselId'
COMMAND_DAO.findAllParams.fields = 'id,mobileId,modemId,userId'
FISHING_TIME_DAO.findAllParams.fields = 'id,mobileId'

//SSAS
SSAS_VESSEL_DAO.findAllParams.fields =
  'id,themisSsasRef,vesselId,contact1Id,contact2Id,contact3Id,active,argosTestEnabled,' +
  'argosTestStartDate,modificationDate,modificationUser,creationDate,versionNum'
SSAS_TESTLOG_DAO.findAllParams.fields =
  'id,ssasVesselId,ssasBeaconId,beaconRef,beaconModel,beaconType,date,event,comment'
SSAS_CONTACT_DAO.findAllParams.fields =
  'id,themisSsasRef,themisClientId,name,function,email,fax,phoneDay,phoneNight,phoneMobile,' +
  'modificationDate,modificationUser,creationDate,versionNum'

RecipientModel.UNICITY_RULE = 'customerId && alias || customer && email' // customer AND alias OR customer AND email
AlertDefinitionModel.UNICITY_RULE = 'customerId && kind && name' // customer AND kind AND name
ZoneModel.UNICITY_RULE = 'customerId && name' // customerId AND name
ZoneTypeModel.UNICITY_RULE = 'customerId && name'
MobileModel.UNICITY_RULE =
  //    (customerId AND internalRef if  internalRef not null)
  // OR (customerId AND registry if  registry not null)
  // OR (customerId AND mmsi if  mmsi not null)
  'customerId && @internalRef || customerId && @registry || customerId && @mmsi'
StatusCodeModel.UNICITY_RULE = 'name'
SpeciesModel.UNICITY_RULE = 'customerId && name' // customerId AND name
UserModel.UNICITY_RULE = 'newLogin <> login || @email'
FlagModel.UNICITY_RULE = 'countryName' // Country Name unique

//USE_CASE("sar");

UNIT_FORMAT.SPEED_FORMAT.attr('fixed', 1)

// Instanciate unique instance of ThmApplication object.
// The rest of the sourec code uses APP() (from core-uda/model/Respource)
let APP = new ThmApplication()
APP.bindEvents()

UTILS.applyTheme('cls')

$.ajaxSetup({
  headers: {
    APPLICATION: 'umv',
    'X-Frame-Options': 'DENY',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'X-Content-Type-Options': 'nosniff',
    'X-XSS-Protection': 1,
    'Content-Security-Policy': "script-src 'self'",
  },
  //crossDomain:    true, JC 05/11/2013 ca fait planter IE !!!!!
  dataType: 'json',
})

// charger la page login
let thmLoginPage = new ThmLoginPage()
thmLoginPage.open({}, true)
try {
  APP.setGoogleAnalytics()
} catch (e) {
  console.trace('Google Analytics failed')
}
