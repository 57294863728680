<div id="{{id}}" class="{{#if hideCriteriaFirstTime}}hidden{{/if}}">
  <field-item item="criteriaFormModel">
    <field-hcombo name="type" label="" {options}="typeOptions"></field-hcombo>

    {{#if resourceMap.mobiles}}
      <uda-list of="mobiles"
                name="mobiles"
                label="{{mobilesLabel}}"
                {visible}="states.mobiles"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.fleets}}
      <uda-list of="fleets"
                name="fleets"
                label="{{fleetsLabel}}"
                {visible}="states.fleets"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.beacons}}
      <uda-list of="beacons" name="beacons"
                label="{{beaconsLabel}}"
                {visible}="states.beacons"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.species}}
      <uda-list of="species" name="species"
                label="{{speciesLabel}}"
                {visible}="states.species"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.speciesCats}}
      <uda-list of="speciesCats" name="speciesCats"
                label="{{speciesCatsLabel}}"
                {visible}="states.speciesCats"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.flags}}
      <uda-list of="flags" name="flags"
                label="{{flagsLabel}}"
                {visible}="states.flags"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.fishingGears}}
      <uda-list of="fishingGears" name="fishingGears"
                label="{{fishingGearsLabel}}"
                {visible}="states.fishingGears"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.mobileTypes}}
      <uda-list of="mobileTypes" name="mobileTypes"
                label="{{mobileTypesLabel}}"
                {visible}="states.mobileTypes"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.alertTypes}}
      <field-states {(field)}="alertTypeKinds"
                    format="string"
                    {options}="kindOptions"
                    options-col="{{kindOptions.length}}"
                    options-text="name"
                    options-value="id"
                    {visible}="states.alertTypes"
                    class="tab">
      </field-states>
      <uda-list of="alertTypes"
                name="alertTypes"
                label="{{alertTypesLabel}}"
                {visible}="states.alertTypes"
                {update-for}="alertTypeKinds"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>
    {{/if}}
    {{#if resourceMap.zones}}
      <uda-list of="zones" name="zones"
                label="{{zonesLabel}}"
                {visible}="states.zones"
                class="inlineGroup"
                update-function="{{optionsUpdateFunction}}">
      </uda-list>

      <div class="inlineGroup" class="{{#unless states.loc}}hidden{{/unless}}">
        <div class="inlineGroup">
          <div class="header-group icon-left icon-m ui-icon-position">
            <h2>{{msg 'northEastLoc'}}</h2>
          </div>
          <!-- Besoin du {visible} pour placer la class "hidden" sur les field et éviter que le getError de UreRequestPage remonte les champs -->
          <field-text name="maxLon" format="num" min="-180" max="180" required="true" {visible}="states.loc"></field-text>
          <field-text name="maxLat" format="num" min="-90" max="180" required="true" {visible}="states.loc"></field-text>
        </div>
        <div class="inlineGroup">
          <div class="header-group icon-left icon-m ui-icon-position">
            <h2>{{msg 'southWestLoc'}}</h2>
          </div>
          <field-text name="minLon" format="num" min="-180" {max}="criteriaFormModel.maxLon" required="true" {visible}="states.loc"></field-text>
          <field-text name="minLat" format="num" min="-90" {max}="criteriaFormModel.maxLat" required="true" {visible}="states.loc"></field-text>
        </div>

      </div>
    {{/if}}
  </field-item>
</div>
