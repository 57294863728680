import DataModel from 'core-uda/model/DataModel'

/**
 * MeteoStationRecord model.
 */
let MeteoStationRecordModel = DataModel.extend({
  modelId: 'meteoStationRecords',
  itemId: 'meteoStationRecord',
})

export default MeteoStationRecordModel
