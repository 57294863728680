import Page from 'core-cmp/Page'
import Toastr from 'core-cmp/Toastr'
import { I18N } from 'core-cmp/util/I18n'
import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import CpcResultPopupTplStache from 'thm/ui/common/cpc/CpcResultPopupTplStache.stache'

/**
 * Confirmation popup.
 * @class CpcResultPopup
 * @extends Page
 */
let CpcResultPopup = Page.extend({
  id: 'cpcResultPopup',
  routerType: 'popup',
  popupWidth: 400,
  allowClose: true,
  modal: true,
  template: CpcResultPopupTplStache,
  i18nPrefix: 'thm.ui.common.cpc.CpcResultPopup.',
  autoBindActions: true,
  useComponentAsViewModel: true,
  styles: {
    cancel: 'btn-cancel',
  },
  hasValidationText: false,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()

    me.attr('text', '')
    me.attr('returnCode', '')
    me.attr('response', '')
    me.attr('error', '')
    me.attr('buttons', [])
    me.attr('buttonCount', 0)
  },

  /**
   * @override
   */
  open: function (context) {
    let me = this,
      msgTitle = I18N.prefix(context.i18nPrefix)

    _.each(context.buttons, function (btn) {
      // On cherche un label par defaut si pas de label positionné
      btn.label = btn.label || me.msg('_' + _.upperFirst(btn.action))
      if (_.isUndefined(btn.style)) {
        // On cherche un style par defaut si pas de style positionné
        btn.style = me.styles.attr(btn.action)
      }
    })

    me.attr(
      'title',
      !!context.i18nPrefix ? msgTitle(context.title) : context.title,
    )
    me.attr('allowClose', true)
    me.attr('returnCode', context.returnCode)
    me.attr('protocolKind', context.protocolKind.toUpperCase())
    me.attr('response', context.response)
    me.attr('error', context.error)

    me.attr('hasReturnCode', context.returnCode !== '')
    me.attr('hasResponse', context.response !== '')
    me.attr('hasError', context.error !== '')
    me.attr('success', context.success)

    me._super(context)

    me.resizePopup()
  },

  // Call by bindActions
  onAction: function (ev) {
    let me = this,
      context = me.context,
      action = $(ev.target).data('.').action

    if (
      me.hasValidationText &&
      $(ev.target).hasClass('btn-ok') &&
      me.attr('validation') !== me.attr('validationText')
    ) {
      Toastr.showToastError('invalidText', 2000)
      return
    }

    me.close().done(function () {
      // il est important de faire l'action après le close.
      // En effet cela pose des problèmes au router si l'action fait un back ou ouvre des fenêtres.
      _.defer(() => {
        context.onAction(action)
      })
    })
  },
})

export default CpcResultPopup
