import { canStache } from 'core-cmp/can'
import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import Toastr from 'core-cmp/Toastr'
import 'thm/ui/common/message/MessagePage.css'
import $ from 'thm/thm-jquery'
import MessagePageFooterTplStache from 'thm/ui/common/message/MessagePageFooterTpl.stache'
import MessagePageTplStache from 'thm/ui/common/message/MessagePageTpl.stache'
import MessageTplStache from 'thm/ui/common/message/MessageTpl.stache'

canStache.registerPartial('MessagePageFooterTpl', MessagePageFooterTplStache)
canStache.registerPartial('MessageTpl', MessageTplStache)
import Message from 'core-uda/model/message/Message'

/**
 * @class thm.ui.commonmessage.MessagePage
 * @parent thm.ui
 * @constructor
 * @param {Object} options
 */
let MessagePage = Page.extend({
  id: 'messagePage',
  routerType: 'tab',
  template: MessagePageTplStache,
  currentPeriod: 'week',
  messages: [],
  count: 1,
  mode: 'read',
  errorMessages: [],
  i18nPrefix: ['umv.ui.message.MessagePage.'],

  bindEvents: function () {
    let me = this
    me._super()

    me.bindActions()

    me.$msgContent = me.$el.find('#' + me.id + '-content')

    APP().on('messagesLoaded', function () {
      if (me.mobileId != 0 && me.context) {
        me.messages = []
        me.context.mobileMessages = APP().models['messages'].getMobileMessages(
          me.mobileId,
        )
        me.setMessages(me.context)
        me.viewModel.attr('messages', me.messages)
        me.updateMessages()
        me.scrollToBottom()
      }
    })

    me.$el.find('#' + me.id + 'Form textarea').click(function () {
      if (me.mode !== 'send') {
        me.changeMode('send')
        me.scrollToBottom()
      }
    })

    me.$el.find('.L-scrollY-content').click(function () {
      if (me.mode === 'send') {
        me.resetParams()
        me.changeMode('read')
      }
    })

    me.$el.find('.msg-delete-options .msg-checkbox').on('click', function (ev) {
      var val = $(this).prop('checked')
      me.$el
        .find('.layout-border-center .msg-checkbox')
        .prop('checked', val)
        .checkboxradio('refresh')
    })

    me.$el
      .find('.msg-delete-options .msg-delete-options-title')
      .on('click', function () {
        me.$el.find('.msg-delete-options .msg-checkbox').click()
      })

    me.$el.find('#' + me.id + '-confirmDelete').click(function () {
      me.deleteMessages()
      me.changeMode('read')
      $(this).removeClass('active')
    })
  },

  onBack: function () {
    var me = this
    me.closedBy = 'back'
    me.close()
  },

  onCancel: function () {
    var me = this
    if (me.mode == 'send') {
      me.resetParams()
      me.changeMode('read')
    }
  },

  onSend: function () {
    var me = this,
      newMsg = new Message()

    newMsg.attr(
      'content',
      $('#messagePageForm').find('field-text[name=title]').find('input').val(),
    )
    newMsg.attr(
      'object',
      $('#messagePageForm')
        .find('field-textarea[name=content]')
        .find('textarea')
        .val(),
    )
    newMsg.attr('mobileId', me.mobileId)

    me.sendMessage(newMsg)
  },

  onCancelDelete: function () {
    var me = this
    me.onDelete()
  },
  onDelete: function () {
    var me = this
    var mode = ''
    if (me.mode == 'delete') {
      mode = 'read'
      me.viewModel.attr('showDeleteFooter', false)
    } else {
      me.viewModel.attr('showDeleteFooter', true)
      mode = 'delete'
    }
    me.viewModel.attr('bindModel.deleteAll', false)
    me.changeMode(mode)
  },

  changeMode: function (mode) {
    var me = this
    me.$msgContent.removeClass('msg-mode-' + me.mode)
    me.mode = mode
    me.$msgContent.addClass('msg-mode-' + me.mode)
  },

  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('headerExtendTpl', me.headerExtendTpl)
    me.viewModel.attr('messages', me.messages)
    me.viewModel.attr('bindModel', { deleteAll: false })
    me.viewModel.attr('formModel', { title: '', content: '' })
    me.viewModel.attr('showDeleteFooter', false)
    me.bindViewModel()
  },

  bindViewModel: function () {
    var me = this
    me.viewModel.attr('bindModel').on('deleteAll', function (ev, val) {
      _.each(me.messages, function (message) {
        message.deleteStatus = val
      })
      me.viewModel.attr('messages', me.messages)
    })
  },
  /**
   * Scroll to bottom
   */
  scrollToBottom: function () {
    var me = this,
      $scroll = me.$el.find('.L-scrollY-content'),
      scrollHeight = $scroll[0].scrollHeight

    $scroll.animate(
      {
        scrollTop: scrollHeight,
      },
      1000,
    )
  },

  /**
   * Affiche les messages
   */
  setMessages: function (context) {
    var me = this,
      mobileMessages = context.mobileMessages
    if (mobileMessages && mobileMessages.messages.length > 0) {
      var messagesInPeriod = mobileMessages.getMessages(context.period)
      for (var i = 0; i < messagesInPeriod.length; i++) {
        var currentMsg = messagesInPeriod[i]
        if (currentMsg.type == 'ERROR') {
          me.errorMessages.push(currentMsg)
        } else {
          me.messages.push(currentMsg.modelToView())
        }
      }
    } else {
      me.messages = []
    }

    if (me.errorMessages.length != 0) {
      _.each(me.errorMessages, function (val) {
        me.messages.push(val.modelToView())
      })
    }
  },

  /**
   * Met à jour les composants graphiques de messages
   * Enhance => Reactive le composant Jqm
   *
   */
  updateMessages: function () {
    var me = this,
      blocks = me.$el.find('.msg-block')

    _.each(blocks, function (block) {
      var height = $(block).find('.msg-content').height() + 20 + 10 // 20 = padding (10top,10bottom), 10 = margin;
      $(block).height(height)
    })

    me.$el.find('.msg-type-icon.ui-icon-alert').on('click', function (ev) {
      var content = $(ev.currentTarget).parent().parent().find('> p').text(),
        id = $(ev.currentTarget).parent().parent().attr('data-id'),
        index = 0,
        msg

      while (index < me.errorMessages.length) {
        if (!!id && me.errorMessages[index].id == id) {
          msg = me.errorMessages[index]
          me.errorMessages.splice(index, 1)
          break
        } else {
          if (me.errorMessages[index].content === content) {
            msg = me.errorMessages[index]
            me.errorMessages.splice(index, 1)
            break
          }
        }
        index++
      }

      index = 0

      while (index < me.messages.length) {
        if (!!id && me.messages[index].id == id) {
          me.messages.splice(index, 1)
          break
        } else {
          if (me.messages[index].content === content) {
            me.messages.splice(index, 1)
            break
          }
        }
        index++
      }

      //dans le cas d'un message déjà existant en base
      // il faut le supprimer
      // en recréer un nouveau
      if (msg && msg.id) {
        msg.attr('status', 'DELETED')
        Message.changeStatus([msg.id], 'DELETED')
        me.messages.push(msg)

        var newMsg = new Message()
        newMsg.attr('content', msg.content)
        newMsg.attr('object', msg.object)
        newMsg.attr('mobileId', msg.mobileId)
        me.sendMessage(newMsg)
        return
      }
      me.sendMessage(msg)
    })
  },

  open: function (context, jqmConfig) {
    var me = this
    me.context = context
    me.closedBy = 'close'
    me.listPage = context.listPage
    me.mobileId = context.mobile.id
    me.errorMessages = []

    me.changeMode(context.mode)
    me.updateViewModel(context)

    me._super(context, jqmConfig)
    me.updateMessages()
    me.scrollToBottom()
  },

  /**
   * Maj du viewModel
   */
  updateViewModel: function (context) {
    var me = this
    me.viewModel.attr('title', context.mobile.name)
    me.resetParams()
    me.messages = []
    me.setMessages(context)
    me.viewModel.attr('messages', me.messages)
  },

  resetParams: function () {
    var me = this
    me.viewModel.attr('formModel', { title: '', content: '' })
  },

  /**
   * Envoi d'un message
   */
  sendMessage: function (message) {
    var me = this,
      params = me.viewModel.attr('formModel').attr()

    if (!message) {
      if (params.title === '' || params.content === '') {
        return
      }
      params.mobileId = me.mobileId
      message = new Message()
      message.viewToModel(params)
    }
    message.attr('type', 'SENDING')
    me.messages.push(message.modelToView())
    me.viewModel.attr('messages', me.messages)
    me.updateMessages()
    me.changeMode('read')
    me.resetParams()

    $.when(message.save())
      .done(function (newData) {
        newData.attr('status', 'READ')
        APP().models['messages'].addMessage(newData)
        Toastr.showToastSuccess('sendMessageSuccess', 3000)
      })
      .fail(function (e) {
        var code = e.status
        me.resetParams()
        me.onMessageSendError(message, code)
      })
  },

  /**
   * En cas d'erreur de message
   * @param message : message envoyé
   * @param code : code d'erreur
   */
  onMessageSendError: function (message, code) {
    var me = this,
      index = 0

    message.attr('status', 'READ')
    message.attr('type', 'ERROR')

    me.errorMessages.push(message)
    //MAJ de l'affichage
    while (index < me.messages.length) {
      if (me.messages[index].content == message.content) {
        me.messages[index] = message.modelToView()
        break
      }
      index++
    }

    me.viewModel.attr('messages', me.messages)
    me.updateMessages()
  },

  /**
   * Suppression de messages
   */
  onConfirmDelete: function () {
    let me = this,
      values = _.filter(me.viewModel.attr('messages'), function (v) {
        return $.isTrue(v.deleteStatus)
      }),
      ids = []

    $.each(values, function (i, val) {
      var id = val.id

      // Message sending
      if (_.isNil(id) || id < 0) {
        return
      }
      ids.push(id)

      var message = APP('messages', val.id)
      message.attr('status', 'DELETED')

      for (var j in me.messages) {
        var val2 = me.messages[j]
        if (val2.id === id) {
          me.messages.splice(j, 1)
          break
        }
      }
    })
    me.viewModel.attr('messages', me.messages)
    me.updateMessages()
    Message.changeStatus(ids, 'DELETED')
    me.onCancelDelete()
  },

  //override
  doAfterClose: function () {
    var me = this
    delete me.mobileId
    if (me.listPage && me.closedBy != 'back') {
      me.listPage.close()
    }
    me._super()
  },
})

export default MessagePage
