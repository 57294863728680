import { THM_PREF } from 'thm/ThmPreferences'
import AlertDefinitionInfoPanel from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel'
import ChildStache from 'thm/ui/common/alertDefinition/AlertDefinitionStationaryInfoPanelTpl.stache'

/**
 * @class AlertDefinitionStationaryInfoPanel
 * Alert Definition "Stationary" Form.
 */
let AlertDefinitionStationaryInfoPanel = AlertDefinitionInfoPanel.extend({
  id: 'alertDefinitionStationaryInfoPanel',
  childTpl: ChildStache,
  timingAllowed: true,

  bindEvents: function () {
    let me = this
    me._super()
    THM_PREF.bind('unitChanged', function (ev, newVal, oldVal) {
      me.updateViewModel()
    })
  },
})
export default AlertDefinitionStationaryInfoPanel
