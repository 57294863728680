import _ from 'core-cmp/lodash'
import { I18N } from 'core-cmp/util/I18n'
import Toastr from 'core-cmp/Toastr'
import { MOBILE_DATA } from 'core-uda/model/mobile/MobileData'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import { APP } from 'core-uda/model/Resource'
import AlertDefinition from 'core-alert/model/AlertDefinition'
import InfoPanel from 'core-uda/ui/InfoPanel'
import AlertDefinitionInfoPanelTplStache from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanelTpl.stache'
import 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel.css'

let udaMsg = I18N.prefix('uda.alertDefinition.')

/**
 * @class thm.ui.common.alertDefinition.AlertDefinitionInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'une définition d'alerte.
 * @param {Object} options
 */
let AlertDefinitionInfoPanel = InfoPanel.extend({
  template: AlertDefinitionInfoPanelTplStache,
  i18nPrefix: [
    'thm.ui.common.alertDefinition.AlertDefinitionInfoPanel.',
    'uda.alertDefinition.',
    'thm.ui.common.beaconModel.CommandPeriodicPolling.',
  ],
  id: 'alertDefinitionInfoPanel',
  childTpl: null,
  child2Tpl: null,
  data: null,
  entityId: 'alertDefinitions',
  timingAllowed: false,
  zoneAllowed: true,
  mobileAllowed: true,
  allowClone: true,
  allowAdvancedSettingsAlertType: [
    'ua_portInOut',
    'ua_zoneInOut',
    'ua_speedInZone',
  ],

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this

    dataModel.attr({
      name: me.msg('Clone') + ' - ' + dataModel.name,
    })
  },

  //override
  getTitle: function () {
    let me = this
    return AlertDefinition.getKindLabel(me.data.kind || 'Default')
  },

  //override
  initViewModel: function () {
    let me = this,
      vesselPropertiesOptions = [
        {
          value: 'name',
          text: me.msg('vesselName'),
        },
        {
          value: 'callSign',
          text: me.msg('callSign'),
        },
        {
          value: 'registryNo',
          text: me.msg('registryNo'),
        },
        {
          value: 'mmsi',
          text: me.msg('mmsi'),
        },
        {
          value: 'imo',
          text: me.msg('imo'),
        },
        {
          value: 'ref',
          text: me.msg('vesselRef'),
        },
      ],
      initAlertInfosOptions = [
        {
          value: 'alertDefName',
          text: me.msg('alertDefName'),
        },
        {
          value: 'coordinates',
          text: me.msg('coordinates'),
        },
        {
          value: 'mapsLink',
          text: me.msg('mapsLink'),
        },
        {
          value: 'alarmDate',
          text: me.msg('alarmDate'),
        },
        {
          value: 'speedInMs',
          text: me.msg('speedInMs'),
        },
        {
          value: 'speedInKt',
          text: me.msg('speedInKt'),
        },
        {
          value: 'heading',
          text: me.msg('heading'),
        },
      ],
      coordinatesFormat = [
        {
          value: 'd',
          text: '-52.90508',
        },
        {
          value: 'dmsO',
          text: '52°54\'30.89" W',
        },
        {
          value: 'dmssO',
          text: '52°54\'30.89512" W',
        },
        {
          value: 'dmO',
          text: "52°90.508' W",
        },
        {
          value: 'dmmO',
          text: "52°90.50851' W",
        },
      ]

    me._super()
    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.attr('mobileTypes', [
      { value: 'none', text: me.msg('noMobiles') },
      { value: 'fleets', text: me.msg('selFleets') },
      { value: 'mobiles', text: me.msg('selMobiles') },
    ])

    me.viewModel.attr('recipientTypes', [
      { value: 'none', text: me.msg('noRecipient') },
      { value: 'recipients', text: me.msg('selRecipient') },
    ])

    me.viewModel.attr(
      'actionTypeOptions',
      me.getCommandActionType(RIGHTS('data.command.create')),
    )

    me.viewModel.attr('sharedAuthorized', RIGHTS('alertDefinitionShared'))
    me.viewModel.attr('activatedAuthorized', RIGHTS('alertDefinitionActivated'))
    me.viewModel.attr('tabs', 'definition')

    // THM-9316
    me.viewModel.attr('allowedCustomers', DATA_AUTHORIZED('customers'))

    me.viewModel.attr('hasReportingIntervalOption', false)
    me.viewModel.attr('tabsColOptions', 3)

    me.viewModel.attr('availableCurfewDays', [
      {
        text: me.msg('monday'),
        value: '1',
      },
      {
        text: me.msg('tuesday'),
        value: '2',
      },
      {
        text: me.msg('wednesday'),
        value: '3',
      },
      {
        text: me.msg('thursday'),
        value: '4',
      },
      {
        text: me.msg('friday'),
        value: '5',
      },
      {
        text: me.msg('saturday'),
        value: '6',
      },
      {
        text: me.msg('sunday'),
        value: '7',
      },
    ])

    me.viewModel.attr('curfewBetweenOptions', [
      {
        text: me.msg('between'),
        value: true,
      },
      {
        text: me.msg('outside'),
        value: false,
      },
    ])

    me.viewModel.attr('whiteListTypes', [
      { value: 'none', text: me.msg('noMobiles') },
      { value: 'fleets', text: me.msg('selFleets') },
      { value: 'mobiles', text: me.msg('selMobiles') },
    ])

    me.viewModel.attr('minutes', me._setOptionsSelector(0, 60))
    me.viewModel.attr('hours', me._setOptionsSelector(0, 24))
    me.viewModel.attr('daysStart', me._setOptionsSelector(1, 31))
    me.viewModel.attr('daysEnd', me._setOptionsSelector(1, 31))
    me.viewModel.attr('months', me._setOptionsSelector(1, 12))

    me.viewModel.attr('ppChannelOptions', [
      {
        value: 'POLL',
        text: 'IRIDIUM',
      },
      {
        value: 'POLL_GPRS',
        text: 'GPRS',
      },
    ])

    me.viewModel.attr('vesselPropertiesOptions', vesselPropertiesOptions)
    me.viewModel.attr('alertInfosOptions', initAlertInfosOptions)
    me.viewModel.attr('alertInfosSave', initAlertInfosOptions)
    me.viewModel.attr('coordinatesFormat', coordinatesFormat)

    me.loadTabsOptions()
  },

  _setOptionsSelector: function (start, limit) {
    let array = []
    for (let i = start; i < limit + start; i++) {
      if (i < 10) {
        array.push({
          text: '0' + i,
          value: Number(i),
        })
      } else {
        array.push({
          text: i,
          value: Number(i),
        })
      }
    }

    return array
  },

  loadTabsOptions: function () {
    let me = this,
      context = me.mobileAllowed || me.zoneAllowed,
      definitionOption = {
        value: 'definition',
        text: me.msg('_Alert'),
        icon: 'info',
      },
      contextOption = {
        value: 'context',
        text: me.msg('mobileTitle'),
        icon: 'mobile',
      },
      settingsOption = {
        value: 'settings',
        text: me.msg('settingsTitle'),
        icon: 'config',
      },
      notifyOption = {
        value: 'actions',
        text: me.msg('notifyTitle'),
        icon: 'mail',
      },
      alertAllowedAdvancedSettings = me.allowAdvancedSettingsAlertType.indexOf(
        me.childId.split('.')[1],
      ),
      displayAdvancedSettings = RIGHTS('data.alert.advancedSettings.active'),
      displayPeriodicPolling = RIGHTS('data.alert.periodicPolling'),
      displayAdvancedSettingsDate =
        displayAdvancedSettings && RIGHTS('data.alert.advancedSettings.date'),
      displayAdvancedSettingsWhiteList =
        displayAdvancedSettings &&
        RIGHTS('data.alert.advancedSettings.whitelist'),
      colOptions = 2,
      options = [definitionOption, notifyOption]

    if (
      displayAdvancedSettings &&
      (displayAdvancedSettingsDate ||
        displayAdvancedSettingsWhiteList ||
        displayPeriodicPolling) &&
      alertAllowedAdvancedSettings > -1
    ) {
      options.splice(1, 0, settingsOption)
      me.viewModel.attr(
        'advancedDateTabAuthorized',
        displayAdvancedSettingsDate,
      )
      me.viewModel.attr(
        'whiteListTabAuthorized',
        displayAdvancedSettingsWhiteList,
      )
      colOptions++
    }

    if (context) {
      options.splice(1, 0, contextOption)
      colOptions++
    }

    me.viewModel.attr('tabsOptions', options)
    me.viewModel.attr('tabsColOptions', colOptions)
  },

  getCommandActionType: function (rightCommandCreation) {
    let me = this,
      commandCreation,
      commandActionType = [{ value: 'none', text: me.msg('_None') }]

    if (!!rightCommandCreation) {
      commandCreation = rightCommandCreation.join(',')

      if (commandCreation.indexOf('poll') >= 0) {
        commandActionType.push({
          value: 'poll',
          text: udaMsg('field.actionType.poll'),
        })
      }
      if (commandCreation.indexOf('reportingInterval') >= 0) {
        commandActionType.push({
          value: 'reportingInterval',
          text: udaMsg('field.actionType.reportingInterval'),
        })
      }
      if (commandCreation.indexOf('administratorPolling') >= 0) {
        commandActionType.push({
          value: 'administratorPolling',
          text: udaMsg('field.actionType.administratorPolling'),
        })
      }
    }

    return commandActionType
  },

  /**
   * Change le type de mobile (none/mobiles/fleets)
   */
  changeMobileType: function () {
    let me = this,
      mobileType = me.getDataModel().mobileType,
      hasMobiles = false,
      hasFleets = false

    switch (mobileType) {
      case 'none':
        break
      case 'mobiles':
        hasMobiles = true
        break
      case 'fleets':
        hasFleets = true
        break
    }

    me.viewModel.attr('hasMobiles', hasMobiles)
    me.viewModel.attr('hasFleets', hasFleets)
  },

  setWhiteListType: function () {
    let me = this,
      authorizedMob = me.getDataModel().authorizedMobiles,
      authorizedFlt = me.getDataModel().authorizedFleets,
      whiteListType

    if (!authorizedMob && !authorizedFlt) {
      whiteListType = 'none'
    } else if (!authorizedMob) {
      whiteListType = 'fleets'
    } else {
      whiteListType = 'mobiles'
    }

    me.viewModel.attr('dataModel.whiteListType', whiteListType)
  },

  changeWhiteListType: function () {
    let me = this,
      whiteListType = me.getDataModel().whiteListType,
      hasMobiles = false,
      hasFleets = false

    switch (whiteListType) {
      case 'none':
        break
      case 'mobiles':
        hasMobiles = true
        break
      case 'fleets':
        hasFleets = true
        break
    }

    me.viewModel.attr('hasWhiteListMobiles', hasMobiles)
    me.viewModel.attr('hasWhiteListFleets', hasFleets)
  },

  //override
  bindViewModel: function () {
    let me = this

    me.viewModel
      .attr('dataModel')
      .on('mobileType', me.proxy(me.changeMobileType))
    me.viewModel
      .attr('dataModel')
      .on('whiteListType', me.proxy(me.changeWhiteListType))
    me.viewModel
      .attr('dataModel')
      .on('actionType', me.proxy(me.changeHasReportingIntervalOption))
    me.viewModel
      .attr('dataModel')
      .on('actionActivated', me.proxy(me.changeHasReportingIntervalOption))
    me.changeMobileType()
    me.changeWhiteListType()

    me.viewModel
      .attr('dataModel')
      .on(
        'advancedSeasonalStartMonth',
        me.changeSeasonalDaysOptions.bind(me, 'Start'),
      )
    me.viewModel
      .attr('dataModel')
      .on(
        'advancedSeasonalEndMonth',
        me.changeSeasonalDaysOptions.bind(me, 'End'),
      )
    me._super()
  },

  changeSeasonalDaysOptions: function (field) {
    let me = this,
      dataModel = me.viewModel.attr('dataModel'),
      dayCurrentValue = me.viewModel.attr(
        'dataModel.advancedSeasonal' + field + 'Day',
      ),
      getDaysInMonth = function (month) {
        // Use 2019 because is not a leap year
        return new Date(2019, month, 0).getDate()
      },
      month = dataModel['advancedSeasonal' + field + 'Month'],
      daysInMonth = getDaysInMonth(month)

    me.viewModel.attr('days' + field, me._setOptionsSelector(1, daysInMonth))
    me.viewModel.attr(
      'dataModel.advancedSeasonal' + field + 'Day',
      dayCurrentValue > daysInMonth ? 1 : dayCurrentValue,
    )
  },

  changeHasReportingIntervalOption: function () {
    let me = this

    me.viewModel.attr(
      'hasReportingIntervalOption',
      me.viewModel.attr('dataModel.actionType') === 'reportingInterval' &&
        me.viewModel.attr('dataModel.actionActivated'),
    )
  },

  updateViewModel: function () {
    let me = this

    if (me.creation) {
      me.viewModel.attr('tabs', 'definition')
    }

    me._super()
    me.viewModel.attr('childTpl', me.childTpl)
    me.viewModel.attr('child2Tpl', me.child2Tpl)

    me.zoneAllowed = APP('alertDefinition').isKindUsedResource(
      me.getDataModel().kind,
      'zone',
    )
    me.mobileAllowed = APP('alertDefinition').isKindUsedResource(
      me.getDataModel().kind,
      'mobile',
    )

    me.viewModel.attr(
      'actionTypeOptions',
      me.getCommandActionType(RIGHTS('data.command.create')),
    )

    me.viewModel.attr('zoneAllowed', me.zoneAllowed)
    me.viewModel.attr('mobileAllowed', me.mobileAllowed)
    me.viewModel.attr('manual', me.getDataModel().kind === 'ua_manual')
    me.viewModel.attr('commandAccess', DATA_AUTHORIZED('command'))
    me.viewModel.attr('timingAllowed', me.timingAllowed)
    me.viewModel.attr(
      'isUserAlertDefinition',
      me.data.kind.indexOf('ua_') === 0,
    )

    me.viewModel.attr('zoneCount', _.size(me.data.zones))
    me.viewModel.attr(
      'sharedEdit',
      RIGHTS('data.alertDefinition.accept') === 'all' ||
        APP().user.id === me.data.userId,
    )
    me.viewModel.attr(
      'mobileFields',
      MOBILE_DATA.getMobileTemplateFields().join(','),
    )

    let alertInfosOptions = me.viewModel.attr('alertInfosSave')

    if (me.data.kind === 'ga_exceptions') {
      if (
        _.findIndex(alertInfosOptions, function (info) {
          return info.value == 'beaconType' || info.value == 'provider'
        }) === -1
      ) {
        alertInfosOptions.push({
          value: 'beaconType',
          text: me.msg('beaconType'),
        })
        alertInfosOptions.push({ value: 'provider', text: me.msg('provider') })
      }
    } else if (
      me.data.kind === 'ua_speedInZone' ||
      me.data.kind === 'ua_portInOut' ||
      me.data.kind === 'ua_zoneInOut'
    ) {
      if (
        _.findIndex(alertInfosOptions, function (info) {
          return info.value == 'zoneName'
        }) === -1
      ) {
        alertInfosOptions.push({ value: 'zoneName', text: me.msg('zoneName') })
      }
    }

    me.viewModel.attr('alertInfosOptions', alertInfosOptions)
    me.viewModel.attr('showClient', RIGHTS('showClient'))

    if (me.creation) {
      let initVesselProperties = { name: true },
        initAlertInfos = { mapsLink: true, alarmDate: true }

      for (const [k, v] of Object.entries(alertInfosOptions)) {
        if (
          v.value == 'beaconType' ||
          v.value == 'provider' ||
          v.value == 'zoneName'
        ) {
          initAlertInfos[v.value] = false
        }
      }

      me.viewModel
        .attr('dataModel')
        .attr('vesselProperties', initVesselProperties)
      me.viewModel.attr('dataModel').attr('alertInfos', initAlertInfos)
    }

    me.setWhiteListType()
    me.loadTabsOptions()
    me.changeMobileType()
    me.changeWhiteListType()
    me.updateOriginalValue()
    me.forceDefaultValues()
  },

  /**
   * To override bu sub classes
   */
  forceDefaultValues: function () {},

  /**
   * Enregistre la valeur chargée pour le checkChanges
   */
  updateOriginalValue: function () {
    let me = this
    me.originalValue = {}
    for (var p in me.getDataModel()) {
      me.originalValue[p] = me.getDataModel()[p]
    }
  },

  /**
   * @override
   */
  loadForEdition: function (data) {
    return AlertDefinition.loadForEdition(data.id, data)
  },

  /**
   * @override
   */
  checkErrors: function () {
    let me = this,
      dataModel = me.viewModel.attr('dataModel'),
      errors = me._super()

    if (errors) {
      return errors
    }

    if (dataModel.advancedSeasonal) {
      let startTime =
          dataModel.advancedSeasonalStartMonth * 31 +
          dataModel.advancedSeasonalStartDay,
        endTime =
          dataModel.advancedSeasonalEndMonth * 31 +
          dataModel.advancedSeasonalEndDay
      if (startTime >= endTime) {
        Toastr.showToastErrorBrut(me.msg('startDateSupEndDate'), 3000)
        return true
      }
    }

    if (dataModel.advancedCurfew) {
      if (
        !dataModel.advancedCurfewDays ||
        !dataModel.advancedCurfewDays.length
      ) {
        Toastr.showToastErrorBrut(me.msg('emptyCurfewDays'), 3000)
        return true
      }

      let startTime =
          dataModel.advancedCurfewStartTimeHour * 60 +
          dataModel.advancedCurfewStartTimeMinute,
        endTime =
          dataModel.advancedCurfewEndTimeHour * 60 +
          dataModel.advancedCurfewEndTimeMinute
      if (startTime >= endTime) {
        Toastr.showToastErrorBrut(me.msg('startTimeSupEndTime'), 3000)
        return true
      }
    }

    return false
  },

  /**
   * @override
   */
  modifyDiffBeforeCheck: function (diff) {
    let me = this,
      dataModel = me.getDataModel()

    if (!dataModel.advancedSeasonal) {
      if (diff.diff.advancedSeasonalStartMonth) {
        delete diff.diff.advancedSeasonalStartMonth
        diff.count--
      }
      if (diff.diff.advancedSeasonalStartDay) {
        delete diff.diff.advancedSeasonalStartDay
        diff.count--
      }
      if (diff.diff.advancedSeasonalEndMonth) {
        delete diff.diff.advancedSeasonalEndMonth
        diff.count--
      }
      if (diff.diff.advancedSeasonalEndDay) {
        delete diff.diff.advancedSeasonalEndDay
        diff.count--
      }
    }

    if (!dataModel.advancedCurfew) {
      if (diff.diff.advancedCurfewDays) {
        delete diff.diff.advancedCurfewDays
        diff.count--
      }
      if (diff.diff.advancedCurfewBetween) {
        delete diff.diff.advancedCurfewBetween
        diff.count--
      }
      if (diff.diff.advancedCurfewStartTimeHour) {
        delete diff.diff.advancedCurfewStartTimeHour
        diff.count--
      }
      if (diff.diff.advancedCurfewStartTimeMinute) {
        delete diff.diff.advancedCurfewStartTimeMinute
        diff.count--
      }
      if (diff.diff.advancedCurfewEndTimeHour) {
        delete diff.diff.advancedCurfewEndTimeHour
        diff.count--
      }
      if (diff.diff.advancedCurfewEndTimeMinute) {
        delete diff.diff.advancedCurfewEndTimeMinute
        diff.count--
      }
    }

    return diff
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me = this,
      dataModel = me.getDataModel(),
      msgPref = {},
      vesselProperties = [],
      alertInfos = [],
      beaconEvents = []

    if (!dataModel.actionActivated) {
      dataModel.actionType = 'none'
    }

    /* Vérification */
    if (me.timingAllowed) {
      if (_.isUndefined(dataModel.alertOnEnd))
        me.viewModel.attr('dataModel').attr('alertOnEnd', false)
      if (_.isUndefined(dataModel.alertOnStart))
        me.viewModel.attr('dataModel').attr('alertOnStart', false)
    }

    if (_.isUndefined(dataModel.activated))
      me.viewModel.attr('dataModel').attr('activated', false)
    if (_.isUndefined(dataModel.shared))
      me.viewModel.attr('dataModel').attr('shared', false)

    switch (dataModel.mobileType) {
      case 'none':
        dataModel.mobiles = []
        dataModel.fleets = []
        break
      case 'mobiles':
        delete dataModel.fleets
        break
      case 'fleets':
        delete dataModel.mobiles
        break
    }
    delete dataModel.mobileType //sera non sauvegardé
    switch (dataModel.whiteListType) {
      case 'none':
        dataModel.authorizedMobiles = []
        dataModel.authorizedFleets = []
        break
      case 'mobiles':
        delete dataModel.authorizedFleets
        break
      case 'fleets':
        delete dataModel.authorizedMobiles
        break
    }
    delete dataModel.whiteListType //sera non sauvegardé

    if (!dataModel.advancedSeasonal) {
      delete dataModel.advancedSeasonalStartMonth
      delete dataModel.advancedSeasonalStartDay
      delete dataModel.advancedSeasonalEndMonth
      delete dataModel.advancedSeasonalEndDay
    }

    if (!dataModel.advancedCurfew) {
      delete dataModel.advancedCurfewDays
      delete dataModel.advancedCurfewBetween
      delete dataModel.advancedCurfewStartTimeHour
      delete dataModel.advancedCurfewStartTimeMinute
      delete dataModel.advancedCurfewEndTimeHour
      delete dataModel.advancedCurfewEndTimeMinute
    }

    // THM-10030
    msgPref = me.generateMsgPref(dataModel)

    dataModel.msgPref = JSON.stringify(msgPref)
    me.viewModel.attr('dataModel').attr('msgPref', JSON.stringify(msgPref))

    return dataModel
  },

  generateMsgPref: function (dataModel) {
    let msgPref = {},
      vesselProperties = [],
      alertInfos = [],
      beaconEvents = []

    if (dataModel.vesselProperties) {
      _.each(dataModel.vesselProperties, (value, property) => {
        if (value) {
          vesselProperties.push(property)
        }
      })
      if (!_.isEmpty(vesselProperties)) {
        msgPref.vesselProperties = vesselProperties
      }
    }

    if (dataModel.alertInfos) {
      _.each(dataModel.alertInfos, (value, info) => {
        if (value) {
          alertInfos.push(info)
        }
      })
      if (!_.isEmpty(alertInfos)) {
        msgPref.alertInfos = alertInfos
      }
    }

    if (dataModel.coordinatesFormat) {
      if (dataModel.alertInfos && dataModel.alertInfos.coordinates) {
        msgPref.coordinatesFormat = dataModel.coordinatesFormat
      }
    }

    if (dataModel.headerTitle) {
      msgPref.header = dataModel.header
    }

    if (dataModel.freeTextTitle) {
      msgPref.freeText = dataModel.freeText
    }

    if (dataModel.beaconEvents) {
      _.each(dataModel.beaconEvents, (value, info) => {
        if (value) {
          beaconEvents.push(info)
        }
      })

      msgPref.beaconEvents = beaconEvents
    }

    return msgPref
  },

  /**
   * @override
   */
  getDataModelForCheckChange: function () {
    let me = this,
      dataModel = me.getDataModel(),
      msgPref = me.generateMsgPref(dataModel)

    dataModel.msgPref = JSON.stringify(msgPref)
    return dataModel
  },

  /**
   * @override
   */
  cleanForEqual: function (attr) {
    let me = this,
      capabilities = APP().getCapabilities('alertDefinition')

    // undefined to false
    attr.alertOnEnd = !!attr.alertOnEnd
    attr.alertOnStart = !!attr.alertOnStart
    attr.activated = !!attr.activated
    attr.shared = !!attr.shared

    if (!attr.actionActivated) {
      attr.actionType = 'none'
    }

    // FIXME : UDA Bug unauthorizedPortsOnly is declare as string, should be boolean
    attr.unauthorizedPortsOnly =
      attr.unauthorizedPortsOnly === true ||
      attr.unauthorizedPortsOnly === 'true'

    switch (attr.mobileType) {
      case 'none':
        delete attr.mobiles
        delete attr.fleets
        break
      case 'mobiles':
        delete attr.fleets
        break
      case 'fleets':
        delete attr.mobiles
        break
    }

    switch (attr.whiteListType) {
      case 'none':
        delete attr.authorizedMobiles
        delete attr.authorizedFleets
        break
      case 'mobiles':
        delete attr.authorizedFleets
        break
      case 'fleets':
        delete attr.authorizedMobiles
        break
    }

    // remove all properties generated by UI
    _.each(_.keys(attr), (key) => {
      if (key !== 'beaconEvents' && !capabilities.fields[key]) {
        delete attr[key]
      }
    })

    return attr
  },

  /**
   * @override
   */
  onSaveSuccess: function (alert, forceClose) {
    let me = this

    if (alert.mobiles) {
      alert.mobiles = alert.mobiles.attr()
    }

    if (alert.fleets) {
      alert.fleets = alert.fleets.attr()
    }

    if (alert.zones) {
      alert.zones = alert.zones.attr()
    }

    alert.generateEditionFields()

    me._super(alert, forceClose)
  },
})

export default AlertDefinitionInfoPanel
