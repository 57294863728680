import { FEATURE_TYPE } from 'core-gis/FeatureConstants'
import FeatureStyleBuilder from 'core-gis/service/style/FeatureStyleBuilder'

let DEFAULT_DEFINITION = {
  none: null,
}

const FEATURE_TYPE_TO_STYLE_ID = {}
FEATURE_TYPE_TO_STYLE_ID[FEATURE_TYPE.METEO_STATION_AERO] = 'circle'

let MeteoStationRecordStyleBuilder = FeatureStyleBuilder.extend({
  /**
   * @override
   */
  init: function (definitions, options) {
    let me = this
    me._super(DEFAULT_DEFINITION, definitions, options)
  },

  /**
   * @override
   */
  getStyle: function (feature, intent) {
    let data = GIS.featureData(feature),
      styleId = FEATURE_TYPE_TO_STYLE_ID[data.type]

    return this._getStyle(styleId, feature, intent)
  },
})

export default MeteoStationRecordStyleBuilder
