import InfoPanel from 'core-uda/ui/InfoPanel'
import SharedVesselInfoPanelTpl from 'thm/ui/common/sharedVessel/SharedVesselInfoPanelTpl.stache'

/**
 * @class SharedVesselInfoPanel
 * Afficher les infos d'un Shared Vessel
 */
let SharedVesselInfoPanel = InfoPanel.extend({
  template: SharedVesselInfoPanelTpl,
  i18nPrefix: [
    'uda.sharedVessel.',
    'thm.ui.common.sharedVessel.SharedVesselInfoPanel.',
  ],
  id: 'sharedVesselInfoPanel',
  entityId: 'sharedVessel',
  allowEdit: true,
  allowDelete: true,
  allowCreate: true,
  editMode: true,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this
    me._super()
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this
    me._super()
  },
})

export default SharedVesselInfoPanel
