import InfoPanel from 'core-uda/ui/InfoPanel'
import { RIGHTS } from 'core-uda/Rights'
import ShipownerInfoPanelTplStache from 'thm/ui/common/ship/ShipownerInfoPanelTpl.stache'
import { CUSTOMER_CONST } from 'core-uda/model/customer/Customer'
import { SHIPOWNER_CONST } from 'core-uda/model/mobile/Shipowner'

/**
 * Shipowner form.
 * @class ShipownerInfoPanel
 */
let ShipownerInfoPanel = InfoPanel.extend({
  template: ShipownerInfoPanelTplStache,
  i18nPrefix: [
    'uda.shipowner.',
    'uda.customer.',
    'thm.ui.common.ship.ShipownerInfoPanel.',
  ],
  id: 'shipownerInfoPanel',
  childTpl: null,
  entityId: 'shipowner',
  allowWidth: true,
  allowClone: true,
  titleKey: 'title',

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this

    dataModel.attr({
      name: me.msg('Clone') + ' - ' + dataModel.name,
    })
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()

    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.attr(
      'personIdentityTypeOptions',
      SHIPOWNER_CONST.personIdentityType,
    )
    me.viewModel.attr('broadcastModeOptions', CUSTOMER_CONST.contactBroadcast)
  },

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    let me = this
    return me.data.name
  },
})

export default ShipownerInfoPanel
