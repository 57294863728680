import QueryByCriteria from 'core-criteria/queryBy/QueryByCriteria'

/**
 * @class core-criteria.queryBy.QueryByCriteria
 * @parent core-criteria.searchBy
 * @constructor
 * Gestion de la configuration de la grille.
 * @param {Object} options
 */
let ThmQueryByCriteria = QueryByCriteria.extend({
  format: 'string',
  name: 'string',
  fields: 'string',
  maxLength: 'number',
  type: 'text',

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this

    me.name = config.name ? config.name : me.name
    me.fields = config.fields

    me._super(config)
  },

  /**
   * @override
   */
  formatModelToUDA: function (paramsUda) {
    let me = this,
      params = me.criteriaFormModel
    if (!paramsUda['query']) {
      paramsUda['query'] = ''
    }
    if (!paramsUda['queryBy']) {
      paramsUda['queryBy'] = ''
    }
    if (params[me.name] && params[me.name].length > 0) {
      paramsUda['queryBy'] += me.fields ? me.fields : me.name + ','
      paramsUda['query'] += '*' + params[me.name] + '*'
    }
    return paramsUda
  },
})

export default ThmQueryByCriteria
