import _ from 'core-cmp/lodash'
import { APP } from 'core-uda/model/Resource'
import { I18N } from 'core-cmp/util/I18n'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'
import SelectByStache from 'thm/ui/common/mobileLifeEventType/ThmSelectMobileLifeEventTypeCriteria.stache'

/**
 * @class ThmSelectMobileLifeEventTypeCriteria
 */
let ThmSelectMobileLifeEventTypeCriteria = ThmSelectByCriteria.extend(
  {
    /**
     * @override
     */
    readParameters: function (resource, criteriaConfig, criteriaName) {
      return { options: ['mobileLifeEventType'] }
    },
  },
  {
    /**
     * @override
     * @param config
     */
    init: function (config) {
      let me = this
      me.stache = SelectByStache
      me._super(config)
      me.typeOptions = [
        {
          value: 'all',
          text: I18N.msg('uda.mobileLifeEvent.all'),
        },
      ]
    },

    /**
     * @override
     */
    setOptions: function () {
      let me = this

      me.resourceMap.mobileLifeEventType = true
      me.states.mobileLifeEventType = false
      me.typeOptions.push({
        value: 'mobileLifeEventType',
        text: I18N.msg('uda.mobileLifeEvent.selection'),
      })
    },

    /**
     * @override
     */
    setParams: function (params) {
      let me = this,
        type,
        id

      me.criteriaFormModel.type = 'all'
      if (params[me.name]) {
        type = me.name
        id = params[me.name].split(',')

        if (!_.isNil(me.states[type])) {
          me.criteriaFormModel.type = type
          me.criteriaFormModel[type] = id
        }
      }
    },

    /**
     * @override
     */
    formatModelToUDA: function () {
      let me = this,
        params = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : '',
        paramsUda = {},
        type = params.type

      if (me.formatModelToUDACustom) {
        paramsUda = me.formatModelToUDACustom(params)
      } else {
        if (type !== 'all' && !_.isEmpty(me.criteriaFormModel[type])) {
          let ids = me.criteriaFormModel[type].join(',')
          paramsUda[resourceName + me.name] = ids
        }
      }

      return paramsUda
    },
  },
)

export default ThmSelectMobileLifeEventTypeCriteria
