<div id="{{id}}" class="page L-scrollY has-header">

  <div class="header-page L-scrollY-header">
    <h1>{{msg 'title'}}</h1>
    <a cls-action="close" class="btn btn-header-left ui-icon-arrow-l"></a>
  </div>

  <ul class="L-scrollY-content list">

    <div class="header-group icon-left icon-m ui-icon-user">
      <h2>{{msg 'preferences'}}</h2>
    </div>

    <li cls-action="preferences" class="list-item-l btn icon-left icon-xl ui-icon-config">
      <h3>{{msg 'general'}}</h3>
      <p>{{msg 'editPref'}}</p>
    </li>

    {{#if userInfos}}
    <li cls-action="userInfos" class="list-item-l btn icon-left icon-xl ui-icon-user">
      <h3>{{msg 'userInfos'}}</h3>
      <p>{{msg 'editUserInfos'}}</p>
    </li>
    {{/if}}

    {{#if mtccReport}}
    <li cls-action="mtccReport" class="list-item-l btn icon-left icon-xl ui-icon-data   Position">
      <h3>{{msg 'mtccReport'}}</h3>
      <p>{{msg 'textMtccReport'}}</p>
    </li>
    {{/if}}

    <div class="header-group icon-left icon-m ui-icon-config">
      <h2>{{msg 'clientSettings'}}</h2>
    </div>

    <li cls-action="generalAlertBroadcast" class="list-item-l btn icon-left icon-xl ui-icon-config">
      <h3>{{msg 'generalAlertBroadcast'}}</h3>
      <p>{{msg 'editGeneralAlertBroadcast'}}</p>
    </li>

    <div class="header-group icon-left icon-m ui-icon-style">
      <h2>{{msg 'tools'}}</h2>
    </div>
    <li cls-action="unitConversion" class="list-item-l btn icon-left icon-xl ui-icon-resizeH">
      <h3>{{msg 'unitConversion'}}</h3>
      <p>{{msg 'textUnitConversion'}}</p>
    </li>

    {{#if importPositions}}
    <li cls-action="importPositions" class="list-item-l btn icon-left icon-xl ui-icon-database">
      <h3>{{msg 'importPositions'}}</h3>

      <p>{{msg 'textImportPositions'}}</p>
    </li>
    {{/if}}

    {{#if importErsMessages}}
    <li cls-action="importErsMessages" class="list-item-l btn icon-left icon-xl ui-icon-database">
      <h3>{{msg 'importErsMessages'}}</h3>

      <p>{{msg 'textImportErsMessages'}}</p>
    </li>
    {{/if}}

    {{#if importMobiles}}
    <li cls-action="importMobiles" class="list-item-l btn icon-left icon-xl ui-icon-database">
      <h3>{{msg 'importMobiles'}}</h3>

      <p>{{msg 'textImportMobiles'}}</p>
    </li>
    {{/if}}
    <div class="header-group icon-left icon-m ui-icon-info">
      <h2>{{msg 'version'}} :</h2>
    </div>

    <li class="list-item-l">
      <p>umv : {{build}}</p>
      <p>uda : {{udaBuild}}</p>
    </li>

    {{#if userGuide}}
    <li cls-action="openUserGuide" class="list-item-l btn icon-left icon-xl ui-icon-help">
      <h3>{{msg 'userGuide'}}</h3>

      <p>{{msg 'textUserGuide'}}</p>
    </li>
    {{/if}}

    {{#if parameters}}
    <li cls-action="parameters" class="list-item-l btn icon-left icon-xl ui-icon-config">
      <h3>{{msg 'parameters'}}</h3>

      <p>{{msg 'textParameters'}}</p>
    </li>
    <li cls-action="reportTemplate" class="list-item-l btn icon-left icon-xl ui-icon-bulletin">
      <h3>{{msg 'reportTemplate'}}</h3>

      <p>{{msg 'textReportTemplate'}}</p>
    </li>
    {{/if}}

  </ul>
    <form id="{{id}}-request-form" class="hidden" action="{{exportFormModel.url}}" method="{{exportFormModel.method}}" target="_blank">
      {{#each exportFormModel.params}}
          <input type="text" name="{{name}}" value="{{value}}">
      {{/each}}
    </form>
</div>
