import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import CheckboxesCriteria from 'core-criteria/listCheckbox/CheckboxesCriteria'
import MobileMerge from 'core-uda/model/mobile/MobileMerge'
import ResourceUI from 'core-uda/ui/ResourceUI'

/**
 * Interface elements for MobileMerge
 */
let MobileMergeUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: MobileMerge.itemId,
      icon: 'vesselMeeting',
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      status: I18N_FIELD('uda.mergeMobile.field.status.'),
    }
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      status: {
        cmpClass: CheckboxesCriteria,
        resourceName: 'mergeMobile',
        optionsKeyPrefix: 'uda.mergeMobile.field.status.',
        validateMandatory: false,
      },
    }
  },
})

const MOBILE_MERGE_UI = new MobileMergeUI()

export default MobileMergeUI
