import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import { CELL_TPL_PERCENT } from 'core-uda/ui/ResourceUI'
import { I18N_FIELD } from 'core-uda/ui/ResourceUI'
import ErsFishingQuotasReport from 'core-uda/model/ersFishingQuotasReport/ErsFishingQuotasReport'
import { DATA_AUTHORIZED } from 'core-uda/Rights'

import SelectCriteria from 'core-criteria/select/SelectCriteria'
import ThmListCriteria from 'thm/ui/common/criteria/ThmListCriteria'
import BooleanCriteria from 'core-criteria/boolean/BooleanCriteria'

import { I18N } from 'core-cmp/util/I18n'
import { PREF } from 'core-cmp/Preferences'

/**
 * User Interface elements for ErsQuery
 */
let ErsFishingQuotasReportUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: ErsFishingQuotasReport.itemId,
      icon: 'fishChart',
      i18nPrefix:
        'thm.ui.common.ersFishingQuotasReport.ErsFishingQuotasReportUI.',
      extraColumnsToDelete: [], // Used to delete dynamic species columns after a URE request
      exportListIntoColumnsUda: {
        speciesDetail: {
          headerObject: 'speciesI18n',
          valueObject: 'quantity',
        },
      },
      onlyDisplayResultColumns: [
        'tripId',
        'tripNumber',
        'tripOpenDate',
        'tripOpenDateMillis',
        'tripCloseDate',
        'tripCloseDateMillis',
      ],
      resultDynamicColumnsConfig: {
        columns: [],
        activeColumns: true,
        deleteExtraColumns: true,
        listProperty: 'speciesDetail',
        columnLabelProperty: 'speciesI18n',
        columnValueProperty: 'quantity',
      },
    })
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      kind: {
        cmpClass: SelectCriteria,
        optionsKeyPrefix:
          'uda.ersFishingQuotasReport.field.quotaState.options.',
      },
      species: {
        cmpClass: ThmListCriteria, //UdaListCriteria,
        i18nPrefix: 'uda.species.',
      },
      addTripDetail: {
        cmpClass: BooleanCriteria,
        labelKey: 'thm.ui.common.ers.field.addTripDetail',
      },
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      quotaUsedPercent: {
        cellTemplate: CELL_TPL_PERCENT,
        dataType: 'string',
      },
      quotaUsedShipPercent: {
        cellTemplate: CELL_TPL_PERCENT,
        dataType: 'string',
      },
      quotaState: I18N_FIELD(
        'uda.ersFishingQuotasReport.field.quotaState.options.',
      ),
    }
  },

  /**
   * Add parameters to grid search (URE/UME)
   */
  addParamsToGridSearch: function (params, gridMode) {
    if (gridMode === 'URE') {
      params.locale = PREF('locale')
    }
  },
})

const ERS_FISHING_QUOTAS_REPORT_UI = new ErsFishingQuotasReportUI()

export default ErsFishingQuotasReportUI
