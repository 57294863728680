import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import Quota from 'core-uda/model/fishing/Quota'
import { CELL_TPL_PERCENT } from 'core-uda/ui/ResourceUI'
import template from 'thm/ui/common/quota/QuotaTpl.stache'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for Quota
 */
let QuotaUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: Quota.itemId,
      i18nPrefix: 'thm.ui.common.quota.QuotaUI.',
      icon: 'fishChart',
      template: template,
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      percAlarm: {
        cellTemplate: CELL_TPL_PERCENT,
      },
      quotaUsed: {
        cellTemplate: CELL_TPL_PERCENT,
      },
    }
  },
})

const QUOTA_UI = new QuotaUI()

export default QuotaUI
