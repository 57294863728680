import AlertDefinitionInfoPanel from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel'
import ChildStache from 'thm/ui/common/alertDefinition/AlertDefinitionFarFromFleetInfoPanelTpl.stache'

/**
 * @class AlertDefinitionFarFromFleetInfoPanel
 *  Alert Definition "Far From Fleet" Form.
 */
let AlertDefinitionFarFromFleetInfoPanel = AlertDefinitionInfoPanel.extend({
  id: 'alertDefinitionFarFromFleetInfoPanel',
  childTpl: ChildStache,
  timingAllowed: true,
})
export default AlertDefinitionFarFromFleetInfoPanel
