import GridPanel from 'core-uda/ui/grid/GridPanel'
import ThmColumnManagementPage from 'thm/ui/grid/ThmColumnManagementPage'

/**
 * @class GridPanel
 *
 * Panel contenant la grille,  barre d'outil grille, ....
 */
let ThmGridPanel = GridPanel.extend({
  /**
   * @override
   */
  onManage: function () {
    ThmColumnManagementPage.openPage()
  },
})

export default ThmGridPanel
