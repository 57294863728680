import Page from 'core-cmp/Page'
import _ from 'core-cmp/lodash'
import $ from 'core-cmp/cmp-jquery'
import Toastr from 'core-cmp/Toastr'
import { UDA_URL } from 'core-uda/model/ResourceDAO'
import template from 'thm/ui/common/settings/ReportTemplateUploadPageTpl.stache'
import 'thm/ui/common/settings/ReportTemplateUploadPage.css'
import ReportTemplate from 'thm/model/report/ReportTemplate'

let ReportTemplateUploadPage = Page.extend({
  template: template,
  i18nPrefix: ['thm.ui.settings.ReportTemplateUploadPage.'],
  id: 'reportTemplateUploadPage',
  routerType: 'popup',
  modal: false,
  popupWidth: 400,
  autoBindActions: true,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()

    me.viewModel.attr('reportTemplateOptions', [])
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    const me = this

    me._super()
    me._loadReportTemplateResources()
  },

  _loadReportTemplateResources: function () {
    const me = this

    return ReportTemplate.findBy({
      fields: 'id,name,resourceName',
    }).done((json) => {
      const options = []

      _.each(json, (item) => {
        const text = item.resourceName + ' / ' + item.name,
          newOption = {
            value: item.id,
            text: text,
            name: item.name,
            resourceName: item.resourceName,
          }

        // Filter report template removing duplicated entries (use most recent entry if 2 duplicated entries found)
        const option = _.find(options, { text })
        if (option) {
          if (+option.value < +newOption.value) {
            _.assign(option, newOption)
          }
        } else {
          options.push(newOption)
        }
      })
      me.viewModel.attr('reportTemplateOptions', options)
      if (options.length > 0) {
        me.viewModel.attr('reportTemplate', options[0].value)
      }
    })
  },

  onSubmit: function () {
    const me = this,
      id = me.viewModel.attr('reportTemplate'),
      entry = _.find(me.viewModel.attr('reportTemplateOptions').attr(), {
        value: id,
      }),
      files = me.viewModel.attr('file')

    if (_.isEmpty(files)) {
      return
    }

    const formData = new FormData(),
      file = files[0]

    formData.append('template', file)
    formData.append('name', entry.name)
    formData.append('resource', entry.resourceName)
    formData.append('id', id)

    const deferred = $.ajax({
      url: UDA_URL + 'reportTemplate', //application=umv&jwt=" + APP().user.token;,
      type: 'POST',
      data: formData,
      processData: false, // tell jQuery not to process the data
      contentType: false, // tell jQuery not to set contentType
    })

    me.listenStatus(deferred, 'submitStatus').done(() => {
      Toastr.showSuccess(me.msg('reportTemplateUpdated'), 4000)
      me._loadReportTemplateResources()
    })
  },
})

export default ReportTemplateUploadPage
