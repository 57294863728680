import InfoPanel from 'core-uda/ui/InfoPanel'
import template from 'thm/ui/common/userZoneType/UserZoneTypeInfoPanelTpl.stache'
import UserZoneType from 'core-zone/model/UserZoneType'
import { RIGHTS } from 'core-uda/Rights'

/**
 * Form for type of user zone
 * @class UserZoneTypeInfoPanel
 */
let UserZoneTypeInfoPanel = InfoPanel.extend({
  template: template,
  i18nPrefix: [
    'uda.userZoneType.',
    'thm.ui.common.userZoneType.UserZoneTypeInfoPanel.',
  ],
  id: 'userZoneTypeInfoPanel',
  entityId: UserZoneType.itemId,
  allowWidth: true,
  titleKey: 'title',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('showClient', RIGHTS('showClient'))
  },

  /**
   * @Override
   * @returns {String} full name of the customer
   */
  getName: function () {
    let me = this
    return me.data.customerCompany + ' - ' + me.data.name
  },
})

export default UserZoneTypeInfoPanel
