import { APP } from 'core-uda/model/Resource'
import RightPanel from 'core-uda/ui/rightPanel/RightPanel'
import ThmUmeMain from 'thm/ui/ume/ThmUmeMain'
import ThmUmvMain from 'thm/ui/umv/ThmUmvMain'
import ThmUreMain from 'thm/ui/ure/ThmUreMain'
import ThmDashboard from 'core-uda/ui/dashboard/UdaDashboard'
import UdaRouter from 'core-uda/ui/UdaRouter'
import { DATA_AUTHORIZED, RIGHTS } from 'core-uda/Rights'
import SarMessages from 'core-sar/ui/sarMessages/SarMessages'

/**
 * Thm Routeur
 */
let ThmRouter = UdaRouter.extend({
  pages: [
    {
      id: 'DASHBOARD',
      icon: 'home',
      titleKey: '_Dashboard',
      cmpClass: ThmDashboard,
      createOrder: 4,
      authorized: function () {
        if (!RIGHTS('dashboard.all')) {
          return false
        }
        return ThmDashboard.isAuthorized()
      },
    },
    {
      id: 'UMV',
      icon: 'earth',
      titleKey: '_Map',
      cmpClass: ThmUmvMain,
      createOrder: 1,
      authorized: function () {
        return true
      },
    },
    {
      id: 'UME',
      icon: 'config',
      titleKey: '_Management',
      cmpClass: ThmUmeMain,
      createOrder: 3,
      authorized: function () {
        if (!RIGHTS('ume.all')) {
          return false
        }
        return (
          !APP('resourcesPreferences').getForMode('UME').isAllInactive() ||
          DATA_AUTHORIZED('parameters')
        )
      },
    },
    {
      id: 'URE',
      icon: 'griddedData',
      titleKey: '_Reporting',
      cmpClass: ThmUreMain,
      createOrder: 2,
      authorized: function () {
        if (!RIGHTS('ure.all')) {
          return false
        }
        return (
          document.body.clientWidth > 600 &&
          (!APP('resourcesPreferences').getForMode('URE').isAllInactive() ||
            DATA_AUTHORIZED('parameters'))
        )
      },
    },
    {
      id: 'sarMessages',
      icon: 'mail',
      titleKey: '_Communication',
      cmpClass: SarMessages,
      createOrder: 5,
      authorized: function () {
        return document.body.clientWidth > 600 && SarMessages.authorized()
      },
    },
    {
      id: 'settingsPage',
      icon: 'user',
      titleKey: 'thm.ui.common.settings.SettingsPage.title',
      authorized: function () {
        return true
      },
      context: {
        showSettingsPage: true,
      },
    },
  ],

  bindEvents: function () {
    let me = this

    ThmUmvMain.get().$el.on('pageOpening', function () {
      if (me.currentPageId !== 'UMV') {
        me.currentPageId = 'UMV'
        APP().trigger('setMode', ['map'])

        me._closeRightPanel(false)
      }
    })

    ThmUmeMain.get().$el.on('pageOpening', function () {
      if (me.currentPageId !== 'UME') {
        me.currentPageId = 'UME'
        APP().trigger('setMode', ['grid'])

        let umePage = ThmUmeMain.get()

        APP().grid = umePage.gridElement
        APP().gridMode = umePage.gridMode
        APP().trigger(umePage.gridMode + 'pageChanged')
        APP().trigger(umePage.gridMode + 'resourcesPreferencesChanged')
        // We show the RightPanel but keep it closed,
        // selectFirstRowGrid event will show it if necessary
        RightPanel.get().show()
        me._closeRightPanel(true)
      }
    })

    ThmUreMain.get().$el.on('pageOpening', function () {
      if (me.currentPageId !== 'URE') {
        me.currentPageId = 'URE'
        APP().trigger('setMode', ['grid'])

        let urePage = ThmUreMain.get()

        APP().grid = urePage.gridElement
        APP().gridMode = urePage.gridMode
        APP().trigger(urePage.gridMode + 'pageChanged')
        APP().trigger(urePage.gridMode + 'resourcesPreferencesChanged')
        me._closeRightPanel(true)
      }
    })

    ThmDashboard.get().$el.on('pageOpening', function () {
      if (me.currentPageId !== 'DASHBOARD') {
        me.currentPageId = 'DASHBOARD'
        APP().trigger('setMode', ['dashboard'])

        // We close the RightPanel
        RightPanel.get().close(true, true)
      }
    })

    if (SarMessages.authorized()) {
      SarMessages.get().$el.on('pageOpening', function () {
        if (me.currentPageId !== 'sarMessages') {
          me.currentPageId = 'sarMessages'
          APP().trigger('setMode', ['grid'])

          // We close the RightPanel
          RightPanel.get().close(true, true)
        }
      })
    }
  },

  _closeRightPanel: function (gridMode) {
    // We close the RightPanel
    RightPanel.get()
      .close(true, true)
      .done(() => {
        RightPanel.get().show()
        // We set the gridMode of the Right Panel
        RightPanel.get().setGridMode(gridMode)
      })
  },
})

const THM_ROUTER = new ThmRouter()
UdaRouter.ROUTER = THM_ROUTER
const PAGE_ANIMATION = THM_ROUTER.pageAnimation

export { THM_ROUTER }
export { PAGE_ANIMATION }
export default ThmRouter
