import { canEvent } from 'core-cmp/can'
import { canAssign } from 'core-cmp/can'
import { APP } from 'core-uda/model/Resource'
import ListSelection from 'core-cmp/ListSelection'
import 'core-cmp/util/DateUtils'
import Toastr from 'core-cmp/Toastr'
import Centric from 'core-uda/model/centric/Centric'
import CriteriaCentric from 'core-mobile/model/centric/CriteriaCentric'
import RadarProductsCentric from 'core-radar/model/centric/RadarProductsCentric'
import RadarShipReportCentric from 'core-radar/model/centric/RadarShipReportCentric'
import RadarOilReportCentric from 'core-radar/model/centric/RadarOilReportCentric'
import Resource from 'core-uda/model/Resource'
import UDAApplication from 'core-uda/model/UDAApplication'
import { DATA_CONTEXT } from 'core-uda/model/DataContext'
import 'core-uda/model/mobile/MobileType'
import _ from 'core-cmp/lodash'
import { THM_PREF } from 'thm/ThmPreferences'
import Capabilities from 'core-uda/model/user/Capabilities'
import ThmMetocModel from 'thm/model/ThmMetocModel'
import ThmMultiMetocModel from 'thm/model/ThmMultiMetocModel'
import Exception from 'core-cmp/Exception'
import { GOOGLE_ANALYTICS } from 'core-uda/service/google/GoogleAnalytics'
import $ from 'thm/thm-jquery'
import ThmToastRequestListener from 'thm/ui/ThmToastRequestListener'
import ThmGridPositions from 'thm/model/grid/ThmGridPositions'
import GridSelection from 'core-mobile/model/GridSelections'
import PositionGridLegend from 'core-mobile/model/PositionGridLegend'
import MobilePlugin from 'core-mobile/MobilePlugin'
import FlagOnPositionPlugin from 'core-mobile/FlagOnPositionPlugin'
import RadarPlugin from 'core-radar/RadarPlugin'
import ZonePlugin from 'core-zone/ZonePlugin'
import AlertPlugin from 'core-alert/AlertPlugin'
import CDataPlugin from 'core-cdata/CDataPlugin'
import SensorPlugin from 'core-sensor/SensorPlugin'
import SarPlugin from 'core-sar/SarPlugin'
import CriteriaPlugin from 'core-criteria/CriteriaPlugin'
import MeteoStationRecordPlugin from 'thm/model/meteoStationRecord/MeteoStationRecordPlugin'
import ThmZoneHandler from 'thm/service/info/ThmZoneHandler'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import Dashboard from 'core-uda/ui/dashboard/UdaDashboard'
import { PREF } from 'core-cmp/Preferences'
import FlagOnPosition from 'core-uda/model/customerObject/FlagOnPosition'
import { MAIN_PAGE } from 'core-uda/ui/UdaMainPage'
import ClusterHandler from 'core-mobile/service/info/ClusterHandler'
import MobileLineHandler from 'core-mobile/service/info/MobileLineHandler'
import ThmMobilePositionHandler from 'thm/service/info/ThmMobilePositionHandler'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'
import { I18N } from 'core-cmp/util/I18n'
import SarOperationModel from 'core-sar/model/sarOperation/SarOperationModel'
import SarOperationLogModel from 'core-sar/model/sarOperationLog/SarOperationLogModel'
import InformationPopup from 'core-cmp/msg/InformationPopup'

// STYLES
import BourbonStyleEngine from 'thm/service/style/ThmBourbonStyleEngine'
import FishingStyleEngine from 'thm/service/style/ThmFishingStyleEngine'
import ThmTrackingStyleEngine from 'thm/service/style/ThmTrackingStyleEngine'
import AnimalStyleEngine from 'thm/service/style/AnimalStyleEngine'
import BourbonStyleInfoPanel from 'thm/ui/style/ThmBourbonStyleInfoPanel'
import FishingStyleInfoPanel from 'thm/ui/style/ThmFishingStyleInfoPanel'
import TrackingStyleInfoPanel from 'thm/ui/style/ThmTrackingStyleInfoPanel'
import AnimalStyleInfoPanel from 'thm/ui/umv/style/AnimalStyleInfoPanel'

// DATA_CONTEXT Resources
import Position from 'core-uda/model/position/Position'
import RadarProduct from 'core-radar/model/RadarProduct'
import SensorMessage from 'core-sensor/model/SensorMessage'
import Alert from 'core-alert/model/Alert'
import CollectedData from 'core-cdata/model/CollectedData'
import SarAlert from 'core-sar/model/sarAlert/SarAlert'
import SarOperation from 'core-sar/model/sarOperation/SarOperation'
import MobileSRUnit from 'core-sar/model/mobileSRUnit/MobileSRUnit'
import SarSad from 'core-sar/model/sarSad/SarSad'
import SarSac from 'core-sar/model/sarSac/SarSac'
import SarDrift from 'core-sar/model/sarDrift/SarDrift'
import SarSruWork from 'core-sar/model/sarSruWork/SarSruWork'

import { METOC_LEGEND_DEFAULTS } from 'core-metoc/ui/wc/MetocLegendVM'

import 'thm/ui/common/quota/Quota.css' // Pour le tpl d'un critère de ersStatistique : VesselTplStache

// Do not remove. Required for Page.get("xxx").
import ThmSaveZonePopup from 'thm/ui/common/zone/ThmSaveZonePopup' //NOSONAR
import AddPositionPopup from 'core-mobile/ui/AddPositionPopup' //NOSONAR
import AlertAckPopup from 'core-alert/ui/alert/AlertAckPopup' //NOSONAR
import SendCommandPage from 'core-mobile/ui/command/SendCommandPage' //NOSONAR

import Log from 'core-cmp/util/Log'
import GeoTiffPlugin from 'core-geotiff/GeoTiffPlugin'

// Logger for module "dao"
const log = Log.get('thm')

// Show METOCLegend date
METOC_LEGEND_DEFAULTS['showDate'] = true

/**
 * Application THM
 */

let ThmApplication = UDAApplication.extend({
  applicationTitle: 'Map',
  exportApplication: 'umv',
  firstLoaded: true,
  idbName: 'thm',
  idbVersion: 1,
  useJwtToken: true,

  // FIX : TO DELETE
  mobileFleetSelection: new ListSelection({}),

  collectedDatasActivated: true,
  alertsActivated: true,
  sensorMessagesActivated: true,

  dynamicGridCount: true,
  newRequest: true,
  firstLoad: true,
  bottom: { from: 'map', prevNext: false } /* !!!! NE PAS SUPPRIMER !!!! */,
  views: [],
  multiModel: new ThmMultiMetocModel(),
  metocModel: new ThmMetocModel(),
  bigDataMode: {},
  currentPref: null,
  messagesCount: 0,
  newUser: false, //décrit si l'utilisateur est nouveau
  chartModels: {
    user: [],
    customer: [],
  },
  beaconsDelete: [],
  palettes: [],
  starts: [],
  allowAnimationMode: true, // Permet d'afficher le bouton animation
  animationMode: false, // Permet d'afficher le bouton animation
  RESOURCES_ICONS: {
    alert: 'alert',
    alertDefinition: 'alert',
    applicationFunction: 'info',
    applicationRole: 'info',
    auditLog: 'duplicate',
    beacon: 'beacon',
    beaconModel: 'beacon',
    collectedData: 'database',
    command: 'command',
    commandLog: 'command',
    commands: 'command',
    customer: 'company',
    customerObject: 'user',
    ersActivity: 'fishing',
    ersAlertReport: 'alert',
    ersAuditReport: 'annotation',
    ersData: 'database',
    ersQuery: 'command',
    ersStatistic: 'chart',
    ersTrip: 'route',
    ersFishingQuotasReport: 'licence',
    ersMissingDeclarationReport: 'estimatedPosition',
    fishingGear: 'fishHook',
    flag: 'flag',
    fleet: 'fleet',
    folder: 'annotation',
    licence: 'licence',
    message: 'mail',
    mobile: 'mobile',
    mobileImage: 'info',
    mobileInfoReport: 'mobileLabel',
    mobileLifeEvent: 'mobileEvent',
    mobileLifeEventType: 'mobileEventLabel',
    mobileType: 'mobileLabel',
    parameter: 'config',
    portType: 'portLabel',
    position: 'position',
    positionSource: 'satellite',
    preference: 'star',
    radarEcho: 'radar',
    radarImage: 'radar',
    radarProduct: 'radar',
    radarProductOil: 'radar',
    radarProductShip: 'radar',
    radarProject: 'radar',
    radarShipReport: 'radar',
    recipient: 'mail',
    request: 'info',
    sensorMessage: 'heading',
    sensorType: 'heading',
    shipowner: 'boatAnchor',
    species: 'fish',
    statusCode: 'status',
    statusData: 'data',
    surveillance: 'camera',
    trip: 'route',
    user: 'user',
    userObject: 'user',
    userZoneType: 'zoneLabel',
    vesselType: 'mobile',
    zone: 'zones',
    zoneType: 'zoneLabel',
    satProvider: 'satellite',
    source: 'satellite',
    fmc: 'fmc',
    lesInmc: 'station',
    fishingProtocol: 'fishing',
    genericMobile: 'mobile',
    quota: 'licence',
    sarSRUnit: 'buoy',
    sarAlert: 'alert',
    manualImport: 'import',
  },

  initializeResources: function () {},

  init: function () {
    let me = this

    me.initializeResources()

    me._super()

    // Register pluggins
    me.registerPlugin(
      new MobilePlugin({
        dashboardWidgets: [
          MobilePlugin.MAIL_WIDGET,
          MobilePlugin.MOBILE_WIDGET,
          MobilePlugin.MOBILE_NO_REPORT_WIDGET,
          MobilePlugin.MOBILE_INTEGRATION,
          MobilePlugin.MOBILE_ASSISTANCE,
          MobilePlugin.MOBILE_LOW_BATTERY,
          MobilePlugin.MOBILE_AIS_REPORT,
        ],
        mapGrids: [ThmGridPositions, GridSelection],
        infoHandlers: [
          ClusterHandler,
          MobileLineHandler,
          ThmMobilePositionHandler,
        ],
        styleModels: [
          BourbonStyleEngine,
          FishingStyleEngine,
          ThmTrackingStyleEngine,
          AnimalStyleEngine,
        ],
        stylePanels: [
          BourbonStyleInfoPanel,
          FishingStyleInfoPanel,
          TrackingStyleInfoPanel,
          AnimalStyleInfoPanel,
        ],
      }),
    )
    me.registerPlugin(new RadarPlugin())
    me.registerPlugin(
      new ZonePlugin({
        infoHandlers: [ThmZoneHandler],
      }),
    )
    me.registerPlugin(
      new AlertPlugin({
        dashboardWidgets: [
          AlertPlugin.ALERT_WIDGET,
          AlertPlugin.BEACON_WIDGET,
          AlertPlugin.HERDS_WIDGET,
        ],
      }),
    )
    me.registerPlugin(
      new CDataPlugin({
        dashboardWidgets: [CDataPlugin.CDATA_WIDGET],
      }),
    )
    me.registerPlugin(new SensorPlugin())
    // me.registerPlugin(new DriftPlugin());
    me.registerPlugin(
      new SarPlugin({
        dashboardWidgets: [
          // SarPlugin.SAR_ALERT_MONTH_WIDGET,
          SarPlugin.SAR_CHART_WIDGET,
          SarPlugin.SAR_ALERT_IDENT_TYPE_WIDGET,
          SarPlugin.SAR_ALERT_MSG_TYPE_WIDGET,
          SarPlugin.MESSAGE_MONTH_WIDGET,
          SarPlugin.MESSAGE_SENDER_WIDGET,
          SarPlugin.SAR_SR_UNITS_WIDGET,
        ],
      }),
    )

    CriteriaPlugin.CRITERIAS.selectBy = {
      cmpClass: ThmSelectByCriteria,
    }
    me.registerPlugin(new CriteriaPlugin())
    me.registerPlugin(new FlagOnPositionPlugin())

    me.registerPlugin(new MeteoStationRecordPlugin())
    me.registerPlugin(new GeoTiffPlugin())

    Centric.DATA_SOURCES = [
      'gis:mapView',
      'page:mobileRequestPage',
      'data:positions',
      'data:trajectories',
      'data:radarProduct',
      'data:radarEcho',
    ]
  },

  /**
   * @override
   */
  upgradeDB: function (db, event) {
    const me = this

    switch (event.oldVersion) {
      case 0:
        // Version 0 means that the client had no database
        // V1.0 stores
        log.info('IndexedDB schema updating to V1.0 ...')

        db.createObjectStore('zoneGeometries', { keyPath: 'id' })

      default:
        log.info('IndexedDB schema uptodate.')
    }
  },

  /**
   * @override
   */
  onLoginSuccess: function () {
    let me = this

    me._super()

    // Load centric after loding rights !
    me.registerCentric(CriteriaCentric.INSTANCE)
    me.registerCentric(RadarProductsCentric.INSTANCE)
    me.registerCentric(RadarShipReportCentric.INSTANCE)
    me.registerCentric(RadarOilReportCentric.INSTANCE)

    // Défini les ressources à chargé lors du changement de contexte :
    // - changements des critères utilisateur.
    // - changement de centric
    DATA_CONTEXT.addMainResource(Position)
    if (DATA_AUTHORIZED('radarProduct')) {
      DATA_CONTEXT.addMainResource(RadarProduct)
    }
    DATA_CONTEXT.addChildResource(Position, Alert)
    DATA_CONTEXT.addChildResource(Position, CollectedData)
    DATA_CONTEXT.addChildResource(Position, SensorMessage)

    if (DATA_AUTHORIZED('searchAndRescue')) {
      if (RIGHTS('RCC')) {
        DATA_CONTEXT.addMainResource(SarAlert)
        DATA_CONTEXT.addChildResource(SarAlert, SarOperation)
        DATA_CONTEXT.addChildResource(SarAlert, MobileSRUnit)
      } else {
        DATA_CONTEXT.addMainResource(SarOperation)
        DATA_CONTEXT.addMainResource(MobileSRUnit)
      }

      // SAR Childs
      DATA_CONTEXT.addChildResource(SarOperation, SarSad)
      DATA_CONTEXT.addChildResource(SarSad, SarSac)
      DATA_CONTEXT.addChildResource(SarSad, SarDrift)
      DATA_CONTEXT.addChildResource(SarSac, SarSruWork)

      me.setModel(new SarOperationModel(), true)
      me.setModel(new SarOperationLogModel(), true)
    }
  },

  /**
   * Current page is map or not set and will be map.
   * @returns {boolean}
   */
  isWillBeMap: function () {
    const me = this,
      router = me.currentPageId || PREF().umvStart.toUpperCase()

    return router === 'UMV' || _.isNil(router)
  },

  bindEvents: function () {
    let me = this

    //MAJ des messages non lus
    me.on('updateMessagesCount', function (ev, data) {
      // FIXME : MAIN_PAGE is a forbiden reference ti UI component from model
      // => to replace by event
      if (MAIN_PAGE() && !!MAIN_PAGE().leftMenu) {
        let msgTooltip = MAIN_PAGE().leftMenu.getButtonViewModel('message')[0]
        if (me.messages && me.messages.length > 0) {
          let countNoRead = 0
          _.each(
            APP().models['messages'].mobileMessagesArray,
            function (mobileMessages) {
              countNoRead += mobileMessages.getCountNoReadMsg()
            },
          )
          me.messagesCount = countNoRead
          if (msgTooltip) {
            msgTooltip.attr('tooltipIcon', me.messagesCount)
          }
        } else {
          if (msgTooltip) {
            msgTooltip.attr('tooltipIcon', false)
          }
        }
      } else {
        setTimeout(function () {
          me.trigger('updateMessagesCount')
        }, 2000)
      }
    })

    me.on('updateRadarProductsStatus', function (ev, data) {
      me._onRadarProductRequestDone(data)
    })

    new ThmToastRequestListener().listen(DATA_CONTEXT)

    me.on('tooManyMobiles', function (ev, data) {
      me._onMobileRequestFail(data)
    })

    // Add an event to show/hide the loader when a new search is launched on the map
    DATA_CONTEXT.on('searchStart', () => {
      if (log.isDebug) {
        log.debug(
          `ThmApplication receives DATA_CONTEXT "searchStart" event (isWillBemap = ${APP().isWillBeMap()})`,
        )
      }

      if (APP().isWillBeMap()) {
        if (log.isDebug) {
          log.debug(
            `ThmApplication trigger "showLoader - true" event (DATA_CONTEXT.showLoader=true)`,
          )
        }

        // DATA_CONTEXT.showLoader : Detect if :
        // - t0 : Map active, new request => loader diplayed
        // - t1 : close map (ex: open management) during request
        // - t2 : end of request but hide loder cancel because map not active
        DATA_CONTEXT.showLoader = true
        APP().trigger('showLoader', [true, true])
      }
    })

    DATA_CONTEXT.on('searchEnd', () => {
      if (log.isDebug) {
        log.debug(`ThmApplication receives DATA_CONTEXT "searchEnd" event`)
      }

      MAIN_PAGE().displayButtons()
      // DATA_CONTEXT.showLoader : Detect if :
      // - t0 : Map active, new request => loader diplayed
      // - t1 : close map (ex: open management) during request
      // - t2 : end of request but hide loder cancel because map not active
      if (APP().isMap() || DATA_CONTEXT.showLoader) {
        if (log.isDebug) {
          log.debug(
            `ThmApplication trigger "showLoader - false" event (DATA_CONTEXT.showLoader=true)`,
          )
        }

        DATA_CONTEXT.showLoader = false
        APP().trigger('showLoader', [false])
      }
    })

    me.on('beforeDataChanged', function (ev, modelId, newRequest) {
      if (modelId === 'positions') {
        if (!newRequest) {
          return
        }
        MAIN_PAGE().displayButtons()

        // On ferme la fenêtre info car si la position que l'on est en train de consulter
        // n'est pas dans les nouvelles positions receuillies, cela pose un problème
        if (_.get(MAIN_PAGE(), 'infoPanel.infoHandler.isMobileData')) {
          MAIN_PAGE().infoPanel.onClose()
        }
      }
    })

    me.on('trajectoriesReplaced', (ev, data, context) => {
      const isStartup = !!_.get(context, 'parentContext.isStartup'),
        hasDefaultMapView = !_.isEmpty(PREF('defaultMapView'))

      //Sécurité
      if (!RIGHTS('afterSearch.zoomAndCenter')) {
        THM_PREF.set('zoomAndCenter', false)
        return
      }

      // Default map view is more important than zoomAnsCenter (first request only)
      if (THM_PREF.zoomAndCenter && !(isStartup && hasDefaultMapView)) {
        APP('trajectories').zoomToMobiles()
      }
    })

    // TODO : check if necessary
    me.on('radarEchoRequestDone', function (ev, data) {
      me._onRadarEchoRequestDone(data)
    })

    // When a fleet used by criteria is modified, update data.
    me.on('fleetChanged', (ev, action, item, id) => {
      let lastParams = DATA_CONTEXT.getLastParams(),
        selectBy

      if (!lastParams) {
        return
      }
      selectBy = lastParams.selectBy

      if (
        action === 'update' &&
        selectBy &&
        selectBy.indexOf('fleet') !== -1 &&
        selectBy.indexOf(id) !== -1
      ) {
        DATA_CONTEXT.find(lastParams)
      }
    })

    THM_PREF.on('estimatedPositionsValue', function (ev, newVal, oldVal) {
      // Au démarrage (initialisation des pref) Il ne faut pas ca déclencher cet événement
      if (APP().getModelSize('trajectories') && newVal !== oldVal) {
        // FIXME JC : Ca relance le cluster alors que pas nécessaire + c'est pas beau de se faire passer pour une DAO !
        APP().trigger('trajectoriesLoaded')
      }
    })

    THM_PREF.on('udaError', function (ev, e) {
      let code = e.status
      if (!!code && code === 401) {
        APP().closeApplication()
      }
    })

    THM_PREF.on('udaFailed', function (ev, err, method) {
      Exception.throwRequestException('preference', method, err)
    })

    me.on('infiniteTimeoutChanged', me.infiniteTimeoutHandler)

    // When aisTraffic changed (the user change this value to hide or show AIS traffic) change the DAO constant.
    // This constant is used by some DAO (Ex : MobileDAO.findOne)
    PREF().on('aisTraffic', () => {
      Resource.BIG_DATA_MODE = me.getAISTrafficMode()
    })

    FlagOnPosition.register()
  },

  /**
   * Gestion du timeout infini
   */
  infiniteTimeoutHandler: function () {
    let me = this
    if (PREF('infiniteTimeout')) {
      if (!me.refreshTokenInterval) {
        me.setInfiniteTimeoutForToken(APP().user.token)
      }
    } else {
      if (me.refreshTokenInterval) {
        clearInterval(me.refreshTokenInterval)
        delete me.refreshTokenInterval
      }
    }
  },

  /**
   * Indique si l'application à accès aux données de traffique AIS.
   * @returns {Boolean} accès aux données de traffique AIS.
   */
  hasAisTraffic: function () {
    let traffic = RIGHTS('position.aisTraffic')

    traffic = traffic ? traffic : false

    return RIGHTS('position.aisFields') && traffic && traffic.length > 0
  },

  /**
   * Indique si le mode AIS est forcé à "default" ou "traffic" par UDA.
   * @returns {boolean} état.
   */
  isAisTrafficModeForced: function () {
    let traffic = RIGHTS('position.aisTraffic')

    return !!traffic && traffic.length === 1
  },

  /**
   * Retourne le mode AIS courant.
   * @return {string} mode.
   **/
  getAISTrafficMode: function () {
    let me = this,
      traffic = RIGHTS('position.aisTraffic'),
      trafficPref = $.isTrue(THM_PREF.aisTraffic)

    if (!me.hasAisTraffic()) {
      return null
    }
    if (traffic.length === 1) {
      return traffic[0]
    }
    return trafficPref ? 'traffic' : 'default'
  },

  /**
   * Evènement déclenché quand la requête des mobiles a failli
   */
  _onMobileRequestFail: function (response) {
    Toastr.showToastr(
      'error',
      'tooManyMobiles',
      RIGHTS('data.mobile.maxResult'),
    )
  },

  onMapLoaded: function (zoom) {
    let me = this

    me.mobileStatLegend = new PositionGridLegend()

    try {
      let critSettings = JSON.parse(PREF('criteriaSettings'))
      APP().datalakeActive =
        DATA_AUTHORIZED('datalake') &&
        !!critSettings?.datalake &&
        APP().isBigDataMode()
    } catch (error) {
      APP().datalakeActive = false
    }

    me.whenData('favoriteRequests').done(() => {
      let defaultFavoriteRequest =
          APP('favoriteRequest').findDefaultRequestForMap(),
        params = defaultFavoriteRequest || DATA_CONTEXT.getDefaultParams()

      if (defaultFavoriteRequest) {
        params = defaultFavoriteRequest.value.attr
          ? defaultFavoriteRequest.value.attr()
          : defaultFavoriteRequest.value
      }

      params.dateType = 'location'
      delete params.format

      /* HACK ARGOS */
      if (me.getUdaService('ARGOS')) {
        delete params.maxPositions
      }

      // TODO : A déplacer dans UI
      MAIN_PAGE().displayButtons()

      if (me.hasAllMobiles) {
        if (log.isDebug) {
          log.debug(
            `ThmApplication.onMapLoaded trigger "showLoader - true" event`,
          )
        }

        // Show loaded until mobiles loaded
        APP().trigger('showLoader', [true])
        me.whenData('mobiles').done(() => {
          if (log.isDebug) {
            log.debug(
              `ThmApplication receives mobiles "loaded" event and trigger "showLoader - false" event`,
            )
          }
          APP().trigger('showLoader', [false])

          DATA_CONTEXT.find({
            isStartup: true,
            params: params,
            zoom: zoom,
          })
        })
      } else {
        DATA_CONTEXT.find({
          isStartup: true,
          params: params,
          zoom: zoom,
        })
      }
    })

    me._super()

    me.metocModel.centerZoomFactor = RIGHTS('map.layers.zoomFactor', 1)
    me.multiModel.createBasicLayers()
    me.multiModel.loadDatas()
    me.metocModel.loadDatas()
    me.infiniteTimeoutHandler()
  },

  /**
   *  Renvoie boolean si on demande un certain  udaService
   * ex: je veux savoir si je suis connecté à THEMIS ? getUdaService("THEMIS") : oui/non
   *  Si on fixe pas ça nous renvoie le udaService
   */
  getUdaService: function (udaService) {
    let me = this

    if (!!udaService) {
      return udaService === me.capabilities.enums.udaService
    }

    return me.capabilities.enums.udaService
  },

  /**
   *  Renvoie boolean si on demande un certain  udaService
   * ex: je veux savoir si je suis connecté à THEMIS ? getUdaService("THEMIS") : oui/non
   *  Si on fixe pas ça nous renvoie le udaService
   */
  getMetocModel: function () {
    let me = this

    if (RIGHTS('map.metoc.isMultiModel', true)) {
      return me.multiModel
    } else {
      return me.metocModel
    }
  },

  /**
   * Permet d'obtenir la couleur d'une icone selon son status
   * @param validationStatus
   * @returns {string}
   */
  getColorAlertValidationStatus: function (validationStatus) {
    let me = this,
      color = 'black'
    if (validationStatus === 'open') {
      color = 'red'
    } else if (validationStatus === 'processing') {
      color = 'yellow'
    }
    return color
  },

  hasAlerts: function () {
    let me = this
    return (
      $.isTrue(THM_PREF.alertsActivated) &&
      me.getCurrentCentricId() === 'criteriaCentric' &&
      DATA_AUTHORIZED('alert')
    )
  },

  hasCollectedDatas: function () {
    let me = this
    return (
      $.isTrue(THM_PREF.collectedDatasActivated) &&
      me.getCurrentCentricId() === 'criteriaCentric' &&
      DATA_AUTHORIZED('collectedData')
    )
  },

  hasSensorMessages: function () {
    let me = this
    return (
      $.isTrue(THM_PREF.sensorMessagesActivated) &&
      me.getCurrentCentricId() === 'criteriaCentric' &&
      DATA_AUTHORIZED('sensorMessage')
    )
  },

  hasRadarProducts: function () {
    let me = this
    return (
      ($.isTrue(THM_PREF.radarsActivated) ||
        me.getCurrentCentricId() !== 'criteriaCentric') &&
      DATA_AUTHORIZED('radarProduct')
    )
  },

  hasRadarEchos: function () {
    let me = this
    return me.hasRadarProducts()
  },

  isSarAlertAuthorized: function () {
    return DATA_AUTHORIZED('searchAndRescue', 'RCC')
  },

  getSruMobileTypes: function () {
    return [
      {
        value: '1',
        text: I18N.msg('core-sar.sru.units.types.' + 1),
        icon: 'vessel',
      },
      {
        value: '2',
        text: I18N.msg('core-sar.sru.units.types.' + 2),
        icon: 'helicopter',
      },
      {
        value: '3',
        text: I18N.msg('core-sar.sru.units.types.' + 3),
        icon: 'plane',
      },
    ]
  },

  setGoogleAnalytics: function () {
    let me = this
    // ga est une variable globale qu'on va supprimer et mettre en privé
    // TODO ga undefined in production
    try {
      GOOGLE_ANALYTICS.setService(ga)
    } catch (e) {
      console.error('Failed to initialize Google Analytics ')
      console.error('  - ' + e)
    }
  },

  loadUserInfo: function (appId) {
    let me = this

    me._super(appId)

    Resource.BIG_DATA_MODE = me.getAISTrafficMode()
    Alert.POSITION_ID = Resource.BIG_DATA_MODE ? 'externalId' : 'id'
    me.whenData('customers').done(() => {
      Alert.POSITION_ID = me.isBigDataMode() ? 'id' : Alert.POSITION_ID
    })
  },

  isStartChoiceAuthorized: function () {
    return Dashboard.isAuthorized()
  },

  /**
   * @override
   * @returns {boolean}
   */
  isDefaultMapRequestEditable: function () {
    return true
  },

  /**
   * @override
   * @returns {boolean}
   */
  isDefaultMapViewEditable: function () {
    return true
  },

  /**
   * Set rights of application
   * @param {object} rights - rights.
   */
  setRights: function (rights) {
    let me = this

    // DEV : To force "star measures" tool
    //rights.role.umv.tools.starMeasure = { authorized: true }

    // DEV : To force "tag" tool
    //rights.role.umv.tools.tag = { authorized: true }

    if (rights.center?.data) {
      rights.center.data.searchAndRescue = rights.center.data.sarSRUnits
      delete rights.center.data.sarSRUnits
    }

    if (rights.role?.data) {
      rights.role.data.searchAndRescue = rights.role.data.sarSRUnits
      delete rights.role.data.sarSRUnits
    }

    if (_.get(rights, 'customer.data.sarSRUnits')) {
      rights.customer.data.searchAndRescue = rights.customer.data.sarSRUnits
      delete rights.customer.data.sarSRUnits
    }

    me._super(rights)
  },

  /**
   * Set capabilities of application
   * @param {object} capabilities - capabilities.
   */
  setCapabilities: function (capabilities) {
    let me = this,
      msg = { clean: [], patch: [] }

    Capabilities.patchChangeFormater(
      'THM-9151',
      msg,
      capabilities,
      'ersActivity',
      'position',
      'position',
    )
    Capabilities.patchChangeType(
      'THM-9151',
      msg,
      capabilities,
      'ersActivity',
      'position',
      'object',
    )

    //Capabilities.patchChangeType("THM-9305", msg, capabilities, "mobile", "lastStatArea1");
    //Capabilities.patchChangeType("THM-9305", msg, capabilities, "mobile", "lastStatArea2");
    //Capabilities.patchChangeType("THM-9305", msg, capabilities, "mobile", "lastStatArea3");

    Capabilities.patchAddManyToMany(
      'THM-10023',
      msg,
      capabilities,
      'alertDefinition',
      'notifyRecipients',
      'recipient',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'alert',
      'customerId',
      'customer',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'alert',
      'activeBeaconId',
      'beacon',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'alert',
      'ersTripId',
      'ersTrip',
    )
    Capabilities.patchDateType(
      'THM-10023',
      msg,
      capabilities,
      'quota',
      'lastComputed',
    )
    Capabilities.patchDateType(
      'THM-10023',
      msg,
      capabilities,
      'radarGeoImage',
      'versionPeriod',
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'extraDataGross',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'thirdPartOperator',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'commercialOperator',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'beneficialOwner',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'registeredOwner',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'technicalManager',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'fishingGears',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'fishingGearsNameList',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'fishingGearsCodeList',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'shipownersNameList',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'beacons',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'licences',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'licenceList',
      true,
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'mobile',
      'activeBeaconId',
      'beacon',
    )

    // Capabilities.patchAddManyToOne("THM-10023", msg,  capabilities, "mobile", "imageId", "mobileImage");

    Capabilities.patchChangeDeprecated(
      'THM-10023',
      msg,
      capabilities,
      'customer',
      'webBackground',
      true,
    )
    Capabilities.patchChangeDeprecated(
      'THM-10023',
      msg,
      capabilities,
      'customer',
      'webCustomized',
      true,
    )
    Capabilities.patchChangeDeprecated(
      'THM-10023',
      msg,
      capabilities,
      'customer',
      'webTheme',
      true,
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'customer',
      'aisRestrictionSet',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'customer',
      'parameters',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'customer',
      'aisSources',
      true,
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'user',
      'authorizedMobiles',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'user',
      'restriction',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'user',
      'authorizedZones',
      true,
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'customerId',
      'customer',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'lesInmcId',
      'lesInmc',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'beaconTypeId',
      'beaconModel',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'satelliteProviderId',
      'satProvider',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'activityFamily',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'modems',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'beacon',
      'dailyReports',
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'satProvider',
      'beaconTypes',
      true,
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'fleet',
      'customerId',
      'customer',
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'fleet',
      'mobiles',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'fleet',
      'criteria',
      true,
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'shipowners',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'fishingGears',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'accessorySpecies',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'authorizedSpecies',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'forbiddenSpecies',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'zones',
      true,
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'portId',
      'zone',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'licence',
      'fishingProtocolId',
      'fishingProtocol',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'quota',
      'licenceId',
      'licence',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'fishingProtocol',
      'customerId',
      'customer',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'fmc',
      'customerId',
      'customer',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'fmc',
      'customerId',
      'customer',
    )

    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'species',
      'unit',
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'alertDefinition',
      'mobiles',
      true,
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'alertDefinition',
      'comparator',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpc',
      'flagId',
      'flag',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpc',
      'customerId',
      'customer',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpc',
      'cpcProtocolId',
      'cpcProtocol',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcCondition',
      'customerId',
      'customer',
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'cpcCondition',
      'mobiles',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'cpcCondition',
      'zones',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'cpcCondition',
      'fleets',
      true,
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcFormat',
      'customerId',
      'customer',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'cpcFormat',
      'fromOverride',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcProtocol',
      'customerId',
      'customer',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'command',
      'mobileActiveBeaconId',
      'beacon',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'command',
      'modemId',
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'position',
      'sensorDatas',
      true,
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'position',
      'activeBeaconProviderId',
      'satProvider',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'mobileLifeEvent',
      'customerId',
      'customer',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'mobileLifeEvent',
      'eventTypeId',
      'mobileLifeEventType',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'mobileLifeEvent',
      'activeBeaconProviderId',
      'satProvider',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcPostData',
      'customerId',
      'customer',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcPostData',
      'userId',
      'user',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcPostData',
      'activeBeaconProviderId',
      'satProvider',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcPostData',
      'cpcId',
      'cpc',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'cpcPostData',
      'cpcProtocolId',
      'cpcProtocol',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'alert',
      'activeBeaconProviderId',
      'satProvider',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersAlertReport',
      'vesselId',
      'mobile',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersData',
      'vesselId',
      'mobile',
    )
    Capabilities.patchRenameField(
      'THM-10023',
      msg,
      capabilities,
      'ersData',
      'dateModif ',
      'modificationDate',
    )
    Capabilities.patchRenameField(
      'THM-10023',
      msg,
      capabilities,
      'ersData',
      'userModif ',
      'modificationUser',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersStatistic',
      'speciesCatId',
      'speciesCat',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersStatistic',
      'vesselId',
      'mobile',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersStatistic',
      'gearId',
      'fishingGear',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersStatistic',
      'tripId',
      'ersTrip',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersTrip',
      'openActivityId',
      'ersActivity',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersTrip',
      'closeActivityId',
      'ersActivity',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'fishingTime',
      'mobileId',
      'mobile',
    )

    Capabilities.patchRenameField(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'gearAvgLength',
      'gearAverageLength',
    )
    Capabilities.patchRenameField(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'gearAvgHeight',
      'gearAverageHeight',
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'catchDetail',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'detail',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'detailErsData',
      true,
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'subActivities',
      true,
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'mainActivityId',
      'ersActivity',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'zoneZeeId',
      'zone',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'mobileDonorId',
      'mobile',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersActivity',
      'mobileRecieverId',
      'mobile',
    )

    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersQuery',
      'responseDetail',
      true,
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'activeBeaconId',
      'beacon',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'activeBeaconProviderId',
      'satProvider',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'customerId',
      'customer',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'itineraryId',
      'zone',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'mobileLicenceIssueDateMillis',
    )
    Capabilities.patchDeleteField(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'mobileLicenceEndDateMillis',
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'trip',
      'mobileHasValidLicence',
      true,
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersMissingDeclarationReport',
      'tripId',
      'ersTrip',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersMissingDeclarationReport',
      'vesselId',
      'mobile',
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersFishingQuotasReport',
      'eezZoneId',
      'zone',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersFishingQuotasReport',
      'faoZoneId',
      'zone',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersFishingQuotasReport',
      'licenceId',
      'licence',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersFishingQuotasReport',
      'tripId',
      'ersTrip',
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersFishingQuotasReport',
      'speciesDetail',
      true,
    )

    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersAuditReport',
      'auditSpeciesCatId',
      'speciesCat',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersAuditReport',
      'vesselId',
      'mobile',
    )
    Capabilities.patchAddManyToOne(
      'THM-10023',
      msg,
      capabilities,
      'ersAuditReport',
      'tripId',
      'ersTrip',
    )
    Capabilities.patchChangeCostly(
      'THM-10023',
      msg,
      capabilities,
      'ersAuditReport',
      'auditData',
      true,
    )

    // ersAuditReport

    // Duplicated relation ! Already done by licence.vesselId.manyToOne = "mobile" and mobile.id.oneToMany.licence = "vesselsId"
    Capabilities.patchDeleteOneToMany(
      'XXX',
      msg,
      capabilities,
      'licence',
      'mobile',
    )
    Capabilities.patchDeleteManyToOne(
      'XXX',
      msg,
      capabilities,
      'mobile',
      'licences',
    )
    msg.patch.sort()
    console.warn(msg.patch.join('\n'))

    msg.clean.sort()
    console.warn(msg.clean.join('\n'))

    me._super(capabilities)

    // Validate Fleet criterias
    APP().on('fleetsLoaded', () => {
      APP('fleet').each((f) => {
        let criteriaList = f.findInvalidCriteriaList()
        if (criteriaList.length > 0) {
          log.warn(`Fleet "${f.name}" contains invalid criteria definitions :`)
          _.each(criteriaList, (c) => {
            log.warn(`  * ${c.join} "${c.field}" ${c.operator} "${c.value}"`)
          })
        }
      })
    })
  },

  getCriteriasByResource: function () {
    let me = this

    return {}
  },

  /**
   * Effectue un proxy de la fonction en argument en utilisant le composant comme contexte.
   * @param {function} fn : fonction
   * @returns {function} : fonction proxifiée
   */
  proxy: function (fn) {
    return $.proxy(fn, this)
  },

  /**
   * @override
   */
  closeApplication: function () {
    let me = this

    _.delay(() => {
      InformationPopup.openPage({
        titleKey: 'disconnectionTitle',
        textKey: 'disconnectionText',
        validateKey: 'returnToLogin',
        onClose: function () {
          me.disconnect()
        },
      })
    }, 500)
  },

  setHeaderToken: function (token) {
    let me = this

    me._super(token)
    if (!!APP() && !!APP().user) {
      APP().user.token = token
    }
  },
})

canAssign(ThmApplication.prototype, canEvent)

export default ThmApplication
