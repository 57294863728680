import { APP } from 'core-uda/model/Resource'
import { THM_PREF } from 'thm/ThmPreferences'
import { DATA_AUTHORIZED } from 'core-uda/Rights'
import Toastr from 'core-cmp/Toastr'
import AlertDefinitionInfoPanel from 'thm/ui/common/alertDefinition/AlertDefinitionInfoPanel'
import $ from 'thm/thm-jquery'
import ChildStache from 'thm/ui/common/alertDefinition/AlertDefinitionSensorWinchMonitoringInfoPanelBehaviorTpl.stache'
import Child2Stache from 'thm/ui/common/alertDefinition/AlertDefinitionSensorWinchMonitoringInfoPanelTpl.stache'
import _ from 'core-cmp/lodash'

/**
 * @class AlertDefinitionSensorWinchMonitoringInfoPanel
 * Alert Definition "Sensor Winch Monitoring" Form.
 */
let AlertDefinitionSensorWinchMonitoringInfoPanel =
  AlertDefinitionInfoPanel.extend({
    id: 'alertDefinitionSensorWinchMonitoringInfoPanel',
    childTpl: ChildStache,
    child2Tpl: Child2Stache,
    timingAllowed: true,

    bindEvents: function () {
      let me = this

      me._super()
      THM_PREF.bind('unitChanged', function (ev, newVal, oldVal) {
        me.updateViewModel()
      })
    },

    updateViewModel: function () {
      let me = this,
        sensorOperator = [
          { value: 'lt', text: '<' },
          { value: 'gt', text: '>' },
        ]

      me.viewModel.attr('sensorOperator', sensorOperator)

      me._super()

      var speedDefined =
        !_.isUndefined(me.viewModel.attr('dataModel.min')) &&
        !_.isUndefined(me.viewModel.attr('dataModel.max'))
      me.viewModel.attr('dataModel.speedActivated', speedDefined)

      me.viewModel.attr(
        'dataModel.historyActivated',
        !!me.viewModel.attr('dataModel.historyTime'),
      )

      me.hasChanges = false

      var sensorTab = me.viewModel.attr('dataModel.sensor')
      if (!!sensorTab) {
        let starboardSensor = _.find(
            me.sensorTypes,
            (st) => st.name == 'Starboard',
          ),
          portSensor = _.find(me.sensorTypes, (st) => st.name == 'Port'),
          sensorItem = _.find(sensorTab, (v) => v.type == starboardSensor.code)
        me.viewModel.attr('dataModel.starboardWinchActivated', !!sensorItem)
        if (sensorItem) {
          me.viewModel.attr(
            'dataModel.starboardOperator',
            sensorItem.attr('operator'),
          )
          me.viewModel.attr(
            'dataModel.starboardValue',
            sensorItem.attr('value'),
          )
        }
        sensorItem = _.find(sensorTab, (v) => v.type == portSensor.code)
        me.viewModel.attr('dataModel.portWinchActivated', !!sensorItem)
        if (sensorItem) {
          me.viewModel.attr(
            'dataModel.portOperator',
            sensorItem.attr('operator'),
          )
          me.viewModel.attr('dataModel.portValue', sensorItem.attr('value'))
        }
      }

      me.activeSpeed()
      me.activeHistory()
      // me.changeSensorType();

      me.updateOriginalValue()
    },

    bindViewModel: function () {
      var me = this

      me.sensorTypes = []
      if (DATA_AUTHORIZED('sensorMessage')) {
        var types = APP('sensorTypes').filter((item) =>
          item.name.match('winch*'),
        )
        _.each(types, (v) =>
          me.sensorTypes.push({ name: v.name.split(' ')[0], code: v.id }),
        )
      }
      me.viewModel
        .attr('dataModel')
        .on('speedActivated', me.proxy(me.activeSpeed))
      me.activeSpeed()
      me.viewModel
        .attr('dataModel')
        .on('historyActivated', me.proxy(me.activeHistory))
      me.activeHistory()
      me.viewModel.attr('dataModel').on('starboardWinchActivated', function () {
        me.activeSensor('Starboard')
      })
      me.viewModel.attr('dataModel').on('portWinchActivated', function () {
        me.activeSensor('Port')
      })

      me._super()
    },

    activeSpeed: function () {
      var me = this,
        speedActivated = me.viewModel.attr('dataModel.speedActivated')
      me.viewModel.attr('activatedSpeed', !!speedActivated)
    },

    activeHistory: function () {
      var me = this,
        historyActivated = me.viewModel.attr('dataModel.historyActivated')
      me.viewModel.attr('activatedHistory', !!historyActivated)
    },

    activeSensor: function (winchType) {
      var me = this,
        sensorActivated = me.viewModel.attr(
          'dataModel.' + winchType.toLowerCase() + 'WinchActivated',
        )
      me.viewModel.attr('activated' + winchType + 'Winch', !!sensorActivated)
    },

    onSave: function (forceClose) {
      var me = this,
        dataModel = me.getDataModel()

      // Sensor
      var sensors = []
      _.each(me.sensorTypes, function (sensorType) {
        let name = sensorType.name.toLowerCase()
        if ($.isTrue(dataModel[name + 'WinchActivated'])) {
          let operator = me.viewModel.attr('dataModel.' + name + 'Operator')
          let value = me.viewModel.attr('dataModel.' + name + 'Value')
          if (!operator || !value) {
            Toastr.showToastr('error', 'componentHasErrors')
            return
          } else {
            sensors.push({
              type: sensorType.code,
              operator: operator,
              value: value,
            })
          }
        }
      })

      if (sensors.length == 0) {
        Toastr.showToastr('error', 'noSensor')
        return
      } else {
        me.viewModel.attr('dataModel.sensor', sensors)
      }

      // Speed
      if ($.isTrue(me.viewModel.attr('activatedSpeed'))) {
        if (!dataModel.min && !dataModel.max) {
          Toastr.showToastr('error', 'componentHasErrors')
          return
        }

        if (dataModel.min && dataModel.max) {
          if (dataModel.min > dataModel.max) {
            Toastr.showToastr('error', 'minCantBeGreater')
            return
          }
        }
      } else {
        me.viewModel.attr('dataModel.min', '')
        me.viewModel.attr('dataModel.max', '')
      }

      // History
      if ($.isTrue(me.viewModel.attr('activatedHistory'))) {
        if (!dataModel.historyTime) {
          Toastr.showToastr('error', 'componentHasErrors')
          return
        }
      } else {
        me.viewModel.attr('dataModel.historyTime', '')
      }

      me._super(forceClose)
    } /*,

   getFocus: function(name) {
   var me = this;

   setTimeout(function(){
   APP.getById('sensorTypes', sensorType).type
   }, 3000);
   }*/,
  })
export default AlertDefinitionSensorWinchMonitoringInfoPanel
