import { APP } from 'core-uda/model/Resource'
import { PREF } from 'core-cmp/Preferences'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import InfoPanel from 'core-uda/ui/InfoPanel'
import FmcInfoPanelTplStache from 'thm/ui/common/fmc/FmcInfoPanelTpl.stache'
import Customer from 'core-uda/model/customer/Customer'
import ZoneTplStache from 'thm/ui/common/zone/ZoneUITpl.stache'
import { RESOURCE_CLASS } from 'core-uda/model/Resource'
import CustomerTplStache from 'core-uda/ui/customer/CustomerUITpl.stache'

/**
 * @class thm.ui.common.fmc.FmcInfoPanel
 * @parent thm.ui
 * @constructor
 * Afficher les infos d'un FMC.
 * @param {Object} options
 */
let FmcInfoPanel = InfoPanel.extend({
  template: FmcInfoPanelTplStache,
  i18nPrefix: ['uda.fmc.', 'thm.ui.common.fmc.FmcInfoPanel.'],
  id: 'fmcInfoPanel',
  childTpl: null,
  entityId: 'fmc',
  allowWidth: true,
  allowClone: true,
  titleKey: 'title',

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this

    dataModel.attr({
      name: me.msg('Clone') + ' - ' + dataModel.name,
    })
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()

    me.viewModel.attr('zoneOptions', [])
    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.customerTpl = CustomerTplStache
    me.updateFieldOptionsFromResource('customerOptions', Customer)
    me.viewModel.on('dataModel.customerId', me.proxy(me.onCustomerChange))
    me.viewModel.attr('zoneTpl', ZoneTplStache)
  },

  onCustomerChange: function () {
    let me = this,
      customerId = me.viewModel.attr('dataModel.customerId')

    APP()
      .whenData('zones')
      .done(() => {
        me.viewModel.attr(
          'zoneOptions',
          APP('zones').filterByKind(['official'], customerId),
        )
      })
  },
})

export default FmcInfoPanel
