import { APP } from 'core-uda/model/Resource'
import { FEATURE_TYPE } from 'core-gis/FeatureConstants'
import { MAIN_PAGE } from 'core-uda/ui/UdaMainPage'
import MobilePositionHandler from 'core-mobile/service/info/MobilePositionHandler'
import _ from 'core-cmp/lodash'
import { MOBILE_DATA } from 'core-uda/model/mobile/MobileData'

/**
 * Mobiles positions handler.
 */
let ThmMobilePositionHandler = MobilePositionHandler.extend(
  {
    ID: 'mobilePosition',

    I18N_ALERT_KIND: true,

    /**
     * Type traités par le handler
     */
    TYPES: [
      FEATURE_TYPE.MOBILE_CLUSTER_POINT,
      FEATURE_TYPE.MOBILE_TRAJECTORY_POINT,
      FEATURE_TYPE.MOBILE_ESTIMATION_POINT,
    ],

    RESOURCES: ['position'],
  },
  {
    /**
     * @override
     */
    canLoadMobile: function (mobileId, mobile) {
      return !_.isNil(mobileId) && (!mobile || mobile.isVirtual)
    },

    /**
     * @override
     */
    selectItem: function (options) {
      let me = this,
        gridPanel = MAIN_PAGE().gridPanel,
        isGridOpened = gridPanel.isVisible(),
        activeChild = gridPanel.getActiveChild()

      if (isGridOpened && activeChild && activeChild.id === 'sensorTimeline') {
        APP().trigger('sensorTimelineActivated', [
          {
            mobileId: me.model.trajectory.id,
          },
        ])
        options.info = false
      }

      me._super(options)
    },

    /**
     * @override
     */
    getInfoPanelModel: function (options = {}) {
      let me = this,
        parentResult = me._super(options)

      if (parentResult.type == 'mobile' && parentResult.data.kind == 'animal') {
        parentResult.type = 'animal'
      }
      return parentResult
    },

    /**
     * @override
     */
    getTitle: function (index) {
      let me = this,
        position = me.getPosition(index),
        mobileTemplateFields = MOBILE_DATA.getMobileTemplateFields(),
        historicKey = 'mobile' + _.upperFirst(mobileTemplateFields[0])

      if (
        RIGHTS('data.positionsCriteria.historic') &&
        PREF().historicVesselDetails
      ) {
        historicKey =
          historicKey === 'mobileRef' ? 'mobileReference' : historicKey
        if (position[historicKey] && position[historicKey].length) {
          return position[historicKey]
        } else {
          return '—'
        }
      }

      return MOBILE_DATA.getMobileLabelForData(position)
    },

    /**
     * @override
     */
    getSubTitle: function (index) {
      let me = this,
        position = me.getPosition(index),
        mobile = me.getPosition(index).mobile,
        mobileTemplateFields = MOBILE_DATA.getMobileTemplateFields(),
        historicKey1 = 'mobile' + _.upperFirst(mobileTemplateFields[1]),
        historicKey2 = 'mobile' + _.upperFirst(mobileTemplateFields[2]),
        historicValue1 = '—',
        historicValue2 = '—'

      historicKey1 =
        historicKey1 === 'mobileRef' ? 'mobileReference' : historicKey1
      historicKey2 =
        historicKey2 === 'mobileRef' ? 'mobileReference' : historicKey2

      if (
        RIGHTS('data.positionsCriteria.historic') &&
        PREF().historicVesselDetails
      ) {
        if (position[historicKey1] && position[historicKey1].length) {
          historicValue1 = position[historicKey1]
        }
        if (position[historicKey2] && position[historicKey2].length) {
          historicValue2 = position[historicKey2]
        }
        return historicValue1 + '/' + historicValue2
      }
      return MOBILE_DATA.getMobileAdditionalLabels(mobile)
    },
  },
)

export default ThmMobilePositionHandler
