import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import CommandLog from 'core-uda/model/command/CommandLog'
import { DATA_AUTHORIZED } from 'core-uda/Rights'

import SelectCriteria from 'core-criteria/select/SelectCriteria'

import { I18N } from 'core-cmp/util/I18n'
import CheckboxesCriteria from 'core-criteria/listCheckbox/CheckboxesCriteria'
import DateFormat from 'core-cmp/format/DateFormat'
import ThmSelectByCriteria from 'thm/ui/common/criteria/ThmSelectByCriteria'
import BeaconType from 'core-uda/model/beacon/BeaconType'

/**
 * User Interface elements for ErsCommandLog
 */
let CommandLogUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: CommandLog.itemId,
      i18nPrefix: 'thm.ui.common.command.CommandLogUI.',
      icon: 'command',
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    return {
      value: {
        format: (value) => {
          if (value !== null && typeof value !== 'undefined') {
            // we have a fake json which JSON.parse cannot parse at all !!!
            // so we have to replace all ' by " to make a real JSON string in an ugly way !!!
            let cleanValue = JSON.parse(value.replaceAll("'", '"'))
            // display dates using the preference format
            return (
              I18N.msg('thm.ui.common.command.CommandLogUI.requestText') +
              ' ' +
              I18N.msg('thm.ui.common.command.CommandLogUI.fromText') +
              ' : ' +
              PREF().format('dateTime', new Date(cleanValue.from)) +
              ' ' +
              I18N.msg('thm.ui.common.command.CommandLogUI.toText') +
              ' : ' +
              PREF().format('dateTime', new Date(cleanValue.to)) +
              '. ' +
              I18N.msg('thm.ui.common.command.CommandLogUI.viaText') +
              ' : ' +
              cleanValue.channel
            )
          }
          return value
        },
        dataType: 'string',
      },
    }
  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      kind: {
        cmpClass: CheckboxesCriteria,
        resourceName: 'commandLog',
        options: BeaconType.getReadableCommands(),
      },
      status: {
        cmpClass: SelectCriteria,
        labelAlign: 'top',
      },
    }
  },
})

const ERS_COMMAND_LOG_UI = new CommandLogUI()

export default CommandLogUI
