import _ from 'core-cmp/lodash'
import InfoPanel from 'core-uda/ui/InfoPanel'
import { APP } from 'core-uda/model/Resource'
import CpcProtocolInfoPanelTplStache from 'thm/ui/common/cpcProtocol/CpcProtocolInfoPanelTpl.stache'

/**
 * @class CpcProtocolInfoPanel
 * Afficher les infos d'un cpcProtocol
 */
let CpcProtocolInfoPanel = InfoPanel.extend({
  template: CpcProtocolInfoPanelTplStache,
  i18nPrefix: [
    'uda.cpcProtocol.',
    'thm.ui.common.cpcProtocol.CpcProtocolInfoPanel.',
  ],
  id: 'cpcProtocolInfoPanel',
  entityId: 'cpcProtocol',

  /**
   * @override
   */
  initViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('isFTP', false)
    me.viewModel.attr('isHTTP', false)
    me.viewModel.attr('isSMTP', false)
    me.viewModel.attr('isFLUX', false)
    me.viewModel.attr('ackTypeOptions', [
      {
        value: 'HTTP',
        text: 'HTTP',
      },
      {
        value: 'Trackwell',
        text: 'TrackWell',
      },
    ])
  },

  /**
   * @override
   */
  updateViewModel: function () {
    let me = this
    me._super()
    me.viewModel.attr('isFTP', me.data.kind === 'ftp')
    me.viewModel.attr('isHTTP', me.data.kind === 'http')
    me.viewModel.attr('isSMTP', me.data.kind === 'smtp')
    me.viewModel.attr('isFLUX', me.data.kind === 'flux')
  },
})

export default CpcProtocolInfoPanel
