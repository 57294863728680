import UreMainPage from 'core-ure/ui/UreMainPage'
import { PAGE_ANIMATION } from 'thm/ui/main/ThmRouter'

let ThmUreMain = UreMainPage.extend({
  id: 'thm-ureMain',
  gridMode: 'URE',
  i18nPrefix: ['ure.ui.GridMainPage.', 'thm.ui.ure.ThmUreMain.'],
  compliantResourcesFromMenuConfig: true,

  /**
   * @override
   */
  init: function (config) {
    let me = this

    me.animation = PAGE_ANIMATION
    me.menuOptionsStyle = 'ui-menu-item'
    me.menuIconAlign = 'left'
    me.menuTitleVisible = false
    me.menuOptionsAlign = 'left'
    me.menuAnimateStyle = 'airbnb'
    me.menuConfig = [
      {
        label: me.msg('menu.vesselBeacon'),
        items: [
          'mobile',
          'commandLog',
          'position',
          'historicalPosition',
          'mobileLifeEvent',
          'missingLocation',
          'locationStatData',
          'cpcPostData',
          'vesselLog',
          'statusMessage',
          'mergeMobile',
          'trip',
          'fishingTrip',
          'illegalVessel',
          'inspectionReport',
          'exceptionReport',
          'acquisitionException',
          'gfcmInspectionReport',
          'beaconLog',
        ],
      },
      {
        label: me.msg('menu.alert'),
        items: ['alert', 'ersAlertReport'],
      },
      {
        label: me.msg('menu.system'),
        items: [
          'connection',
          'userAction',
          'auditLog',
          'ersData',
          'reportLog',
          'reportSchedule',
          'ssasAuditLog',
        ],
      },
      {
        label: me.msg('menu.statistics'),
        items: ['statistic', 'ersStatistic'],
      },
      {
        label: me.msg('menu.fishing'),
        items: [
          'ersTrip',
          'fishingTime',
          'ersActivity',
          'ersQuery',
          'catchDeclaration',
          'ersMissingDeclarationReport',
          'ersOperationReport',
        ],
      },
      {
        label: me.msg('menu.fishingMonitoring'),
        items: ['ersFishingQuotasReport', 'ersAuditReport'],
      },
    ]

    me._super(config)
  },
})

export default ThmUreMain
