import _ from 'core-cmp/lodash'

const EDITORS = {
  boolean: {
    isSwitch: true,
  },
  text: {
    isText: true,
    format: '',
  },
  date: {
    isDate: true,
    format: 'dateTime',
  },
  integer: {
    isText: true,
    format: 'pnum',
  },
  list: {
    isCheckboxes: true,
  },
  enum: {
    isSelect: true,
  },
  textList: {
    isTextarea: true,
  },
  textArea: {
    isTextarea: true,
  },
}

const CUSTOM_DEFINITION = {
  // For WMS Layers : select a sublist of layer from the layer list defined at center level
  map_metoc_filter: (parameter) => {
    const { definition } = parameter
    const centerMetocFilter = APP().rights.center?.map?.metoc?.filter

    // If no filter defined at center level, don't show this parameter
    if (!centerMetocFilter?.trim()) {
      delete parameter.definition
      return
    }

    parameter.value = (parameter.value || '')
      .split(',')
      .map((option) => option.trim())
      .join(',')

    // If filter defined at center level, initialzed the list at customer level with this list
    // editable using checkboxes
    definition.type = 'list'
    definition.values = centerMetocFilter
      .split(',')
      .map((option) => ({ text: option.trim(), value: option.trim() }))
  },
}

/**
 * Utility class to construct parameters centers datas before giving them to the stache template
 * Chapters part are not managed
 * Used in CustomerInfoPanel for example
 */
const PARAMETERS_CONSTRUCT_DATAS = {
  options: [],

  updateParametersDatas: function (parameters) {
    let me = this,
      dataToParam = {},
      groups = [],
      groupMap = {},
      group,
      prefix,
      name,
      chapters = [],
      field

    me.options = []
    _.each(parameters, function (p) {
      name = p.name.replace(/\./g, '_')

      dataToParam[name] = p

      // Apply custom configuration if needed
      CUSTOM_DEFINITION[name]?.(p)

      if (!!p.definition) {
        // si les groupes, composant ou order ne sont pas définis pour ce paramêtre,
        // on les ajoute au groupe "unknown" classé en tête de liste.
        if (!p.definition.component) {
          p.definition.component = '000 Unknown'
        }
        if (!p.definition.group) {
          p.definition.group = '000 To be processed'
        }
        if (!p.definition.label) {
          p.definition.label = '??? ' + name
        }
        if (!p.definition.order) {
          p.definition.order = 0
        }
        prefix =
          p.definition.component.substring(0, 3) +
          '-' +
          p.definition.group.substring(0, 3)

        group = groupMap[prefix]

        if (!group) {
          group = {
            title: p.definition.group.substring(4),
            chapter: p.definition.component.substring(4),
            order: prefix,
            fields: [],
            expanded: false,
          }
          groups.push(group)
          groupMap[prefix] = group
        }

        field = _.extend(
          {
            name: name,
            fieldLabel: p.definition.label,
            placeholder: p.definition.placeholder || '',
            tooltip: p.definition.tooltip || '',
            options: me.getOptions(p),
            col: p.definition.col || 2,
            order: p.definition.order,
            parentCondition: true,
          },
          EDITORS[p.definition.type],
        )

        if (p.definition.format) {
          field.format = p.definition.format
        }
        if (p.definition.col) {
          field.col = p.definition.col
        }
        /*if (p.definition.parent) {
          let parentName  = p.definition.parent,
            parent      = _.find(APP('parameters').datas, {name: parentName});
          // Only For Boolean Parents !!
          if (parent && parent.definition.type === "boolean") {
            field.parentCondition = parent.value === "true" ? true : false;
            field.parent = parent;
          }
        }*/
        group.fields.push(field)
      }
    })

    // trier les groupes sur leur poids:
    groups = _.sortBy(groups, function (group) {
      return group.order
    })

    // Puis pour chaque groupe, trier les champs par poids:
    _.each(groups, function (group) {
      group.fields = _.sortBy(group.fields, function (field) {
        return parseInt(field.order)
      })
    })

    return {
      groups: groups,
      dataToParam: dataToParam,
      options: me.options,
    }
  },

  getOptions: function (p) {
    let me = this,
      key = (p.name + 'Options').replace(/\./g, '_'),
      options = p.definition.values

    if (!options) {
      return null
    }
    me.options.push({ key: key, options: options })
    return key
  },
}

// For debug on browser mode
if (typeof window !== 'undefined') {
  window.PARAMETERS_CONSTRUCT_DATAS = PARAMETERS_CONSTRUCT_DATAS
}

export { PARAMETERS_CONSTRUCT_DATAS }
export default PARAMETERS_CONSTRUCT_DATAS
