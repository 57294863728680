import _ from 'lodash'
import { APP } from 'core-uda/model/Resource'
import template from 'thm/ui/common/beaconType/CommandReportingInterval.stache'
import CommandHandler from 'core-mobile/ui/command/CommandHandler'

const intervalFieldNames = ['interval']
const minToSecFieldNames = ['accelerationPeriod', 'trackingFrequencyRate']

/**
 * Handler for Pirat Free Form Command.
 * @class CommandReportingInterval
 */
let CommandReportingInterval = CommandHandler.extend({
  id: 'reportingInterval',
  i18nPrefix: 'thm.ui.common.beaconModel.CommandReportingInterval.',
  template: template,
  hasProgDuration: false,
  duration: 10,
  intervalOptions: [],
  interval: '',

  /**
   * @override
   * @param mobiles
   */
  onMobileListChange: function (mobiles) {
    const me = this,
      satPro = APP('satProviders')
        ? APP('satProviders').find({ name: 'COSAM' })
        : undefined

    if (satPro) {
      let beacons = _.reduce(
          mobiles,
          (list, mobile) => {
            if (!_.isNil(mobile.activeBeaconId)) {
              list.push(APP('beacons', mobile.activeBeaconId))
            }
            return list
          },
          [],
        ),
        cosamBeacons = beacons.filter(
          (beacon) => beacon.satelliteProviderId === satPro.id,
        )

      // Si il n'y a que des COSAM alors on affiche le champ "duration"
      me.attr(
        'hasProgDuration',
        !!cosamBeacons.length && cosamBeacons.length === beacons.length,
      )
    } else {
      me.attr('hasProgDuration', false)
    }
  },

  /**
   * @override
   */
  onCommandChange: function (commandStatus) {
    const me = this

    if (commandStatus.name === me.id && commandStatus.values) {
      let options = commandStatus.values.split(',')
      me.attr('intervalOptions', options)
      me.attr('interval', options[0])
    }
  },

  /**
   * @override
   */
  onSubmit: function (params) {
    const me = this
    if (me.hasProgDuration) {
      params.value = me.duration * 60
    }

    if (/\d*m/.test(me.interval)) {
      params.interval = parseInt(me.interval)
    } else if (/\d*h/.test(me.interval)) {
      params.interval = parseInt(me.interval) * 60
    } else if (/\d*d/.test(me.interval)) {
      params.interval = parseInt(me.interval) * 60 * 24
    }
  },
})

CommandHandler.register(CommandReportingInterval)

export default CommandReportingInterval
