import ResourceUI, { CELL_TPL_CLICKABLE_URL } from 'core-uda/ui/ResourceUI'
import GfcmInspectionReport from 'core-uda/model/gfcmInspectionReport/GfcmInspectionReport'

/**
 * User Interface elements for GfcmInspectionReport
 */
let GfcmInspectionReportUI = ResourceUI.extend({
  i18nPrefix: '',

  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: GfcmInspectionReport.itemId,
      i18nPrefix: 'thm.ui.common.gfcmInspectionReport.GfcmInspectionReportUI.',
      icon: 'annotation',
    })
  },

  /**
   * @override
   */
  onGridRowPrepared: function (dxEvent) {
    let data = dxEvent.data,
      $el = dxEvent.rowElement

    $el.on('click', () => {
      let tabLink = window.document.createElement('a')
      tabLink.href = data.sharepointURL
      tabLink.target = '_blank'
      document.body.appendChild(tabLink)
      tabLink.click()
      document.body.removeChild(tabLink)
    })
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    let me = this
    return {
      inspectors: {
        format: (values) => {
          if (values && values.length) {
            return values.join('<br/>')
          }
          return values
        },
        dataType: 'string',
        encodeHtml: false,
      },
      violations: {
        format: (values) => {
          if (values && values.length) {
            return values.join('<br/>')
          }
          return values
        },
        dataType: 'string',
        encodeHtml: false,
      },
      sharepointURL: {
        dataType: 'string',
        cellTemplate: function (container, options) {
          CELL_TPL_CLICKABLE_URL(
            container,
            options.value,
            me.msg('sharepointURLText'),
          )
        },
      },
    }
  },
})

const GFCM_INSPECTION_REPORT_UI = new GfcmInspectionReportUI()

export default GfcmInspectionReportUI
