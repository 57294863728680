import { APP } from 'core-uda/model/Resource'

/**
 * Constantes UMV
 */
const ThmConstants = {}

const REQUESTS = {
  NEWREQUEST: 'NEWREQUEST',
  ONEPOSITION: 'ONEPOSITION',
  REFRESH: 'REFRESH',
  UPDATE: 'UPDATE',
}

let VERSION = '???-SNAPSHOT'

const isSnapshot = System.isEnv('snapshot')
const isLocalSnapshot = System.isEnv('localSnapshot')

if (
  (System.isEnv('production') || isSnapshot || isLocalSnapshot) &&
  _.isString(System.version)
) {
  VERSION = System.version

  if (isSnapshot || isLocalSnapshot) {
    VERSION += '-SNASPHOT'
  }
}

ThmConstants.REQUESTS = REQUESTS
ThmConstants.VERSION = VERSION

export { REQUESTS }
export { VERSION }

export default ThmConstants
