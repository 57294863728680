import { APP } from 'core-uda/model/Resource'
import { PREF } from 'core-cmp/Preferences'
import { RIGHTS, DATA_AUTHORIZED } from 'core-uda/Rights'
import InfoPanel from 'core-uda/ui/InfoPanel'
import GenericMobileInfoPanelTplStache from 'thm/ui/common/mobile/GenericMobileInfoPanelTpl.stache'
import Mobile from 'core-uda/model/mobile/Mobile'
import Customer from 'core-uda/model/customer/Customer'
import MobileType from 'core-uda/model/mobile/MobileType'
import BeaconTplStache from 'core-mobile/ui/beacon/BeaconTpl.stache'
import ImageTabMobileTplStache from 'thm/ui/common/mobile/ImageTabMobileTpl.stache'
import EventsTabMobileTplStache from 'thm/ui/common/mobile/EventsTabMobileTpl.stache'
import EventTplStache from 'thm/ui/common/mobile/EventTpl.stache'
import EditEventPopup from 'thm/ui/common/mobile/EditEventPopup'
import _ from 'core-cmp/lodash'
import MobileImage from 'core-uda/model/mobile/MobileImage'
import 'thm/ui/common/mobile/EventImageMobile.css'
import { CUSTOMER_CONST } from 'core-uda/model/customer/Customer'

/**
 * Generic mobile form
 * @class GenericMobileInfoPanel
 */
let GenericMobileInfoPanel = InfoPanel.extend({
  template: GenericMobileInfoPanelTplStache,
  i18nPrefix: [
    'thm.ui.common.MobileInfoPanel.',
    'thm.ui.common.mobile.GenericMobileInfoPanel.',
    'uda.mobile.',
    'uda.genericMobile.',
  ],
  id: 'genericMobileTypeInfoPanel',
  childTpl: null,
  entityId: 'mobile',
  allowWidth: true,
  allowClone: true,

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this

    dataModel.attr({
      name: me.msg('Clone') + ' - ' + dataModel.attr('name'),
      beacons: '',
    })
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
      reader = new FileReader()

    reader.onload = function (e) {
      me.setImgSrc(e.target.result)
    }

    me._super()

    me.viewModel.attr('showClient', RIGHTS('showClient'))
    me.viewModel.attr('tabs', 'identification')
    me.viewModel.attr('tabsOptions', me.loadTabsOptions())
    me.viewModel.attr('broadcastModeOptions', CUSTOMER_CONST.contactBroadcast)
    me.fieldSelectOptionFromResource(
      'customer',
      'customerOptions',
      Customer,
      'id',
      'company',
    )
    me.fieldSelectOptionFromResource(
      'mobileType',
      'mobileTypeOptions',
      MobileType,
      'id',
      'type',
    )
    me.viewModel.attr('beaconDatas', APP('beacon').getData())
    me.viewModel.attr('beaconTpl', BeaconTplStache)
    me.viewModel.attr('eventTpl', EventTplStache)
    me.viewModel.attr('eventEditPage', EditEventPopup.getId())
    me.viewModel.attr('beaconEditParams', {
      hasListHeader: false,
      title: me.msg('beacon'),
    })
    me.viewModel.attr('eventEditParams', {
      hasListHeader: false,
      title: me.msg('event'),
    })
    me.viewModel.attr('ImageTabTpl', ImageTabMobileTplStache)
    me.viewModel.attr('EventsTabTpl', EventsTabMobileTplStache)
    me.viewModel.attr('image', null)
    me.viewModel.attr('imgChanged', false)
    me.viewModel.on('image', function (evt, fileList) {
      reader.readAsDataURL(fileList[0])
      this.attr('imgChanged', true)
    })

    me.showImage()
  },

  updateViewModel: function () {
    let me = this

    me._super()
    me.showImage()
  },

  setImgSrc: function (result) {
    let me = this

    me.viewModel.attr('imgSrc', result)
  },

  showImage: function () {
    let me = this

    if (me.viewModel.attr('dataModel.imageId')) {
      MobileImage.findOne(me.viewModel.attr('dataModel.imageId')).done(
        (image) => {
          me.setImgSrc(image)
        },
      )
    } else {
      me.setImgSrc(null)
    }
  },

  loadTabsOptions: function () {
    let me = this
    return [
      {
        value: 'identification',
        text: me.msg('identificationOption'),
        icon: 'info',
      },
      { value: 'image', text: me.msg('imageOption'), icon: 'import' },
      { value: 'events', text: me.msg('eventsOption'), icon: 'mobileEvent' },
    ]
  },

  getTitle: function () {
    let me = this
    return me.msg('title')
  },

  onDeleteImage: function () {
    let me = this

    me.viewModel.attr('imgSrc', '')
    me.viewModel.attr('imgChanged', true)
  },

  checkChanges: function () {
    let me = this,
      changes = me._super()

    return changes || me.viewModel.attr('imgChanged')
  },

  getMobile: function (id) {
    let me = this,
      deferred = new $.Deferred()

    if (APP().hasAllMobiles) {
      Mobile.findOne(id)
        .done((mobile) => deferred.resolve(mobile))
        .fail((err) => deferred.reject(err))
    } else {
      deferred.resolve(APP('mobiles', id))
    }

    return deferred
  },

  /**
   * Sauvegarde
   */
  save: function (forceClose) {
    let me = this,
      data = me.getDataModel(),
      mobile = new Mobile(data),
      mobileImage

    me.getMobile(me.viewModel.attr('dataModel.id'))
      .done((mobileFromApp) => {
        if (
          me.viewModel.attr('dataModel.imageId') &&
          me.viewModel.attr('imgSrc') != ''
        ) {
          // update
          mobileImage = new MobileImage({
            id: me.viewModel.attr('dataModel.imageId'),
            image: me.viewModel.attr('imgSrc'),
          })
        } else if (!me.viewModel.attr('dataModel.imageId')) {
          // create
          mobileImage = new MobileImage({ image: me.viewModel.attr('imgSrc') })
        } else {
          // delete
          mobileImage = new MobileImage({
            id: me.viewModel.attr('dataModel.imageId'),
          })
        }

        if (me.viewModel.attr('imgChanged')) {
          if (
            me.viewModel.attr('imgSrc') == '' &&
            me.viewModel.attr('dataModel.imageId')
          ) {
            mobileImage.destroy().done(function () {
              mobileFromApp.image = null
              mobile.attr('imageId', '')
              me.saveMobile(mobile, forceClose, null)
            })
          } else {
            mobileImage.save().done(function (image) {
              mobileFromApp.image = image.image
              me.saveMobile(mobile, forceClose, image)
            })
          }
        } else {
          me.saveMobile(mobile, forceClose, null)
        }
      })
      .fail((err) => console.error(err))
  },

  saveMobile: function (mobile, forceClose, image) {
    let me = this,
      newImage = me.viewModel.attr('dataModel.imageId') ? false : true

    if (!_.isNil(image) && newImage) {
      mobile.attr('imageId', image.id)
    }

    me.listenStatus(mobile.save(), 'saveStatus').done(function (newMobile) {
      let updatedItem = APP(me.entityId).createOrReplaceById(newMobile)
      me.onAfterSave(forceClose, updatedItem)
    })
  },
})

export default GenericMobileInfoPanel
