import NafTemplateModel from 'thm/model/nafTemplate/NafTemplateModel'
import UserObject from 'core-uda/model/userObject/UserObject'

/**
 * Modèle de NafTemplate.
 */
let NafTemplate = UserObject.extend(
  {
    modelClazz: NafTemplateModel,
  },
  {
    family: 15,
    type: 'nafTemplate',

    saveTemplate: function (json) {
      let me = this,
        value = {
          data: json,
        }
      me.attr('value', value)
    },
  },
)

export default NafTemplate
