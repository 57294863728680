import { canDefineMap } from 'core-cmp/can'
import ResourceUI from 'core-uda/ui/ResourceUI'
import SpeciesCat from 'core-uda/model/species/SpeciesCat'
import template from 'thm/ui/common/speciesCat/SpeciesCatTpl.stache'
import { PREF } from 'core-cmp/Preferences'

import { I18N } from 'core-cmp/util/I18n'

/**
 * User Interface elements for SpeciesCat
 */
let SpeciesCatUI = ResourceUI.extend({
  /**
   * @override
   */
  init: function () {
    let me = this

    me._super({
      resourceId: SpeciesCat.itemId,
      i18nPrefix: 'thm.ui.common.speciesCat.SpeciesCatUI.',
      icon: 'fishLabel',
      labelField: 'code',
      searchFields: ['code'],
      template: template,
      isListEditable: true,
    })
  },

  getColumnOptions: function () {
    return {
      i18n: {
        format: function (value) {
          return value && value[PREF('locale')] ? value[PREF('locale')] : ''
        },
        dataType: 'string',
      },
    }
  },
})

const SPECIES_CAT_UI = new SpeciesCatUI()

export default SpeciesCatUI
